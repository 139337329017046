import * as React from 'react';
import { Stat } from '../../models/Statistics';
import { StatValue } from '../StatValue';

interface Props extends Stat {
  loading: boolean;
  showZeroValue?: boolean;
  hasError: boolean;
}

export const StatItem = (props: Props) => {
  let valueComponent;

  if (props.loading) {
    valueComponent = (
      <div className="stats-item">
        <div className="stat">Loading...</div>
      </div>
    );
  } else if (props.showZeroValue) {
    valueComponent = <StatValue {...{ ...props, value: 0 }} />;
  } else if (props.value === undefined || props.hasError) {
    valueComponent = (
      <div className="stats-item">
        <div className="stat">No Data</div>
      </div>
    );
  } else {
    valueComponent = <StatValue {...props} />;
  }
  return <div className="dashboard-indicators__item">{valueComponent}</div>;
};
