export enum LoadingStatus {
  PENDING,
  LOADING,
  INITIAL_LOADING,
  NEXT_PAGE_LOADING,
  SUCCESS,
  ERROR,
}

export type ChangingStatus = 'loading' | 'success' | 'fail' | null;
