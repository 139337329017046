import moment from 'moment';
import { FilterState, Freeze } from '../store/filter/reducers';
import { Option, StickedOption, OptionID } from './Option';
import { CampaignStatusType } from './Campaign';
import { GraphsNames } from './GraphsNames';
import { MetricOption, DimensionOption } from './Report';

export const getAdditionalDashboardFields = () => ({
  lastModified: moment().unix(),
  // Prevent app crash when running previous release's (2.3.0) code
  sortingGroupsColumns: [],
  filteredGroupsIds: [],
});

export interface Dashboard {
  value: number;
  label: string;
  id: string;
  data: DashboardData;
}

export interface DashboardData {
  timezone: OptionID | null;
  tableLevel: Option;
  search: string;
  status: Option<CampaignStatusType>;
  [GraphsNames.graph1]: Option;
  [GraphsNames.graph2]: Option;
  sortingColumns: StickedOption[];
  sortingWorkspacesColumns?: StickedOption[];
  sortingAdvertisersColumns?: StickedOption[];
  sortingExchangesColumns?: StickedOption[];
  sortingInsertionOrdersColumns?: StickedOption[];
  freeze: Freeze;
  // This key [selectedCampaignTypes] has been renamed to selectedCreativeTypes
  selectedCampaignTypes?: Option[];
  selectedCreativeTypes: Option[];
  dimension: Option | DimensionOption | null;
  metric: Option | MetricOption | null;
  filteredCampaignsIds: number[];
  lastModified?: number;
  selectedCampaigns?: number[];
  selectedInsertionOrders?: number[];
  ioBudgetTypes?: number[];
  campaignTypeIds?: number[];
  advertisersOwIds?: string;
  workspacesOwIds?: string;
  isAllAdvertisersSelected?: boolean | null;
  selectedCampaignTypeIds?: number[];
  sidebarOpened?: boolean;
}

interface DashboardDTO extends Omit<FilterState, 'advertisersOwIds' | 'workspacesOwIds'> {
  dimension: Option | DimensionOption | null;
  metric: Option | MetricOption | null;
  filteredCampaignsIds: number[];
  advertisersOwIds?: string;
  workspacesOwIds?: string;
  sidebarOpened: boolean;
}

export function convertFilterToDashboardData(filter: DashboardDTO): DashboardData {
  const {
    timezone,
    tableLevel,
    search,
    status,
    sortingColumns,
    sortingWorkspacesColumns,
    sortingAdvertisersColumns,
    sortingExchangesColumns,
    sortingInsertionOrdersColumns,
    freeze,
    selectedCreativeTypes,
    dimension,
    metric,
    filteredCampaignsIds,
    selectedCampaigns,
    selectedInsertionOrders,
    ioBudgetTypes,
    campaignTypeIds,
    advertisersOwIds,
    workspacesOwIds,
    isAllAdvertisersSelected,
    sidebarOpened,
  } = filter;

  return {
    timezone,
    selectedCreativeTypes,
    tableLevel,
    search,
    status,
    sortingColumns,
    sortingWorkspacesColumns,
    sortingAdvertisersColumns,
    sortingExchangesColumns,
    sortingInsertionOrdersColumns: sortingInsertionOrdersColumns,
    freeze,
    [GraphsNames.graph1]: filter[GraphsNames.graph1],
    [GraphsNames.graph2]: filter[GraphsNames.graph2],
    dimension,
    metric,
    filteredCampaignsIds: filteredCampaignsIds || ([] as number[]),
    selectedCampaigns: selectedCampaigns || ([] as number[]),
    selectedInsertionOrders: selectedInsertionOrders || ([] as number[]),
    ioBudgetTypes: ioBudgetTypes || ([] as number[]),
    campaignTypeIds: campaignTypeIds || ([] as number[]),
    advertisersOwIds,
    workspacesOwIds,
    isAllAdvertisersSelected,
    sidebarOpened,
  };
}
