import React from 'react';
import moment from 'moment';
import { Tooltip } from 'factor';
import { IO_BUDGET_TYPE_ID } from 'iqm-framework';
import { EM_DASH } from '../../../../../../../constants/text';
import { LambdaResponse } from '../../../../../../../models/Response';
import { CurrencyFormat } from '../../../../../../../utils/format';
import { pluralize } from '../../../../../../../utils/pluralize';
import { getPacingClassName, compactCurrencyFormatter } from '../utils';
import styles from './styles.module.scss';

interface Props {
  pacingData: Pick<
    LambdaResponse,
    | 'actualSpent'
    | 'budgetTotal'
    | 'targetImpression'
    | 'pacingPercentage'
    | 'expectedSpent'
    | 'budgetTypeId'
  > & {
    startTime?: string | number | null;
    endTime?: string | number | null;
    totalDuration?: number | null;
    remainingDuration?: number | null;
    defaultStartTimeString?: string;
    timezone?: string;
  };
  budgetCellType: 'daily' | 'total';
  children: React.ReactNode;
}

const PercentFormat = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const compactNumberFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  // @ts-ignore
  notation: 'compact',
});

const formatBudgetNumber = (value: any, budgetTypeId: number) => {
  if (typeof value !== 'number') {
    return EM_DASH;
  }

  return budgetTypeId === IO_BUDGET_TYPE_ID.IMPRESSIONS_BASED
    ? `Imps ${compactNumberFormatter.format(value)}`
    : value < 1000
    ? CurrencyFormat.format(value)
    : `${compactCurrencyFormatter.format(value)}`;
};

export const PacingInfoTooltip = (props: Props) => {
  const { children, pacingData, budgetCellType } = props;

  const getTooltipLabel = () => {
    const {
      actualSpent,
      budgetTotal,
      targetImpression,
      pacingPercentage,
      expectedSpent,
      totalDuration,
      remainingDuration,
      startTime,
      endTime,
      budgetTypeId = IO_BUDGET_TYPE_ID.DOLLAR_BASED,
      defaultStartTimeString,
      timezone,
    } = pacingData || {};

    let timeSpent: number | string = defaultStartTimeString || EM_DASH;

    if (typeof totalDuration === 'number' && typeof remainingDuration === 'number') {
      timeSpent = totalDuration - remainingDuration;
    } else if (budgetCellType === 'total' && !endTime && startTime) {
      const currentDate = moment().valueOf();
      const current = moment(currentDate)
        .tz(timezone || 'UTC')
        .startOf('D')
        .valueOf();
      const start = moment(Number(startTime) * 1000)
        .tz(timezone || 'UTC')
        .startOf('D')
        .valueOf();
      timeSpent = moment(current).diff(start, 'days');
    }

    const colorClass =
      typeof actualSpent === 'number' && typeof expectedSpent === 'number' && endTime
        ? getPacingClassName((actualSpent / (expectedSpent || 1)) * 100).text
        : '';
    const isDollarBased = budgetTypeId === IO_BUDGET_TYPE_ID.DOLLAR_BASED;

    return (
      <div className={styles.contents}>
        <div className={styles.row}>
          {budgetCellType === 'daily' ? 'Daily' : 'Actual'}{' '}
          {isDollarBased ? 'budget spent' : 'impressions served'}:{' '}
          <span className={`${colorClass} ${styles.demi}`}>
            {formatBudgetNumber(actualSpent, budgetTypeId)} /{' '}
            {formatBudgetNumber(isDollarBased ? budgetTotal : targetImpression, budgetTypeId)}
          </span>
        </div>
        <div className={styles.row}>
          {budgetCellType === 'daily' ? 'Daily' : 'Actual'}{' '}
          {isDollarBased ? 'budget spent %' : 'impressions served %'}:{' '}
          <span className={`${colorClass} ${styles.demi}`}>
            {typeof pacingPercentage === 'number'
              ? PercentFormat.format(pacingPercentage / 100)
              : EM_DASH}
          </span>
        </div>
        <div className={styles.row}>
          Expected {isDollarBased ? 'budget spent' : 'impressions served'}:{' '}
          <span className={styles.demi}>
            {budgetTypeId === IO_BUDGET_TYPE_ID.IMPRESSIONS_BASED &&
            !endTime &&
            budgetCellType === 'total'
              ? EM_DASH
              : formatBudgetNumber(expectedSpent, budgetTypeId)}
          </span>
        </div>
        <div className={styles.row}>
          Time spent:{' '}
          <span className={styles.demi}>
            {`${timeSpent} ${
              timeSpent === EM_DASH
                ? ''
                : pluralize(
                    budgetCellType === 'daily' ? 'hr' : 'day',
                    Number.parseInt(`${timeSpent}`),
                  )
            }`}{' '}
            /{' '}
            {(budgetCellType !== 'total' || endTime) && typeof totalDuration === 'number'
              ? `${totalDuration} ${pluralize(
                  budgetCellType === 'daily' ? 'hr' : 'day',
                  totalDuration,
                )}`
              : EM_DASH}
          </span>
        </div>
      </div>
    );
  };

  return (
    <Tooltip className={styles.tooltip} label={getTooltipLabel()} portal auto>
      {children}
    </Tooltip>
  );
};
