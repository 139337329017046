import { AppState } from '../store';

export const hasNoCustomers = (state: AppState) => {
  const {
    isCustomersDropdownEmpty,
    isCustomersDropdownError,
    isCustomersDropdownLoaded,
    isAllAdvertisersSelected,
  } = state.filter;
  const { isWorkspaceOwnerOrg, isPlatformOwnerOrg } = state.auth.userData;
  return (
    isCustomersDropdownEmpty &&
    !isCustomersDropdownError &&
    isCustomersDropdownLoaded &&
    !isAllAdvertisersSelected &&
    (isPlatformOwnerOrg || isWorkspaceOwnerOrg)
  );
};
