import { getInstance } from './index';
import axios, { Canceler } from 'axios';

import { WithResponse } from '../models/Response';

let audiencesCanceler: Canceler[] = [] as Canceler[];

export function cancelAllGetAudiences() {
  if (audiencesCanceler) {
    audiencesCanceler.forEach((canceler) => canceler('Canceled by the user'));
    audiencesCanceler = [] as Canceler[];
  }
}

export async function getAudiences(campaignIds: string): Promise<WithResponse<any>> {
  try {
    const response: any = await getInstance().get(
      `/v3/cmp/audience/target/list?campaignIds=${campaignIds}`,
      {
        cancelToken: new axios.CancelToken((c) => {
          audiencesCanceler.push(c);
        }),
      },
    );

    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
}
