import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { AppState } from '../../../../store';
import { GraphsNames } from '../../../../models/GraphsNames';
import { PairedGraphModel } from '../../../../models/Graph';
import { BarWithLineChart } from './components/BarWithLineChart';
import { Option } from '../../../../models/Option';
import { SkeletonChart } from './components/SkeletonChart';
import { useGraphData } from '../../../../hooks/useGraphData';

interface Props {
  [GraphsNames.graph1]: Option;
  [GraphsNames.graph2]: Option;
}

export const GraphsComponent = (props: Props) => {
  const { graph1, graph2 } = props;

  const graphDataQuery = useGraphData();

  const d1Key = graph1.value;
  const d2Key = graph2.value;

  const data: PairedGraphModel[] =
    graphDataQuery?.data?.map((data, i) => {
      const start = moment(
        typeof data.start_date === 'string' ? +data.start_date : data.start_date,
      ).valueOf();
      const end = moment(
        typeof data.end_date === 'string' ? +data.end_date : data.end_date,
      ).valueOf();

      return {
        start,
        end,
        graph1: +data[d1Key] || 0,
        graph2: +data[d2Key] || 0,
        xAxiesLabel: i === graphDataQuery.data.length - 1 ? end : start,
      };
    }) || [];

  if (graphDataQuery.isLoading) {
    return <SkeletonChart />;
  }

  return <BarWithLineChart data={data} graph1={graph1} graph2={graph2} />;
};

// TODO: add memoize
const mapState = (state: AppState) => {
  return {
    [GraphsNames.graph1]: state.filter[GraphsNames.graph1],
    [GraphsNames.graph2]: state.filter[GraphsNames.graph2],
  };
};
export const Graphs = connect(mapState)(GraphsComponent);
