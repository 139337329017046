import React from 'react';
import { Icon } from 'factor';
import { IOStatusMapper } from 'iqm-framework';
import styles from './styles.module.scss';

interface Props {
  ioStatusId: number;
}

export const InsertionOrderStatusCell = (props: Props) => {
  const { ioStatusId } = props;
  const { icon, label } = IOStatusMapper[ioStatusId] || {};

  return (
    <div className={styles.wrapper}>
      {icon && (
        <Icon
          name={icon}
          className={`${styles.icon} ${icon === 'StatusReadyColor' ? styles.icon14 : ''}`}
        />
      )}
      {label || '—'}
    </div>
  );
};
