import React from 'react';

import './style.scss';

export const NoDataChart = (props: { message?: string | null }) => {
  return (
    <div className="sidebar-charts__error-wrapper">
      <div className="sidebar-charts__error-message">{props.message || 'No Data'}</div>
    </div>
  );
};
