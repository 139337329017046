import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
  TableComponent as KTable,
  TableBaseRoot,
  getHeaders,
  IO_BUDGET_TYPE_ID,
  getPathNumber,
  getAccountType,
} from 'iqm-framework';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import camelCase from 'lodash/camelCase';
import moment from 'moment';

import { LoadingStatus } from '../../../../../../models/LoadingStatus';
import { LambdaResponse } from '../../../../../../models/Response';
import { CampaignType, statusIconMap } from '../../../../../../models/Campaign';
import { TableSortingParams } from '../../../../../../models/Table';
import { flattenOptions, getColumns } from '../../../../../../models/Option';
import { IQM_API_URL_V3 } from '../../../../../../config';
import { AppState } from '../../../../../../store';
import { User } from '../../../../../../models/User';
import { CreativeTypeIconMapper } from '../../../../../../constants/creatives';
import { IconTextCell } from '../../cellTypes/IconTextCell';
import { TFilters } from '../types';
import { BudgetCell } from './cellTypes/BudgetCell';
import { CurrencyFormat } from '../../../../../../utils/format';
import { CampaignPacingCell } from '../../cellTypes/BudgetPacingCell/CampaignPacingCell';
import { EM_DASH } from '../../../../../../constants/text';
import { DailyPacingCell } from '../../cellTypes/BudgetPacingCell/DailyPacingCell';
import {
  CampaignPacingHeaderTooltip,
  DailyPacingHeaderTooltip,
} from '../../cellTypes/BudgetPacingCell/HeaderTooltip';
import { DateRange } from '../../../../../../store/filter/reducers';
import { CAMPAIGN_BUILDER_URL } from '../../../../../../constants/url';
import styles from './styles.module.scss';

interface StateProps {
  userData: User;
  dateRange: DateRange | null;
}

interface OwnProps {
  insertionOrder: LambdaResponse;
  filters: TFilters;
  setLoading: (ls: LoadingStatus) => void;
}

interface Props extends OwnProps, StateProps {}

const DEFAULT_SORTING = { direction: 'desc' as 'desc', field: 'campaignId' } as TableSortingParams;

const COL_SORTING = [
  'campaignId',
  'campaignName',
  'status',
  'creativesCount',
  'budgetDay',
  'budgetTotal',
  'maxBid',
  'pacingPercentage',
  'dailyPacingPercentage',
  'startTime',
  'endTime',
  'spent',
  'platformMediaEarning',
  'platformBidShadingEarnings',
  'bidShadingSaving',
  'workspaceEarning',
  'workspaceBidShadingEarnings',
  'workspaceTotalEarning',
  'percentageOfTotalSpent',
  'targetImpression',
  'impressions',
  'clicks',
  'reach',
  'CTR',
  'winRate',
  'VCR',
  'frequency',
  'dataCost',
  'mediaSpent',
  'timezone',
  'eCPM',
  'eCPC',
  'eCPCV',
  'startCount',
  'firstCount',
  'midCount',
  'thirdCount',
  'completeCount',
  'audioVideoViewed',
  'totalAttributedConversion',
  'totalAttributedViewThroughConversion',
  'totalAttributedClickThroughConversion',
  'costPerAttributedConversion',
  'totalAttributedConversionRate',
];

let TableComponentInstance;

export const TableComponent = (props: Props) => {
  const { insertionOrder, userData, filters, setLoading, dateRange } = props;
  const { ioBudgetTypeId } = insertionOrder;
  const [sorting, setSorting] = useState<TableSortingParams>(DEFAULT_SORTING);

  const tableConfig = useMemo(() => {
    return {
      header: {
        campaignId: {
          label: 'ID',
          className: 'w-80-80',
          sortingKey: 'campaignId',
        },
        campaignName: {
          label: 'Campaign Name',
          sortingKey: 'campaignName',
          className: 'w-200-450',
        },
        status: {
          label: 'Status',
          sortingKey: 'status',
          className: 'w-100-200',
        },
        creativesCount: {
          label: 'Creatives',
          sortingKey: 'creativesCount',
          className: 'w-120-120',
        },
        budgetDay: {
          label: 'Daily Budget',
          sortingKey: 'budgetDay',
          className: 'w-100-200',
        },
        budgetTotal: {
          label: 'Total Budget',
          sortingKey: 'budgetTotal',
          className: 'w-100-200',
        },
        maxBid: {
          label: 'Max Bid Price',
          sortingKey: 'maxBid',
          className: 'w-100-200',
        },
        pacingPercentage: {
          sortingKey: 'pacingPercentage',
          label: (
            <div className="d-flex align-items-center">
              Campaign Pacing
              <CampaignPacingHeaderTooltip />
            </div>
          ),
          className: 'w-150-300',
        },
        dailyPacingPercentage: {
          sortingKey: 'dailyPacingPercentage',
          label: (
            <div className="d-flex align-items-center">
              Daily Pacing
              <DailyPacingHeaderTooltip />
            </div>
          ),
          className: 'w-150-300',
        },
        timezone: {
          sortingKey: 'campaignTimezone',
          label: 'Time Zone',
          className: 'w-80-120',
        },
        workspaceBidShadingEarnings: {
          sortingKey: 'workspaceBidShadingEarnings',
          label: 'Workspace Bid Shading Earnings',
          className: 'w-150-650',
        },
      },
      body: {
        campaignId: {
          key: (data) => (data.rowClassName === 'tr-unsorted' ? '' : data.campaignId),
          className: 'w-80-80',
        },
        campaignName: {
          key: (data) => {
            const creativeType = get(data, 'creativeType', '');
            const iconName = CreativeTypeIconMapper[camelCase(creativeType || '')] || '';
            const status = (data.status || '').toLowerCase();
            const isAdvancedCampaign = data.campaignTypeId === CampaignType.ADVANCED || 1;

            const textNode =
              userData.isPlatformOwnerOrg ||
              userData.isWorkspaceOwnerOrg ||
              status === 'expired' ||
              status === 'deleted' ? (
                data.campaignName || ''
              ) : (
                <a
                  href={`${CAMPAIGN_BUILDER_URL}/u/${getPathNumber()}/#/${
                    isAdvancedCampaign ? 'campaign-edit' : 'campaign-edit-pg'
                  }/${data.campaignId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.campaignNameLink}
                >
                  {data.campaignName}
                </a>
              );
            return <IconTextCell iconName={iconName} text={textNode} />;
          },
          className: 'w-200-450',
        },
        status: {
          key: (data) => {
            const { status } = data;
            if (!status) {
              return '—';
            }
            const iconName = statusIconMap[status.toLowerCase()] || '';

            return (
              <IconTextCell
                iconName={iconName}
                iconClassName={styles.statusIcon}
                text={capitalize(status)}
              />
            );
          },
          className: 'w-100-200',
        },
        creativesCount: {
          key: (data) => (data.rowClassName === 'tr-unsorted' ? '—' : data.creativesCount),
          className: 'w-120-120 _right',
        },
        budgetDay: {
          key: (data) => (
            <BudgetCell
              budgetTypeId={ioBudgetTypeId || 0}
              value={
                ioBudgetTypeId === IO_BUDGET_TYPE_ID.DOLLAR_BASED
                  ? data.budgetDay
                  : data.dailyImpression
              }
            />
          ),
          className: `w-100-200 _right`,
        },
        budgetTotal: {
          key: (data) => (
            <BudgetCell
              budgetTypeId={ioBudgetTypeId || 0}
              value={
                ioBudgetTypeId === IO_BUDGET_TYPE_ID.DOLLAR_BASED
                  ? data.budgetTotal
                  : data.targetImpression
              }
            />
          ),
          className: `w-100-200 _right`,
        },
        maxBid: {
          key: (data) =>
            typeof data.maxBid === 'number' && data.rowClassName !== 'tr-unsorted'
              ? CurrencyFormat.format(data.maxBid)
              : '—',
          className: 'w-100-200 _right',
        },
        pacingPercentage: {
          key: (data) =>
            data.rowClassName !== 'tr-unsorted' ? (
              <CampaignPacingCell campaign={data} />
            ) : (
              <div className={styles.emDash}>{EM_DASH}</div>
            ),
          className: `${styles.tdPacingPercentage} w-150-300 _right`,
        },
        dailyPacingPercentage: {
          key: (data) => {
            if (data.rowClassName === 'tr-unsorted') {
              return <div className={styles.emDash}>{EM_DASH}</div>;
            }

            return <DailyPacingCell campaign={data} />;
          },
          className: `${styles.tdPacingPercentage} w-150-300 _right`,
        },
        timezone: {
          key: (data) => data.campaignTimezone || '—',
          className: 'w-100-160',
        },
      },
    };
  }, [ioBudgetTypeId, userData.isPlatformOwnerOrg, userData.isWorkspaceOwnerOrg]);

  const columns = useMemo(() => {
    const configCols = Object.keys(tableConfig.header).map((item) => ({
      value: item,
      alwaysEnabled: true,
    }));

    const isAdvertiserOnly = getAccountType() === 'Advertiser';

    const campaignColumns = flattenOptions(
      getColumns({
        isBetaUser: userData.isBetaUser,
        isPlatformOwnerOrg: userData.isPlatformOwnerOrg,
        isWorkspaceOwnerOrg: userData.isWorkspaceOwnerOrg,
        isBidShadingEnabled: isAdvertiserOnly ? userData.isBidShadingEnabled : true,
        isVldEnabled: userData.isVldEnabled,
        hadVldGenerated: userData.hadVldGenerated,
      }),
    )
      .filter((col) => col.value !== 'organizationName')
      .filter((col) => !configCols.find((configCol) => configCol.value === col.value));
    const unsortedCols = [...configCols, ...campaignColumns];
    return COL_SORTING.map((col) =>
      unsortedCols.find((campaignCol) => campaignCol.value === col),
    ).filter((col) => col);
  }, [tableConfig, userData]);

  const dataRequest = useMemo(() => {
    const headers = getHeaders(userData) as { [key: string]: string };
    const { isPlatformOwnerOrg, isWorkspaceOwnerOrg } = userData || {};
    const request = {
      url: `${IQM_API_URL_V3}/das/report/by-campaign`,
      method: 'post' as 'post',
      data: {
        ioIdsList: [insertionOrder.ioId],
        sortBy: get(sorting, 'field'),
        order: get(sorting, 'direction', '').toUpperCase() || undefined,
        searchField: filters.searchField,
        campaignStatus: get(filters, 'status'),
        creativeTypeIds: get(filters, 'creativeTypes.length')
          ? filters.creativeTypes.map((ct) => ct.value).join(',')
          : undefined,
        timezoneId: insertionOrder.ioTimezone,
        startDate: dateRange ? `${moment(dateRange.start).valueOf()}` : moment().valueOf(),
        endDate: dateRange ? `${moment(dateRange.end).valueOf()}` : moment().valueOf(),
        owIds:
          (isPlatformOwnerOrg || isWorkspaceOwnerOrg) && insertionOrder.owId
            ? `${insertionOrder.owId}`
            : undefined,
      },
      params: {},
      headers,
    };
    return request;
  }, [userData, filters, insertionOrder, sorting, dateRange]);

  useEffect(() => {
    if (TableComponentInstance) {
      TableComponentInstance.cancelDataRequest();
      TableComponentInstance.setState(
        {
          loadingData: false,
          data: [],
        },
        () => {
          TableComponentInstance.getNewData();
        },
      );
    }
  }, [filters]);

  const transformRequestParams = useCallback((apiParams, tableState) => {
    const params = { ...apiParams };
    params.pageNo = params.pgno;
    params.noOfEntries = params.no_of_entries;

    delete params.sort_by;
    delete params.sort_type;
    delete params.draw;
    delete params.pgno;
    delete params.no_of_entries;

    const sorting = get(tableState, 'sorting');

    return {
      ...params,
      sortBy: sorting ? sorting.field : undefined,
      order: sorting ? sorting.direction.toUpperCase() : undefined,
    };
  }, []);

  const updateSorting = useCallback(
    ({ sorting: sortingParams }: { sorting: TableSortingParams }) => {
      setSorting(sortingParams);
    },
    [],
  );

  const onFetchDataError = useCallback(() => {
    setLoading(LoadingStatus.ERROR);
  }, [setLoading]);

  const onDataLoading = useCallback(() => {
    setLoading(LoadingStatus.LOADING);
  }, [setLoading]);

  const setRef = useCallback((ref) => {
    TableComponentInstance = ref;
  }, []);

  return (
    <TableBaseRoot
      selectedColumns={columns as any[]}
      allColumns={columns as any[]}
      onColumnsChanged={() => {
        /* noop */
      }}
      dataRequest={dataRequest}
    >
      <KTable
        headerMapping={tableConfig.header}
        bodyMapping={tableConfig.body}
        offsetTop={0}
        checkbox={false}
        checkboxInHeader={false}
        emptyTableLabel="No Campaigns Found"
        dataPath="data.recordsList"
        totalPath="data.recordsTotal"
        countPath="data.filteredRecords"
        idField="id"
        skeleton={
          {
            columns: Object.values(tableConfig.header).length,
            rows: 6,
          } as any
        }
        tableParams={{
          tbodyRowHeight: 46,
          theadRowHeight: 40,
          preventNavigationByScroll: true,
          fixedXScroller: true,
          rowKeyExtractor: (row) => row.id,
          onChange: updateSorting,
          sorting,
          tableMaxHeight: '400px',
          className: styles.table,
        }}
        transformRequestParams={transformRequestParams}
        defaultSorting={sorting}
        onFetchDataError={onFetchDataError}
        onDataLoading={onDataLoading}
        innerRef={setRef}
      />
    </TableBaseRoot>
  );
};

const mapState = (state: AppState) => ({
  userData: state.auth.userData,
  dateRange: state.filter.dateRange,
});

export const Table = connect<any, any, any, any>(mapState)(TableComponent);
