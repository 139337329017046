import React from 'react';
import { createPortal } from 'react-dom';
import { Icon, Dialog, DialogHeader, DialogContent } from 'factor';
import { LoadingStatus } from '../../../../../models/LoadingStatus';
import { ErrorLabel } from './ErrorLabel';
import styles from './styles.module.scss';

interface Props {
  onClose: () => void;
  title: string;
  subtitle: string;
  titleIcon: string;
  loading: LoadingStatus;
  children: React.ReactNode;
}

export const TableDialogWrapper = (props: Props) => {
  const { onClose, title, subtitle, titleIcon, loading, children } = props;

  return createPortal(
    <Dialog open onClickOutside={onClose} className={styles.dialog} portal>
      <DialogHeader onClickClose={onClose} headerFooterBorders>
        <h3 className={`title-card ${styles.dialogHeader}`}>
          {!!titleIcon && <Icon name={titleIcon} className={`mr-2 ${styles.headerIcon}`} />}
          <div className={styles.headerTitle}>{title}</div>
          &nbsp;
          <div className={styles.headerSubtitle}>{subtitle}</div>
        </h3>
      </DialogHeader>
      <DialogContent>
        <div
          className={`${styles.dialogBody} ${
            loading === LoadingStatus.ERROR ? styles.withError : ''
          }`}
        >
          <div
            className={`${styles.elevation} ${styles.tableWrapper} ${
              loading === LoadingStatus.ERROR ? 'd-none' : ''
            }`}
          >
            {children}
          </div>
          {loading === LoadingStatus.ERROR ? <ErrorLabel /> : null}
        </div>
      </DialogContent>
    </Dialog>,
    document.body,
  );
};
