import React, { useState } from 'react';
import { Header, getPathNumber } from 'iqm-framework';
import { Button } from 'factor';
import { trackIntercomEvent } from '../utils/trackIntercomEvent';

import { Balance } from './navigationBar/balance/Balance';
import { currentUserType, UserType } from '../utils/userType';
import { DataDogLogger } from '../services/DataDog';
import { CreateCampaignDialog } from './CreateCampaignDialog';

import './navigationBar/style.scss';

export const NavigationBar = () => {
  const [isCreateCampaignDialogOpen, setIsCreateCampaignDialogOpen] = useState(false);

  //TODO: add clear auth state
  const handleLogout = () => {};

  const onCreateCampaignClick = () => {
    DataDogLogger.Campaigns.clickOnNewCampaign();
    setIsCreateCampaignDialogOpen(true);
  };

  const onCreateIOClick = () => {
    window.open(encodeURI(`/campaigns/u/${getPathNumber()}/#/io-list?createIO=true`), '_blank');
  };

  //@ts-ignore
  Object.defineProperty(window, 'Intercom', {
    set: function(Intercom) {
      if (typeof Intercom === 'function') {
        this._Intercom = Intercom;
        trackIntercomEvent();
      }
    },
    get: function() {
      return this._Intercom;
    },
    configurable: true,
  });

  return (
    <>
      <Header leftLogo={'Dashboard'} onLogout={handleLogout} title="Dashboard">
        {currentUserType === UserType.ADVERTISER && (
          <>
            <Button className="btn-square _conflower-blue _md mr-3" onClick={onCreateIOClick}>
              <span className="btn-square__prefix">+</span>
              New IO
            </Button>
            <Button
              className="btn-square _conflower-blue _filled _md"
              onClick={onCreateCampaignClick}
            >
              <span className="btn-square__prefix">+</span>
              New Campaign
            </Button>
          </>
        )}
        <Balance />
      </Header>
      {isCreateCampaignDialogOpen && (
        <CreateCampaignDialog onClose={() => setIsCreateCampaignDialogOpen(false)} />
      )}
    </>
  );
};
