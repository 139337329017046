import { getInstance } from './index';

export function getCreativeTypes(): Promise<any> {
  return getInstance()
    .get('/v2/master/static/creativeTypes')
    .then((response) => response.data.responseObject)
    .catch((err) => {
      console.log('err', err);
      return Promise.reject(err);
    });
}
