import pacingStyles from './styles.module.scss';

export const getPacingClassName = (pacingPercent: number | null) => {
  if (typeof pacingPercent !== 'number' || Number.isNaN(pacingPercent)) {
    return { background: pacingStyles.pacingUnknown, text: pacingStyles.pacingUnknownText };
  } else if (pacingPercent >= 120) {
    return { background: pacingStyles.pacingPurple, text: pacingStyles.pacingPurpleText };
  } else if (pacingPercent >= 90) {
    return { background: pacingStyles.pacingGreen, text: pacingStyles.pacingGreenText };
  } else if (pacingPercent >= 60) {
    return { background: pacingStyles.pacingYellow, text: pacingStyles.pacingYellowText };
  }
  return { background: pacingStyles.pacingRed, text: pacingStyles.pacingRedText };
};

export const PercentFormat = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const compactCurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  // @ts-ignore
  notation: 'compact',
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});
