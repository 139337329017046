import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import axios from 'axios';
import { Select } from 'factor';
import { connect } from 'react-redux';
import { isEqual, debounce, get } from 'lodash';

import { LoadingStatus } from '../../../../../models/LoadingStatus';
import { AppState } from '../../../../../store';
import { API } from '../../../../../api';
import {
  convertCampaignGroupDTOtoCampaignGroupWithPayloads,
  DropdownGroupsList,
} from '../../../../../models/CampaignGroup';
import { Option, OptionIDWithPayload } from '../../../../../models/Option';
import { appActions } from '../../../../../store/app/actions';
import { GroupedCampaign } from '../../../../../models/Campaign';
import { TableLevel } from '../../../../../store/filter/reducers';
import { Dashboard } from '../../../../../models/Dashboards';
import {
  filterActions,
  SetSelectedCampaigns,
  LoadCampaignOptions,
  SetCampaignOptionsLoading,
} from '../../../../../store/filter/actions';
import { SelectCampaignsWrapperOption } from '../../../../../components/SelectCampaignsWrapperOption/SelectCampaignsWrapperOption';
import { EmptySearchLabel } from './SelectCampaignsByInsertionOrderWrapper/EmptySearchLabel';
import { getInsertionOrderCampaignListParams } from '../../../../../models/InsertionOrder';
import { SelectCampaignsOptionHeader } from './SelectCampaignsByInsertionOrderWrapper/SelectCampaignsOptionHeader';
import styles from './SelectCampaignsByInsertionOrderWrapper/styles.module.scss';

interface Props extends SetSelectedCampaigns, LoadCampaignOptions, SetCampaignOptionsLoading {
  dashboard: Dashboard | null;
  creativeTypeIds: string;
  status: Option;
  owIds: string;
  dashboardResetIndicator: boolean;
  isWorkspaceOwnerOrg: boolean;
  isPlatformOwnerOrg: boolean;
  hasLoadingError: boolean;
  toggleVirtualizedListOpen: (isOpen: boolean) => void;
  isCustomersDropdownLoaded: boolean;
  tableLevel: TableLevel;
  selectedCampaigns: number[];
  campaignOptionsLoading: LoadingStatus;
  searchedListParams: ReturnType<typeof getInsertionOrderCampaignListParams>;
  campaignOptions: DropdownGroupsList[];
  selectedInsertionOrders: number[] | null;
  campaignTypeIds: number[];
  ioBudgetTypes: number[];
}

const CAMPAIGN_OPTION_HEIGHT = 50;
const GROUP_HEADER_HEIGHT = 32;

const getUngroupedListWithLabels = (
  list: OptionIDWithPayload<GroupedCampaign>[],
  isPlatformOwnerOrg: boolean,
  isWorkspaceOwnerOrg: boolean,
  searchStr?: string,
) => {
  const labeledOptions = list.map((item) => {
    const reactLabel = (
      <SelectCampaignsWrapperOption
        showOrganization={isPlatformOwnerOrg || isWorkspaceOwnerOrg}
        campaign={item.payload || {}}
        searchField={searchStr}
        className="pl-2"
      />
    );
    return { ...item, reactLabel };
  });

  return labeledOptions;
};

const getGroupedOptions = (
  list: DropdownGroupsList[],
  isPlatformOwnerOrg: boolean,
  isWorkspaceOwnerOrg: boolean,
  searchStr?: string,
): DropdownGroupsList[] => {
  return (
    list.map((group: DropdownGroupsList) => {
      const newGroup = {
        ...group,
        reactLabel: (
          <SelectCampaignsOptionHeader
            ioId={group.ioId as number}
            ioName={group.ioName as string}
            ioBudgetTypeId={group.ioBudgetTypeId as number}
          />
        ),
      };
      if (group.options) {
        newGroup.options = getUngroupedListWithLabels(
          group.options,
          isPlatformOwnerOrg,
          isWorkspaceOwnerOrg,
          searchStr,
        );
      } else {
        newGroup.options = [];
      }
      return newGroup;
    }) || []
  ).filter((opt) => opt.options && opt.options.length);
};

export const SelectCampaignsByIOWrapper = (props: Props) => {
  const {
    isCustomersDropdownLoaded,
    dashboard,
    dashboardResetIndicator,
    setSelectedCampaigns,
    loadCampaignOptions,
    selectedCampaigns,
    owIds,
    creativeTypeIds,
    status,
    campaignOptionsLoading,
    searchedListParams,
    campaignOptions,
    toggleVirtualizedListOpen,
    isPlatformOwnerOrg,
    isWorkspaceOwnerOrg,
    setCampaignOptionsLoading,
    selectedInsertionOrders,
    campaignTypeIds,
    ioBudgetTypes,
  } = props;
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [searchedList, setSearchedList] = useState<any[]>([]);
  const [searchedListLoading, setSearchedListLoading] = useState<LoadingStatus>(
    LoadingStatus.PENDING,
  );
  const prevProps = useRef<any>(props);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSearchField = useCallback(
    debounce((search: string) => {
      setDebouncedSearch(search);
    }, 500),
    [],
  );

  useEffect(() => {
    const fetchFxn = async () => {
      if (debouncedSearch) {
        setSearchedListLoading(LoadingStatus.LOADING);
        API.InsertionOrder.cancelGetIOCampaignsSearch();
        try {
          const res = await API.InsertionOrder.getIOCampaignsSearch({
            ...searchedListParams,
            searchField: debouncedSearch,
          });

          setSearchedList(
            convertCampaignGroupDTOtoCampaignGroupWithPayloads(
              get(res, 'data.ioCampaignsList', []),
            ),
          );
          setSearchedListLoading(LoadingStatus.PENDING);
        } catch (err) {
          if (!axios.isCancel(get(err, 'response.data'))) {
            setSearchedListLoading(LoadingStatus.ERROR);
          }
        }
      }
    };
    fetchFxn();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  useEffect(() => {
    if (
      (!isEqual(prevProps.current.status.value, status.value) ||
        !isEqual(prevProps.current.creativeTypeIds, creativeTypeIds) ||
        !isEqual(prevProps.current.selectedInsertionOrders, selectedInsertionOrders) ||
        !isEqual(prevProps.current.campaignTypeIds, campaignTypeIds) ||
        !isEqual(prevProps.current.ioBudgetTypes, ioBudgetTypes) ||
        !isEqual(prevProps.current.owIds, owIds)) &&
      isEqual(prevProps.current.dashboard, dashboard)
    ) {
      setDebouncedSearch('');
      loadCampaignOptions(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    status.value,
    creativeTypeIds,
    selectedInsertionOrders,
    owIds,
    campaignTypeIds,
    ioBudgetTypes,
  ]);

  useEffect(() => {
    if (
      dashboard &&
      dashboard.data &&
      (!isEqual(prevProps.current.dashboard, dashboard) ||
        (isEqual(prevProps.current.dashboard, dashboard) &&
          dashboardResetIndicator !== prevProps.current.dashboardResetIndicator))
    ) {
      setDebouncedSearch('');
      loadCampaignOptions(dashboard.data.selectedCampaigns || null, dashboard);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard, dashboardResetIndicator]);

  useEffect(() => {
    setCampaignOptionsLoading(LoadingStatus.INITIAL_LOADING);

    if (dashboard && dashboard.data) {
      loadCampaignOptions(dashboard.data.selectedCampaigns || null, dashboard);
    } else {
      loadCampaignOptions(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = useMemo(() => {
    if (debouncedSearch) {
      return getGroupedOptions(
        (searchedList || []) as any[],
        isPlatformOwnerOrg,
        isWorkspaceOwnerOrg,
        debouncedSearch,
      );
    }
    return getGroupedOptions(
      (campaignOptions || []) as any[],
      isPlatformOwnerOrg,
      isWorkspaceOwnerOrg,
      debouncedSearch,
    );
  }, [debouncedSearch, campaignOptions, searchedList, isPlatformOwnerOrg, isWorkspaceOwnerOrg]);

  const selectedCampaignOptions = useMemo(
    () =>
      campaignOptions
        .map((grouped) => grouped.options || [])
        .flat(1)
        .filter(
          (cmpOpt) => cmpOpt && cmpOpt.payload && selectedCampaigns.includes(cmpOpt.payload.id),
        ),
    [campaignOptions, selectedCampaigns],
  );

  const selectedGroups = useMemo(() => {
    if (!selectedCampaigns || !selectedCampaigns.length) {
      return [];
    }
    const selectedCampaignIds = new Set(selectedCampaigns);
    const list = debouncedSearch ? searchedList : campaignOptions;
    if (!list) {
      return [];
    }
    const selectedGroups = list.filter((group: any) =>
      group.options.every((cmp) => selectedCampaignIds.has(+cmp.id)),
    );

    return getGroupedOptions(selectedGroups, isPlatformOwnerOrg, isWorkspaceOwnerOrg);
  }, [
    selectedCampaigns,
    searchedList,
    campaignOptions,
    debouncedSearch,
    isPlatformOwnerOrg,
    isWorkspaceOwnerOrg,
  ]);

  const handleSelection = useCallback(
    (selection: any[], selectedOption: any, isClearAll?: boolean) => {
      if (isClearAll) {
        setSelectedCampaigns([]);
        return;
      }
      if (!selectedOption) {
        setSelectedCampaigns(
          selection
            .filter((opt) => !opt.options && (opt as any).payload)
            .map((opt) => (opt as any).payload.id),
        );
        return;
      }
      if (selectedOption.options) {
        // Deselecting a group
        if (selectedOption.isActive) {
          setSelectedCampaigns(
            selectedCampaigns
              .filter(
                (id) =>
                  !selectedOption.options.find(
                    (selectedCmp: any) => selectedCmp.payload && selectedCmp.payload.id === id,
                  ),
              )
              .map((opt) => opt),
          );
        } else {
          // Selecting a group
          const newlySelected = selectedOption.options
            .filter((opt: any) => !selectedCampaigns.find((id) => id === opt.payload.id))
            .map((opt: any) => opt.payload.id);
          setSelectedCampaigns(selectedCampaigns.concat(newlySelected));
        }
      } else {
        // Deselecting a single campaign option
        if (selectedOption.isActive) {
          setSelectedCampaigns(
            selectedCampaigns.filter((id) => +id !== +selectedOption.payload.id),
          );
        } else {
          // Selecting a single campaign option
          setSelectedCampaigns(selectedCampaigns.concat([selectedOption.payload.id]));
        }
      }
    },
    [setSelectedCampaigns, selectedCampaigns],
  );

  const renderNoOptionValue = useCallback(
    () => (
      <EmptySearchLabel
        isEmpty={
          !(
            searchedListLoading === LoadingStatus.ERROR ||
            campaignOptionsLoading === LoadingStatus.ERROR
          ) && !campaignOptions.length
        }
        isError={
          searchedListLoading === LoadingStatus.ERROR ||
          campaignOptionsLoading === LoadingStatus.ERROR
        }
        searchTerm={debouncedSearch}
        isLoading={
          searchedListLoading === LoadingStatus.LOADING ||
          campaignOptionsLoading === LoadingStatus.LOADING ||
          (!isCustomersDropdownLoaded && (isPlatformOwnerOrg || isWorkspaceOwnerOrg))
        }
      />
    ),
    [
      debouncedSearch,
      campaignOptionsLoading,
      searchedListLoading,
      isCustomersDropdownLoaded,
      campaignOptions,
      isWorkspaceOwnerOrg,
      isPlatformOwnerOrg,
    ],
  );

  const selectedOptions = useMemo(() => {
    return (selectedGroups as any[]).concat(selectedCampaignOptions);
  }, [selectedCampaignOptions, selectedGroups]);

  const getPlaceholder = () => () => {
    const { selectedCampaigns = [] } = props;
    return `${selectedCampaigns.length} Selected`;
  };

  useEffect(() => {
    prevProps.current = props;
  });

  return (
    <Select
      className={styles.selectWrapper}
      placeholder="Select Campaigns"
      searchPlaceholder="Search by IO Name, IO ID, Campaign ID, Name, Status"
      label="Campaigns"
      isSearchable
      isSearchClearable
      searchByValue
      isMulti
      isClearable={selectedCampaignOptions && selectedCampaignOptions.length}
      hideClearAllButton={!selectedCampaignOptions || !selectedCampaignOptions.length}
      allSelectable
      value={selectedOptions}
      options={options}
      onChange={handleSelection}
      multiPlaceholder={getPlaceholder()}
      showLabelAlways
      emptyDropdownLabel={renderNoOptionValue()}
      changeSearchHandler={setSearchField}
      virtualizationProps={{
        height: 300,
        width: 500,
        itemSize: (item: any) => (item.deep ? CAMPAIGN_OPTION_HEIGHT : GROUP_HEADER_HEIGHT),
        overscanCount: 5,
      }}
      toggleOpenProp={toggleVirtualizedListOpen}
      showControlLabel
      withCheckbox
      tooltipParams={{
        label: 'Search and filter data by the specific Campaigns',
      }}
      hideSelectAllWhenEmpty
    />
  );
};

const mapState = (state: AppState) => {
  const creativeTypeIds = (state.filter.selectedCreativeTypes || [])
    .map((cType) => cType.value)
    .sort()
    .join(',');
  const hasLoadingError = state.filter.campaignOptionsLoading === LoadingStatus.ERROR;
  const searchedListParams = getInsertionOrderCampaignListParams(state);

  return {
    creativeTypeIds,
    hasLoadingError,
    searchedListParams,
    dashboard: state.dashboards.selectedDashboard,
    status: state.filter.status,
    owIds: state.filter.advertisersOwIds,
    dashboardResetIndicator: state.dashboards.dashboardResetIndicator,
    isPlatformOwnerOrg: state.auth.userData.isPlatformOwnerOrg,
    isWorkspaceOwnerOrg: state.auth.userData.isWorkspaceOwnerOrg,
    campaignOptionsLoading: state.filter.campaignOptionsLoading,
    campaignOptions: state.filter.campaignOptions,
    selectedCampaigns: state.filter.selectedCampaigns,
    isCustomersDropdownLoaded: state.filter.isCustomersDropdownLoaded,
    tableLevel: state.filter.tableLevel ? state.filter.tableLevel.value : '',
    selectedInsertionOrders: state.filter.selectedInsertionOrders,
    campaignTypeIds: state.filter.campaignTypeIds,
    ioBudgetTypes: state.filter.ioBudgetTypes,
  };
};

const mapActions = {
  toggleVirtualizedListOpen: appActions.toggleVirtualizedListOpen,
  setSelectedCampaigns: filterActions.setSelectedCampaigns,
  loadCampaignOptions: filterActions.loadCampaignOptions,
  setCampaignOptionsLoading: filterActions.setCampaignOptionsLoading,
};

const areStatePropsEqual = (next, prev) =>
  Object.keys(next).every((key) =>
    key === 'searchedListParams' || key === 'selectedInsertionOrders'
      ? isEqual(next[key], prev[key])
      : next[key] === prev[key],
  );

export const SelectCampaignsByInsertionOrderWrapper = connect(mapState, mapActions, undefined, {
  areStatePropsEqual,
})(SelectCampaignsByIOWrapper);
