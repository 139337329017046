import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogHeader, DialogFooter, DialogContent, Spinner, Button, Icon } from 'factor';
import { IOBudgetTypeMapper } from 'iqm-framework';
import get from 'lodash/get';
import { ChangingStatus } from '../../../../../../../models/LoadingStatus';
import { API } from '../../../../../../../api';
import { toastActions, Open } from '../../../../../../../store/toast/actions';
import { GENERAL_API_ERROR } from '../../../../../../../constants/tooltips';
import styles from './styles.module.scss';

interface DispatchProps {
  openToast: Open['open'];
}

interface OwnProps {
  onClose: () => void;
  onDeleteSuccess: () => void;
  insertionOrders: { ioId: number; ioName: string; ioBudgetTypeId: number }[];
}

interface Props extends DispatchProps, OwnProps {}

export const DeleteInsertionOrderDialogComponent = (props: Props) => {
  const { onClose, insertionOrders, openToast, onDeleteSuccess } = props;
  const [loading, setLoading] = useState<ChangingStatus>(null);

  const handleDelete = useCallback(async () => {
    try {
      setLoading('loading');
      const res = await API.InsertionOrder.deleteIO({
        ioIdsList: insertionOrders.map((io) => io.ioId),
      });
      const successMessage = get(res, 'data', 'Insertion Order(s) deleted successfully');
      setLoading('success');
      openToast(successMessage);
      onDeleteSuccess();
    } catch (err) {
      setLoading('fail');
      const errorMsg = get(err, 'data.errorObjects[0].error', GENERAL_API_ERROR);
      openToast(errorMsg);
    }
  }, [insertionOrders, onDeleteSuccess, openToast]);

  const budgetIcon = get(IOBudgetTypeMapper[get(insertionOrders, '[0].ioBudgetTypeId')], 'icon');

  return (
    <Dialog open onClickOutside={onClose}>
      <DialogHeader>
        <h3 className="title-card">
          {insertionOrders.length > 1
            ? `Delete ${insertionOrders.length} Insertion Orders?`
            : 'Delete Insertion Order?'}
        </h3>
      </DialogHeader>
      <DialogContent>
        {loading ? (
          <div className="ml-auto mr-auto">
            <Spinner status={loading} onClose={onClose} />
          </div>
        ) : (
          <div className={styles.bodyText}>
            {insertionOrders.length > 1 ? (
              <span>
                Are you sure you want to delete the{' '}
                <span className={styles.bold}>{insertionOrders.length}</span> insertion orders? Once
                they are deleted, all the underlying campaigns will also be deleted.
              </span>
            ) : (
              <span>
                Are you sure you want to delete the
                {!!budgetIcon && (
                  <>
                    {' '}
                    <Icon name={budgetIcon} className={styles.budgetIcon} />
                  </>
                )}{' '}
                <span className={styles.bold}>{insertionOrders[0].ioName}</span> insertion order?
                Once it is deleted, all the underlying campaigns will also be deleted.
              </span>
            )}
          </div>
        )}
      </DialogContent>
      <DialogFooter>
        <div className="d-flex justify-content-end">
          <Button
            className="_md btn-square _conflower-blue mr-3"
            disabled={!!loading}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            className="_md btn-square _persimmon _filled"
            disabled={!!loading}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </div>
      </DialogFooter>
    </Dialog>
  );
};

const mapAction = {
  openToast: toastActions.open,
};

export const DeleteInsertionOrderDialog = connect<any, any, any, any>(
  null,
  mapAction,
)(DeleteInsertionOrderDialogComponent);
