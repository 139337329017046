import axios, { AxiosInstance } from 'axios';
import { getHeaders } from 'iqm-framework';

import { login } from './Auth';
import { getProfile, getAvatar, getAvailableBalance } from './Profile';
import { getTotalResult } from './Statistics';
import { getGraphData } from './Graphs';
import {
  campaignsCount,
  getTableData,
  getCampaignDetails,
  updateCampaign,
  updateCampaignCompletely,
  updateCampaignBudget,
  duplicateCampaigns,
  pauseCampaign,
  deleteCampaign,
  updateEndTime,
  runCampaign,
  restoreCampaign,
  cancelGetCampaignsDropdownList,
  getCampaignsDropdownList,
} from './Campaigns';
import { getCampaignsList } from './CampaignsList';
import { getCreativeTypes } from './CreativeTypes';
import { getAdvertisers, getCustomerConfig } from './Advertisers';
import { getTimezones } from './Timezone';
import { downloadCSV } from './ReportDownload';
import { getReportsData, getReportsTypes } from './Reports';
import { cancelAllGetAudiences, getAudiences } from './Audiences';
import {
  getIOCampaigns,
  cancelGetIOCampaigns,
  getIOCampaignsSearch,
  cancelGetIOCampaignsSearch,
  getInsertionOrderList,
  cancelGetInsertionOrderList,
  updateEndTime as updateIOEndTime,
  updateBudget as updateIOBudget,
  deleteIO,
  duplicateIO,
} from './InsertionOrder';

let iqmInstance: AxiosInstance;
let iqmInstanceWithTimestamps: AxiosInstance;

export interface InstanceConfig {
  baseURL: string;
  token: string;
  owId: number;
}

export function createIqmInstance(config: InstanceConfig) {
  const instance = axios.create({
    baseURL: `${config.baseURL}/api`,
    headers: getHeaders({ apiToken: config.token }),
  });

  instance.interceptors.response.use(
    (response: any) => {
      return response;
    },
    (response: any) => {
      if (axios.isCancel(response)) {
        const res = {
          response: {
            data: response,
          },
        };

        throw res;
      }
      if (response.response && response.response.status === 401) {
        console.log('[LOGOUT]');
        // AuthService.logout();
      }
      return Promise.reject(response);
    },
  );

  iqmInstance = instance;
}

export function createIqmInstanceWithTimestamps(config: InstanceConfig) {
  const instance = axios.create({
    baseURL: `${config.baseURL}/api`,
    headers: getHeaders({ apiToken: config.token }),
  });

  instance.interceptors.request.use((request: any) => {
    request.meta = { requestTimestamp: Date.now() };
    return request;
  });

  instance.interceptors.response.use(
    (response: any) => {
      return {
        ...response,
        data: {
          ...response.data,
          responseTimestamp: Date.now(),
          requestTimestamp: response.config.meta.requestTimestamp,
        },
      };
    },
    (response: any) => {
      const responseWithTimestamps = {
        ...response,
        data: {
          ...response.data,
          responseTimestamp: Date.now(),
          requestTimestamp: response.config.meta.requestTimestamp,
        },
      };

      if (axios.isCancel(response)) {
        const res = {
          response: {
            data: responseWithTimestamps,
          },
        };

        throw res;
      }
      if (response.response && response.response.status === 401) {
        console.log('[LOGOUT]');
        // AuthService.logout();
      }
      throw responseWithTimestamps;
    },
  );

  iqmInstanceWithTimestamps = instance;
}

export function getInstance(): AxiosInstance {
  return iqmInstance || axios;
}

export function getInstanceWithTimestamps(): AxiosInstance {
  return iqmInstanceWithTimestamps || axios;
}

export const API = {
  auth: {
    login,
  },
  profile: {
    getProfile,
    getAvatar,
    getAvailableBalance,
  },
  statistics: {
    getTotalResult,
  },
  graph: {
    getGraphData,
  },
  campaigns: {
    getTableData,
    getCampaignDetails,
    updateCampaign,
    updateCampaignCompletely,
    updateCampaignBudget,
    campaignsCount,
    duplicateCampaigns,
    pauseCampaign,
    deleteCampaign,
    updateEndTime,
    runCampaign,
    restoreCampaign,
    cancelGetCampaignsDropdownList,
    getCampaignsDropdownList,
  },
  campaignsList: {
    getCampaignsList,
  },
  creativeTypes: {
    getCreativeTypes,
  },
  advertisers: {
    getAdvertisers,
    getCustomerConfig,
  },
  timezone: {
    getTimezones,
  },
  report: {
    downloadCSV,
  },
  reports: {
    getReportsData,
    getReportsTypes,
  },
  audiences: {
    getAudiences,
    cancelAllGetAudiences,
  },
  InsertionOrder: {
    getIOCampaigns,
    cancelGetIOCampaigns,
    getIOCampaignsSearch,
    cancelGetIOCampaignsSearch,
    getInsertionOrderList,
    cancelGetInsertionOrderList,
    updateEndTime: updateIOEndTime,
    updateBudget: updateIOBudget,
    deleteIO,
    duplicateIO,
  },
};
