import { ORIGIN_URL } from 'iqm-framework';
import { User } from '../../models/User';
import { createAction } from '../../utils/actions';
import { createIqmInstance, createIqmInstanceWithTimestamps } from '../../api/index';
import { createLambdaInstance } from '../../api/lambdaAPI/index';
import { createLambdaV3Instance } from '../../api/lambdaV3API/index';
import { authConstants } from './constants';

export const authActions = {
  login(user: User) {
    createIqmInstance({
      baseURL: ORIGIN_URL,
      token: user.apiToken,
      owId: user.userId,
    });
    createIqmInstanceWithTimestamps({
      baseURL: ORIGIN_URL,
      token: user.apiToken,
      owId: user.userId,
    });
    createLambdaInstance({
      baseURL: ORIGIN_URL,
      token: user.apiToken,
      owId: user.userId,
    });
    createLambdaV3Instance({
      baseURL: ORIGIN_URL,
      token: user.apiToken,
      owId: user.userId,
    });
    return createAction<User>(authConstants.LOGIN_SUCCESS, user);
  },
  handleProfileLoaded(user: User) {
    return createAction<User>(authConstants.HANDLE_PROFILE_LOADED, user);
  },
};

export type Login = {
  login: (user: User) => void;
};

export type HandleProfileLoaded = {
  handleProfileLoaded: (user: User) => void;
};
