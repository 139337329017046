import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../../store';
import { OptionIDWithPayload } from '../../../../../models/Option';
import { filterActions } from '../../../../../store/filter/actions';
import { SelectInsertionOrders } from '../../../../../components/SelectInsertionOrders';

interface Props {
  setSelectedInsertionOrders: (ios: number[]) => void;
  setSelectedCampaigns: (cmps: number[]) => void;
  selectedInsertionOrders: number[] | null;
}

export let SelectInsertionOrdersWrapperRef;

const SelectInsertionOrdersWrapperComponent = (props: Props) => {
  const { setSelectedInsertionOrders, selectedInsertionOrders, setSelectedCampaigns } = props;

  const handleSelection = (
    selection: OptionIDWithPayload<number>[],
    selectedOption?: OptionIDWithPayload<number>,
  ) => {
    if (selectedOption) {
      if ((selectedOption as any).isActive) {
        setSelectedInsertionOrders(
          selectedInsertionOrders
            ? selectedInsertionOrders.filter((io) => io !== selectedOption.value)
            : [],
        );
      } else {
        setSelectedInsertionOrders(
          selectedInsertionOrders
            ? selectedInsertionOrders.concat([selectedOption.value])
            : [selectedOption.value],
        );
      }
    } else {
      setSelectedInsertionOrders(selection.map((io) => io.id));
    }
    setSelectedCampaigns([] as number[]);
  };

  const onReload = () => {
    setSelectedInsertionOrders([]);
  };

  const setRef = (ref) => {
    SelectInsertionOrdersWrapperRef = ref;
  };

  return (
    <SelectInsertionOrders
      selectedInsertionOrders={selectedInsertionOrders}
      isMulti
      isClearable
      handleSelectionMulti={handleSelection}
      onReload={onReload}
      setRef={setRef}
    />
  );
};

const mapState = (state: AppState) => ({
  selectedInsertionOrders: state.filter.selectedInsertionOrders,
});

const mapAction = {
  setSelectedInsertionOrders: filterActions.setSelectedInsertionOrders,
  setSelectedCampaigns: filterActions.setSelectedCampaigns,
};

export const SelectInsertionOrdersWrapper = connect<any, any, any, any>(
  mapState,
  mapAction,
)(SelectInsertionOrdersWrapperComponent);
