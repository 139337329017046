interface TokenResponse {
  access_token: string;
  scopes: string;
  access_token_expires_at: string;
}

interface IqmAuth {
  owId: number;
  apiToken: string;
}

const REACT_APP_GOOGLE_API_URL =
  process.env.REACT_APP_GOOGLE_API_URL || 'https://oauth.stage.iqm.com';

const popup = (url: string, target?: string) => {
  const width = Math.min(500, window.screen.width - 40);
  const height = Math.min(550, window.screen.height - 40);
  const features = [
    `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no`,
    `width=${width}`,
    `height=${height}`,
    `top=${window.screen.height / 2 - height / 2}`,
    `left=${window.screen.width / 2 - width / 2}`,
  ].join();
  const popupWindow = window.open(url, target, features);
  if (!popupWindow || popupWindow.closed || typeof popupWindow.closed === 'undefined') return null;
  popupWindow.focus();
  return popupWindow;
};

const makeHeaders = (iqmAuth: IqmAuth) => ({
  'x-iaa-ow-id': iqmAuth.owId.toString(),
  Authorization: `Bearer ${iqmAuth.apiToken}`,
});

const fetchToken = async (iqmAuth: IqmAuth) => {
  const res = await fetch(`${REACT_APP_GOOGLE_API_URL}/api/token`, {
    headers: makeHeaders(iqmAuth),
  });

  if (res.status === 404) {
    return null;
  }

  if (!res.ok) {
    throw new Error('Failed to fetch token');
  }

  return (await res.json()) as TokenResponse;
};

const getToken = async (iqmAuth: IqmAuth) => {
  const token = fetchToken(iqmAuth).catch((e) => {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  });
  return token;
};

const fetchPopupUrl = async (scopes: string[], iqmAuth: IqmAuth) => {
  const res = await fetch(`${REACT_APP_GOOGLE_API_URL}/api/auth`, {
    method: 'POST',
    body: JSON.stringify({ scope: scopes.join(' ') }),
    headers: { ...makeHeaders(iqmAuth), 'Content-Type': 'application/json' },
  });

  if (!res.ok) {
    throw new Error('Failed to submit code');
  }

  return res.text();
};

const hasAllScopes = (token: TokenResponse, scopes: [string, ...string[]]) =>
  scopes.every((scope) => token.scopes.includes(scope));

class AuthorizationError extends Error {
  type: string;

  constructor(type: string, message: string) {
    super(message);
    this.type = type;
  }
}

export const authorize = async (scopes: [string, ...string[]], iqmAuth: IqmAuth) => {
  const token = await getToken(iqmAuth);

  if (token && hasAllScopes(token, scopes)) return token;

  const authUrl = await fetchPopupUrl(scopes, iqmAuth);

  const authWindow = popup(authUrl, '_blank');

  if (!authWindow) {
    throw new AuthorizationError('popup_failed_to_open', 'Failed to open popup');
  }

  try {
    await new Promise<void>((resolve, reject) => {
      const resolvedController = new AbortController();
      const closeInterval = setInterval(() => {
        if (authWindow.closed) {
          resolvedController.abort();
          reject(new AuthorizationError('popup_closed', 'Popup closed'));
          clearInterval(closeInterval);
        }
      }, 100);
      // @ts-ignore
      window.addEventListener(
        'message',
        (msg) => {
          if (msg.data.success) {
            resolvedController.abort();
            resolve();
            return;
          }
          if (msg.data.error) {
            resolvedController.abort();
            reject(new AuthorizationError('popup_error', msg.data.error));
          }
        },
        { signal: resolvedController.signal },
      );
    });
  } finally {
    authWindow.close();
  }

  const newToken = await getToken(iqmAuth);

  if (!newToken) throw new AuthorizationError('token_fetch_failed', 'Failed to fetch token');

  return newToken;
};

interface CredentialsResponse {
  client_id: string;
  developer_key: string;
}

let credentials: Promise<CredentialsResponse> | undefined;

const fetchCredentials = async (iqmAuth: IqmAuth) => {
  const res = await fetch(`${REACT_APP_GOOGLE_API_URL}/api/credentials`, {
    headers: makeHeaders(iqmAuth),
  });

  if (!res.ok) {
    throw new Error('Failed to fetch credentials');
  }

  return res.json() as Promise<CredentialsResponse>;
};

export const getCredentials = async (iqmAuth: IqmAuth) => {
  if (!credentials) {
    credentials = fetchCredentials(iqmAuth);
  }
  const c = await credentials.catch((e) => {
    // eslint-disable-next-line no-console
    console.error(e);
    credentials = undefined;
    return null;
  });
  return c;
};
