import React from 'react';
import { Icon } from 'factor';
import styles from './styles.module.scss';

interface Props {
  iconName?: string;
  text?: React.ReactNode;
  iconClassName?: string;
  className?: string;
}

export const IconTextCell = (props: Props) => {
  const { iconName, text, iconClassName, className } = props;
  return (
    <div className={`${styles.iconTextCell} ${className || ''}`}>
      {!!iconName && (
        <Icon name={iconName} className={`mr-2 ${styles.icon} ${iconClassName || ''}`} />
      )}
      <span className={styles.overflowEllipses}>{text}</span>
    </div>
  );
};
