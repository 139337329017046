import axios, { AxiosResponse, Canceler } from 'axios';
import { QueryFunctionContext } from '@tanstack/react-query';

import { LambdaV3GraphResponse, WithResponse, ResponseV3 } from '../../models/Response';
import { getInstance } from '..';
import { CampaignStatusType } from '../../models/Campaign';
import { getGraphDataKeys } from '../QueryKeys';
import { TableLevel } from '../../models/Table';

export interface GetGraphDataParameters {
  ioIdsList?: number[] | undefined;
  campaignIds?: string | undefined;
  token?: string | undefined;
  campaignStatus?: CampaignStatusType | undefined;
  owIds?: string;
  startDate: string;
  endDate: string;
  timezoneId?: number | null;
  searchField?: string;
  creativeTypeIds?: string;
  resultType: 'data';
  isGraph: true;
}

let cancelCampaigns: Canceler;

function getCampaignGraphData(query: GetGraphDataParameters): Promise<LambdaV3GraphResponse[]> {
  if (cancelCampaigns) {
    cancelCampaigns('Canceled by the user');
  }
  return getInstance()
    .post('/v3/das/report/by-campaign', query, {
      cancelToken: new axios.CancelToken((c) => {
        cancelCampaigns = c;
      }),
    })
    .then(
      (response: AxiosResponse<ResponseV3<LambdaV3GraphResponse>>) =>
        response.data.data?.recordsList || [],
    )
    .catch((err) => Promise.reject(err));
}

let cancelAdvertisers: Canceler;

function getAdvertisersGraphData(query: GetGraphDataParameters): Promise<LambdaV3GraphResponse[]> {
  if (cancelAdvertisers) {
    cancelAdvertisers('Canceled by the user');
  }
  return getInstance()
    .post('/v3/das/report/by-customer', query, {
      cancelToken: new axios.CancelToken((c) => {
        cancelAdvertisers = c;
      }),
    })
    .then(
      (response: AxiosResponse<WithResponse<ResponseV3<LambdaV3GraphResponse>>>) =>
        response.data.data?.data?.recordsList || [],
    )
    .catch((err) => Promise.reject(err));
}

let cancelWorkspaces: Canceler;

function getWorkspacesGraphData(query: GetGraphDataParameters): Promise<LambdaV3GraphResponse[]> {
  if (cancelWorkspaces) {
    cancelWorkspaces('Canceled by the user');
  }
  return getInstance()
    .post('/v3/das/report/by-workspace', query, {
      cancelToken: new axios.CancelToken((c) => {
        cancelWorkspaces = c;
      }),
    })
    .then(
      (response: AxiosResponse<WithResponse<ResponseV3<LambdaV3GraphResponse>>>) =>
        response.data.data?.data?.recordsList || [],
    )
    .catch((err) => Promise.reject(err));
}

let cancelInsertionOrders: Canceler;

function getInsertionOrdersGraphData(
  query: GetGraphDataParameters,
): Promise<LambdaV3GraphResponse[]> {
  if (cancelInsertionOrders) {
    cancelInsertionOrders('Canceled by the user');
  }
  return getInstance()
    .post('/v3/das/report/by-io', query, {
      cancelToken: new axios.CancelToken((c) => {
        cancelInsertionOrders = c;
      }),
    })
    .then(
      (response: AxiosResponse<ResponseV3<LambdaV3GraphResponse>>) =>
        response.data.data?.recordsList || [],
    )
    .catch((err) => Promise.reject(err));
}

const graphApiFunctionMapper = {
  [TableLevel.Campaigns]: getCampaignGraphData,
  [TableLevel.InsertionOrders]: getInsertionOrdersGraphData,
  [TableLevel.Advertisers]: getAdvertisersGraphData,
  [TableLevel.Workspaces]: getWorkspacesGraphData,
  [TableLevel.Exchanges]: getCampaignGraphData,
};

export const getGraphData = async (
  queryContext: QueryFunctionContext<ReturnType<typeof getGraphDataKeys['keys']>>,
): Promise<LambdaV3GraphResponse[]> => {
  const { tableLevel, scope, ...params } = queryContext?.queryKey?.[0] || {};

  return (
    (await graphApiFunctionMapper[tableLevel]?.(params)) ||
    Promise.reject(new Error('No graph function found for this table level'))
  );
};
