import React from 'react';
import { connect } from 'react-redux';
import { Select } from 'factor';
import { AppState } from '../../../../../../../store';
import { Option } from '../../../../../../../models/Option';

interface StateProps {
  creativeTypesOptions: Option[];
}

interface OwnProps {
  creativeTypes: Option[];
  setCreativeTypes: (ct: Option[]) => void;
}

interface Props extends StateProps, OwnProps {}

const SelectCreativeTypesComponent = (props: Props) => {
  const { creativeTypesOptions, creativeTypes, setCreativeTypes } = props;

  return (
    <Select
      options={creativeTypesOptions || []}
      value={creativeTypes}
      onChange={setCreativeTypes}
      isMulti
      isClearable
      allSelectable
      placeholder="Creative Type"
    />
  );
};

const mapState = (state: AppState) => ({
  creativeTypesOptions: state.filter.creativeTypes,
});

export const SelectCreativeTypes = connect<any, any, any, any>(mapState)(
  SelectCreativeTypesComponent,
);
