export const CAMPAIGN_AUDIENCE_ALL_UNAPPROVED = `None of the attached audiences are ready in this campaign. The ad serving will start once any of them gets ready.`;

export const CAMPAIGN_AUDIENCE_SOME_UNAPPROVED = `Some of the attached audiences are not ready in this campaign. The ads will be served to the ready audiences only.`;

export const GENERAL_API_ERROR = 'Something went wrong. Please try again over a few minutes.';

export const BUDGET_INPUT_TOO_HIGH =
  'Invalid input. Please enter a value less than or equal to $10,000,000,000';

export const BUDGET_TOTAL_TOO_HIGH =
  'Invalid input. Total budget must be less than or equal to $10,000,000,000';

export const NO_CUSTOMER_ASSIGNED =
  'You do not have a customer assigned. Please contact your administrator for assistance.';

export const AUTO_SUM_IO_BUDGET_WARNING =
  "Total budget of the selected IOs can't be edited as one of the IO's total budget is fixed to the total campaign budget";

export const AUTO_SUM_IO_BUDGET_WARNING_SINGLE =
  "Total budget can't be edited as it is fixed to the total campaign budget";

export const IMPRESSION_IO_BUDGET_WARNING_SINGLE =
  'Budget is not editable for impression-based IOs';

export const IMPRESSION_CAMPAIGN_BUDGET_WARNING_SINGLE = `Budget is not editable for impression-based campaigns`;

export const PG_CAMPAIGN_TOTAL_BUDGET_WARNING = 'Total Budget is not editable for the PG Campaigns';
