import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogContent, DialogButtons, TextField } from 'factor';
import { filterActions } from '../../../store/filter/actions';
import { dashboardsActions, CreateDashboard } from '../../../store/dashboards/actions';
import { toastActions } from '../../../store/toast/actions';

interface Props extends CreateDashboard {
  open: boolean;
  onClose: () => void;
  openToast: (name: string) => void;
}

const emptyValidation = {
  func: (value: string) => {
    return !!(value && value.trim());
  },
  error: (name: string) => {
    return `${name} must be filled!`;
  },
};

const TemplateNameModalComponent = (props: Props) => {
  const { createDashboard, onClose, openToast, open } = props;
  const [name, setName] = useState<string>('');

  const handleClose = () => {
    setName('');
    onClose();
  };

  const handleSubmit = () => {
    if (name && name.trim()) {
      createDashboard(name)
        .then(() => {
          openToast(`${name} has been created!`);
        })
        .catch((e) => {
          openToast(`${name} hasn't been created!`);
        })
        .finally(() => {
          handleClose();
        });
    }
  };

  const handleValueChange = (value: string) => {
    setName(value);
  };

  const checkNameValidity = (name: string) => {
    return name.trim().length < 150;
  };

  return (
    <Dialog open={open} onClickOutside={handleClose} className="save-template">
      <DialogContent>
        <h5 className="title-card mb-3">Create Dashboard</h5>
        <div className="mb-3">
          <TextField
            name="Dashboard Name"
            label="Dashboard Name"
            validationRules={[
              emptyValidation,
              {
                func: checkNameValidity,
                error: () => '* Dashboard name should be less than 150 characters',
              },
            ]}
            value={name}
            inputAttributes={{
              autoFocus: true,
            }}
            onChange={handleValueChange}
          />
        </div>
        <DialogButtons
          buttons={[
            {
              className: '_md',
              title: 'Cancel',
              handler: handleClose,
            },
            {
              title: 'Apply',
              handler: handleSubmit,
              className: 'btn-square _conflower-blue _filled _md',
              disabled: !emptyValidation.func(name) || !checkNameValidity(name),
            },
          ]}
        />
      </DialogContent>
    </Dialog>
  );
};

const mapActionsToProp = {
  ...filterActions,
  createDashboard: dashboardsActions.createDashboard,
  openToast: toastActions.open,
};

export const TemplateNameModal = connect(null, mapActionsToProp)(TemplateNameModalComponent);
