import React, { useMemo } from 'react';
import { Option } from '../../../../../../models/Option';
import { SearchField } from '../../../../../../components/SearchField';
import { TFilters } from '../types';
import { SelectStatus } from './SelectStatus';
import { SelectCreativeTypes } from './SelectCreativeTypes';
import styles from './styles.module.scss';

interface OwnProps {
  filters: TFilters;
  setFilters: React.Dispatch<React.SetStateAction<TFilters>>;
  ioId: number;
  owId: number;
}

interface Props extends OwnProps {}

export const Filters = (props: Props) => {
  const { filters, setFilters, ioId, owId } = props;

  const { setSearch, setStatus, setCreativeTypes } = useMemo(
    () => ({
      setSearch: (searchField: string) => {
        setFilters((f: TFilters) => ({ ...f, searchField }));
      },
      setStatus: (status: Option) => {
        setFilters((f: TFilters) => ({ ...f, status: status.value }));
      },
      setCreativeTypes: (creativeTypes: Option[]) => {
        setFilters((f: TFilters) => ({ ...f, creativeTypes }));
      },
    }),
    [setFilters],
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.dropdownFilters}>
        <SelectStatus status={filters.status} setStatus={setStatus} ioId={ioId} owId={owId} />
        <SelectCreativeTypes
          creativeTypes={filters.creativeTypes}
          setCreativeTypes={setCreativeTypes}
        />
      </div>

      <SearchField
        search={filters.searchField}
        updateSearch={setSearch}
        placeholder="Search by ID, Name, Status"
      />
    </div>
  );
};
