import React from 'react';
import { Select } from 'factor';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import queryString from 'query-string';

import { Option } from '../../../../../models/Option';
import { CampaignStatusType } from '../../../../../models/Campaign';
import { AppState } from '../../../../../store';
import { filterActions } from '../../../../../store/filter/actions';

interface Props extends RouteComponentProps {
  selected: Option<CampaignStatusType>;
  options: Option<CampaignStatusType>[];
  updateStatus: (value: Option<CampaignStatusType>) => void;
}

class SelectStatusWrapperComponent extends React.Component<Props> {
  componentDidMount() {
    const { options } = this.props;
    if (options.length) {
      this.getStatusFromUrl();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { options } = this.props;
    if (options.length !== prevProps.options.length) {
      this.getStatusFromUrl();
    }
  }

  handleChange = (value) => {
    this.props.updateStatus(value);
  };

  getStatusFromUrl = () => {
    const { options } = this.props;
    const { search } = this.props.location;
    if (search) {
      const queryParams = queryString.parse(search);
      if (queryParams.action === 'changeStatus') {
        const status = JSON.parse(atob(queryParams.payload as string)).status;
        const findedStatus = options.find((o) => o.value === status);
        findedStatus && this.handleChange(findedStatus);
      }
    }
  };

  render() {
    const { options, selected } = this.props;

    const optionsWithoutBudgetPausedStatus = options.filter((o) => o.value !== 'budget_paused');

    return (
      <Select
        className={`_with-legend _${selected.value}`}
        label="Status"
        options={optionsWithoutBudgetPausedStatus}
        showControlLabel={true}
        value={selected}
        onChange={this.handleChange}
        tooltipParams={{
          label: 'Filter data by Campaign Status',
        }}
      />
    );
  }
}

const mapState = (state: AppState) => {
  return {
    selected: state.filter.status,
    options: state.filter.statusOptions,
  };
};

const mapActions = {
  updateStatus: filterActions.updateStatus,
};
export const SelectStatusWrapper = withRouter(
  connect(mapState, mapActions)(SelectStatusWrapperComponent),
);
