import { TableLevel } from './Table';

export interface DimensionOption {
  id?: number;
  label: string;
  order: number;
  groupId?: number;
  key: string;
  value: string;
  isFilterSupported?: boolean;
  isCustomerDependent?: boolean;
}

export interface MetricOption {
  id: number;
  label: string;
  order: number;
  defaultEnabled: boolean;
  key: string;
  value: string;
  dependentDimensions: number[] | null;
}

export type DimensionOptions = { [key: string]: DimensionOption[] }[];
export type MetricOptions = { [key: string]: MetricOption[] }[];
export type DimensionOptionsDTO = { [key: string]: Omit<DimensionOption, 'value'>[] }[];

const allowedDimensionIds = [1, 9, 11, 18, 19, 20, 21, 22, 23, 27, 24, 28, 29, 31];
const allowedMetricIds = [5, 6, 9, 10, 11, 13, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 32];

export const DEFAULT_DIMENSION_KEY = 'campaign';
export const DEFAULT_METRIC_KEY = 'CTR';

const additionalDimensionOptions = {
  advertiser: {
    label: 'Advertiser',
    order: 1,
    key: TableLevel.Advertisers,
    value: TableLevel.Advertisers,
  },
  workspace: {
    label: 'Workspace',
    order: 2,
    key: TableLevel.Workspaces,
    value: TableLevel.Workspaces,
  },
};

const normalize = (str: string) => str.replace(/(\$|_|\(|\))/g, '').toLowerCase();

export const transformDimensionOptions = (
  dimensionOptions: DimensionOptionsDTO,
  isPlatformOwnerOrg: boolean,
  isWorkspaceOwnerOrg: boolean,
): DimensionOptions => {
  const transformedDimensionOptions = dimensionOptions.map((dimensionGroup) => {
    const headerString = Object.entries(dimensionGroup)[0][0];
    const options = Object.entries(dimensionGroup)[0][1]
      .filter((opt) => !!allowedDimensionIds.find((id) => id === opt.id))
      .map((opt) => ({ ...opt, value: opt.key }))
      .sort((a, b) => (a.order || 0) - (b.order || 0));
    return { [headerString]: options };
  });

  if (isPlatformOwnerOrg || isWorkspaceOwnerOrg) {
    let customerOptions = {
      Customers: isPlatformOwnerOrg
        ? [additionalDimensionOptions.advertiser, additionalDimensionOptions.workspace]
        : [additionalDimensionOptions.advertiser],
    };
    return [...transformedDimensionOptions, customerOptions];
  }
  return transformedDimensionOptions;
};

export const getDimension = (
  loadedDimension: { label: string; value: string } | null | undefined,
  dimensionOptions: DimensionOptions,
) => {
  if (!dimensionOptions || !dimensionOptions.length) {
    return null;
  }
  const searchableDimensions = dimensionOptions.reduce((acc, curr) => {
    return [...acc, ...Object.values(curr)[0]];
  }, [] as DimensionOption[]);
  if (!loadedDimension || !loadedDimension.value) {
    return (
      searchableDimensions.find((dim) => dim.value === DEFAULT_DIMENSION_KEY) ||
      searchableDimensions[0]
    );
  }

  const deprecatedDimensionsMapper = {
    traffic_type: 'channel',
    inventory: 'siteAndApp',
  };

  const dimensionValue = normalize(
    deprecatedDimensionsMapper[loadedDimension.value] || loadedDimension.value,
  );
  return (
    searchableDimensions.find((dimension) => normalize(dimension.value) === dimensionValue) ||
    searchableDimensions[0]
  );
};

export const transformMetricOptions = (metricOptions: MetricOptions): MetricOptions => {
  return metricOptions.map((metricGroup) => {
    const headerString = Object.entries(metricGroup)[0][0];
    const options = Object.entries(metricGroup)[0][1]
      .filter((opt) => !!allowedMetricIds.find((id) => id === opt.id))
      .map((opt) => ({ ...opt, value: opt.key }))
      .sort((a, b) => (a.order || 0) - (b.order || 0));
    return { [headerString]: options };
  });
};

export const getMetric = (
  loadedMetric: { label: string; value: string } | null | undefined,
  metricOptions: MetricOptions,
) => {
  if (!metricOptions || !metricOptions.length) {
    return null;
  }
  const searchableMetrics = metricOptions.reduce((acc, curr) => {
    return [...acc, ...Object.values(curr)[0]];
  }, [] as MetricOption[]);
  if (!loadedMetric || !loadedMetric.value) {
    return (
      searchableMetrics.find(
        (metric) => normalize(metric.value) === normalize(DEFAULT_METRIC_KEY),
      ) || null
    );
  }

  const deprecatedMetricsMapper = {
    cvr: 'conversionRate',
    cpcv: 'eCPCV',
    video_start: 'startCount',
    'video_25%': 'firstCount',
    'video_50%': 'midCount',
    'video_75%': 'thirdCount',
    'video_100%': 'completeCount',
    'total_spent_($)': 'spent',
    'platform_earning_($)': 'platformEarnings',
    'workspace_earning_($)': 'workspaceEarnings',
  };
  const metricValue = normalize(deprecatedMetricsMapper[loadedMetric.value] || loadedMetric.value);
  return (
    searchableMetrics.find((metric) => normalize(metric.value) === metricValue) ||
    searchableMetrics[0]
  );
};

export const filterMetricsForDimension = (
  selectedDimension: DimensionOption | null,
  metricOptions: MetricOptions,
  isPlatformOwnerOrg: boolean,
  isWorkspaceOwnerOrg: boolean,
): MetricOptions => {
  const forbiddenAdvertiserMetrics = [
    'workspaceMediaEarning',
    'workspaceEarnings',
    'platformSpent',
    'platformMediaEarning',
    'platformEarnings',
  ];
  const forbiddenAdvertiserWorkspaceMetrics = [
    'eCPM',
    'eCPC',
    'eCPCV',
    'conversionRate',
    'startCount',
    'firstCount',
    'midCount',
    'thirdCount',
    'completeCount',
  ];

  if (
    selectedDimension &&
    selectedDimension.value === TableLevel.Advertisers &&
    (isPlatformOwnerOrg || isWorkspaceOwnerOrg)
  ) {
    if (isPlatformOwnerOrg) {
      return metricOptions.map((metricGroup) => ({
        [Object.keys(metricGroup)[0]]: Object.values(metricGroup)[0].filter(
          (metric) =>
            !['workspaceEarnings', 'workspaceMediaEarning']
              .concat(forbiddenAdvertiserWorkspaceMetrics)
              .includes(metric.value),
        ),
      }));
    }
    return metricOptions.map((metricGroup) => ({
      [Object.keys(metricGroup)[0]]: Object.values(metricGroup)[0].filter(
        (metric) =>
          !forbiddenAdvertiserMetrics
            .concat(forbiddenAdvertiserWorkspaceMetrics)
            .includes(metric.value),
      ),
    }));
  } else if (
    selectedDimension &&
    selectedDimension.value === TableLevel.Workspaces &&
    isPlatformOwnerOrg
  ) {
    return metricOptions.map((metricGroup) => ({
      [Object.keys(metricGroup)[0]]: Object.values(metricGroup)[0].filter(
        (metric) => !forbiddenAdvertiserWorkspaceMetrics.includes(metric.value),
      ),
    }));
  }
  const forbidden = forbiddenAdvertiserMetrics.concat(['workspaceSpent']);
  return metricOptions.map((metricGroup) => ({
    [Object.keys(metricGroup)[0]]: Object.values(metricGroup)[0].filter(
      (metric) => !forbidden.includes(metric.value),
    ),
  }));
};

export const integerFormatMetrics = [
  'impressions',
  'clicks',
  'startCount',
  'firstCount',
  'midCount',
  'thirdCount',
  'completeCount',
];
export const percentFormatMetrics = ['winRate', 'CTR', 'conversionRate', 'VCR'];
export const currencyFormatMetrics = [
  'eCPC',
  'eCPM',
  'eCPCV',
  'spent',
  'workspaceSpent',
  'workspaceEarnings',
  'workspaceMediaEarning',
  'platformSpent',
  'platformEarnings',
  'platformMediaEarning',
];
