import moment from 'moment';
import { AppState } from '..';
import { TableLevel } from '../filter/reducers';

export const getReportDataApiParameters = (state: AppState) => {
  const { dimension, metric } = state.reports;
  const {
    dateRange,
    timezone,
    selectedCreativeTypes,
    status,
    search,
    advertisersOwIds,
    selectedCampaigns,
  } = state.filter;

  const useResultDashboard =
    dimension &&
    dimension.value !== TableLevel.Advertisers &&
    dimension.value !== TableLevel.Workspaces;

  if (!dateRange || !dimension || !metric) {
    return null;
  }

  const filterDimensions = JSON.stringify([
    { filter: 'campaign' },
    ...(dimension.value !== 'campaign' ? [{ filter: dimension.value }] : []),
  ]);
  const sortByMapper = {
    platformEarnings: 'platformMediaEarning',
    workspaceEarnings: 'workspaceMediaEarning',
  };

  return useResultDashboard
    ? {
        startDate: moment(dateRange.start).valueOf(),
        endDate: moment(dateRange.end).valueOf(),
        dimensions: filterDimensions,
        timezoneId: timezone ? timezone.id : null,
        timezoneName: timezone ? timezone.value : null,
        customerIds: advertisersOwIds ? advertisersOwIds.split(',').map((id) => +id) : undefined,
        columns: [metric.value],
        filters: {
          campaignIds: selectedCampaigns ? selectedCampaigns : undefined,
          creativeTypeIds: selectedCreativeTypes.length
            ? selectedCreativeTypes.map((c) => c.value)
            : undefined,
          campaignStatuses: status.value !== 'all' ? [status.value] : undefined,
        },
        requestType: 2,
        reportAggregated: dimension.value === 'campaign' ? '0' : '1',
        searchField: search,
        sortType: 'desc',
        sortBy: metric.value,
        resultType: 'data',
        noOfEntries: 5,
      }
    : {
        startDate: `${moment(dateRange.start).valueOf()}`,
        endDate: `${moment(dateRange.end).valueOf()}`,
        timezoneId: timezone ? timezone.id : null,
        sortBy: sortByMapper[metric.value] || metric.value,
        order: 'DESC',
        owIds: advertisersOwIds,
        campaignStatus: status.value,
        creativeTypeIds: selectedCreativeTypes.map((c) => c.value).join(','),
        searchField: search,
        pageNo: 1,
        noOfEntries: 5,
        resultType: 'data',
      };
};
