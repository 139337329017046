import React from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { Icon } from 'factor';
import { CampaignsActions as Actions } from '../../../../../../../models/CampaignsActions';
import { BtnMenu } from '../components/BtnMenu';
import { AppState } from '../../../../../../../store';
import { Campaign, CampaignType } from '../../../../../../../models/Campaign';
import { Application } from '../../../../../../../models/Application';
import { ButtonsDialog } from './ButtonsDialog';
import { CAMPAIGNS_APP_NAME } from '../../../../../../../constants/applications';
import { SetEndDateButton } from './SetEndDateButton';

interface Props {
  selectedCampaigns: Campaign[];
  allowedApplications: Application[];
  isWorkspaceOrSuperUser: boolean;
  disabled?: boolean;
  tooltip?: string;
}
interface State {
  btnMenuIsOpen: boolean;
  dialogIsOpen: boolean;
  action: string | null;
}

const DISABLE_EDIT_STATUSES = ['expired', 'deleted'];

class ActionsMenuComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      btnMenuIsOpen: false,
      dialogIsOpen: false,
      action: null,
    };
  }

  toggleBtnMenu = () => {
    const { btnMenuIsOpen } = this.state;
    this.setState({
      btnMenuIsOpen: !btnMenuIsOpen,
    });
  };

  openBtnMenu = () => {
    this.setState({
      btnMenuIsOpen: true,
    });
  };

  closeBtnMenu = () => {
    this.setState({
      btnMenuIsOpen: false,
    });
  };

  closeDialog = () => {
    this.setState({
      dialogIsOpen: false,
    });
  };

  actionHandler = (action) => {
    this.setState({
      btnMenuIsOpen: false,
      dialogIsOpen: true,
      action,
    });
  };

  isCampaignsChangeable = (statuses: String[]): boolean => {
    const { selectedCampaigns } = this.props;
    return (
      selectedCampaigns.filter((camapign) => statuses.includes(camapign.status)).length !==
      selectedCampaigns.length
    );
  };

  isDeletedSelected = () => this.props.selectedCampaigns.some((item) => item.status === 'deleted');

  get isDifferentBudgetTypesSelected() {
    const { selectedCampaigns } = this.props;
    return (
      selectedCampaigns.length &&
      selectedCampaigns.some((cmp) => cmp.budgetTypeId !== selectedCampaigns[0].budgetTypeId)
    );
  }

  get isPGCampaignSelected() {
    const { selectedCampaigns } = this.props;
    return (
      !!selectedCampaigns.length &&
      selectedCampaigns.some((cmp) => cmp.campaignTypeId === CampaignType.PG)
    );
  }

  render() {
    const {
      selectedCampaigns,
      allowedApplications,
      isWorkspaceOrSuperUser,
      disabled,
      tooltip,
    } = this.props;
    const { btnMenuIsOpen } = this.state;

    const isSelectedFromOldPlarform = !!(
      selectedCampaigns.length && selectedCampaigns.some((i) => !i.isCampaignFromNewPlatform)
    );
    const isEditAllowed = !!(
      allowedApplications && allowedApplications.find((app) => app.appName === CAMPAIGNS_APP_NAME)
    );

    return (
      <React.Fragment>
        <BtnMenu
          isOpen={btnMenuIsOpen}
          open={this.openBtnMenu}
          close={this.closeBtnMenu}
          className="filter__btn _change-campaigns"
          tooltipParams={{
            label: tooltip || 'Edit, Duplicate, Delete, or set the End Date of selected Campaigns',
          }}
          disabled={disabled}
          content={
            <div className="btn-select">
              <button className="btn-select__title" onClick={this.closeBtnMenu}>
                Actions
              </button>
              <div className="btn-select__buttons">
                {isEditAllowed && (
                  <button
                    className="btn-select__btn"
                    onClick={() => this.actionHandler(Actions.edit)}
                    disabled={
                      selectedCampaigns.length > 1 ||
                      !this.isCampaignsChangeable(DISABLE_EDIT_STATUSES) ||
                      isSelectedFromOldPlarform
                    }
                  >
                    <i className="btn-select__icon">
                      <Icon name="Edit" />
                    </i>
                    Edit
                  </button>
                )}
                <button
                  className="btn-select__btn"
                  disabled={
                    this.isDifferentBudgetTypesSelected ||
                    isSelectedFromOldPlarform ||
                    isWorkspaceOrSuperUser ||
                    this.isPGCampaignSelected
                  }
                  onClick={() => this.actionHandler(Actions.duplicate)}
                >
                  <i className="btn-select__icon">
                    <Icon name="Copy" />
                  </i>
                  Duplicate
                </button>
                <button
                  className="btn-select__btn"
                  onClick={() => this.actionHandler(Actions.delete)}
                  disabled={
                    !this.isCampaignsChangeable(DISABLE_EDIT_STATUSES) || this.isDeletedSelected()
                  }
                >
                  <i className="btn-select__icon">
                    <Icon name="Delete" />
                  </i>
                  Delete
                </button>
                <SetEndDateButton closeBtnMenu={this.closeBtnMenu} />
              </div>
            </div>
          }
        >
          <span className="btn-square__prefix">
            <Icon name="Expand" />
          </span>
          Actions
        </BtnMenu>
        {createPortal(
          <ButtonsDialog
            selectedCampaigns={selectedCampaigns}
            dialogIsOpen={this.state.dialogIsOpen}
            action={this.state.action}
            close={this.closeDialog}
          />,
          document.body,
        )}
      </React.Fragment>
    );
  }
}

const actionsMenuMapState = (state: AppState) => ({
  selectedCampaigns: state.table.selectedTableCampaigns,
  allowedApplications: state.filter.allowedApplications,
  isWorkspaceOrSuperUser:
    state.auth.userData.isWorkspaceOwnerOrg || state.auth.userData.isPlatformOwnerOrg,
});

export const ActionsMenu = connect(actionsMenuMapState)(ActionsMenuComponent);
