import React, { useMemo, useState } from 'react';
import {
  Icon,
  Dialog,
  DialogHeader,
  DialogFooter,
  Button,
  Table,
  TextField,
  Tooltip,
  WithMiddleEllipses,
} from 'factor';
import { getPathNumber, IO_STATUS_ID } from 'iqm-framework';

import { RadioGroup } from '../RadioGroup';
import { EM_DASH } from '../../constants/text';
import { OptionIDWithPayload } from '../../models/Option';
import { InsertionOrderBasic } from '../../models/InsertionOrder';
import { SelectInsertionOrders } from '../SelectInsertionOrders/SelectInsertionOrders';
import { InsertionOrderStatusCell } from '../../pages/dashboard/dashboardContent/tableWrapper/cellTypes/InsertionOrderStatusCell';
import { CampaignType } from '../../models/Campaign';

import styles from './styles.module.scss';
import { IOBudgetTypeMapper } from 'iqm-framework';

interface Props {
  onClose: () => void;
}

export const CreateCampaignDialog = (props: Props) => {
  const { onClose } = props;
  const [selectedInsertionOrder, setSelectedInsertionOrder] = useState<OptionIDWithPayload<
    InsertionOrderBasic
  > | null>(null);
  const [campaignName, setCampaignName] = React.useState('');
  const [campaignType, setCampaignType] = React.useState<number>(CampaignType.ADVANCED);

  const isCreateDisabled =
    !campaignName?.trim()?.length || !campaignType || !selectedInsertionOrder;

  const onClickCreateCampaign = () => {
    if (!selectedInsertionOrder) {
      return;
    }

    window.open(
      encodeURI(
        `/campaigns/u/${getPathNumber()}/#/${
          campaignType === CampaignType.ADVANCED ? 'campaign-create' : 'campaign-create-pg'
        }?ioId=${selectedInsertionOrder.id}&campaignName=${campaignName?.trim()}`,
      ),
      '_blank',
    );

    onClose();
  };

  const handleSelection = (selection: OptionIDWithPayload<InsertionOrderBasic>) => {
    setSelectedInsertionOrder(selection);
  };

  const tableConfig = useMemo(
    () => ({
      header: [
        {
          label: 'ID',
          className: 'w-80-80',
        },
        {
          label: 'Insertion Order Name',
          className: 'w-250-650',
        },
        {
          label: 'Status',
          className: 'w-120-120',
        },
        {
          label: 'Action',
          className: 'w-100-100',
        },
      ],
      body: [
        {
          key: (row: OptionIDWithPayload<InsertionOrderBasic>) =>
            row.payload ? row.payload.ioId : EM_DASH,
          className: 'w-80-80',
        },
        {
          key: (row: OptionIDWithPayload<InsertionOrderBasic>) => {
            const { ioBudgetTypeId } = row.payload || {};
            const budgetIcon = IOBudgetTypeMapper[ioBudgetTypeId as number]
              ? IOBudgetTypeMapper[ioBudgetTypeId as number].icon
              : null;

            return row.payload ? (
              <div className={styles.ioNameWrapper}>
                {!!budgetIcon && <Icon name={budgetIcon} className={styles.budgetIcon} />}
                <Tooltip
                  label={row.payload.ioName || EM_DASH}
                  portal
                  auto
                  useTooltipWrapperDimensions
                  className={styles.ioNameTooltip}
                >
                  <WithMiddleEllipses text={row.payload.ioName || EM_DASH} />
                </Tooltip>
              </div>
            ) : (
              EM_DASH
            );
          },
          className: 'w-250-650',
        },
        {
          key: (row: OptionIDWithPayload<InsertionOrderBasic>) =>
            row.payload ? (
              <InsertionOrderStatusCell ioStatusId={row.payload.ioStatusId} />
            ) : (
              EM_DASH
            ),
          className: 'w-120-120',
        },
        {
          key: () => (
            <button onClick={() => setSelectedInsertionOrder(null)} className={styles.actionButton}>
              <Icon name="DeleteAlt" />
            </button>
          ),
          className: 'w-100-100',
        },
      ],
    }),
    [],
  );

  return (
    <Dialog open headerFooterBorders className={styles.dialog}>
      <DialogHeader headerFooterBorders onClickClose={onClose}>
        <h3 className="title-card">Create Campaign</h3>
      </DialogHeader>

      <div className={styles.dialogContent}>
        <div className={styles.bodyText}>
          Enter the details of the campaign you would like to create
        </div>

        <div className="row">
          <div className={styles.inputCol}>
            <SelectInsertionOrders
              className={styles.select}
              isMulti={false}
              isClearable={false}
              handleSelectionSingle={handleSelection}
              selectedInsertionOrders={selectedInsertionOrder ? [selectedInsertionOrder] : null}
              ioStatusIds={[IO_STATUS_ID.ACTIVE, IO_STATUS_ID.EXPIRED]}
            />
          </div>
          <div className={styles.inputCol}>
            <TextField
              label="Campaign Name"
              placeholder="Enter Campaign Name"
              value={campaignName}
              onChange={(value) => {
                setCampaignName(value?.trim() ? value || '' : '');
              }}
              underline
            />
          </div>
          <div className={styles.radioCol}>
            <RadioGroup
              label="Campaign Type"
              options={[
                {
                  value: CampaignType.ADVANCED,
                  label: (
                    <>
                      <Icon name="CampaignTypeAdvanced" className={styles.budgetTypeIcon} />{' '}
                      Advanced
                    </>
                  ),
                },
                {
                  value: CampaignType.PG,
                  label: (
                    <>
                      <Icon name="CampaignTypePG" className={styles.budgetTypeIcon} /> PG
                    </>
                  ),
                },
              ]}
              value={campaignType}
              onChange={(value: number | string) =>
                setCampaignType(Number.parseInt(`${value}`, 10))
              }
            />
          </div>
        </div>
        {!!selectedInsertionOrder && (
          <div className={`${styles.tableWrapper} mb-2`}>
            <Table
              header={tableConfig.header}
              body={tableConfig.body}
              data={[selectedInsertionOrder]}
              rowKeyExtractor={(r) => r.id}
              enableContextMenu={false}
              tbodyRowHeight={40}
              theadRowHeight={40}
              className={styles.table}
              fixedHeader
              offsetTop={0}
            />
          </div>
        )}
      </div>

      <DialogFooter headerFooterBorders>
        <div className={styles.footer}>
          <Button onClick={onClose} className="mr-3 btn-square _md _conflower-blue">
            Cancel
          </Button>
          <Button
            disabled={isCreateDisabled}
            onClick={onClickCreateCampaign}
            className="btn-square _md _filled _conflower-blue"
          >
            Create
          </Button>
        </div>
      </DialogFooter>
    </Dialog>
  );
};
