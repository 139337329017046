import React from 'react';
import { connect } from 'react-redux';

import { AppState } from '../../store';
import { SelectContainer } from './dashboardContent/SelectContainer';
import { CreateTemplateContainer } from './dashboardContent/CreateTemplateContainer';
import { DatesContainer } from './dashboardContent/DatesContainer';
import { TableWrapper } from './dashboardContent/TableWrapper';
import { commonActions } from '../../store/common/actions';
import { ResetButton } from './dashboardContent/ResetButton';
import { LoadingStatus } from '../../models/LoadingStatus';

import './dashboardContent/styles.scss';

interface Props {
  isAuth: boolean;
  loading: boolean;
  dashboardsLength: number;
  getLists: () => void;
  isPlatformOwnerOrg: boolean;
  isWorkspaceOwnerOrg: boolean;
  isProfileLoaded: boolean;
}

export class DashboardContentComponent extends React.PureComponent<Props> {
  constructor(props) {
    super(props);

    this.state = {
      dialogIsOpen: false,
    };
  }

  componentDidMount() {
    const { getLists, isAuth } = this.props;
    if (isAuth) {
      getLists();
    }
  }

  componentDidUpdate(prevProps) {
    const { getLists, isAuth, isPlatformOwnerOrg, isWorkspaceOwnerOrg } = this.props;
    if (
      isAuth &&
      (isAuth !== prevProps.isAuth ||
        isPlatformOwnerOrg !== prevProps.isPlatformOwnerOrg ||
        isWorkspaceOwnerOrg !== prevProps.isWorkspaceOwnerOrg)
    ) {
      getLists();
    }
  }

  render() {
    const { dashboardsLength, loading, isAuth, isProfileLoaded } = this.props;
    const hasDashboards = dashboardsLength > 0;

    return (
      <div className="dashboard">
        <header className="dashboard-header dashboard__header">
          {!loading && isProfileLoaded && (
            <>
              <div className="dashboard-header__controls">
                {hasDashboards && <SelectContainer className={'dashboard-header__select'} />}
                <CreateTemplateContainer
                  isNew={!hasDashboards}
                  className={'dashboard-header__create'}
                />
                <ResetButton />
              </div>
            </>
          )}
          <DatesContainer className={'dashboard-header__datespicker'} />
        </header>
        {isAuth ? <TableWrapper className={'dashboard__table-wrapper'} /> : null}
      </div>
    );
  }
}

const mapState = (state: AppState) => ({
  isAuth: state.auth.authorized,
  loading:
    state.dashboards.loading || state.reports.dimensionsMetricsLoading === LoadingStatus.LOADING,
  dashboardsLength: state.dashboards.dashboards.length,
  isPlatformOwnerOrg: state.auth.userData.isPlatformOwnerOrg,
  isWorkspaceOwnerOrg: state.auth.userData.isWorkspaceOwnerOrg,
  isProfileLoaded: state.auth.isProfileLoaded,
});

const mapActions = {
  getLists: commonActions.getLists,
};

export const DashboardContent = connect(mapState, mapActions)(DashboardContentComponent);
