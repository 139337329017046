import React from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'factor';

import { StatItem } from '../../../components/stats/StatItem';
import { AppState } from '../../../store';
import { StatFormat, StatisticsTotalResult } from '../../../models/Statistics';
import './indicatorsContainer/styles.scss';

interface Props {
  className?: string;
  total: StatisticsTotalResult;
  prev: StatisticsTotalResult;
  loading: boolean;
  hasError: boolean;
}
class IndicatorsContainerComponent extends React.Component<Props> {
  render() {
    const { total, prev, loading, className = '', hasError } = this.props;
    return (
      <Tooltip
        className={`dashboard-indicators ${className}`}
        label="Summary information compared to previous period of the same size"
      >
        <div className="dashboard-indicators__wrapper">
          <StatItem
            title={'Impressions'}
            loading={loading}
            value={total.impressions}
            prevValue={prev.impressions}
            hasError={hasError}
          />
          <StatItem
            title={'eCPM'}
            loading={loading}
            format={StatFormat.currency}
            value={total.eCPM}
            prevValue={prev.eCPM}
            reverse
            hasError={hasError}
          />
          <StatItem
            title={'eCPC'}
            loading={loading}
            format={StatFormat.currency}
            value={total.eCPC}
            prevValue={prev.eCPC}
            reverse
            hasError={hasError}
          />
          <StatItem
            title={'Clicks'}
            loading={loading}
            value={total.clicks}
            prevValue={prev.clicks}
            hasError={hasError}
          />
          <StatItem
            title={'Spent'}
            loading={loading}
            format={StatFormat.currency}
            value={total.mediaSpent}
            prevValue={prev.mediaSpent}
            hasError={hasError}
          />
          <StatItem
            title={'CTR'}
            loading={loading}
            format={StatFormat.percent}
            value={total.eCTR}
            prevValue={prev.eCTR}
            hasError={hasError}
          />
          <StatItem
            title={'WinRate'}
            loading={loading}
            format={StatFormat.percent}
            value={total.winRate}
            prevValue={prev.winRate}
            hasError={hasError}
          />
          <StatItem
            infoLabel="Total spent is the amount spent by the campaign(s)
            for the selected timeframe, that includes the media
            cost, data cost and pre-bid cost"
            infoIcon
            title={'Total Advertiser Spent'}
            loading={loading}
            format={StatFormat.currency}
            value={total.spent}
            prevValue={prev.spent}
            hasError={hasError}
          />
        </div>
      </Tooltip>
    );
  }
}

const mapState = (state: AppState) => {
  return {
    total: state.statistics.total || ({} as StatisticsTotalResult),
    prev: state.statistics.totalPrev || ({} as StatisticsTotalResult),
    loading: state.statistics.loading || !state.filter.isCustomersDropdownLoaded,
    hasError: !!state.statistics.totalError || !!state.statistics.totalPrevError,
  };
};
export const IndicatorsContainer = connect(mapState)(IndicatorsContainerComponent);
