import React from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogContent, Button, Checkbox } from 'factor';
import { AppState } from '../../../../../../../store';
import { tableActions } from '../../../../../../../store/table/actions';
import { TableSortingParams } from '../../../../../../../models/Table';
import { TableComponentInstance } from '../../../../../dashboardContent/tableWrapper/Table';
import { localStorageService } from '../../../../../../../services/localStorage';

interface Props {
  close: () => void;
  currentSorting: TableSortingParams;
  newSorting: TableSortingParams | null;
  updateSorting: (sorting: TableSortingParams) => void;
}

interface State {
  remember: boolean;
  showModal: boolean;
}

const STORAGE_NAME = 'confirmSorting';

class ConfirmSortingDialogComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      remember: false,
      showModal: this.checkShowingModal(),
    };
  }

  cancel = () => {
    TableComponentInstance.setState({
      sorting: {
        field: this.props.currentSorting.field,
        direction: this.props.currentSorting.direction,
      },
    });
    this.props.close();
  };

  updateSorting = () => {
    if (this.props.newSorting) {
      this.props.updateSorting(this.props.newSorting);
    }
  };

  confirmHandler = () => {
    if (this.state.remember) {
      localStorageService.saveLocal<boolean>(STORAGE_NAME, true);
    }
    this.updateSorting();
    this.props.close();
  };

  checkShowingModal() {
    const store = localStorageService.getLocal(STORAGE_NAME);
    if (store) {
      this.updateSorting();
    }
    return !store;
  }

  render() {
    const { showModal } = this.state;
    return showModal ? (
      <Dialog open onClickOutside={this.cancel}>
        <DialogContent>
          <h3 className="title-card mb-4">{`Confirm sorting?`}</h3>
          <h5 className="title-card-subtitle mb-4">This will clear selection.</h5>
          <div className="d-flex justify-content-end align-items-center mt-2">
            <div className="mr-3 flex-grow-1">
              <Checkbox
                checked={this.state.remember}
                label="Don't ask me again"
                name="DontAsk"
                size="sm"
                onChange={() => this.setState({ remember: !this.state.remember })}
              />
            </div>
            <Button className="btn-square _conflower-blue mr-2 _md" onClick={this.cancel}>
              Cancel
            </Button>
            <Button
              className="btn-square _conflower-blue _filled _md"
              onClick={this.confirmHandler}
            >
              Confirm
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    ) : null;
  }
}

const mapStateToProps = (state: AppState) => ({
  currentSorting: state.table.sorting,
});

const mapActions = {
  updateSorting: tableActions.updateSorting,
};

export const ConfirmSortingDialog = connect(
  mapStateToProps,
  mapActions,
)(ConfirmSortingDialogComponent);
