import React from 'react';

import './sidebar/style.scss';

export class Sidebar extends React.PureComponent {
  calcScrollBarWidth = () => {
    const { clientWidth } = document.documentElement;
    const { innerWidth } = window;
    return innerWidth - clientWidth;
  };

  onMouseEnterHandler = () => {
    const bodyEl = document.body;
    const navBarEl = document.querySelector('.nav-bar') as HTMLElement;
    const scrollBarWidth = this.calcScrollBarWidth();
    bodyEl.style.paddingRight = `${scrollBarWidth}px`;
    bodyEl.style.overflow = 'hidden';
    if (navBarEl) {
      const navBarPaddingRight = getComputedStyle(navBarEl).paddingRight;
      if (navBarPaddingRight) {
        const navBarPaddingRightValue = +navBarPaddingRight.replace('px', '') || 0;
        navBarEl.style.paddingRight = navBarPaddingRightValue + scrollBarWidth + 'px';
      }
    }
  };

  onMouseLeaveHandler = () => {
    const bodyEl = document.body;
    const navBarEl = document.querySelector('.nav-bar') as HTMLElement;
    bodyEl.style.paddingRight = '';
    bodyEl.style.overflow = '';
    if (navBarEl) {
      navBarEl.style.paddingRight = '';
    }
  };

  render() {
    const { children } = this.props;
    return (
      <div
        className="sidebar"
        onMouseEnter={this.onMouseEnterHandler}
        onMouseLeave={this.onMouseLeaveHandler}
      >
        {children}
      </div>
    );
  }
}
