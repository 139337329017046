import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Query, useQuery } from '@tanstack/react-query';

import { getGraphDataApiParameters } from '../store/graphs/selectors';
import { lambdaV3API } from '../api/lambdaV3API';
import { getGraphDataKeys } from '../api/QueryKeys';
import { AppState } from '../store';
import { createAction } from '../utils/actions';
import { toastConstants } from '../store/toast/constants';
import { promisifiedDebounce } from '../utils/debounce';
import { LAMBDA_DEBOUNCE } from '../constants/debounce';
import { queryClient } from '../cache';

export const useGraphData = () => {
  const parameters = useSelector(getGraphDataApiParameters);
  const tableLevel = useSelector((state: AppState) => state.filter.tableLevel?.value);
  const isSidebarOpen = useSelector((state: AppState) => state.app.sidebarOpened);
  const isCustomersDropdownLoaded = useSelector(
    (state: AppState) => state.filter.isCustomersDropdownLoaded,
  );

  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGraphData = React.useCallback(
    promisifiedDebounce(lambdaV3API.graph.getGraphData, LAMBDA_DEBOUNCE, () => {
      queryClient.cancelQueries({
        predicate: (query: Query<any, any, any, any>) =>
          query.queryKey?.[0]?.scope === 'getGraphData',
      });
    }),
    [],
  ) as typeof lambdaV3API.graph.getGraphData;

  return useQuery({
    queryFn: debouncedGraphData,
    queryKey: getGraphDataKeys.keys({ scope: 'getGraphData', tableLevel, ...parameters! }),
    enabled: !!tableLevel && !!parameters && !!isSidebarOpen && isCustomersDropdownLoaded,
    onError: () => {
      dispatch(
        createAction(
          toastConstants.TOAST_OPEN,
          'Something went wrong. Please try again over a few minutes.',
        ),
      );
    },
    cacheTime: 5 * 60 * 1000,
  });
};
