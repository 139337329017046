import React, { useEffect, useMemo, useState, useRef } from 'react';
import { LazySelect } from 'iqm-framework';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { WithMiddleEllipses, Tooltip } from 'factor';

import { AppState } from '../../store';
import { LoadingStatus } from '../../models/LoadingStatus';
import { OptionIDWithPayload } from '../../models/Option';
import { InsertionOrderBasic } from '../../models/InsertionOrder';
import { InsertionOrderOptionComponent } from '../../components/InsertionOrderOptionComponent';
import { EmptySearchLabel } from '../../components/EmptySearchLabel';
import styles from './styles.module.scss';
import { EM_DASH } from '../../constants/text';

const URL = `api/v3/cmp/io/basic/list`;

interface OwnProps {
  ioStatusIds?: number[];
  selectedInsertionOrders: number[] | OptionIDWithPayload<InsertionOrderBasic>[] | null;
  handleSelectionSingle?: (selectedOption: OptionIDWithPayload<InsertionOrderBasic>) => void;
  handleSelectionMulti?: (
    selection: OptionIDWithPayload<InsertionOrderBasic>[],
    selectedOption?: OptionIDWithPayload<InsertionOrderBasic>,
  ) => void;
  onReload?: () => void;
  setRef?: (ref: any) => void;
  isMulti: boolean;
  isClearable: boolean;
  className?: string;
}

interface StateProps {
  owIds: string;
  isPlatformOwnerOrg: boolean;
  isWorkspaceOwnerOrg: boolean;
  isCustomersDropdownLoaded: boolean;
  isDashboardsLoaded: boolean;
}

interface Props extends OwnProps, StateProps {}

export let SelectInsertionOrdersRef;

const SelectInsertionOrdersComponent = (props: Props) => {
  const {
    ioStatusIds,
    owIds,
    selectedInsertionOrders,
    isPlatformOwnerOrg,
    isWorkspaceOwnerOrg,
    isCustomersDropdownLoaded,
    handleSelectionSingle,
    handleSelectionMulti,
    onReload,
    isDashboardsLoaded,
    setRef,
    isMulti,
    isClearable,
    className,
  } = props;
  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.PENDING);
  const [url, setUrl] = useState<string | null>(URL);
  const lazySelectRef = useRef<any>(null);
  const prevOwIds = useRef<any>(owIds);

  // Force LazySelect to reload on Customers dropdown selection change
  useEffect(() => {
    if (
      (isPlatformOwnerOrg || isWorkspaceOwnerOrg) &&
      isCustomersDropdownLoaded &&
      isDashboardsLoaded &&
      (get(prevOwIds, 'current.length') !== get(owIds, 'length') ||
        !isEqual(owIds, prevOwIds.current))
    ) {
      if (onReload) {
        onReload();
      }

      setUrl(null);
    }
    prevOwIds.current = owIds;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [owIds, isDashboardsLoaded, isCustomersDropdownLoaded]);

  useEffect(() => {
    if (!url) {
      setUrl(URL);
    }
  }, [url]);

  const selectedOptions = useMemo(() => {
    return selectedInsertionOrders
      ? (selectedInsertionOrders as any[]).map(
          (io: number | OptionIDWithPayload<InsertionOrderBasic>) => ({
            id: typeof io === 'number' ? io : io.id,
            value: typeof io === 'number' ? io : io.value,
            label: typeof io === 'number' ? undefined : io.label,
            reactLabel: typeof io === 'number' ? undefined : io.reactLabel,
          }),
        )
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInsertionOrders, loading]);

  const getPlaceholder = () => () => {
    return selectedInsertionOrders && selectedInsertionOrders.length
      ? `Insertion Order${selectedInsertionOrders.length > 1 ? 's' : ''} (${
          selectedInsertionOrders.length
        })`
      : undefined;
  };

  const transformRequestParams = (params: any) => {
    if (params.pageNo === 1) {
      setLoading(LoadingStatus.LOADING);
    }

    return {
      pageNo: params.pageNo,
      noOfEntries: params.noOfEntries,
      sortBy: '-ioId',
      searchField: params.searchField,
      ioStatusIdsList: ioStatusIds,
      ...(isPlatformOwnerOrg || isWorkspaceOwnerOrg
        ? { owIdList: owIds && owIds.length ? owIds.split(',').map((id) => +id) : [] }
        : {}),
    };
  };

  const mapResponseData = (res: any) => {
    setLoading(LoadingStatus.PENDING);
    return {
      ...res.data,
      data: res.data.ioBasicDetailsList.map((io) => ({
        reactLabel: (
          <InsertionOrderOptionComponent
            id={io.ioId}
            name={io.ioName}
            ioStatusId={io.ioStatusId}
            ioBudgetTypeId={io.ioBudgetTypeId}
          />
        ),
        label: io.ioName || '',
        value: io.ioId,
        id: io.ioId,
        payload: io as InsertionOrderBasic,
      })),
    };
  };

  const getEmptyDropdownLabel = () => {
    const isEmpty =
      loading !== LoadingStatus.LOADING &&
      !get(lazySelectRef, 'current.selectRef.current.props.options', []).length;

    return (
      <EmptySearchLabel
        searchTerm={get(lazySelectRef, 'current.selectRef.current.searchInput.value', '')}
        isEmpty={isEmpty}
        isError={loading === LoadingStatus.ERROR}
        isLoading={
          loading === LoadingStatus.LOADING ||
          ((isWorkspaceOwnerOrg || isPlatformOwnerOrg) && !isCustomersDropdownLoaded)
        }
      />
    );
  };

  const setLazySelectRef = (ref) => {
    lazySelectRef.current = ref;
    SelectInsertionOrdersRef = ref;
    if (setRef) {
      setRef(ref);
    }
  };

  if (!url) {
    return null;
  }

  return (
    <LazySelect
      ref={setLazySelectRef}
      key={'insertionOrders'}
      selectSpecificProps={{
        className: `${styles.select} ${className || ''}`,
        placeholder: `Select Insertion Order${isMulti ? 's' : ''}`,
        searchPlaceholder: 'Search by ID, Name',
        label: `Insertion Order${isMulti ? 's' : ''}`,
        isSearchable: true,
        isSearchClearable: true,
        searchByValue: true,
        isClearable: isClearable && selectedInsertionOrders && selectedInsertionOrders.length,
        hideClearAllButton:
          !isClearable || !selectedInsertionOrders || !selectedInsertionOrders.length,
        showLabelAlways: true,
        emptyDropdownLabel: getEmptyDropdownLabel(),
        showControlLabel: true,
        withCheckbox: true,
        isOptionsLoading: loading === LoadingStatus.LOADING,
        hideSelectAllWhenEmpty: true,
        ...(isMulti
          ? { isMulti: true, allSelectable: true, multiPlaceholder: getPlaceholder() }
          : {}),
        ...(!isMulti
          ? {
              value:
                selectedOptions && selectedOptions[0]
                  ? {
                      ...selectedOptions[0],
                      reactLabel: (
                        <Tooltip
                          portal
                          auto
                          label={selectedOptions[0]?.label || EM_DASH}
                          adjustPortalPosition
                        >
                          <WithMiddleEllipses text={selectedOptions[0]?.label}></WithMiddleEllipses>
                        </Tooltip>
                      ),
                    }
                  : undefined,
              allSelectable: false,
              isMulti: false,
            }
          : {}),
      }}
      params={
        (isPlatformOwnerOrg || isWorkspaceOwnerOrg) && owIds
          ? { owIdList: (owIds || '').split(',').map((id) => +id) }
          : {}
      }
      onChange={isMulti ? handleSelectionMulti : handleSelectionSingle}
      apiPath={
        (!isWorkspaceOwnerOrg && !isPlatformOwnerOrg) || isCustomersDropdownLoaded
          ? url
          : (null as any)
      }
      httpMethod="POST"
      transformPagingParameters={transformRequestParams}
      mapServerResponseData={mapResponseData}
      value={selectedOptions}
      totalRecordsPropName="filteredRecords"
      numberOfEntries={50}
      useParamsAsBody
      onError={
        (isWorkspaceOwnerOrg || isPlatformOwnerOrg) && !isCustomersDropdownLoaded
          ? undefined
          : () => setLoading(LoadingStatus.ERROR)
      }
    />
  );
};

const mapState = (state: AppState) => ({
  owIds: state.filter.advertisersOwIds,
  isPlatformOwnerOrg: state.auth.userData.isPlatformOwnerOrg,
  isWorkspaceOwnerOrg: state.auth.userData.isWorkspaceOwnerOrg,
  isCustomersDropdownLoaded: state.filter.isCustomersDropdownLoaded,
  isDashboardsLoaded: !state.dashboards.loading,
});

export const SelectInsertionOrders = connect<any, any, any, any>(
  mapState,
  null,
)(SelectInsertionOrdersComponent);
