import { Action } from '../../models/Action';
import { Dashboard } from '../../models/Dashboards';
import { reducerFromMap } from '../../utils/actions';
import { dashboardConstants } from './constants';

export interface DashboardState {
  dashboards: Dashboard[];
  selectedDashboard: Dashboard | null;
  loading: boolean;
  dashboardResetIndicator: boolean;
}

export const defaultDashboardsState: DashboardState = {
  dashboards: [],
  selectedDashboard: null,
  loading: true,
  dashboardResetIndicator: false,
};

function createDashboard(state: DashboardState, action: Action<Dashboard>): DashboardState {
  return {
    ...state,
    dashboards: [...state.dashboards, action.payload],
    selectedDashboard: null,
  };
}

function getDashboards(state: DashboardState, action: Action<Dashboard[]>): DashboardState {
  return {
    ...state,
    dashboards: action.payload,
  };
}

function updateDashboard(state: DashboardState, action: Action<Dashboard>): DashboardState {
  const { dashboards } = state;
  return {
    ...state,
    dashboards: dashboards.map((d) => (d.id === action.payload.id ? action.payload : d)),
    selectedDashboard: action.payload,
  };
}

function deleteDashboard(state: DashboardState, action: Action<Dashboard>): DashboardState {
  const { dashboards, selectedDashboard } = state;
  const removeIndex = dashboards.findIndex((d) => d.id === action.payload.id);
  if (removeIndex >= 0) {
    dashboards.splice(removeIndex, 1);
  }
  return {
    ...state,
    dashboards,
    selectedDashboard:
      selectedDashboard && selectedDashboard.id === action.payload.id ? null : selectedDashboard,
  };
}

function selectDashboard(state: DashboardState, action: Action<Dashboard>): DashboardState {
  const selectedDashboard = state.dashboards.find((d) => d.id === action.payload.id) || null;
  return {
    ...state,
    selectedDashboard,
  };
}

function clearSelectedDashboard(state: DashboardState): DashboardState {
  return {
    ...state,
    selectedDashboard: null,
  };
}

function resetDashboards(): DashboardState {
  return defaultDashboardsState;
}

function loadingStart(state: DashboardState): DashboardState {
  return {
    ...state,
    loading: true,
  };
}

function loadingFinished(state: DashboardState): DashboardState {
  return {
    ...state,
    loading: false,
  };
}

function indicateDashboardHasReset(state: DashboardState): DashboardState {
  return {
    ...state,
    dashboardResetIndicator: !state.dashboardResetIndicator,
  };
}

const reducer = reducerFromMap(defaultDashboardsState, {
  [dashboardConstants.CREATE_DASHBOARD]: createDashboard,
  [dashboardConstants.GET_DASHBOARDS]: getDashboards,
  [dashboardConstants.UPDATE_DASHBOARD]: updateDashboard,
  [dashboardConstants.DELETE_DASHBOARD]: deleteDashboard,
  [dashboardConstants.SELECT_DASHBOARD]: selectDashboard,
  [dashboardConstants.CLEAR_SELECTED_DASHBOARD]: clearSelectedDashboard,
  [dashboardConstants.DASHBOARD_LOADING_START]: loadingStart,
  [dashboardConstants.DASHBOARD_LOADING_END]: loadingFinished,
  [dashboardConstants.RESET_DASHBOARDS]: resetDashboards,
  [dashboardConstants.INDICATE_DASHBOARD_HAS_RESET]: indicateDashboardHasReset,
});

export const dashboards = (state: DashboardState, action: Action<any>) => reducer(state, action);
