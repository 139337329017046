import React from 'react';
import { Button, withClickOutside, Tooltip } from 'factor';

import './btnMenu/styles.scss';

interface Props {
  className: string;
  content: any;
  isOpen: boolean;
  disabled?: boolean;
  open: () => void;
  close: () => void;
  tooltipParams?: object;
}

const BtnWrapper = ({ tooltipParams, children }) => {
  return tooltipParams ? <Tooltip {...tooltipParams}>{children}</Tooltip> : children;
};

class BtnMenuComponent extends React.Component<Props> {
  handleClickOutside = () => {
    this.props.close();
  };

  render() {
    const { className, content, children, isOpen, open, disabled, tooltipParams } = this.props;

    return (
      <div
        className={`btn-menu ${isOpen ? '_opened' : ''}
				${className || ''}`}
      >
        <div className="btn-menu__wrapper">
          <BtnWrapper tooltipParams={tooltipParams}>
            <Button
              className="btn-square _conflower-blue btn-menu__btn"
              onClick={open}
              disabled={!!disabled}
            >
              {children}
            </Button>
          </BtnWrapper>
          <div className="btn-menu__content-wrapper">
            <div className="btn-menu__content">{content}</div>
          </div>
        </div>
      </div>
    );
  }
}

export const BtnMenu = withClickOutside(BtnMenuComponent);
