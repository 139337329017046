import React, { useState, useRef } from 'react';
import { LazySelect } from 'iqm-framework';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { AppState } from '../../../../../../../../../store';
import { LoadingStatus } from '../../../../../../../../../models/LoadingStatus';
import { InsertionOrderOption } from '../../../../../../../../../models/InsertionOrder';
import { GetInsertionOrderListParams } from '../../../../../../../../../api/InsertionOrder';
import { InsertionOrderOptionComponent } from '../../../../../../../../../components/InsertionOrderOptionComponent';
import { EmptySearchLabel } from '../../../../../../../../../components/EmptySearchLabel';
import styles from './styles.module.scss';

interface Props {
  owIds: number[];
  setSelectedInsertionOrder: (selected: InsertionOrderOption) => void;
  selectedInsertionOrder: InsertionOrderOption | null;
  isPlatformOwnerOrg: boolean;
  isWorkspaceOwnerOrg: boolean;
  className?: string;
  apiBodyParams?: Partial<GetInsertionOrderListParams>;
}

const SelectInsertionOrdersWrapperComponent = (props: Props) => {
  const {
    owIds,
    setSelectedInsertionOrder,
    selectedInsertionOrder,
    isPlatformOwnerOrg,
    isWorkspaceOwnerOrg,
    className = '',
    apiBodyParams = {},
  } = props;
  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.PENDING);
  const lazySelectRef = useRef<any>(null);

  const transformRequestParams = (params: any) => {
    if (params.pageNo === 1) {
      setLoading(LoadingStatus.LOADING);
    }

    return {
      pageNo: params.pageNo,
      noOfEntries: params.noOfEntries,
      searchField: params.searchField,
      sortBy: '-ioId',
      ...(isPlatformOwnerOrg || isWorkspaceOwnerOrg
        ? { owIdList: owIds && owIds.length ? owIds : [] }
        : {}),
      ...apiBodyParams,
    };
  };

  const mapResponseData = (res: any) => {
    setLoading(LoadingStatus.PENDING);
    return {
      ...res.data,
      data: res.data.ioBasicDetailsList.map((io) => ({
        ...io,
        reactLabel: (
          <InsertionOrderOptionComponent
            id={io.ioId}
            name={io.ioName}
            disablePadding
            ioBudgetTypeId={io.ioBudgetTypeId}
            ioStatusId={io.ioStatusId}
          />
        ),
        label: io.ioName || '',
        value: io.ioId,
        id: io.ioId,
        ioTimezone: io.ioTimeZoneId,
      })),
    };
  };

  const getEmptyDropdownLabel = () => {
    const isEmpty =
      loading !== LoadingStatus.LOADING &&
      !get(lazySelectRef, 'current.selectRef.current.props.options', []).length;

    return (
      <EmptySearchLabel
        searchTerm={get(lazySelectRef, 'current.selectRef.current.searchInput.value', '')}
        isEmpty={isEmpty}
        isError={loading === LoadingStatus.ERROR}
        isLoading={loading === LoadingStatus.LOADING}
      />
    );
  };

  return (
    <LazySelect
      ref={lazySelectRef}
      key={'insertionOrders'}
      selectSpecificProps={{
        className: `${styles.select} ${className}`,
        placeholder: 'Select Insertion Order',
        searchPlaceholder: 'Search by ID, Name',
        label: 'Insertion Orders',
        isSearchable: true,
        emptyDropdownLabel: getEmptyDropdownLabel(),
        isOptionsLoading: loading === LoadingStatus.LOADING,
        isClearable: false,
        allSelectable: false,
        isMulti: false,
        hideValueOnFocus: false,
        value: selectedInsertionOrder
          ? { ...selectedInsertionOrder, reactLabel: selectedInsertionOrder.label }
          : null,
      }}
      params={(isPlatformOwnerOrg || isWorkspaceOwnerOrg) && owIds ? { owIdList: owIds } : {}}
      onChange={setSelectedInsertionOrder}
      apiPath="api/v3/cmp/io/basic/list"
      httpMethod="POST"
      transformPagingParameters={transformRequestParams}
      mapServerResponseData={mapResponseData}
      value={selectedInsertionOrder ? [selectedInsertionOrder] : []}
      totalRecordsPropName="filteredRecords"
      numberOfEntries={50}
      useParamsAsBody
      onError={() => setLoading(LoadingStatus.ERROR)}
    />
  );
};

const mapState = (state: AppState) => ({
  isPlatformOwnerOrg: state.auth.userData.isPlatformOwnerOrg,
  isWorkspaceOwnerOrg: state.auth.userData.isWorkspaceOwnerOrg,
});

export const SelectInsertionOrdersWrapper = connect<any, any, any, any>(
  mapState,
  null,
)(SelectInsertionOrdersWrapperComponent);
