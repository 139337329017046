import * as React from 'react';
import { connect } from 'react-redux';
import { Layout } from 'factor';

import { NavigationBar } from '../components/NavigationBar';
import { DashboardWrapper } from './dashboard/DashboardWrapper';
import { Sidebar } from '../components/Sidebar';
import { SidebarContent } from '../components/sidebar/components/SidebarContent';
import { DashboardContent } from './dashboard/DashboardContent';
import { appActions } from '../store/app/actions';
import { AppState } from '../store';
import { DataDogLogger } from '../services/DataDog';

import './dashboard/styles.scss';

interface Props {
  sidebarOpened: boolean;
  toggleSidebar: (value: boolean) => void;
}

class DashboardComponent extends React.Component<Props> {
  handleToggle = () => {
    const { sidebarOpened } = this.props;
    const isOpening = !sidebarOpened;

    if (isOpening) {
      DataDogLogger.App.toggleSidebar({ isOpening });
    }
    this.props.toggleSidebar(isOpening);
  };

  render() {
    return (
      <React.Fragment>
        <NavigationBar />
        <Layout
          opened={this.props.sidebarOpened}
          toggleSidebar={this.handleToggle}
          sidebar={
            <Sidebar>
              <SidebarContent />
            </Sidebar>
          }
        >
          <DashboardWrapper>
            <DashboardContent />
          </DashboardWrapper>
        </Layout>
      </React.Fragment>
    );
  }
}

const mapState = (state: AppState) => {
  return {
    sidebarOpened: state.app.sidebarOpened,
  };
};

const mapActions = {
  toggleSidebar: appActions.toggleSidebar,
};

export const Dashboard = connect(mapState, mapActions)(DashboardComponent);
