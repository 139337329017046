import React from 'react';
import { connect } from 'react-redux';
import { Select } from 'factor';

import { AppState } from '../../../../../store';
import { Option } from '../../../../../models/Option';
import {
  filterActions,
  SelectCreativeType as SelectCreativeTypeAction,
} from '../../../../../store/filter/actions';

interface Props extends SelectCreativeTypeAction {
  creativeTypes: Option[];
  selectedTypes: Option[];
}

class SelectCreativeTypeComponent extends React.Component<Props> {
  render() {
    const { creativeTypes, selectedTypes, selectCreativeType } = this.props;
    return (
      <Select
        onChange={selectCreativeType}
        label="Creative Type"
        placeholder="Creative Type"
        options={creativeTypes}
        closeMenuOnSelect={true}
        value={selectedTypes}
        showControlLabel={true}
        isClearable={true}
        isMulti={true}
        allSelectable={true}
        tooltipParams={{
          label: 'Filter data by the Type of the Creative used by the Campaign',
        }}
      />
    );
  }
}

const mapState = (state: AppState) => ({
  creativeTypes: state.filter.creativeTypes,
  selectedTypes: state.filter.selectedCreativeTypes,
});

const mapActions = {
  selectCreativeType: filterActions.selectCreativeType,
};

export const SelectCreativeType = connect(mapState, mapActions)(SelectCreativeTypeComponent);
