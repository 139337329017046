import { OptionIDWithPayload } from './Option';
import { GroupedCampaign } from './Campaign';

export interface CampaignGroupDTO {
  name: string;
  id: number;
  ioName?: string;
  ioId?: number;
  ioBudgetTypeId?: number;
  campaigns: GroupedCampaign[];
  campaignCount: number;
  created?: number;
  lastModified?: number;
}

export interface CampaignGroup {
  name: string;
  id: number;
  children: CampaignGroup[];
  campaignCount: number;
  created?: number;
  lastModified?: number;
}

export interface GroupsList {
  value: number;
  label: string;
  options: OptionIDWithPayload<GroupedCampaign>[];
}

export interface DropdownGroupsList extends Omit<GroupsList, 'value'> {
  groupId: number;
  value: string;
  ioId?: number;
  ioName?: string;
  ioBudgetTypeId?: number;
}

export const convertSingleCampaignGroupDTOtoCampaignGroup = (
  group: CampaignGroupDTO,
): GroupsList => {
  return {
    value: group.id,
    label: group.name || '',
    options:
      (group.campaigns &&
        group.campaigns.map((campaign, index) => ({
          value: campaign.id,
          id: campaign.id,
          label: campaign.name || '',
        }))) ||
      [],
  };
};

export const convertSingleCampaignGroupDTOtoCampaignGroupWithPayloads = (
  group: CampaignGroupDTO,
): DropdownGroupsList => {
  return {
    value: `g-${group.id || group.ioId}`,
    label: group.name || group.ioName || '',
    groupId: group.id || (group.ioId as number),
    ioName: group.ioName,
    ioId: group.ioId,
    ioBudgetTypeId: group.ioBudgetTypeId,
    options:
      (group.campaigns &&
        group.campaigns.map((campaign, index) => ({
          value: campaign.id,
          id: campaign.id,
          label: campaign.name || '',
          payload: campaign,
        }))) ||
      [],
  };
};

export const convertCampaignGroupDTOtoCampaignGroupWithPayloads = (
  groups: CampaignGroupDTO[],
): DropdownGroupsList[] => {
  return groups.map(convertSingleCampaignGroupDTOtoCampaignGroupWithPayloads);
};
