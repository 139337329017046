import React from 'react';
import { connect } from 'react-redux';
import { Select, Icon } from 'factor';
import { IOBudgetTypeMapper } from 'iqm-framework';

import { AppState } from '../../../../../../store/index';
import {
  filterActions,
  SetCampaignTypeIds,
  SetIoBudgetTypes,
} from '../../../../../../store/filter/actions';
import {
  campaignTypeIconMap,
  campaignTypeOptions as campaignTypeOptionsModel,
} from '../../../../../../models/Campaign';
import { Option } from '../../../../../../models/Option';
import { TableLevel } from '../../../../../../models/Table';

interface StateProps {
  ioBudgetTypes: number[];
  campaignTypeIds: number[];
  tableLevel: Option;
}

interface DispatchProps extends SetIoBudgetTypes, SetCampaignTypeIds {}

interface OwnProps {
  className?: string;
}

interface Props extends StateProps, DispatchProps, OwnProps {}

interface CampaignTypeOption {
  label: string;
  reactLabel?: React.ReactNode;
  value: string;
  ioBudgetTypeId?: number;
  campaignTypeId?: number;
  options?: CampaignTypeOption[];
}

const budgetHeader = {
  label: 'Budget',
  value: 'BudgetHeader',
};

const campaignHeader = {
  label: 'Campaign',
  value: 'CampaignHeader',
};

const SelectCampaignTypeWrapperComponent = (props: Props) => {
  const {
    ioBudgetTypes,
    setIoBudgetTypes,
    campaignTypeIds,
    setCampaignTypeIds,
    tableLevel,
    className = '',
  } = props;

  const budgetTypeOptions = React.useMemo(() => {
    return tableLevel.value === TableLevel.Campaigns
      ? {
          ...budgetHeader,
          options: Object.entries(IOBudgetTypeMapper).map((entry: any) => ({
            value: `budget-${+entry[0]}`,
            label: entry[1].label,
            reactLabel: (
              <div className="w-100 d-flex align-items-center">
                <Icon name={entry[1].icon} className="mr-2" />
                {entry[1].label}
              </div>
            ),
            ioBudgetTypeId: +entry[0],
          })),
        }
      : null;
  }, [tableLevel]);

  const campaignTypeOptions = React.useMemo(() => {
    return {
      ...campaignHeader,
      options: [
        ...campaignTypeOptionsModel.map((option) => ({
          value: `campaignType-${option.id}`,
          label: option.label,
          reactLabel: (
            <div className="w-100 d-flex align-items-center">
              <Icon name={campaignTypeIconMap[option.id] || ''} className="mr-2" />
              {option.label}
            </div>
          ),
          campaignTypeId: option.id,
        })),
      ],
    };
  }, []);

  const options: CampaignTypeOption[] = React.useMemo(() => {
    if (tableLevel.value === TableLevel.Campaigns) {
      return [campaignTypeOptions, ...(budgetTypeOptions ? [budgetTypeOptions] : [])];
    }

    return campaignTypeOptions.options;
  }, [budgetTypeOptions, campaignTypeOptions, tableLevel]);

  const value = React.useMemo(() => {
    const selectValue = options
      .map((header) => header.options || header || [])
      .flat()
      .filter(
        (opt) =>
          (opt.campaignTypeId && campaignTypeIds.includes(opt.campaignTypeId)) ||
          (opt.ioBudgetTypeId && ioBudgetTypes.includes(opt.ioBudgetTypeId)),
      );

    if (tableLevel.value === TableLevel.Campaigns) {
      if (
        budgetTypeOptions &&
        budgetTypeOptions.options.every((opt) => ioBudgetTypes.includes(opt.ioBudgetTypeId))
      ) {
        selectValue.push(budgetHeader);
      }
      if (
        campaignTypeOptions.options.every((opt) => campaignTypeIds.includes(opt.campaignTypeId))
      ) {
        selectValue.push(campaignHeader);
      }
    }

    return selectValue;
  }, [options, campaignTypeIds, ioBudgetTypes, campaignTypeOptions, budgetTypeOptions, tableLevel]);

  const onChange = (options: CampaignTypeOption[]) => {
    const newIoBudgetTypes = options.map((opt) => opt.ioBudgetTypeId).filter((v) => v);
    const newCampaignTypes = options.map((opt) => opt.campaignTypeId).filter((v) => v);
    setIoBudgetTypes(newIoBudgetTypes as number[]);
    setCampaignTypeIds(newCampaignTypes as number[]);
  };

  const getMultiPlaceholder = () => () =>
    `${ioBudgetTypes.length + campaignTypeIds.length} Selected`;

  return (
    <Select
      isMulti
      isClearable
      hideSelectAllWhenEmpty
      options={options}
      onChange={onChange}
      value={value}
      placeholder="Campaign Type"
      label="Campaign Type"
      multiPlaceholder={getMultiPlaceholder()}
      className={className}
    />
  );
};

const mapState = (state: AppState) => ({
  ioBudgetTypes: state.filter.ioBudgetTypes,
  campaignTypeIds: state.filter.campaignTypeIds,
  tableLevel: state.filter.tableLevel,
});

const mapAction = {
  setIoBudgetTypes: filterActions.setIoBudgetTypes,
  setCampaignTypeIds: filterActions.setCampaignTypeIds,
};

export const SelectCampaignTypeWrapper = connect<any, any, any, any>(
  mapState,
  mapAction,
)(SelectCampaignTypeWrapperComponent);
