export const showVldInsightsCost = ({
  isWorkspaceOwnerOrg = false,
  isPlatformOwnerOrg = false,
  hadVldGenerated = false,
  isVldEnabled = false,
}: {
  isWorkspaceOwnerOrg?: boolean;
  isPlatformOwnerOrg?: boolean;
  hadVldGenerated?: boolean;
  isVldEnabled?: boolean;
}): boolean => {
  const isAdvertiserUser = !isWorkspaceOwnerOrg && !isPlatformOwnerOrg;
  return !isAdvertiserUser || hadVldGenerated || isVldEnabled;
};
