import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { authConstants } from './constants';
import { User } from '../../models/User';

export type AuthState = {
  authorized: boolean;
  isProfileLoaded: boolean;
  userData: User;
};

export const defaultAuthState: AuthState = {
  authorized: false,
  isProfileLoaded: false,
  userData: {
    apiToken: '',
    userId: 0,
    domainURL: '',
    email: '',
    organizationName: '',
    firstName: '',
    lastName: '',
    userAvatar: '',
    isBetaUser: false,
    isWorkspaceOwnerOrg: false,
    isCampaignAppOwner: false,
    isPlatformOwnerOrg: false,
    isBidShadingEnabled: false,
    isVldEnabled: false,
    hadVldGenerated: false,
  },
};

function loginSuccess(state: AuthState, action: Action<User>): AuthState {
  const { payload } = action;
  return {
    ...state,
    userData: payload,
    authorized: true,
  };
}

function handleProfileLoaded(state: AuthState, action: Action<User>): AuthState {
  return {
    ...state,
    isProfileLoaded: true,
    userData: {
      ...state.userData,
      ...action.payload,
    },
  };
}

const reducer = reducerFromMap<AuthState>(defaultAuthState, {
  [authConstants.LOGIN_SUCCESS]: loginSuccess,
  [authConstants.HANDLE_PROFILE_LOADED]: handleProfileLoaded,
});

export const auth = (state: AuthState = defaultAuthState, action: Action<any>) =>
  reducer(state, action);
