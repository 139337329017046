import axios, { AxiosResponse, Canceler } from 'axios';
import get from 'lodash/get';
import { QueryFunctionContext } from '@tanstack/react-query';

import { getLambdaV3Instance } from './index';
import { Response, LambdaV3GraphResponse, WithResponse, ResponseV3 } from '../../models/Response';
import { MetricOption, DimensionOptionsDTO } from '../../models/Report';
import { IQM_API_URL_V3 } from '../../config';
import { getInstance } from '..';
import { TableLevel } from '../../models/Table';
import { CampaignStatusType } from '../../models/Campaign';
import { getCampaignReportDataKeys, getReportDataKeys } from '../QueryKeys';

export interface GetReportDataParameters {
  startDate: string;
  endDate: string;
  timezoneId: number | null;
  sortBy: string;
  order: 'DESC';
  owIds: string;
  campaignStatus: CampaignStatusType;
  creativeTypeIds: string;
  searchField: string;
  pageNo: 1;
  noOfEntries: 5;
  resultType: 'data';
}

let cancelAdvertisers: Canceler;

function getAdvertisersData(query): Promise<LambdaV3GraphResponse[]> {
  if (cancelAdvertisers) {
    cancelAdvertisers('Canceled by the user');
  }
  return getInstance()
    .post('/v3/das/report/by-customer', query, {
      cancelToken: new axios.CancelToken((c) => {
        cancelAdvertisers = c;
      }),
    })
    .then(
      (response: AxiosResponse<ResponseV3<LambdaV3GraphResponse>>) =>
        response.data.data?.recordsList || [],
    )
    .catch((err) => Promise.reject(err));
}

let cancelWorkspaces: Canceler;

function getWorkspacesData(query): Promise<LambdaV3GraphResponse[]> {
  if (cancelWorkspaces) {
    cancelWorkspaces('Canceled by the user');
  }
  return getInstance()
    .post('/v3/das/report/by-workspace', query, {
      cancelToken: new axios.CancelToken((c) => {
        cancelWorkspaces = c;
      }),
    })
    .then(
      (response: AxiosResponse<ResponseV3<LambdaV3GraphResponse>>) =>
        response.data.data?.recordsList || [],
    )
    .catch((err) => Promise.reject(err));
}

export interface GetCampaignReportDataParameters {
  startDate: number;
  endDate: number;
  dimensions: string;
  timezoneId: number | null;
  timezoneName: string | null;
  customerIds?: number[];
  columns: string[];
  filters?: {
    campaignIds?: number[];
    creativeTypeIds?: any[];
    campaignStatuses?: CampaignStatusType[];
  };
  requestType: 2;
  reportAggregated: '0' | '1';
  searchField: string;
  sortType: 'desc';
  sortBy: string;
  resultType: 'data';
  noOfEntries: 5;
}

let cancelReportData: Canceler;

function getCampaignReportData(query): Promise<LambdaV3GraphResponse[]> {
  if (cancelReportData) {
    cancelReportData('Canceled by the user');
  }

  return getLambdaV3Instance()
    .post('/ra/report/execute', query, {
      baseURL: IQM_API_URL_V3,
      cancelToken: new axios.CancelToken((c) => {
        cancelReportData = c;
      }),
    })
    .then(
      (response: AxiosResponse<WithResponse<Response<LambdaV3GraphResponse>>>) =>
        response.data.data?.data || [],
    )
    .catch((err) => Promise.reject(err));
}

export interface DimensionsAndMetricsResponse {
  dimensions: DimensionOptionsDTO;
  metrics: { [key: string]: MetricOption[] }[];
}

export const getDimensionsAndMetrics = async (): Promise<DimensionsAndMetricsResponse> => {
  return getLambdaV3Instance()
    .get('/ra/report/dimension-metrics/detail', { baseURL: IQM_API_URL_V3 })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return Promise.reject(get(err, 'response', err));
    });
};

const reportsApiFunctionMapper = {
  [TableLevel.Advertisers]: getAdvertisersData,
  [TableLevel.Workspaces]: getWorkspacesData,
};

export const getReportsGraphData = async (
  queryContext: QueryFunctionContext<ReturnType<typeof getReportDataKeys.keys>>,
) => {
  const { dimension, scope, ...params } = queryContext.queryKey?.[0] || {};

  return (
    (await reportsApiFunctionMapper[dimension]?.(params)) ||
    Promise.reject(new Error('No graph function found for this table level'))
  );
};

export const getCampaignReportsGraphData = async (
  queryContext: QueryFunctionContext<ReturnType<typeof getCampaignReportDataKeys.keys>>,
) => {
  const { dimension, scope, ...params } = queryContext.queryKey?.[0] || {};

  return await getCampaignReportData(params);
};
