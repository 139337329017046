import React from 'react';
import { IOBudgetTypeMapper } from 'iqm-framework';

interface Props {
  budgetTypeId: number;
  className?: string;
}

export const BudgetLabelIcon = (props: Props) => {
  const { budgetTypeId, className } = props;
  const budgetIconName = IOBudgetTypeMapper[budgetTypeId]
    ? IOBudgetTypeMapper[budgetTypeId].valueIcon
    : null;

  return budgetIconName ? (
    <div className={`${className || ''} ${budgetIconName === 'Show' && 'mr-1'}`}>
      {budgetIconName === 'Show' ? 'Imps' : '$'}
    </div>
  ) : null;
};
