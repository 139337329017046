import React from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { Button } from 'factor';
import { CampaignsActions as Actions } from '../../../../../../../models/CampaignsActions';
import { AppState } from '../../../../../../../store';
import { Campaign } from '../../../../../../../models/Campaign';
import { ButtonsDialog } from './ButtonsDialog';
import { AutoTooltip } from '../../../../../../../components/AutoTooltip';
import './styles.scss';

interface RunButtonProps {
  selectedCampaigns: Campaign[];
  disabled?: boolean;
  tooltip?: string;
}
interface RunButtonState {
  dialogIsOpen: boolean;
  action: string | null;
}

class RunButtonComponent extends React.Component<RunButtonProps, RunButtonState> {
  constructor(props) {
    super(props);

    this.state = {
      dialogIsOpen: false,
      action: null,
    };
  }

  closeDialog = () => {
    this.setState({
      dialogIsOpen: false,
    });
  };

  actionHandler = (action) => {
    this.setState({
      dialogIsOpen: true,
      action,
    });
  };

  render() {
    const { selectedCampaigns, tooltip, disabled } = this.props;

    const isSelectedFromOldPlarform = !!(
      selectedCampaigns.length && selectedCampaigns.some((i) => !i.isCampaignFromNewPlatform)
    );

    return selectedCampaigns.every((item) => ['expired', 'paused'].includes(item.status)) ? (
      <>
        <AutoTooltip label={tooltip} auto portal>
          <Button
            className="btn-square _filled _conflower-blue campaign-actions-button__btn _run-button"
            onClick={() => this.actionHandler(Actions.run)}
            disabled={disabled || isSelectedFromOldPlarform}
          >
            Run
          </Button>
        </AutoTooltip>
        {createPortal(
          <ButtonsDialog
            selectedCampaigns={selectedCampaigns}
            dialogIsOpen={this.state.dialogIsOpen}
            action={this.state.action}
            close={this.closeDialog}
          />,
          document.body,
        )}
      </>
    ) : null;
  }
}

const runButtonMapState = (state: AppState) => ({
  status: state.filter.status,
  selectedCampaigns: state.table.selectedTableCampaigns,
});

export const RunButton = connect(runButtonMapState)(RunButtonComponent);
