import React, { useMemo } from 'react';
import { Tooltip, Icon } from 'factor';
import moment from 'moment';
import { connect } from 'react-redux';
import { AppState } from '../../../../../../../store';
import { Open, toastActions } from '../../../../../../../store/toast/actions';
import { Campaign, CampaignType } from '../../../../../../../models/Campaign';
import { LambdaResponse } from '../../../../../../../models/Response';
import { Option, OptionID } from '../../../../../../../models/Option';
import { DateRange, TableLevel } from '../../../../../../../store/filter/reducers';
import get from 'lodash/get';
import { localStorageService } from '../../../../../../../services/localStorage';
import { REPORTS_URL } from '../../../../../../../constants/url';
import { getPathNumber } from 'iqm-framework';
import styles from './styles.module.scss';

interface Props {
  clicked: () => void;
  openToast: Open['open'];
  className?: string;
  selectedCampaigns: Campaign[];
  selectedInsertionOrders?: LambdaResponse[];
  timezone: OptionID | null;
  dateRange: DateRange | null;
  isPlatformOwnerOrg: boolean;
  isWorkspaceOwnerOrg: boolean;
  tableLevel: Option;
  isAppAllowed: boolean;
  disabled?: boolean;
  tooltip?: string;
}

const PG_ADVANCED_SELECTED =
  'Actions are not applicable if PG and Advanced campaigns are selected together';

const ReportItemComponent = (props: Props) => {
  const {
    openToast,
    dateRange,
    timezone,
    selectedCampaigns,
    selectedInsertionOrders,
    isPlatformOwnerOrg,
    isWorkspaceOwnerOrg,
    tableLevel,
    isAppAllowed,
  } = props;

  const isPGCampaignSelected = React.useMemo(() => {
    return (
      !!selectedCampaigns.length &&
      selectedCampaigns.some((cmp) => cmp.campaignTypeId === CampaignType.PG)
    );
  }, [selectedCampaigns]);

  const isAdvancedCampaignSelected = React.useMemo(() => {
    return (
      !!selectedCampaigns.length &&
      selectedCampaigns.some((cmp) => cmp.campaignTypeId === CampaignType.ADVANCED)
    );
  }, [selectedCampaigns]);

  const isSelectionEligible = useMemo(
    () =>
      selectedCampaigns.length &&
      selectedCampaigns.every((item) =>
        ['expired', 'paused', 'deleted', 'running'].includes(item.status),
      ),
    [selectedCampaigns],
  );

  const disableActions = isPGCampaignSelected && isAdvancedCampaignSelected;

  const getTooltipLabel = () => {
    if (isPGCampaignSelected && isAdvancedCampaignSelected) {
      return PG_ADVANCED_SELECTED;
    }
    return undefined;
  };

  const isButtonDisabled = !!isSelectionEligible ? disableActions : true;

  const openReportsAppWithDraft = () => {
    if (
      !dateRange ||
      !timezone ||
      (tableLevel.value === TableLevel.Campaigns && !get(selectedCampaigns, 'length')) ||
      (tableLevel.value === TableLevel.InsertionOrders && !get(selectedInsertionOrders, 'length'))
    ) {
      openToast('Failed to save report');
      return;
    }

    let tableLevelParameters = {} as any;

    switch (tableLevel.value) {
      case TableLevel.Campaigns:
        const campaignIds = (selectedCampaigns as Campaign[])
          .map((item: Campaign) => item.campaignId)
          .join(',');
        const campaignsOwIds =
          isPlatformOwnerOrg || isWorkspaceOwnerOrg
            ? Array.from(
                (selectedCampaigns as Campaign[]).reduce((acc, item) => {
                  if (item.owId) {
                    acc.add(item.owId);
                  }
                  return acc;
                }, new Set<number>()),
              )
            : undefined;
        tableLevelParameters = { campaignIds, owIds: campaignsOwIds };
        break;
      case TableLevel.InsertionOrders:
        const ioIds = (selectedInsertionOrders as LambdaResponse[])
          .map((item) => item.ioId)
          .join(',');
        const ioOwIds =
          isPlatformOwnerOrg || isWorkspaceOwnerOrg
            ? Array.from(
                (selectedInsertionOrders as LambdaResponse[]).reduce((acc, item) => {
                  if (item.owId) {
                    acc.add(item.owId);
                  }
                  return acc;
                }, new Set<number>()),
              )
            : undefined;
        tableLevelParameters = { ioIds, owIds: ioOwIds };
        break;
    }

    const startDate = moment(dateRange.start)
      .valueOf()
      .toString();
    const endDate = moment(dateRange.end)
      .valueOf()
      .toString();

    const params = {
      source: 'dashboardApp',
      startDate,
      endDate,
      timezone: timezone.id.toString(),
      timezoneValue: timezone.value,
      ...tableLevelParameters,
    };

    try {
      localStorageService.saveLocal('reportDraft', params);
      const link = `${REPORTS_URL}/u/${getPathNumber()}/#/create-from-draft`;
      window.open(link, '_blank');
    } catch (err) {
      openToast('Failed to save report');
    }
  };

  if (!isAppAllowed) {
    return null;
  }

  return (
    <Tooltip label={getTooltipLabel() || 'Create Report'} position="top">
      <button
        className={`btn-select__btn ${styles.listBtn}`}
        onClick={openReportsAppWithDraft}
        disabled={isButtonDisabled}
      >
        <Icon name="Report Builder" className={styles.icon} />
        Create Report
      </button>
    </Tooltip>
  );
};

const mapState = (state: AppState) => ({
  selectedCampaigns: state.table.selectedTableCampaigns,
  selectedInsertionOrders: state.table.selectedTableInsertionOrders,
  timezone: state.filter.timezone,
  dateRange: state.filter.dateRange,
  isPlatformOwnerOrg: state.auth.userData.isPlatformOwnerOrg,
  isWorkspaceOwnerOrg: state.auth.userData.isWorkspaceOwnerOrg,
  tableLevel: state.filter.tableLevel,
});

const mapAction = {
  openToast: toastActions.open,
};

export const ReportItem = connect<any, any, any, any>(mapState, mapAction)(ReportItemComponent);
