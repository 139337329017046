import React from 'react';
import moment from 'moment';
import { Tooltip } from 'factor';

import { EM_DASH } from '../../../../../../../constants/text';
import { LambdaResponse } from '../../../../../../../models/Response';
import { TotalPacingCell } from '../TotalPacingCell';
import { PacingInfoTooltip } from '../PacingInfoTooltip';
import { CampaignType } from '../../../../../../../models/Campaign';
import styles from './styles.module.scss';

interface Props {
  campaign: LambdaResponse;
}

const SHOW_DASH_FOR_STATUSES = ['draft', 'pending', 'rejected'];

export const CampaignPacingCell = (props: Props) => {
  const { campaign } = props;
  const {
    pacingPercentage,
    startTime,
    endTime,
    status,
    actualSpent,
    expectedSpent,
    campaignDuration,
    remainingDuration,
    budgetTypeId,
    campaignTypeId,
  } = campaign || {};

  const emptyCell = <div className={styles.emptyCell}>{EM_DASH}</div>;

  if (campaignTypeId === CampaignType.PG) {
    return (
      <Tooltip
        className="w-100"
        auto
        portal
        label="Campaign pacing is not applicable for PG campaigns."
      >
        {emptyCell}
      </Tooltip>
    );
  }

  if (
    SHOW_DASH_FOR_STATUSES.includes((status || '').toLowerCase()) ||
    typeof pacingPercentage !== 'number' ||
    typeof actualSpent !== 'number' ||
    typeof expectedSpent !== 'number' ||
    (startTime && moment.unix(+startTime).isAfter(moment()))
  ) {
    return emptyCell;
  }

  return (
    <PacingInfoTooltip
      budgetCellType="total"
      pacingData={{
        ...campaign,
        totalDuration: campaign.campaignDuration,
        timezone: campaign.campaignTimezone,
      }}
    >
      <TotalPacingCell
        actualSpent={actualSpent}
        expectedSpent={expectedSpent}
        totalDuration={campaignDuration}
        remainingDuration={remainingDuration}
        endTime={(endTime as any) as number}
        pacingPercentage={pacingPercentage}
        budgetTypeId={budgetTypeId as number}
        isStatusExpired={(status || '').toLowerCase() === 'expired'}
        isStatusDeleted={(status || '').toLowerCase() === 'deleted'}
      />
    </PacingInfoTooltip>
  );
};
