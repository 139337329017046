import React from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'factor';
import {
  TableDownloadCsvButton as CSVButton,
  TableDownloadXlsxButton as XLSXButton,
} from 'iqm-framework';

import { SelectGroupByWrapper } from './filters/SelectGroupByWrapper';
import { SelectCampaignsByInsertionOrderWrapper } from './filters/SelectCampaignsByInsertionOrderWrapper';
import { SelectStatusWrapper } from './filters/SelectStatusWrapper';
import { SelectCreativeType } from './filters/SelectCreativeTypeWrapper';
import { AppState } from '../../../../store';
import { TableLevel } from '../../../../store/filter/reducers';
import { filterActions, GetAllowedApplications } from '../../../../store/filter/actions';
import { Option } from '../../../../models/Option';
import { SelectInsertionOrdersWrapper } from './filters/SelectInsertionOrdersWrapper';
import { SelectIoBudgetTypeWrapper } from './filters/SelectIoBudgetTypeWrapper';
import { SelectCampaignTypeWrapper } from './filters/SelectCampaignTypeWrapper';
import TableExport from './TableExport';

import './filters/styles.scss';

interface Props extends GetAllowedApplications {
  tableLevel: Option;
  className?: string;
  disableDownloadButtons: boolean;
  isCustomersDropdownLoaded: boolean;
  isDashboardsLoading: boolean;
  isPlatformOwnerOrg: boolean;
  isWorkspaceOwnerOrg: boolean;
}

class FiltersComponent extends React.Component<Props> {
  componentDidMount() {
    const { getAllowedApplications } = this.props;
    getAllowedApplications();
  }

  render() {
    const {
      tableLevel,
      className = '',
      disableDownloadButtons,
      isCustomersDropdownLoaded,
      isDashboardsLoading,
      isWorkspaceOwnerOrg,
      isPlatformOwnerOrg,
    } = this.props;

    const columnsMapper = {
      budget: { value: 'budget_total' },
      timezone: { value: 'campaignTimezone' },
      workspaceName: { value: 'organizationName' },
    };

    const isAdvertiserWorkspace =
      tableLevel.value === TableLevel.Workspaces || tableLevel.value === TableLevel.Advertisers;

    return (
      <div className={`filter ${className}`}>
        <div className="filter__row">
          <div className="filter__left">
            <div className="filter__selects">
              <div className="filter__select _groupby">
                <SelectGroupByWrapper />
              </div>
              {tableLevel.value === TableLevel.InsertionOrders && (
                <SelectIoBudgetTypeWrapper className="mr-3" />
              )}
              {(tableLevel.value === TableLevel.Campaigns ||
                tableLevel.value === TableLevel.Advertisers ||
                tableLevel.value === TableLevel.Workspaces) && (
                <SelectCampaignTypeWrapper className="mr-3" />
              )}
              {tableLevel.value !== TableLevel.InsertionOrders && (
                <>
                  <div className="filter__select _statuses">
                    <SelectStatusWrapper />
                  </div>
                  <div className="filter__select _types">
                    <SelectCreativeType />
                  </div>
                </>
              )}
              <div
                className={`filter__select _campaigns ${
                  isAdvertiserWorkspace ? 'filter__hidden' : ''
                }`}
              >
                {tableLevel.value !== TableLevel.Exchanges && <SelectInsertionOrdersWrapper />}
                {tableLevel.value !== TableLevel.InsertionOrders &&
                  (!(isWorkspaceOwnerOrg || isPlatformOwnerOrg) || isCustomersDropdownLoaded) &&
                  !isDashboardsLoading && <SelectCampaignsByInsertionOrderWrapper />}
              </div>
            </div>
          </div>
          {tableLevel.value !== TableLevel.InsertionOrders && (
            <div className="filter__right">
              <TableExport
                columnsMapper={columnsMapper}
                disableRenameColValue={
                  isAdvertiserWorkspace ? ['budgetDay', 'budgetTotal'] : undefined
                }
              />
              <Tooltip
                disable={disableDownloadButtons}
                label="Download CSV"
                position="bottom"
                auto={false}
              >
                <CSVButton
                  columnsMapper={columnsMapper}
                  fileName="Campaigns"
                  disabled={disableDownloadButtons}
                  disableRenameColValue={
                    isAdvertiserWorkspace ? ['budgetDay', 'budgetTotal'] : undefined
                  }
                />
              </Tooltip>
              <Tooltip
                disable={disableDownloadButtons}
                label="Download XLS"
                position="bottom"
                auto={false}
              >
                <XLSXButton
                  columnsMapper={columnsMapper}
                  fileName="Campaigns"
                  disabled={disableDownloadButtons}
                  disableRenameColValue={
                    isAdvertiserWorkspace ? ['budgetDay', 'budgetTotal'] : undefined
                  }
                />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapState = (state: AppState) => ({
  tableLevel: state.filter.tableLevel,
  isCustomersDropdownLoaded: state.filter.isCustomersDropdownLoaded,
  isDashboardsLoading: state.dashboards.loading,
  isPlatformOwnerOrg: state.auth.userData.isPlatformOwnerOrg,
  isWorkspaceOwnerOrg: state.auth.userData.isWorkspaceOwnerOrg,
});

const mapActions = {
  getAllowedApplications: filterActions.getAllowedApplications,
};

export const Filters = connect(mapState, mapActions)(FiltersComponent);
