import upperFirst from 'lodash/upperFirst';

export enum CreativeTypeIconMapper {
  video = 'VideoXS',
  image = 'BannerXS',
  html = 'HtmlXS',
  audio = 'VoiceXS',
  native = 'CreativeNative',
}

export const IMAGE_CREATIVE_ID = 11;
export const HTML_CREATIVE_ID = 13;
export const NATIVE_CREATIVE_ID = 15;
export const VIDEO_CREATIVE_ID = 14;
export const AUDIO_CREATIVE_ID = 17;

export const creativeTypeViewByCreativeTypeId = {
  [IMAGE_CREATIVE_ID]: {
    iconName: 'BannerXS',
    title: 'Image',
  },
  [HTML_CREATIVE_ID]: {
    iconName: 'HtmlXS',
    title: 'HTML',
  },
  [NATIVE_CREATIVE_ID]: {
    iconName: 'CreativeNative',
    title: 'Native',
  },
  [VIDEO_CREATIVE_ID]: {
    iconName: 'VideoXS',
    title: 'Video',
  },
  [AUDIO_CREATIVE_ID]: {
    iconName: 'VoiceXS',
    title: 'Audio',
  },
};

export const CreativeStatus = {
  pending: 1,
  running: 2,
  paused: 3,
  deleted: 4,
  rejected: 5,
  all: 0,
};

export const creativeStatusOptions = Object.entries(CreativeStatus)
  .map((entry) => ({
    value: entry[1],
    label: upperFirst(entry[0]),
    icon: `Status${upperFirst(entry[0])}Color`,
  }))
  .sort((a, b) => a.value - b.value);
