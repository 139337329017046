import React from 'react';
import { Tooltip, Icon } from 'factor';
import { Campaign, CampaignType } from '../../../../../../../models/Campaign';
import { OptionID } from '../../../../../../../models/Option';
import { DateRange } from '../../../../../../../store/filter/reducers';
import moment from 'moment';
import { BIDMODEL_URL } from '../../../../../../../constants/url';
import { getPathNumber } from 'iqm-framework';
import { AppState } from '../../../../../../../store';
import { connect } from 'react-redux';
import styles from './styles.module.scss';

interface Props {
  selectedCampaigns: Campaign[];
  isAppAllowed: boolean;
  timezone: OptionID | null;
  dateRange: DateRange | null;
}

const OptimizeItemCompoenent = (props: Props) => {
  const { selectedCampaigns, isAppAllowed, timezone, dateRange } = props;

  const isSelectionNotEligible = React.useMemo(
    () =>
      !isAppAllowed ||
      selectedCampaigns.length !== 1 ||
      !['expired', 'paused', 'deleted', 'running', 'draft', 'pending'].includes(
        selectedCampaigns[0].status,
      ),
    [selectedCampaigns, isAppAllowed],
  );

  const handleOptimizeClicked = () => {
    if (selectedCampaigns.length === 1 && dateRange && timezone) {
      const selectedCampaign = selectedCampaigns[0];
      const tzId = timezone.id.toString();
      const startDate = moment(dateRange.start)
        .valueOf()
        .toString();
      const endDate = moment(dateRange.end)
        .valueOf()
        .toString();
      const params = {
        startDate,
        endDate,
        timezone: tzId,
      };
      const paramsString = new URLSearchParams(params).toString();
      const link = `${BIDMODEL_URL}/u/${getPathNumber()}/#/creative/${selectedCampaign.campaignId.toString()}?${paramsString}`;
      window.open(link, '_blank');
    }
  };

  const getTooltipLabel = () => {
    if (
      selectedCampaigns.length === 1 &&
      selectedCampaigns[0] &&
      selectedCampaigns[0].campaignTypeId === CampaignType.PG
    ) {
      return "PG Campaigns can't be optimised";
    } else {
      return 'Optimize the selected Campaign (opens in new tab)';
    }
  };

  const isBidModellingDisabled =
    (selectedCampaigns[0] && selectedCampaigns[0].campaignTypeId === CampaignType.PG) ||
    isSelectionNotEligible;

  if (!isAppAllowed) {
    return null;
  }

  return (
    <Tooltip label={getTooltipLabel()} position="top">
      <button
        className={`btn-select__btn ${styles.listBtn}`}
        disabled={isBidModellingDisabled}
        onClick={handleOptimizeClicked}
      >
        <Icon name="Bid Model" className={styles.icon} />
        Optimize Campaign
      </button>
    </Tooltip>
  );
};
const mapState = (state: AppState) => {
  return {
    selectedCampaigns: state.table.selectedTableCampaigns,
    timezone: state.filter.timezone,
    dateRange: state.filter.dateRange,
  };
};

export const OptimizeItem = connect<any, any, any, any>(mapState, null)(OptimizeItemCompoenent);
