import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import { AppState } from '../../../../../store';
import { Campaign, CampaignType } from '../../../../../models/Campaign';
import { ActionsMenu } from './selectedCampaigns/campaignsActionsButtons/ActionsMenu';
import { PauseButton } from './selectedCampaigns/campaignsActionsButtons/PauseButton';
import { RunButton } from './selectedCampaigns/campaignsActionsButtons/RunButton';
import { BudgetActionsButton } from './selectedCampaigns/BudgetActionsButton';
import { TableComponentInstance } from '../Table';
import { CAMPAIGN_BUDGET_TYPE } from '../../../../../constants';
import { OpenInAppMenuList } from './selectedCampaigns/OpenInAppMenuList/OpenInAppMenuList';

const PG_ADVANCED_SELECTED =
  'Actions are not applicable if PG and Advanced campaigns are selected together';

interface Props {
  totalItems: number | null;
  selected: Campaign[];
  isWorkspaceOrSuperUser: boolean;
}

const SelectedCampaignsComponent = (props: Props) => {
  const { totalItems, selected, isWorkspaceOrSuperUser } = props;

  const selectedEditable = useMemo(
    () => !selected.every((campaign) => campaign.status === 'deleted'),
    [selected],
  );

  const shouldHideSetBudgetButton = useMemo(
    () => selected.length && selected.some((item) => 'expired' === item.status),
    [selected],
  );

  const disableTotalAndDailyBudget = useMemo(
    () =>
      selected.length &&
      selected.some(
        (item) =>
          CAMPAIGN_BUDGET_TYPE.IMPRESSION_BASED === item.budgetTypeId ||
          item.campaignTypeId === CampaignType.PG,
      ),
    [selected],
  );

  const isDifferentBudgetTypesSelected = useMemo(() => {
    return selected.length && selected.some((cmp) => cmp.budgetTypeId !== selected[0].budgetTypeId);
  }, [selected]);

  const isSomeDeletedSelected = useMemo(
    () => selected.length && selected.some((cmp) => cmp.status === 'deleted'),
    [selected],
  );

  const handleClear = () => {
    if (TableComponentInstance) {
      TableComponentInstance.clearSelected();
    }
  };

  const isPGCampaignSelected = React.useMemo(() => {
    return !!selected.length && selected.some((cmp) => cmp.campaignTypeId === CampaignType.PG);
  }, [selected]);

  const isAdvancedCampaignSelected = React.useMemo(() => {
    return (
      !!selected.length && selected.some((cmp) => cmp.campaignTypeId === CampaignType.ADVANCED)
    );
  }, [selected]);

  const disableActions = isPGCampaignSelected && isAdvancedCampaignSelected;

  const disabledAndTooltipProps = {
    disabled: disableActions,
    tooltip: disableActions ? PG_ADVANCED_SELECTED : undefined,
  };

  const renderFilterActions = () => {
    if (isDifferentBudgetTypesSelected) {
      return null;
    }

    if (!isWorkspaceOrSuperUser && isSomeDeletedSelected) {
      return <ActionsMenu {...disabledAndTooltipProps} />;
    }

    const firstCmpOwId = selected[0].owId;
    if (selected.every((cmp) => cmp.owId === firstCmpOwId) && !isSomeDeletedSelected) {
      return (
        <>
          <RunButton {...disabledAndTooltipProps} />
          <ActionsMenu {...disabledAndTooltipProps} />
          <PauseButton {...disabledAndTooltipProps} />
          {selectedEditable && !shouldHideSetBudgetButton && (
            <BudgetActionsButton
              {...disabledAndTooltipProps}
              buttonsToHide={disableTotalAndDailyBudget ? ['totalBudget', 'dailyBudget'] : []}
            />
          )}
        </>
      );
    }

    return null;
  };

  return selected.length ? (
    <React.Fragment>
      <div className="filter__selected">
        <button className="btn-close filter__remove" onClick={handleClear} />
        {totalItems
          ? `${selected.length} out of ${totalItems} Selected`
          : `${selected.length} selected`}
      </div>
      {renderFilterActions()}
      <OpenInAppMenuList />
    </React.Fragment>
  ) : null;
};

const mapState = (state: AppState) => {
  return {
    totalItems: state.table.totalItems,
    selected: state.table.selectedTableCampaigns,
    isWorkspaceOrSuperUser:
      state.auth.userData.isWorkspaceOwnerOrg || state.auth.userData.isPlatformOwnerOrg,
  };
};

export const SelectedCampaigns = connect<any, any, any, any>(
  mapState,
  null,
)(SelectedCampaignsComponent);
