import React, { useState, useEffect, useCallback, useRef } from 'react';
import { TextFieldWithIcon } from 'factor';
import debounce from 'lodash/debounce';
import get from 'lodash/get';

interface Props {
  search: string;
  updateSearch: (str: string) => void;
  placeholder?: string;
  debounceTime?: number;
  focusOnMount?: boolean;
}

export const SearchField = (props: Props) => {
  const { search, updateSearch, placeholder, debounceTime = 300, focusOnMount } = props;
  const [localSearchValue, setLocalSearchValue] = useState(search);
  const textFieldRef = useRef<any>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    debounce((searchValue) => {
      updateSearch(searchValue);
    }, debounceTime),
    [updateSearch, debounceTime],
  );

  useEffect(() => {
    if (search !== localSearchValue) {
      handleChange(localSearchValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localSearchValue]);

  useEffect(() => {
    if (search !== localSearchValue) {
      setLocalSearchValue(search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (focusOnMount && get(textFieldRef, 'current.inputRef.focus')) {
      textFieldRef.current.inputRef.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (event) => {
    setLocalSearchValue(event.target.value);
  };

  return (
    <TextFieldWithIcon
      ref={textFieldRef}
      iconName="Search"
      onChange={handleInputChange}
      value={localSearchValue}
      placeholder={placeholder || 'Search'}
      spellcheck={false}
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
    />
  );
};
