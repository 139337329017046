import * as React from 'react';
import './styles.scss';

interface Props {
  messages: string[];
}

export const ToastContent = (props: Props) => (
  <div className="toast-message__container">
    {props.messages.map((m, i) => (
      <p className="toast-message__message" key={i}>
        {m}
      </p>
    ))}
  </div>
);
