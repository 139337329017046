import { AppState } from '..';
import { GetGraphDataParameters } from '../../api/lambdaV3API/GraphData';
import { TableLevel } from '../filter/reducers';

export const getGraphDataApiParameters = (state: AppState): GetGraphDataParameters | null => {
  const { auth } = state;
  const {
    dateRange,
    timezone,
    selectedCreativeTypes,
    status,
    search,
    tableLevel,
    advertisersOwIds,
    selectedInsertionOrders,
    selectedCampaigns,
  } = state.filter;

  const useResultDashboard =
    tableLevel.value !== TableLevel.Workspaces &&
    tableLevel.value !== TableLevel.Advertisers &&
    tableLevel.value !== TableLevel.InsertionOrders;

  let { start, end } = dateRange || {};

  if (dateRange && auth.userData?.apiToken && start && end) {
    return {
      startDate: `${start.valueOf()}`,
      endDate: `${end.valueOf()}`,
      timezoneId: timezone ? timezone.id : null,
      searchField: search,
      owIds: advertisersOwIds,
      ...(tableLevel.value !== TableLevel.InsertionOrders
        ? {
            campaignStatus: status.value,
            creativeTypeIds: selectedCreativeTypes.map((c) => c.value).join(','),
          }
        : {}),
      resultType: 'data' as const,
      isGraph: true as const,
      ...(useResultDashboard
        ? {
            campaignIds:
              selectedCampaigns && selectedCampaigns.length
                ? selectedCampaigns.join(',')
                : undefined,
            token: `${auth.userData.apiToken}`,
          }
        : {}),
      ...((tableLevel.value === TableLevel.InsertionOrders ||
        tableLevel.value === TableLevel.Campaigns) &&
      !selectedCampaigns?.length
        ? { ioIdsList: selectedInsertionOrders || [] }
        : {}),
    };
  }

  return null;
};
