import React from 'react';
import { connect } from 'react-redux';
import { IO_STATUS_ID, IO_BUDGET_TYPE_ID } from 'iqm-framework';
import { EM_DASH } from '../../../../../../../constants/text';
import { LambdaResponse } from '../../../../../../../models/Response';
import { TotalPacingCell } from '../TotalPacingCell';
import { PacingInfoTooltip } from '../PacingInfoTooltip';
import { AppState } from '../../../../../../../store';
import { OptionID } from '../../../../../../../models/Option';

interface Props {
  insertionOrder: LambdaResponse;
  timezones: OptionID[];
}

export const IOPacingCellComponent = (props: Props) => {
  const { insertionOrder, timezones } = props;
  const {
    campaignsCount,
    ioActualSpent,
    ioExpectedSpent,
    ioPacingPercentage,
    ioStartTime,
    ioEndTime,
    ioDuration,
    ioRemainingDuration,
    ioBudgetTypeId,
    ioStatusId,
    ioTotalBudget,
    targetImpression,
    ioTimezone,
  } = insertionOrder;

  if (
    !campaignsCount ||
    typeof ioActualSpent !== 'number' ||
    typeof ioExpectedSpent !== 'number' ||
    typeof ioPacingPercentage !== 'number' ||
    !(ioBudgetTypeId === IO_BUDGET_TYPE_ID.DOLLAR_BASED ? ioTotalBudget : targetImpression)
  ) {
    return <>{EM_DASH}</>;
  }
  const IoTimezone =
    timezones.filter((timezone) => timezone.id === ioTimezone)[0] &&
    timezones.filter((timezone) => timezone.id === ioTimezone)[0].value;

  return (
    <PacingInfoTooltip
      budgetCellType="total"
      pacingData={{
        actualSpent: ioActualSpent || 0,
        budgetTotal: ioTotalBudget,
        targetImpression: targetImpression,
        pacingPercentage: ioPacingPercentage,
        expectedSpent: ioExpectedSpent,
        totalDuration: ioDuration,
        remainingDuration: ioRemainingDuration,
        startTime: typeof ioStartTime === 'number' ? ioStartTime / 1000 : ioStartTime,
        endTime: typeof ioEndTime === 'number' ? ioEndTime / 1000 : ioEndTime,
        budgetTypeId: ioBudgetTypeId,
        timezone: IoTimezone,
      }}
    >
      <TotalPacingCell
        pacingPercentage={ioPacingPercentage}
        endTime={typeof ioEndTime === 'number' ? ioEndTime / 1000 : ioEndTime}
        actualSpent={ioActualSpent}
        expectedSpent={ioExpectedSpent}
        totalDuration={ioDuration}
        remainingDuration={ioRemainingDuration}
        budgetTypeId={ioBudgetTypeId as number}
        isStatusExpired={ioStatusId === IO_STATUS_ID.EXPIRED}
        isStatusDeleted={ioStatusId === IO_STATUS_ID.DELETED}
      />
    </PacingInfoTooltip>
  );
};

const mapState = (state: AppState) => ({
  timezones: state.filter.timezones,
});

export const IOPacingCell = connect(mapState)(IOPacingCellComponent);
