export enum CampaignStatusType {
  all = 'all',
  budgetPaused = 'budget_paused',
  deleted = 'deleted',
  draft = 'draft',
  expired = 'expired',
  pending = 'pending',
  running = 'running',
  paused = 'paused',
  rejected = 'rejected',
  draft_deleted = 'draft_deleted',
}

export const creativeIconMap = {
  '11': 'CreativeImage',
  '13': 'CreativeHtml',
  '14': 'CreativeVideo',
  '15': 'CreativeNative',
  '16': 'CreativeTicker',
  '17': 'CreativeAudio',
  '18': 'CreativeGotv',
};

export const creativeStringIconMap = {
  video: 'CreativeVideo',
  image: 'CreativeImage',
  html: 'CreativeHtml',
  audio: 'CreativeAudio',
};

export type CampaignBudgetType = 'totalBudget' | 'dailyBudget' | 'maxBid';

export enum CampaignType {
  ADVANCED = 1,
  PG = 2,
}

export const campaignTypeIconMap = {
  [CampaignType.ADVANCED]: 'CampaignTypeAdvanced',
  [CampaignType.PG]: 'CampaignTypePG',
};

export const campaignTypeOptions = [
  {
    id: CampaignType.PG,
    name: 'pg',
    label: 'PG',
  },
  {
    id: CampaignType.ADVANCED,
    name: 'advanced',
    label: 'Advanced',
  },
];

export const statusMap = {
  [CampaignStatusType.running]: 'Running',
  [CampaignStatusType.paused]: 'Paused',
  [CampaignStatusType.budgetPaused]: 'Budget Paused',
  [CampaignStatusType.pending]: 'Pending',
  [CampaignStatusType.draft]: 'Draft',
  [CampaignStatusType.expired]: 'Expired',
  [CampaignStatusType.deleted]: 'Deleted',
  [CampaignStatusType.draft_deleted]: 'Deleted',
  [CampaignStatusType.all]: 'All',
};

export const statusIconMap = {
  [CampaignStatusType.running]: 'StatusRunningColor',
  [CampaignStatusType.pending]: 'StatusPendingColor',
  [CampaignStatusType.paused]: 'StatusPausedColor',
  [CampaignStatusType.draft]: 'StatusDraftColor',
  [CampaignStatusType.expired]: 'StatusExpiredColor',
  [CampaignStatusType.deleted]: 'StatusDeletedColor',
  [CampaignStatusType.rejected]: 'StatusRejectedColor',
  [CampaignStatusType.draft_deleted]: 'StatusDeletedColor',
};

export type CampaignStatus = {
  [key in CampaignStatusType]: number;
};

export interface Campaign {
  bid: number;
  bidImpressions: number;
  budget: number;
  campaignType: string;
  click: number;
  conversion: number;
  creativeType: number | string;
  budgetDay: number;
  eCTR: number;
  eCVR: number;
  endTime: number;
  campaignId;
  impression: number;
  campaignName: string;
  startTime: number;
  status: CampaignStatusType;
  timezone: string;
  winRate: number;
  rowClassName?: string;
  total?: boolean;
  campaignTimezone: string;
  budgetPacing?: boolean;
  isCampaignFromNewPlatform?: boolean;
  owId?: number;
  budgetTotal?: number;
  maxBid?: number;
  ioId?: number;
  ioTimezone?: number;
  ioName?: string;
  ioBudgetDistributionMethod?: number;
  ioStartTime?: number;
  ioEndTime?: number;
  ioTotalBudget?: number;
  isAutoSumIoTotalBudget?: boolean;
  ioStatusId?: number;
  budgetTypeId: number;
  campaignTypeId?: CampaignType;
  isEligibleForVLDInsightsReport?: boolean;
}

export interface CampaignDTO {
  campaign_name: string;
  campaign_url: string;
  creative_type: number;
  max_bid: number;
  start_date: number;
  timezone: object;
  total_budget: number;
  bid_pacing: 0 | 1;
  total_budget_pacing: 0 | 1;
}

export interface UpdateCampaignsEndDate {
  campaignIds: string;
  endDate: number;
  advertiserId: number;
  dspId: number;
}

export function convertCampaignToDTO(campaign: Campaign): CampaignDTO {
  return {
    campaign_name: campaign.campaignName,
    campaign_url: 'https://abc.com',
    creative_type: +campaign.creativeType,
    max_bid: campaign.bid,
    start_date: campaign.startTime,
    timezone: { name: 'UTC', id: 11, label: 'UTC' },
    total_budget: campaign.budget,
    total_budget_pacing: 0,
    bid_pacing: 1,
  };
}

export interface GroupedCampaign {
  advertiserId: number;
  creativeTypeId: number;
  dspId: number;
  id: number;
  name: string | null;
  organizationName: string;
  owId: number;
  status: string;
  uowId: number;
  lastModified: number;
}

export type IOCampaign = {
  creativeTypeId: number;
  id: number;
  name: string | null;
  owId: number;
  status: string;
  uowId: number;
  lastModified: number;
};

export type ListCampaign = {
  id: number;
  campaignId: number;
  name: string;
  creativeTypeId: number;
  status: string;
  startTime: number;
  endTime: number;
  createdAt: number;
  modifiedAt: number;
  owId: number;
  organizationName: string;
  organizationLogo: string;
  ioId: number;
  ioName: string;
  ioStatusId: number;
  ioBudgetTypeId: number;
};
