import { ColumnOptions } from 'iqm-framework';
import { GraphsNames } from './GraphsNames';
import { Option } from './Option';
import { Campaign } from './Campaign';
import { TableLevel } from './Table';

export interface GrapthParamsShort {
  field: string;
  sort_by?: string;
  graphName: GraphsNames;
}

export interface GraphParams extends GrapthParamsShort {
  frmtime: number;
  totime: number;
}

export interface PairedGraphModel {
  start: number;
  end: number;
  graph1: DataValue;
  graph2: DataValue;
  xAxiesLabel: number;
}

export interface GraphObjectDTO {
  key: string;
  values: Array<[DataValue, DataValue]>;
}

export type GraphDTO = Array<GraphObjectDTO>;

type DataValue = number | string | Date;

export interface GraphModelValue {
  id: string;
  data: Array<[DataValue, DataValue]>;
}

export type GraphModel = Array<GraphModelValue>;

export const Graph1DefaultMetricOption = { value: 'impressions', label: 'Impressions' };
export const Graph2DefaultMetricOption = { value: 'CTR', label: 'CTR' };

export interface AggregatedOption {
  value: string;
  label: string;
  isGroupHeader?: boolean;
  options?: Option[];
}

export interface AggregatedMetricOption {
  value: string;
  label: string;
  isGroupHeader: boolean;
  options: Option[];
}

const metricMap = {
  Expenditure: ['Total Spent ($)', 'eCPC', 'eCPM', 'CPCV'],
  Totals: ['Clicks', 'Impressions'],
  Rates: ['WinRate', 'CTR', 'CVR', 'VCR'],
  Video: ['Video Start', 'Video 25%', 'Video 50%', 'Video 75%', 'Video 100%'],
};

const makeValueOption = (v) =>
  v
    .toLowerCase()
    .replace(/%s/g, '')
    .replace(/%y/g, 'y')
    .split(' ')
    .join('_');
const makeLabelOption = (v) => v.replace(/%s/g, 's').replace(/%y/g, 'ies');

const aggregeteOption = (data: object): AggregatedMetricOption[] => {
  const options: AggregatedMetricOption[] = [];
  for (const [key, value] of Object.entries(data)) {
    options.push({
      value: makeValueOption(`${key}_group_header`),
      label: key,
      isGroupHeader: true,
      options: value.map((v) => {
        let newLabel = v === 'Total Spent ($)' ? 'Total Advertiser Spent ($)' : makeLabelOption(v);
        return { value: makeValueOption(v), label: newLabel };
      }),
    });
  }
  return options;
};

export const dimensionOptions = [
  {
    value: 'campaigns_group_header',
    label: 'Campaigns',
    isGroupHeader: true,
    options: [
      { value: 'campaign', label: 'Campaigns' },
      { value: 'campaign_group', label: 'Campaign Groups' },
    ],
  },
  {
    value: 'creatives_group_header',
    label: 'Creatives',
    isGroupHeader: true,
    options: [
      { value: 'creative', label: 'Creatives' },
      { value: 'creative_type', label: 'Creative Types' },
    ],
  },
  {
    value: 'demographics_group_header',
    label: 'Demographics',
    isGroupHeader: true,
    options: [
      { value: 'age_group', label: 'Age Groups' },
      { value: 'gender', label: 'Genders' },
      { value: 'ethnicity', label: 'Ethnicities' },
      { value: 'language', label: 'Languages' },
      { value: 'income_range', label: 'Income Ranges' },
    ],
  },
  {
    value: 'technologies_group_header',
    label: 'Technologies',
    isGroupHeader: true,
    options: [
      { value: 'device_type', label: 'Device Types' },
      { value: 'os', label: 'OS' },
      { value: 'manufacturer', label: 'Manufacturers' },
    ],
  },
  {
    value: 'deliveries_group_header',
    label: 'Deliveries',
    isGroupHeader: true,
    options: [
      { value: 'traffic_type', label: 'Traffic Types' },
      { value: 'inventory', label: 'Sites & Apps' },
      { value: 'exchange', label: 'Exchanges' },
    ],
  },
];

export const getDimensionOptions = (
  isPlatformOwnerOrg: boolean,
  isWorkspaceOwnerOrg: boolean,
): AggregatedMetricOption[] => {
  const firstSlice = dimensionOptions.slice(0, 2);
  const lastSlice = dimensionOptions.slice(2);
  if (isPlatformOwnerOrg) {
    return [
      ...firstSlice,
      {
        value: 'customers_group_header',
        label: 'Customers',
        isGroupHeader: true,
        options: [
          { value: 'advertisers', label: 'Advertisers' },
          { value: 'workspaces', label: 'Workspaces' },
        ],
      },
      ...lastSlice,
    ];
  } else if (isWorkspaceOwnerOrg) {
    return [
      ...firstSlice,
      {
        value: 'customers_group_header',
        label: 'Customers',
        isGroupHeader: true,
        options: [{ value: 'advertisers', label: 'Advertisers' }],
      },
      ...lastSlice,
    ];
  }

  return dimensionOptions;
};

export const metricOptions = aggregeteOption(metricMap);

const totalsGroup = {
  value: 'totals_group_header',
  label: 'Totals',
  isGroupHeader: true,
  options: [
    { label: 'Clicks', value: 'clicks' },
    { label: 'Impressions', value: 'impressions' },
  ],
};

const expenditureGroup = {
  value: 'expenditure_group_label',
  label: 'Expenditure',
  isGroupHeader: true,
  options: [{ label: 'Total Advertiser Spent ($)', value: 'total_spent_($)' }],
};

const metricOptionsAdvertisersWorkspaces = [
  totalsGroup,
  {
    value: 'rates_group_header',
    label: 'Rates',
    isGroupHeader: true,
    options: [
      { label: 'WinRate', value: 'winrate' },
      { label: 'CTR', value: 'ctr' },
      { label: 'VCR', value: 'vcr' },
    ],
  },
];

const conversionsGroup = {
  value: 'conversions_group_header',
  label: 'Conversions',
  isGroupHeader: true,
  options: [ColumnOptions.ColumnTotalAttributedConversion],
};

const metricOptionsInsertionOrders = [totalsGroup, expenditureGroup, conversionsGroup];

const workspaceSpent = { label: 'Workspace Spent ($)', value: 'workspace_spent_($)' };
const workspaceMediaEarning = {
  label: 'Workspace Media Earning ($)',
  value: 'workspace_earning_($)',
};
const platformSpent = { label: 'Platform Spent ($)', value: 'platform_spent_($)' };
const platformMediaEarning = { label: 'Platform Media Earning ($)', value: 'platform_earning_($)' };

export const getMetricOptions = (
  selectedDimension: Option | null,
  isPlatformOwnerOrg: boolean,
  isWorkspaceOwnerOrg: boolean,
): AggregatedMetricOption[] => {
  if (selectedDimension && selectedDimension.value === TableLevel.InsertionOrders) {
    return metricOptionsInsertionOrders;
  }
  if (
    !selectedDimension ||
    (selectedDimension.value !== 'advertisers' && selectedDimension.value !== 'workspaces')
  ) {
    return metricOptions;
  }

  const expenditureGroup = {
    value: 'expenditure_group_label',
    label: 'Expenditure',
    isGroupHeader: true,
    options: [{ label: 'Total Advertiser Spent ($)', value: 'total_spent_($)' }],
  };
  const earningsGroup = {
    value: 'earnings_group_label',
    label: 'Earnings',
    isGroupHeader: true,
    options: [] as Option[],
  };

  if (selectedDimension.value === 'advertisers' && (isPlatformOwnerOrg || isWorkspaceOwnerOrg)) {
    expenditureGroup.options.push(workspaceSpent);
    if (isPlatformOwnerOrg) {
      expenditureGroup.options.push(platformSpent);
      earningsGroup.options.push(platformMediaEarning);
    }
  } else if (selectedDimension.value === 'workspaces' && isPlatformOwnerOrg) {
    expenditureGroup.options.push(workspaceSpent);
    expenditureGroup.options.push(platformSpent);
    earningsGroup.options.push(workspaceMediaEarning);
    earningsGroup.options.push(platformMediaEarning);
  }

  return earningsGroup.options.length
    ? [expenditureGroup, earningsGroup, ...metricOptionsAdvertisersWorkspaces]
    : [expenditureGroup, ...metricOptionsAdvertisersWorkspaces];
};

export enum ReportingAvialableDimensions {
  exchange = 'exchange_reports',
  manufacturer = 'manufacturer_reports',
  os = 'os_reports',
  inventory_group = 'app_reports',
}

export enum DimensionsHelper {
  campaign = 'campaignName',
  exchange = 'exchange',
  age_group = 'age',
  income_range = 'income_group',
}

export enum ReportingAvialableMetrics {
  bid_impression = 'bidImpressions',
  clicks = 'clicks',
  ecpc = 'eCPC',
  ecpm = 'eCPM',
  cpcv = 'eCPCV',
  ctr = 'CTR',
  cvr = 'CVR',
  vcr = 'VCR',
  impressions = 'impressions',
  winrate = 'winRate',
  video_start = 'startCount',
  'video_25%' = 'firstCount',
  'video_50%' = 'midCount',
  'video_75%' = 'thirdCount',
  'video_100%' = 'completeCount',
  'total_spent_($)' = 'spent',
  'customer_spent_($)' = 'customerSpent',
  'workspace_spent_($)' = 'workspaceSpent',
  'workspace_earning_($)' = 'workspaceMediaEarning',
  'platform_spent_($)' = 'platformSpent',
  'platform_earning_($)' = 'platformMediaEarning',
}

export interface GraphLambdaResponse extends Campaign {
  date: number;
  start_date: string;
  end_date: string;
}

export const integerFormatMetrics = [
  'Impressions',
  'Clicks',
  'Video Start',
  'Video 25%',
  'Video 50%',
  'Video 75%',
  'Video 100%',
];
export const percentFormatMetrics = ['WinRate', 'CTR', 'CVR', 'Total Advertiser Spent (%)', 'VCR'];
export const currencyFormatMetrics = [
  'eCPC',
  'CPI',
  'eCPM',
  'CPCV',
  'Total Advertiser Spent ($)',
  'Workspace Spent ($)',
  'Platform Spent ($)',
  'Workspace Media Earning ($)',
  'Platform Media Earning ($)',
];
