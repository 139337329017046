import React from 'react';
import { Tooltip } from 'factor';

import styles from './styles.module.scss';

interface Props {
  value?: number;
  rowClassName?: string;
  tooltip?: string;
  onClick: () => void;
  rumLogActionName?: string;
}

export const ClickableCountCell = (props: Props) => {
  const { value, onClick, rowClassName, tooltip, rumLogActionName } = props;

  const isTotalRow = rowClassName === 'tr-unsorted';
  const isPrintable = typeof value === 'number';

  if (isTotalRow || !isPrintable || value === 0) {
    return <div className={styles.wrapper}>{isPrintable && !isTotalRow ? value : '—'}</div>;
  }

  const cellContent = (
    <div
      className={`${styles.wrapper} ${styles.clickable}`}
      role="button"
      onClick={onClick}
      {...(rumLogActionName ? { 'data-dd-action-name': rumLogActionName } : {})}
    >
      {value}
    </div>
  );

  if (tooltip) {
    return (
      <>
        <Tooltip label={tooltip} portal auto adjustPortalPosition>
          {cellContent}
        </Tooltip>
      </>
    );
  }
  return cellContent;
};
