import React from 'react';
import { ProgressCircle, SuccessCircle, ErrorCircle } from 'factor';

import style from './spinner/styles.module.scss';

const SHOW_ICON_TIME = 2000;

type Props = {
  status: 'loading' | 'success' | 'fail';
  onClose: () => void;
};

const SpinnerComponent = (props: Props) => {
  const { status, onClose } = props;

  const renderIcon = () => {
    let iconComp = <ProgressCircle />;
    switch (status) {
      case 'success':
        iconComp = <SuccessCircle />;
        setTimeout(onClose, SHOW_ICON_TIME);
        break;

      case 'fail':
        iconComp = <ErrorCircle />;
        setTimeout(onClose, SHOW_ICON_TIME);
        break;

      default:
        break;
    }

    return iconComp;
  };

  return <div className={style.container}>{renderIcon()}</div>;
};

export const Spinner = SpinnerComponent;
