import React from 'react';
import { Icon, ProgressCircle } from 'factor';

import styles from './styles.module.scss';

interface Props {
  searchTerm: string;
  isLoading: boolean;
  isError: boolean;
  isEmpty: boolean;
}

export const EmptySearchLabel = (props: Props) => {
  const { searchTerm, isLoading, isError, isEmpty } = props;

  return (
    <div className={`${styles.noSearchResultsWrapper} ${isLoading ? styles.isLoading : ''}`}>
      {isLoading ? (
        <ProgressCircle className={styles.spinner} size={32} />
      ) : (
        <>
          <Icon name="EmptyClipboard" className={styles.icon} />
          <div className={styles.mainTitle}>{isError ? 'Oops' : 'No results found'}</div>
          {!isEmpty ? (
            <div className={styles.subtitle}>
              {isError ? (
                <>
                  <div className={'w-100'}>Something went wrong.</div>
                  <div className="w-100">Please try again</div>
                </>
              ) : (
                `We can’t find any items matching your search "${searchTerm}".`
              )}
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};
