import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { tableConstants } from './constants';
import { TableNameUpdateParams, TableResponse } from '../../models/Table';
import { Campaign } from '../../models/Campaign';
import { TableSortingParams } from '../../models/Table';
import { LambdaResponse } from '../../models/Response';
import { AudienceWarning } from '../../models/Audience';

export interface TableState {
  error: string;
  response: TableResponse;
  selectedTableCampaigns: ({ campaign_id: string } & any)[];
  filteredCampaigns: LambdaResponse[];
  filteredCampaignsIds: number[];
  sorting: TableSortingParams;
  data: LambdaResponse[];
  campaignsList: LambdaResponse[];
  totalItems: number | null;
  campaignAudienceWarnings: { [key: string]: AudienceWarning };
  selectedTableInsertionOrders: LambdaResponse[];
}

export const defaultTableState: TableState = {
  error: '',
  // TODO: Can we remove it?
  response: {
    data: [],
  },
  selectedTableCampaigns: [],
  filteredCampaigns: [],
  filteredCampaignsIds: [],
  data: [],
  campaignsList: [],
  sorting: {
    field: 'impressions',
    direction: 'desc',
  },
  totalItems: null,
  campaignAudienceWarnings: {},
  selectedTableInsertionOrders: [],
};

function updateCampaignName(state: TableState, action: Action<TableNameUpdateParams>): TableState {
  const { data, selectedTableCampaigns } = state;
  const updatedData = data.map((el) => {
    if (el.campaignId === action.payload.id) {
      el.campaignName = action.payload.name;
    }
    return el;
  });
  const updatedSelectedCampaigns = selectedTableCampaigns.map((el) => {
    if (+el.campaignId === action.payload.id) {
      el.campaignName = action.payload.name;
    }
    return el;
  });
  return {
    ...state,
    data: updatedData,
    selectedTableCampaigns: updatedSelectedCampaigns,
  };
}

function addSelectedCampaign(state: TableState, action: Action<Campaign[]>): TableState {
  return {
    ...state,
    selectedTableCampaigns: [...action.payload],
  };
}

function removeSelectedCampaign(state: TableState, action: Action<Campaign>): TableState {
  const { selectedTableCampaigns } = state;
  const index = selectedTableCampaigns.findIndex((c) => c.campaignId === action.payload.campaignId);
  if (index !== -1) {
    selectedTableCampaigns.splice(index, 1);
  }
  return {
    ...state,
    selectedTableCampaigns: [...selectedTableCampaigns],
    response: {
      ...state.response,
      data: state.response.data.map((d) =>
        action.payload.campaignId === d.campaignId ? { ...d, rowClassName: '' } : { ...d },
      ),
    },
  };
}

function clearSelectedCampaigns(state: TableState): TableState {
  return {
    ...state,
    selectedTableCampaigns: [],
    response: {
      ...state.response,
      data: state.response.data.map(({ rowClassName, ...rest }) => ({
        ...rest,
      })),
    },
  };
}

function selectAllCampaigns(state: TableState): TableState {
  const selectedTableCampaigns = [...state.response.data];

  return {
    ...state,
    selectedTableCampaigns,
    response: {
      ...state.response,
      data: state.response.data.map((d) => ({
        ...d,
        rowClassName: '_selected',
      })),
    },
  };
}

function updateSortingParams(state: TableState, action: Action<TableSortingParams>): TableState {
  return {
    ...state,
    sorting: action.payload,
  };
}

function clearCampaignsData(state: TableState): TableState {
  return {
    ...state,
    selectedTableCampaigns: [],
    response: {
      data: [],
    },
  };
}

function setFilteredCampaigns(state: TableState, action: Action<LambdaResponse[]>): TableState {
  return {
    ...state,
    filteredCampaigns: action.payload,
  };
}

function setFilteredCampignsIds(state: TableState, action: Action<number[]>): TableState {
  const idsSet = new Set(action.payload);
  const filteredCampaignsIds = [...idsSet];
  return {
    ...state,
    filteredCampaignsIds,
    filteredCampaigns: state.data.filter(
      (campaign) => campaign.campaignId && filteredCampaignsIds.includes(+campaign.campaignId),
    ),
  };
}

function setCampaigns(state: TableState, action: Action<LambdaResponse[]>): TableState {
  const { selectedTableCampaigns } = state;

  if (!selectedTableCampaigns.length) {
    return {
      ...state,
      data: action.payload,
    };
  }

  const updatedSelectedCampaigns = selectedTableCampaigns.map((selectedCmp) => {
    const match = action.payload.find(
      (cmp: LambdaResponse) => cmp.campaignId === selectedCmp.campaignId,
    );
    return match || selectedCmp;
  });

  return {
    ...state,
    selectedTableCampaigns: updatedSelectedCampaigns,
    data: action.payload,
  };
}

function updateCampignsList(state: TableState, action: Action<LambdaResponse[]>): TableState {
  return {
    ...state,
    campaignsList: action.payload,
  };
}

function setTotalItems(state: TableState, action: Action<number | null>): TableState {
  return {
    ...state,
    totalItems: action.payload,
  };
}

function addCampaignAudienceWarnings(
  state: TableState,
  action: Action<{ [key: string]: AudienceWarning }>,
): TableState {
  return {
    ...state,
    campaignAudienceWarnings: { ...state.campaignAudienceWarnings, ...action.payload },
  };
}

function clearCampaignAudienceWarnings(state: TableState): TableState {
  return {
    ...state,
    campaignAudienceWarnings: {},
  };
}

function setSelectedInsertionOrders(
  state: TableState,
  action: Action<LambdaResponse[]>,
): TableState {
  return {
    ...state,
    selectedTableInsertionOrders: [...action.payload],
  };
}

const reducer = reducerFromMap(defaultTableState, {
  [tableConstants.TABLE_UPDATE_CAMPAIGN_NAME]: updateCampaignName,
  [tableConstants.TABLE_ADD_SELECTED_CAMPAIGN]: addSelectedCampaign,
  [tableConstants.TABLE_REMOVE_SELECTED_CAMPAIGN]: removeSelectedCampaign,
  [tableConstants.TABLE_CLEAR_SELECTED_CAMPAIGN]: clearSelectedCampaigns,
  [tableConstants.TABLE_UPDATE_SORTING_PARAMS]: updateSortingParams,
  [tableConstants.TABLE_SELECT_ALL_CAMPAIGNS]: selectAllCampaigns,
  [tableConstants.TABLE_CLEAR_CAMPAIGNS_DATA]: clearCampaignsData,
  [tableConstants.TABLE_SET_FILTERED_CAMPAIGNS]: setFilteredCampaigns,
  [tableConstants.TABLE_SET_FILTERED_CAMPAIGNS_IDS]: setFilteredCampignsIds,
  [tableConstants.TABLE_SET_CAMPAIGNS]: setCampaigns,
  [tableConstants.UPDATE_CAMPAIGNS_LIST]: updateCampignsList,
  [tableConstants.TABLE_SET_TOTAL_ITEMS]: setTotalItems,
  [tableConstants.ADD_CAMPAIGN_AUDIENCE_WARNINGS]: addCampaignAudienceWarnings,
  [tableConstants.CLEAR_CAMPAIGN_AUDIENCE_WARNINGS]: clearCampaignAudienceWarnings,
  [tableConstants.TABLE_SELECT_INSERTION_ORDERS]: setSelectedInsertionOrders,
});

export const table = (state: TableState = defaultTableState, action: Action<any>) =>
  reducer(state, action);
