import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Button, Tooltip, Icon } from 'factor';
import moment from 'moment';
import { getPathNumber } from 'iqm-framework';
import get from 'lodash/get';
import { Campaign } from '../../models/Campaign';
import { Option, OptionID } from '../../models/Option';
import { LambdaResponse } from '../../models/Response';
import { DateRange, TableLevel } from '../../store/filter/reducers';
import { localStorageService } from '../../services/localStorage';
import { REPORTS_URL } from '../../constants/url';
import { REPORT_APP_NAME } from '../../constants/applications';
import { Application } from '../../models/Application';
import { AppState } from '../../store';
import { Open, toastActions } from '../../store/toast/actions';
import styles from './styles.module.scss';

interface Props {
  clicked: () => void;
  openToast: Open['open'];
  className?: string;
  selectedCampaigns?: Campaign[];
  selectedInsertionOrders?: LambdaResponse[];
  timezone: OptionID | null;
  dateRange: DateRange | null;
  isPlatformOwnerOrg: boolean;
  isWorkspaceOwnerOrg: boolean;
  tableLevel: Option;
  allowedApplications: Application[];
  disabled?: boolean;
  tooltip?: string;
}

const ReportButtonComponent = (props: Props) => {
  const {
    className,
    openToast,
    dateRange,
    timezone,
    selectedCampaigns,
    selectedInsertionOrders,
    isPlatformOwnerOrg,
    isWorkspaceOwnerOrg,
    tableLevel,
    allowedApplications,
    disabled,
    tooltip,
  } = props;

  const isReportAppAllowed = useMemo(
    () =>
      !!(allowedApplications && allowedApplications.find((app) => app.appName === REPORT_APP_NAME)),
    [allowedApplications],
  );

  const openReportsAppWithDraft = () => {
    if (
      !dateRange ||
      !timezone ||
      (tableLevel.value === TableLevel.Campaigns && !get(selectedCampaigns, 'length')) ||
      (tableLevel.value === TableLevel.InsertionOrders && !get(selectedInsertionOrders, 'length'))
    ) {
      openToast('Failed to save report');
      return;
    }

    let tableLevelParameters = {} as any;

    switch (tableLevel.value) {
      case TableLevel.Campaigns:
        const campaignIds = (selectedCampaigns as Campaign[])
          .map((item: Campaign) => item.campaignId)
          .join(',');
        const campaignsOwIds =
          isPlatformOwnerOrg || isWorkspaceOwnerOrg
            ? Array.from(
                (selectedCampaigns as Campaign[]).reduce((acc, item) => {
                  if (item.owId) {
                    acc.add(item.owId);
                  }
                  return acc;
                }, new Set<number>()),
              )
            : undefined;
        tableLevelParameters = { campaignIds, owIds: campaignsOwIds };
        break;
      case TableLevel.InsertionOrders:
        const ioIds = (selectedInsertionOrders as LambdaResponse[])
          .map((item) => item.ioId)
          .join(',');
        const ioOwIds =
          isPlatformOwnerOrg || isWorkspaceOwnerOrg
            ? Array.from(
                (selectedInsertionOrders as LambdaResponse[]).reduce((acc, item) => {
                  if (item.owId) {
                    acc.add(item.owId);
                  }
                  return acc;
                }, new Set<number>()),
              )
            : undefined;
        tableLevelParameters = { ioIds, owIds: ioOwIds };
        break;
    }

    const startDate = moment(dateRange.start)
      .valueOf()
      .toString();
    const endDate = moment(dateRange.end)
      .valueOf()
      .toString();

    const params = {
      source: 'dashboardApp',
      startDate,
      endDate,
      timezone: timezone.id.toString(),
      timezoneValue: timezone.value,
      ...tableLevelParameters,
    };

    try {
      localStorageService.saveLocal('reportDraft', params);
      const link = `${REPORTS_URL}/u/${getPathNumber()}/#/create-from-draft`;
      window.open(link, '_blank');
    } catch (err) {
      openToast('Failed to save report');
    }
  };

  if (!isReportAppAllowed) {
    return null;
  }

  return (
    <Tooltip label={tooltip || 'Create Report'}>
      <Button
        className={`btn-square _conflower-blue ${className || ''}`}
        onClick={openReportsAppWithDraft}
        disabled={disabled}
      >
        <Icon name="Report Builder" className={styles.icon} />
        Report
      </Button>
    </Tooltip>
  );
};

const mapState = (state: AppState) => ({
  selectedCampaigns: state.table.selectedTableCampaigns,
  selectedInsertionOrders: state.table.selectedTableInsertionOrders,
  timezone: state.filter.timezone,
  dateRange: state.filter.dateRange,
  isPlatformOwnerOrg: state.auth.userData.isPlatformOwnerOrg,
  isWorkspaceOwnerOrg: state.auth.userData.isWorkspaceOwnerOrg,
  tableLevel: state.filter.tableLevel,
  allowedApplications: state.filter.allowedApplications,
});

const mapAction = {
  openToast: toastActions.open,
};

export const ReportButton = connect<any, any, any, any>(mapState, mapAction)(ReportButtonComponent);
