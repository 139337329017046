import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogHeader,
  DialogContent,
  DialogFooter,
  Spinner,
  TextField,
  Button,
  Icon,
  ButtonsGroup,
  Tooltip,
} from 'factor';
import { CurrencyFormat } from '../../../../../../../utils/format';
import { AppState } from '../../../../../../../store';
import { ChangingStatus } from '../../../../../../../models/LoadingStatus';
import { LambdaResponse } from '../../../../../../../models/Response';
import { ioTotalBudgetOptions, IOBudgetActions } from '../../../../../../../models/InsertionOrder';
import {
  SetSelectedInsertionOrders,
  tableActions,
  UpdateInsertionOrdersBudget,
} from '../../../../../../../store/table/actions';
import { TableComponentInstance } from '../../../Table';
import { GetTotal, statisticsActions } from '../../../../../../../store/statistics/actions';
import sharedStyles from '../shared.module.scss';

interface Props extends UpdateInsertionOrdersBudget, GetTotal, SetSelectedInsertionOrders {
  onClose: () => void;
  selectedInsertionOrders: LambdaResponse[];
  isWorkspaceOrSuperUser: boolean;
}

export const SetInsertionOrderTotalBudgetDialogComponent = (props: Props) => {
  const {
    onClose,
    selectedInsertionOrders,
    updateInsertionOrdersBudget,
    isWorkspaceOrSuperUser,
    getTotal,
    setSelectedInsertionOrders,
  } = props;
  const [value, setValue] = useState('');
  const [initialValue, setInitialValue] = useState('');
  const [updating, setUpdating] = useState<ChangingStatus | null>(null);
  const [totalBudgetOption, setTotalBudgetOption] = useState(ioTotalBudgetOptions[0].value);

  useEffect(() => {
    if (selectedInsertionOrders.length === 1) {
      const initialVal = `${(selectedInsertionOrders[0] as any).ioTotalBudget}`;
      setValue(initialVal);
      setInitialValue(initialVal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
  const higherTotalSpent = useMemo(
    () =>
      totalBudgetOption === IOBudgetActions.SET &&
      selectedInsertionOrders.find((io) => (io as any).spent && +value < (io as any).spent),
    [selectedInsertionOrders, value],
  );
  */
  // Remove this once total spent is available via API
  const higherTotalSpent = useMemo(() => false as any, []);

  const alertText = useMemo(() => {
    if (!value) {
      return <>The total budget will be updated as per the newly added amount.</>;
    }

    if (value && higherTotalSpent) {
      return (
        <>
          The total budget can not be less than the total spent (
          {CurrencyFormat.format(higherTotalSpent.spent || 0)}) of {higherTotalSpent.ioName}
        </>
      );
    }
    const common = (
      <>
        The total budget for the{' '}
        {selectedInsertionOrders.length === 1
          ? selectedInsertionOrders[0].ioName
          : `${selectedInsertionOrders.length} insertion orders`}{' '}
        will be&nbsp;
      </>
    );
    if (totalBudgetOption === IOBudgetActions.SET) {
      return (
        <>
          {common}
          <span className={sharedStyles.bold}>{CurrencyFormat.format(+value)}</span>
        </>
      );
    } else {
      const rest =
        selectedInsertionOrders.length === 1 &&
        typeof (selectedInsertionOrders[0] as any).ioTotalBudget === 'number' ? (
          <>
            <span className={sharedStyles.bold}>
              {CurrencyFormat.format(+(selectedInsertionOrders[0] as any).ioTotalBudget)} +{' '}
              <span className={sharedStyles.greenText}>{CurrencyFormat.format(+value)}</span> ={' '}
              {CurrencyFormat.format(+value + +(selectedInsertionOrders[0] as any).ioTotalBudget)}
            </span>
          </>
        ) : (
          <>
            increased by&nbsp;
            <span className={sharedStyles.bold}>{CurrencyFormat.format(+value)}</span>
          </>
        );
      return (
        <span>
          {common}
          {rest}
        </span>
      );
    }
  }, [value, selectedInsertionOrders, totalBudgetOption, higherTotalSpent]);

  const onClickChange = async () => {
    setUpdating('loading');
    try {
      await updateInsertionOrdersBudget({
        ioBudgetTypeId: selectedInsertionOrders[0].ioBudgetTypeId,
        ...(selectedInsertionOrders[0].ioBudgetTypeId === 1
          ? { budget: Number(value) }
          : { ioTotalImpressions: Number(value) }),
        budgetUpdateType: totalBudgetOption,
        ...(isWorkspaceOrSuperUser ? { ioOwId: (selectedInsertionOrders[0] as any).owId } : {}),
      });
      if (TableComponentInstance && TableComponentInstance.state.data) {
        const ioIds = new Set(selectedInsertionOrders.map((io) => io.ioId));

        const updater = (io) => {
          if (ioIds.has(io.ioId)) {
            return {
              ...io,
              ioTotalBudget:
                totalBudgetOption === IOBudgetActions.SET
                  ? +value
                  : (io.ioTotalBudget || 0) + +value,
            };
          }
          return io;
        };

        TableComponentInstance.setState({
          data: TableComponentInstance.state.data.map(updater),
        });
        setSelectedInsertionOrders(selectedInsertionOrders.map(updater));
      }
      getTotal().then((res) => {
        if (TableComponentInstance) {
          TableComponentInstance.setState({ totalData: [{ ...res, total: true }] });
        }
      });
      setUpdating('success');
    } catch (err) {
      setUpdating('fail');
    }
  };

  const handleBudgetTypeChange = (value: IOBudgetActions) => {
    setTotalBudgetOption(value);
    setValue('');
  };

  return (
    <Dialog open className={sharedStyles.dialog}>
      <DialogHeader headerFooterBorders>
        <h3 className="title-card">Change Total Budget</h3>
      </DialogHeader>
      <DialogContent>
        {!updating && (
          <div className="d-flex flex-column">
            <div className={sharedStyles.subtitle}>
              Enter the <span className={sharedStyles.bold}>Total Budget</span> for the{' '}
              {selectedInsertionOrders.length === 1 ? (
                <>
                  insertion order{' '}
                  <span className={sharedStyles.bold}>{selectedInsertionOrders[0].ioName}</span>
                </>
              ) : (
                <>
                  <span className={sharedStyles.length}>{selectedInsertionOrders.length}</span>{' '}
                  insertion orders
                </>
              )}
              .
            </div>
            <div className="d-flex align-items-center mb-4">
              <TextField
                name="edit_io_budget"
                label="New Total Budget"
                type="amount"
                onChange={(value: string) => {
                  setValue(value);
                }}
                value={value}
                className="mr-3"
              />
              <ButtonsGroup
                size="sm"
                items={ioTotalBudgetOptions}
                value={totalBudgetOption}
                onChange={handleBudgetTypeChange}
                className="mr-3"
              />
              <Tooltip label="Set exact budget or add more to the existing budget across selected campaigns">
                <Icon name="Question" />
              </Tooltip>
            </div>
            <div className={sharedStyles.alert}>
              {higherTotalSpent ? (
                <Icon className={sharedStyles.infoIcon} name="ErrorTriangle" />
              ) : (
                <Icon className={sharedStyles.infoIcon} name="Info" />
              )}
              {alertText}
            </div>
          </div>
        )}
        {updating ? (
          <div className="ml-auto mr-auto">
            <Spinner status={updating} onClose={onClose} />
          </div>
        ) : null}
      </DialogContent>
      <DialogFooter headerFooterBorders>
        <div className="d-flex flex-row justify-content-end">
          <Button
            onClick={onClose}
            className="_md btn-square _conflower-blue mr-3"
            disabled={!!updating}
          >
            Cancel
          </Button>
          <Button
            onClick={onClickChange}
            className="_md btn-square _conflower-blue _filled"
            disabled={
              !!updating ||
              !value ||
              !!higherTotalSpent ||
              (totalBudgetOption === IOBudgetActions.SET && value === initialValue)
            }
          >
            Change
          </Button>
        </div>
      </DialogFooter>
    </Dialog>
  );
};

const mapState = (state: AppState) => ({
  selectedInsertionOrders: state.table.selectedTableInsertionOrders,
  isWorkspaceOrSuperUser:
    state.auth.userData.isPlatformOwnerOrg || state.auth.userData.isWorkspaceOwnerOrg,
});

const mapAction = {
  updateInsertionOrdersBudget: tableActions.updateInsertionOrdersBudget,
  getTotal: statisticsActions.getTotal,
  setSelectedInsertionOrders: tableActions.setSelectedInsertionOrders,
};

export const SetInsertionOrderTotalBudgetDialog = connect<any, any, any, any>(
  mapState,
  mapAction,
)(SetInsertionOrderTotalBudgetDialogComponent);
