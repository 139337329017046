import { Action } from '../../models/Action';
import { appConstants } from './constants';

export const appActions = {
  toggleSidebar(value): Action<boolean> {
    return {
      type: appConstants.TOGGLE_SIDEBAR,
      payload: value,
    };
  },
  toggleVirtualizedListOpen(isOpen: boolean): Action<boolean> {
    return {
      type: appConstants.TOGGLE_VIRTUALIZED_LIST_OPEN,
      payload: isOpen,
    };
  },
};
