import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { reportsConstants } from './constants';
import { StatisticReportModel } from '../../models/Statistics';
import { Option } from '../../models/Option';
import {
  MetricOptions,
  DimensionOptions,
  DimensionOption,
  MetricOption,
} from '../../models/Report';
import { LoadingStatus } from '../../models/LoadingStatus';

export interface StatisticReport extends StatisticReportModel {
  name: string;
}

export interface ReportsState {
  dimension: DimensionOption | null;
  metric: MetricOption | null;
  types: Option[];
  dimensionsMetricsLoading: LoadingStatus;
  dimensionOptions: DimensionOptions;
  metricOptions: MetricOptions;
}

export const defaultReportState = {
  dimension: null,
  metric: null,
  types: [],
  dimensionsMetricsLoading: LoadingStatus.PENDING,
  dimensionOptions: [] as DimensionOptions,
  metricOptions: [] as MetricOptions,
};

function changeDimension(
  state: ReportsState,
  action: Action<DimensionOption | null>,
): ReportsState {
  return {
    ...state,
    dimension: action.payload,
  };
}

function changeMetric(state: ReportsState, action: Action<MetricOption | null>): ReportsState {
  return {
    ...state,
    metric: action.payload,
  };
}

function dimensionsMetricsLoadingStart(state: ReportsState): ReportsState {
  return {
    ...state,
    dimensionsMetricsLoading: LoadingStatus.LOADING,
  };
}

function dimensionsMetricsLoadingSuccess(
  state: ReportsState,
  action: Action<{ dimensionOptions: DimensionOptions; metricOptions: MetricOptions }>,
): ReportsState {
  return {
    ...state,
    dimensionsMetricsLoading: LoadingStatus.SUCCESS,
    ...action.payload,
  };
}

function dimensionsMetricsLoadingError(state: ReportsState) {
  return {
    ...state,
    dimensionsMetricsLoading: LoadingStatus.ERROR,
    dimensionOptions: [],
    metricOptions: [],
  };
}

function resetState(state: ReportsState): ReportsState {
  return {
    ...defaultReportState,
  };
}

const reducer = reducerFromMap(defaultReportState, {
  [reportsConstants.CHANGE_DIMENSION]: changeDimension,
  [reportsConstants.CHANGE_METRIC]: changeMetric,
  [reportsConstants.LOADING_DIMENSIONS_METRICS__START]: dimensionsMetricsLoadingStart,
  [reportsConstants.LOADING_DIMENSIONS_METRICS__SUCCESS]: dimensionsMetricsLoadingSuccess,
  [reportsConstants.LOADING_DIMENSIONS_METRICS__ERROR]: dimensionsMetricsLoadingError,
  [reportsConstants.RESET_STATE]: resetState,
});

export const reports = (state: ReportsState, action: Action<any>) => reducer(state, action);
