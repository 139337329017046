import React from 'react';
import { Snackbar } from 'factor';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { toastActions } from '../../store/toast/actions';
import styles from './styles.module.scss';

interface Props {
  children: JSX.Element;
  toastOpened: boolean;
  toastMessage: string | JSX.Element;
  closeToast: () => void;
}

class DashboardWrapperComponent extends React.Component<Props> {
  render() {
    const { children, toastOpened, closeToast, toastMessage } = this.props;
    return (
      <>
        <div className={`main ${styles.main}`}>{children}</div>
        <Snackbar
          open={toastOpened}
          onClose={closeToast}
          message={toastMessage}
          showCloseButton={true}
        />
      </>
    );
  }
}

const mapState = (state: AppState) => {
  return {
    toastOpened: state.toast.isOpen,
    toastMessage: state.toast.message,
  };
};

const mapActions = {
  closeToast: toastActions.close,
};

export const DashboardWrapper = connect(mapState, mapActions)(DashboardWrapperComponent);
