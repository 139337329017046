import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Icon } from 'factor';
import { AppState } from '../../../../../store';
import { LambdaResponse } from '../../../../../models/Response';
import { TableLevel } from '../../../../../models/Table';
import { SearchWrapper } from './SearchWrapper';
import { tableActions } from '../../../../../store/table/actions';
import { TableComponentInstance } from '../Table';

interface OwnProps {
  placeholder?: string;
}

interface StateProps {
  selectedCampaigns: any[];
  selectedInsertionOrders: LambdaResponse[];
  tableLevel: TableLevel | null;
}

interface DispatchProps {
  setSelectedCampaigns: (cmps: any[]) => void;
  setSelectedInsertionOrders: (ios: LambdaResponse[]) => void;
}

interface Props extends OwnProps, StateProps, DispatchProps {}

const SearchWrapperWithIconComponent = (props: Props) => {
  const {
    placeholder,
    selectedCampaigns,
    selectedInsertionOrders,
    tableLevel,
    setSelectedCampaigns,
    setSelectedInsertionOrders,
  } = props;
  const [isIcon, setIsIcon] = useState(false);
  const [focusOnMount, setFocusOnMount] = useState(false);

  const handleSearchOpen = () => {
    setIsIcon(false);
    setFocusOnMount(true);

    if (tableLevel === TableLevel.Campaigns) {
      setSelectedCampaigns([]);
    }
    if (tableLevel === TableLevel.InsertionOrders) {
      setSelectedInsertionOrders([]);
    }
    if (TableComponentInstance) {
      TableComponentInstance.clearSelected();
    }
  };

  const tableSelection = useMemo(() => {
    switch (tableLevel) {
      case TableLevel.Campaigns:
        return selectedCampaigns;
      case TableLevel.InsertionOrders:
        return selectedInsertionOrders;
      default:
        return null;
    }
  }, [tableLevel, selectedCampaigns, selectedInsertionOrders]);

  useEffect(() => {
    setFocusOnMount(false);
  }, [tableLevel, tableSelection]);

  useEffect(() => {
    if (tableSelection && tableSelection.length) {
      setIsIcon(true);
    } else {
      setIsIcon(false);
    }
  }, [tableSelection]);

  if (isIcon) {
    return (
      <Button
        iconName="Search"
        onClick={handleSearchOpen}
        className="mx-2 _cornflower-blue  btn-circle"
      >
        <Icon name="Search" />
      </Button>
    );
  }

  return (
    <div className="mx-3">
      <SearchWrapper placeholder={placeholder} focusOnMount={focusOnMount} />
    </div>
  );
};

const mapState = (state: AppState) => ({
  selectedCampaigns: state.table.selectedTableCampaigns,
  selectedInsertionOrders: state.table.selectedTableInsertionOrders,
  tableLevel: state.filter.tableLevel ? state.filter.tableLevel.value : null,
});

const mapAction = {
  setSelectedCampaigns: tableActions.addSelectedCampaign,
  setSelectedInsertionOrders: tableActions.setSelectedInsertionOrders,
};

export const SearchWrapperWithIcon = connect<any, any, any, any>(
  mapState,
  mapAction,
)(SearchWrapperWithIconComponent) as React.FunctionComponent<OwnProps>;
