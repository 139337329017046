import React from 'react';

import { Radio } from '../Radio/Radio';
import styles from './styles.module.scss';

interface Props {
  options: { label: React.ReactNode; value: number | string }[];
  label: string;
  value: number | string | null;
  onChange: (value: number | string) => void;
  buttonListClassName?: string;
}

export const RadioGroup = (props: Props) => {
  const { options, label, value, onChange, buttonListClassName } = props;

  return (
    <div className={styles.container}>
      <label className={styles.label}>{label}</label>
      <div className={`${styles.buttonList} ${buttonListClassName || ''}`}>
        {options.map(({ label, value: optionValue }) => (
          <Radio
            title={label}
            checked={optionValue === value}
            onChange={() => {
              onChange(optionValue);
            }}
          />
        ))}
      </div>
    </div>
  );
};
