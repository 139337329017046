import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router';
import { HashRouter as Router } from 'react-router-dom';
import {
  DownloadCsvProgress,
  DownloadXlsxProgress,
  getOwId,
  getPathNumber,
  DataDogRumRoute,
  AuthService,
} from 'iqm-framework';
import { QueryClientProvider } from '@tanstack/react-query';

import { queryClient } from './cache';
import { API } from './api';
import { User } from './models/User';
import { authActions, Login, HandleProfileLoaded } from './store/auth2/actions';
import { toastActions, Open } from './store/toast/actions';
import { Dashboard } from './pages/Dashboard';
import { localStorageService } from './services/localStorage';
import { AppState } from './store';
import { GENERAL_API_ERROR } from './constants/tooltips';

import './scss/index.scss';

type Props = Login &
  HandleProfileLoaded & {
    openToast: Open['open'];
    authorized: boolean;
  };

const AppComponent = (props: Props) => {
  const { authorized, login, handleProfileLoaded, openToast } = props;
  const userData = localStorageService.getBaseInfo<User>();
  const userId = getOwId();

  useEffect(() => {
    if (userId && userData) {
      if (userData.failureDetails && userData.failureDetails.status === 'SUSPENDED') {
        window.location.href = `/marketplace/u/${getPathNumber()}/#/suspended`;
        return;
      }
      login({ ...userData, userId: +userId });
      AuthService.authorize(userData, true)
        .then(async (res: User) => {
          const response = await API.advertisers.getCustomerConfig(getOwId());
          handleProfileLoaded({
            ...res,
            userId: +userId,
            isBidShadingEnabled: !!response.data?.isBidShadingEnabled,
            isVldEnabled: !!response.data?.isVldEnabled,
            hadVldGenerated: !!response.data?.hadVldGenerated,
          });
        })
        .catch((err: Error) => {
          openToast(GENERAL_API_ERROR);
        });
    }

    if (!userData && !userId && !authorized) {
      window.location.href = `/#/login`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login]);

  return authorized ? (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Switch>
          <DataDogRumRoute
            key="/download-xls/:params"
            path="/download-xlsx"
            component={DownloadXlsxProgress}
            exact
          />
          <DataDogRumRoute
            key="/download-csv/:params"
            path="/download-csv"
            component={DownloadCsvProgress}
            exact
          />
          <DataDogRumRoute key="/" path="*" component={Dashboard} />
        </Switch>
      </Router>
    </QueryClientProvider>
  ) : null;
};

const mapState = (state: AppState) => ({
  userData: state.auth.userData,
  authorized: state.auth.authorized,
});

const mapActions = {
  login: authActions.login,
  handleProfileLoaded: authActions.handleProfileLoaded,
  openToast: toastActions.open,
};

export const App = connect(mapState, mapActions)(AppComponent);
