import React from 'react';
import { Button, Checkbox, DialogContent } from 'factor';

import { localStorageService } from '../../../../../../services/localStorage';
import { Dashboard } from '../../../../../../models/Dashboards';

interface Props {
  dashboard: Dashboard;
  onClose: () => void;
  onDelete: () => void;
}

interface State {
  remember: boolean;
  showModal: boolean;
}

const STORAGE_NAME = 'deleteDashboard';

class DeleteDashboardDialogContentComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      remember: false,
      showModal: this.checkShowingModal(),
    };
  }

  handleChBoxChange = (value: boolean) => {
    this.setState({
      remember: value,
    });
  };

  handleDelete = () => {
    if (this.state.remember) {
      localStorageService.saveLocal<boolean>(STORAGE_NAME, true);
    }
    this.props.onDelete();
  };

  checkShowingModal = () => {
    const { onClose, onDelete } = this.props;
    const store = localStorageService.getLocal(STORAGE_NAME);
    if (store) {
      onDelete();
      onClose();
    }
    return !store;
  };

  render() {
    const { onClose, dashboard } = this.props;
    const { remember } = this.state;

    return (
      <DialogContent>
        <h3 className="title-card mb-4">Delete Dashboard?</h3>
        <h5 className="title-card-subtitle mb-4">
          Delete the <span className="_black">{dashboard.label}</span> dashboard?
        </h5>
        <div className="d-flex justify-content-end align-items-center">
          <div className="mr-3 flex-grow-1">
            <Checkbox
              label="Don't ask me again"
              checked={remember}
              onChange={this.handleChBoxChange}
            />
          </div>
          <Button className="btn-square _conflower-blue mr-2 _md" onClick={onClose}>
            Cancel
          </Button>
          <Button className="btn-square _persimmon _filled _md" onClick={this.handleDelete}>
            Delete
          </Button>
        </div>
      </DialogContent>
    );
  }
}

export const DeleteDashboardDialogContent = DeleteDashboardDialogContentComponent;
