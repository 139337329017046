import { Dispatch } from 'redux';
import { AppState } from '../';
import { statisticsActions } from '../statistics/actions';
import { filterActions } from '../filter/actions';
import { reportsActions } from '../reports/actions';
import { dashboardsActions } from '../dashboards/actions';

export const commonActions = {
  getRelatedData() {
    return async (dispatch: Dispatch<any>, getState: () => AppState) => {
      const state = getState();
      const { isCustomersDropdownLoaded } = state.filter;
      if (isCustomersDropdownLoaded) {
        dispatch(filterActions.getStatuses());
      }

      dispatch(filterActions.getTimezones());
    };
  },
  getRelatedLambdaData() {
    return async (dispatch: Dispatch<any>) => {
      dispatch(statisticsActions.getPrevTotal());
    };
  },
  getLists() {
    return async (dispatch: Dispatch<any>) => {
      return Promise.allSettled([
        dispatch(dashboardsActions.getDashboards()),
        dispatch(reportsActions.getDimensionsAndMetrics()),
      ]);
    };
  },
};
