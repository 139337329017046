import axios from 'axios';
import { getInstance } from './index';
import { Profile } from '../models/Profile';
import { HEADER_TOKEN_NAME, IQM_BASE_URL, PANDA_API_URL } from '../config';

const PandaBaseUrl = PANDA_API_URL;

export function getProfile(): Promise<Profile> {
  return getInstance()
    .get('/manage/profile')
    .then((response) => response.data)
    .catch((err) => Promise.reject(err));
}

export function getAvatar(token: string): Promise<{ avatar: string }> {
  const { protocol } = window.document.location;
  return axios
    .get(`${protocol}//${PandaBaseUrl}/avatar-lambda-api/avatar`, {
      headers: {
        [HEADER_TOKEN_NAME]: token,
        Authorization: 'Basic YWRtaW46VHJFMnVNb1A=',
        'Base-Url': IQM_BASE_URL,
      },
    })
    .then((response) => response.data)
    .catch((err) => Promise.reject(err.response));
}

export function getAvailableBalance(): Promise<{ availableBalance: number }> {
  return getInstance()
    .get('/v3/fa/available-balance?isCustomerRequest=false')
    .then((response) => response.data)
    .then((data) => ({ availableBalance: data.data }))
    .catch((err) => Promise.reject(err.response));
}
