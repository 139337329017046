import React from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { Button } from 'factor';
import { CampaignsActions as Actions } from '../../../../../../../models/CampaignsActions';
import { AppState } from '../../../../../../../store';
import { Campaign } from '../../../../../../../models/Campaign';
import { ButtonsDialog } from './ButtonsDialog';

import './styles.scss';
import { AutoTooltip } from '../../../../../../../components/AutoTooltip';

interface PauseButtonProps {
  selectedCampaigns: Campaign[];
  tooltip?: string;
  disabled?: boolean;
}
interface PauseButtonState {
  action: string | null;
  dialogIsOpen: boolean;
}

class PauseButtonComponent extends React.Component<PauseButtonProps, PauseButtonState> {
  constructor(props) {
    super(props);

    this.state = {
      action: null,
      dialogIsOpen: false,
    };
  }

  closeDialog = () => {
    this.setState({
      dialogIsOpen: false,
    });
  };

  actionHandler = (action) => {
    this.setState({
      dialogIsOpen: true,
      action,
    });
  };

  render() {
    const { selectedCampaigns, disabled, tooltip } = this.props;

    return selectedCampaigns.find((campaign) => campaign.status === 'running') ? (
      <>
        <AutoTooltip label={tooltip} portal auto>
          <Button
            className="btn-square _conflower-blue campaign-actions-button__btn _pause-button"
            onClick={() => this.actionHandler(Actions.pause)}
            disabled={disabled}
          >
            Pause
          </Button>
        </AutoTooltip>
        {createPortal(
          <ButtonsDialog
            selectedCampaigns={selectedCampaigns}
            dialogIsOpen={this.state.dialogIsOpen}
            action={this.state.action}
            close={this.closeDialog}
          />,
          document.body,
        )}
      </>
    ) : null;
  }
}

const pauseButtonMapState = (state: AppState) => ({
  selectedCampaigns: state.table.selectedTableCampaigns,
});

export const PauseButton = connect(pauseButtonMapState)(PauseButtonComponent);
