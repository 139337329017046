import React, { useMemo } from 'react';
import { BtnMenu } from '../components/BtnMenu';
import { Icon } from 'factor';
import { OptimizeItem } from './OptimizeItem';
import { ReportItem } from './ReportItem';
import { BiddingInsightsItem } from './BiddingInsightsItem';
import { VLDInsightsItem } from './VLDInsightsItem';
import { Application } from '../../../../../../../models/Application';
import { AppState } from '../../../../../../../store';
import { connect } from 'react-redux';
import {
  BID_MODEL_APP_NAME,
  INSIGHTS_APP_NAME,
  REPORT_APP_NAME,
} from '../../../../../../../constants/applications';

interface Props {
  allowedApplications: Application[];
}

export const OpenInAppMenuListComponent = (props: Props) => {
  const { allowedApplications } = props;

  const [isOpen, setIsOpen] = React.useState(false);

  const isReportAppAllowed = useMemo(
    () =>
      !!(allowedApplications && allowedApplications.find((app) => app.appName === REPORT_APP_NAME)),
    [allowedApplications],
  );

  const isBidModelAppAllowed = React.useMemo(
    () =>
      !!(
        allowedApplications && allowedApplications.find((app) => app.appName === BID_MODEL_APP_NAME)
      ),
    [allowedApplications],
  );

  const isInsightsAppAllowed = React.useMemo(
    () =>
      !!(
        allowedApplications && allowedApplications.find((app) => app.appName === INSIGHTS_APP_NAME)
      ),
    [allowedApplications],
  );

  if (!isReportAppAllowed && !isBidModelAppAllowed && !isInsightsAppAllowed) {
    return null;
  }

  return (
    <BtnMenu
      isOpen={isOpen}
      open={() => {
        setIsOpen(true);
      }}
      close={() => {
        setIsOpen(false);
      }}
      className="filter__btn _change-campaigns"
      disabled={false}
      content={
        <div className="btn-select">
          <button className="btn-select__title">More</button>
          <div className="btn-select__buttons">
            <ReportItem isAppAllowed={isReportAppAllowed} />
            <OptimizeItem isAppAllowed={isBidModelAppAllowed} />
            <BiddingInsightsItem isAppAllowed={isInsightsAppAllowed} />
            <VLDInsightsItem isAppAllowed={isInsightsAppAllowed} />
          </div>
        </div>
      }
    >
      <span className="btn-square__prefix">
        <Icon name="Expand" />
      </span>
      More
    </BtnMenu>
  );
};

const mapState = (state: AppState) => ({
  allowedApplications: state.filter.allowedApplications,
});

export const OpenInAppMenuList = connect<any, any, any, any>(
  mapState,
  null,
)(OpenInAppMenuListComponent);
