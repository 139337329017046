import axios, { AxiosInstance } from 'axios';
import { getHeaders } from 'iqm-framework';

import { InstanceConfig } from '../index';
import { LAMBDA_API_URL_V3 } from '../../config';
import {
  getDimensionsAndMetrics,
  getReportsGraphData,
  getCampaignReportsGraphData,
} from './ReportsGraphData';
import {
  getCampaignStatistics,
  cancelGetCampaignStatistics,
  getAdvertisersStatistics,
  cancelGetAdvertisersStatistics,
  getWorkspacesStatistics,
  cancelGetWorkspacesStatistics,
  getExchangesStatistics,
  cancelGetExchangesStatistics,
  getInsertionOrdersStatistics,
  cancelGetInsertionOrdersStatistics,
  getCampaignPrevStatistics,
  cancelGetCampaignPrevStatistics,
  getAdvertisersPrevStatistics,
  cancelGetAdvertisersPrevStatistics,
  getWorkspacesPrevStatistics,
  cancelGetWorkspacesPrevStatistics,
  getExchangesPrevStatistics,
  cancelGetExchangesPrevStatistics,
  getInsertionOrdersPrevStatistics,
  cancelGetInsertionOrdersPrevStatistics,
} from './CommonData';
import { getGraphData } from './GraphData';
import { getCampaignsData } from './SupplementalData';

let lambdaInstance: AxiosInstance;

export function createLambdaV3Instance(config: InstanceConfig) {
  const instance = axios.create({
    baseURL: LAMBDA_API_URL_V3,
    headers: {
      ...getHeaders({ apiToken: config.token }),
    },
  });

  instance.interceptors.response.use(
    (response: any) => {
      return response;
    },
    (response: any) => {
      if (axios.isCancel(response)) {
        // eslint-disable-next-line no-throw-literal
        throw {
          response: {
            data: response,
          },
        };
      }
      return Promise.reject(response);
    },
  );

  lambdaInstance = instance;
}

export function getLambdaV3Instance(): AxiosInstance {
  return lambdaInstance || axios;
}

export const lambdaV3API = {
  reportsGraph: {
    getDimensionsAndMetrics,
    getReportsGraphData,
    getCampaignReportsGraphData,
  },
  graph: {
    getGraphData,
  },
  common: {
    getCampaignStatistics,
    cancelGetCampaignStatistics,
    getAdvertisersStatistics,
    cancelGetAdvertisersStatistics,
    getWorkspacesStatistics,
    cancelGetWorkspacesStatistics,
    getExchangesStatistics,
    cancelGetExchangesStatistics,
    getInsertionOrdersStatistics,
    cancelGetInsertionOrdersStatistics,
    getCampaignPrevStatistics,
    cancelGetCampaignPrevStatistics,
    getAdvertisersPrevStatistics,
    cancelGetAdvertisersPrevStatistics,
    getWorkspacesPrevStatistics,
    cancelGetWorkspacesPrevStatistics,
    getExchangesPrevStatistics,
    cancelGetExchangesPrevStatistics,
    getInsertionOrdersPrevStatistics,
    cancelGetInsertionOrdersPrevStatistics,
  },
  supplemental: {
    getCampaignsData,
  },
};
