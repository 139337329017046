import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Tooltip, Icon } from 'factor';
import { IO_STATUS_ID } from 'iqm-framework';
import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';
import { LambdaResponse } from '../../../../../models/Response';
import { AppState } from '../../../../../store';
import { tableActions, SetSelectedInsertionOrders } from '../../../../../store/table/actions';
import {
  statisticsActions,
  GetStatisticsTotalStart,
} from '../../../../../store/statistics/actions';
import { SetInsertionOrderEndDateDialog } from './SelectedInsertionOrders/SetInsertionOrderEndDateDialog';
import { SetInsertionOrderTotalBudgetDialog } from './SelectedInsertionOrders/SetInsertionOrderTotalBudgetDialog';
import { DeleteInsertionOrderDialog } from './SelectedInsertionOrders/DeleteInsertionOrderDialog';
import { DuplicateInsertionOrderDialog } from './SelectedInsertionOrders/DuplicateInsertionOrderDialog';
import { TableComponentInstance } from '../Table';
import {
  AUTO_SUM_IO_BUDGET_WARNING,
  AUTO_SUM_IO_BUDGET_WARNING_SINGLE,
} from '../../../../../constants/tooltips';
import { CAMPAIGN_BUDGET_TYPE } from '../../../../../constants';
import { BtnMenu } from './selectedCampaigns/components/BtnMenu';
import { SelectInsertionOrdersWrapperRef } from './SelectInsertionOrdersWrapper';
import { ReportButton } from '../../../../../components/ReportButton';
import styles from './SelectedInsertionOrders/styles.module.scss';

interface Props extends GetStatisticsTotalStart {
  selectedTableInsertionOrders: LambdaResponse[];
  setSelectedTableInsertionOrders: SetSelectedInsertionOrders['setSelectedInsertionOrders'];
  isSuperOrWorkspace: boolean;
}

enum IODialog {
  NONE,
  CHANGE_END_DATE,
  CHANGE_TOTAL_BUDGET,
  DELETE_INSERTION_ORDER,
  DUPLICATE_INSERTION_ORDER,
}

const SelectedInsertionOrdersComponent = (props: Props) => {
  const {
    selectedTableInsertionOrders,
    setSelectedTableInsertionOrders,
    isSuperOrWorkspace,
    getStatisticsTotalStart,
  } = props;
  const [openedDialog, setOpenedDialog] = useState<IODialog>(IODialog.NONE);
  const [isActionsMenuOpen, setIsActionsMenuOpen] = useState(false);

  const handleClear = () => {
    if (TableComponentInstance) {
      TableComponentInstance.clearSelected();
    }
    setSelectedTableInsertionOrders([]);
  };

  const handleClose = () => {
    setOpenedDialog(IODialog.NONE);
  };

  const totalItems = get(TableComponentInstance, 'state.totalItems');
  const selectedPillText = totalItems ? (
    <>
      {selectedTableInsertionOrders.length} out of {totalItems} selected
    </>
  ) : (
    <>{selectedTableInsertionOrders.length} selected</>
  );

  const isAutoSumIoTotalBudget = useMemo(
    () =>
      selectedTableInsertionOrders &&
      selectedTableInsertionOrders.some((io) => (io as any).isAutoSumIoTotalBudget),
    [selectedTableInsertionOrders],
  );

  const differentOwIdsSelected = isSuperOrWorkspace
    ? !selectedTableInsertionOrders.every(
        (io) => (io as any).owId === (selectedTableInsertionOrders[0] as any).owId,
      )
    : false;

  const {
    isAllIODeleted,
    isSomeIODeleted,
    isAllIOExpired,
    isSomeIOExpired,
    isSomeImpressionIOs,
  } = useMemo(() => {
    if (!selectedTableInsertionOrders.length) {
      return {
        isAllIODeleted: false,
        isSomeIODeleted: false,
        isAllIOExpired: false,
        isSomeIOExpired: false,
        isSomeImpressionIOs: false,
      };
    }

    const init = {
      isAllIODeleted: true,
      isSomeIODeleted: false,
      isAllIOExpired: true,
      isSomeIOExpired: false,
      isSomeImpressionIOs: false,
    };

    return selectedTableInsertionOrders.reduce((acc, curr) => {
      return {
        isAllIODeleted: acc.isAllIODeleted && curr.ioStatusId === IO_STATUS_ID.DELETED,
        isSomeIODeleted: acc.isSomeIODeleted || curr.ioStatusId === IO_STATUS_ID.DELETED,
        isAllIOExpired: acc.isAllIOExpired && curr.ioStatusId === IO_STATUS_ID.EXPIRED,
        isSomeIOExpired: acc.isSomeIOExpired || curr.ioStatusId === IO_STATUS_ID.EXPIRED,
        isSomeImpressionIOs:
          acc.isSomeImpressionIOs ||
          (curr as any).budgetTypeId === CAMPAIGN_BUDGET_TYPE.IMPRESSION_BASED,
      };
    }, init);
  }, [selectedTableInsertionOrders]);

  const isDifferentBudgetTypesIOs = useMemo(
    () => uniqBy(selectedTableInsertionOrders, (io) => io.ioBudgetTypeId).length > 1,
    [selectedTableInsertionOrders],
  );

  const totalBudgetButtonDisabledTooltip = useMemo(() => {
    if (isSomeIODeleted) {
      return 'Total Budget can’t edited for the Deleted IO';
    }
    if (isSomeIOExpired) {
      return `Total budget can't be edited for the Expired IO`;
    }
    if (differentOwIdsSelected) {
      return 'Total budget can’t be updated for the IOs from different organizations';
    }
    if (isDifferentBudgetTypesIOs) {
      return `Total budget can't be edited for the IOs with different budget types`;
    }
    if (isSomeImpressionIOs) {
      return 'Budget is not editable for impression based IOs.';
    }
    if (isAutoSumIoTotalBudget) {
      return selectedTableInsertionOrders.length > 1
        ? AUTO_SUM_IO_BUDGET_WARNING
        : AUTO_SUM_IO_BUDGET_WARNING_SINGLE;
    }
    return null;
  }, [
    selectedTableInsertionOrders.length,
    isSomeIODeleted,
    isDifferentBudgetTypesIOs,
    differentOwIdsSelected,
    isSomeImpressionIOs,
    isAutoSumIoTotalBudget,
    isSomeIOExpired,
  ]);

  const setTotalBudgetButton = useMemo(() => {
    const button = (
      <Button
        className="btn-square _conflower-blue mr-2"
        onClick={
          !totalBudgetButtonDisabledTooltip
            ? () => setOpenedDialog(IODialog.CHANGE_TOTAL_BUDGET)
            : undefined
        }
        disabled={!!totalBudgetButtonDisabledTooltip}
      >
        Set Total Budget
      </Button>
    );

    return !totalBudgetButtonDisabledTooltip ? (
      button
    ) : (
      <Tooltip label={totalBudgetButtonDisabledTooltip} portal auto>
        {button}
      </Tooltip>
    );
  }, [totalBudgetButtonDisabledTooltip]);

  const endDateBtn = useMemo(() => {
    let disabledBtnTooltip = '';
    if (differentOwIdsSelected) {
      disabledBtnTooltip = 'End date can’t be updated for the IOs from different organizations';
    }
    if (isSomeIODeleted) {
      disabledBtnTooltip = 'End Date can’t be edited for the Deleted IO';
    }

    const btn = (
      <Button
        className="btn-square _conflower-blue mr-2"
        onClick={() => setOpenedDialog(IODialog.CHANGE_END_DATE)}
        disabled={!!disabledBtnTooltip}
      >
        Set End Date
      </Button>
    );

    if (disabledBtnTooltip) {
      return (
        <Tooltip label={disabledBtnTooltip} portal>
          {btn}
        </Tooltip>
      );
    }
    return btn;
  }, [differentOwIdsSelected, isSomeIODeleted]);

  const onIOModificationSuccess = () => {
    setSelectedTableInsertionOrders([]);

    if (TableComponentInstance) {
      TableComponentInstance.setState({
        selected: [],
        loading: true,
      });

      TableComponentInstance.getNewData();
    }

    getStatisticsTotalStart();

    if (SelectInsertionOrdersWrapperRef) {
      SelectInsertionOrdersWrapperRef.setState(
        {
          pageNo: 1,
          searchField: '',
          totalRecords: 0,
          filteredRecords: 0,
        },
        () => {
          if (SelectInsertionOrdersWrapperRef) {
            SelectInsertionOrdersWrapperRef.loadData();
          }
        },
      );
    }
  };

  const handleActionMenuSelection = (dialog: IODialog) => {
    setOpenedDialog(dialog);
    setIsActionsMenuOpen(false);
  };

  return (
    <>
      <div className={`${styles.wrapper} ${!selectedTableInsertionOrders.length ? 'd-none' : ''}`}>
        <div className="filter__selected mr-2">
          <button className="btn-close filter__remove" onClick={handleClear} />
          {selectedPillText}
        </div>
        {
          <BtnMenu
            isOpen={isActionsMenuOpen}
            open={() => setIsActionsMenuOpen(true)}
            close={() => setIsActionsMenuOpen(false)}
            className="filter__btn mr-2"
            disabled={selectedTableInsertionOrders.length > 1 && isSomeIODeleted}
            tooltipParams={
              isSomeIODeleted
                ? { label: 'No bulk actions available for the deleted IOs' }
                : undefined
            }
            content={
              <div className="btn-select">
                <button className="btn-select__title" onClick={() => setIsActionsMenuOpen(false)}>
                  Actions
                </button>
                <div className="btn-select__buttons">
                  {!isSuperOrWorkspace && (
                    <button
                      className="btn-select__btn"
                      onClick={() => handleActionMenuSelection(IODialog.DUPLICATE_INSERTION_ORDER)}
                      disabled={selectedTableInsertionOrders.length > 1}
                    >
                      <Icon name="Copy" className={`mr-2 ${styles.icon20}`} />
                      Duplicate
                    </button>
                  )}
                  <button
                    className="btn-select__btn"
                    onClick={() => handleActionMenuSelection(IODialog.DELETE_INSERTION_ORDER)}
                    disabled={isSomeIODeleted}
                  >
                    <Icon name="DeleteAlt" className={`mr-2 ${styles.iconGrey}`} />
                    Delete
                  </button>
                </div>
              </div>
            }
          >
            <span className="btn-square__prefix">
              <Icon name="Expand" />
            </span>
            Actions
          </BtnMenu>
        }

        {!isAllIODeleted && !isAllIOExpired && setTotalBudgetButton}

        {!isAllIODeleted && endDateBtn}

        <ReportButton />
      </div>

      {openedDialog === IODialog.CHANGE_TOTAL_BUDGET ? (
        <SetInsertionOrderTotalBudgetDialog onClose={handleClose} />
      ) : null}
      {openedDialog === IODialog.CHANGE_END_DATE ? (
        <SetInsertionOrderEndDateDialog onClose={handleClose} />
      ) : null}
      {openedDialog === IODialog.DELETE_INSERTION_ORDER ? (
        <DeleteInsertionOrderDialog
          insertionOrders={selectedTableInsertionOrders}
          onClose={handleClose}
          onDeleteSuccess={onIOModificationSuccess}
        />
      ) : null}
      {openedDialog === IODialog.DUPLICATE_INSERTION_ORDER ? (
        <DuplicateInsertionOrderDialog
          insertionOrder={selectedTableInsertionOrders[0] as any}
          onClose={handleClose}
          onDuplicateSuccess={onIOModificationSuccess}
        />
      ) : null}
    </>
  );
};

const mapState = (state: AppState) => ({
  selectedTableInsertionOrders: state.table.selectedTableInsertionOrders,
  isSuperOrWorkspace:
    state.auth.userData.isPlatformOwnerOrg || state.auth.userData.isWorkspaceOwnerOrg,
});

const mapAction = {
  setSelectedTableInsertionOrders: tableActions.setSelectedInsertionOrders,
  getStatisticsTotalStart: statisticsActions.getStatisticsTotalStart,
};

export const SelectedInsertionOrders = connect<any, any, any, any>(
  mapState,
  mapAction,
)(SelectedInsertionOrdersComponent);
