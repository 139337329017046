import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Moment } from 'moment';
import {
  TimezoneProvider,
  TimezonePicker,
  EpochDateRangePicker,
  NestedCustomerSelect,
} from 'iqm-framework';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';

import { OptionID } from '../../../../models/Option';
import { AppState } from '../../../../store';
import {
  filterActions,
  SelectTimezone,
  SetWorkspacesOwIds,
  SetAdvertisersOwIds,
  SetIsCustomersDropdownLoaded,
  SetIsCustomersDropdownError,
  SetIsCustomersDropdownEmpty,
  GetStatuses,
  GetCreativeTypes,
  SetIsAllAdvertisersSelected,
} from '../../../../store/filter/actions';
import { DateRange } from '../../../../store/filter/reducers';
import { tableActions, SetFilteredCampaignsIds } from '../../../../store/table/actions';
import { commonActions } from '../../../../store/common/actions';
import { toastActions } from '../../../../store/toast/actions';
import { Dashboard } from '../../../../models/Dashboards';
import { AuthState } from '../../../../store/auth2/reducer';
import { hasNoCustomers } from '../../../../utils/errors';
import { NO_CUSTOMER_ASSIGNED } from '../../../../constants/tooltips';
import { LoadingStatus } from '../../../../models/LoadingStatus';
import {
  getCalendarMin,
  getCalendarMax,
  getDefaultDateRange,
  getDatesOnMinMaxUpdate,
  PRESELECTED,
} from './utils';
import '../../../../components/dropdown/styles.scss';

interface Props
  extends SelectTimezone,
    SetWorkspacesOwIds,
    SetAdvertisersOwIds,
    SetIsCustomersDropdownLoaded,
    SetIsCustomersDropdownError,
    SetIsCustomersDropdownEmpty,
    GetStatuses,
    GetCreativeTypes,
    SetFilteredCampaignsIds,
    SetIsAllAdvertisersSelected {
  isAuth: boolean;
  isProfileLoaded: boolean;
  className?: string;
  dateRange: DateRange | null;
  defaultDateRangeWasUpdated: boolean;
  timezone: OptionID | null;
  timezones: OptionID[];
  selectedDashboard: Dashboard | null;
  profile: AuthState;
  getRelatedData: () => void;
  setDateRange: (item: DateRange) => void;
  setInitialDateRange: (item: DateRange) => void;
  openToast: (message: string | JSX.Element) => void;
  isCustomersDropdownAllowed: boolean;
  isCustomersDropdownLoaded: boolean;
  hasNoCustomers: boolean;
  advertisersOwIds: string;
  workspacesOwIds: string;
  isAllAdvertisersSelected: boolean | null;
  isDashboardsLoading: boolean;
  dashboards: Dashboard[];
  isCustomersDropdownMounted: boolean;
  setIsCustomersDropdownMounted: (isMounted: boolean) => void;
}

const DatesContainerComponent = (props: Props) => {
  const {
    timezone,
    isAuth,
    dateRange,
    openToast,
    advertisersOwIds,
    isProfileLoaded,
    isCustomersDropdownAllowed,
    isCustomersDropdownLoaded,
    setIsCustomersDropdownLoaded,
    setIsCustomersDropdownEmpty,
    setIsCustomersDropdownError,
    hasNoCustomers,
    getStatuses,
    getCreativeTypes,
    getRelatedData,
    setInitialDateRange,
    setDateRange,
    setAdvertisersOwIds,
    setWorkspacesOwIds,
    className,
    timezones,
    selectTimezone,
    profile,
    selectedDashboard,
    defaultDateRangeWasUpdated,
    isAllAdvertisersSelected,
    isDashboardsLoading,
    setIsAllAdvertisersSelected,
    dashboards,
    workspacesOwIds,
    isCustomersDropdownMounted,
    setIsCustomersDropdownMounted,
  } = props;

  const [calendarMinimumDate, setCalendarMinimumDate] = useState<Moment | null>(
    getCalendarMin(timezone),
  );
  const [calendarMaximumDate, setCalendarMaximumDate] = useState<Moment | null>(
    getCalendarMax(timezone),
  );
  const prevTimezone = useRef<OptionID | null>(timezone);
  const prevCustomersSelection = useRef<any[]>([]);

  useEffect(() => {
    if (isAuth) {
      getRelatedData();
      getCreativeTypes();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  useEffect(() => {
    if (!isCustomersDropdownMounted) {
      setTimeout(() => setIsCustomersDropdownMounted(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomersDropdownMounted]);

  useEffect(() => {
    if (!isEqual(timezone, prevTimezone.current)) {
      setCalendarMinimumDate(getCalendarMin(timezone));
      setCalendarMaximumDate(getCalendarMax(timezone));
    }
    prevTimezone.current = timezone;
  }, [timezone]);

  useEffect(() => {
    if (!isCustomersDropdownAllowed && isProfileLoaded) {
      setIsCustomersDropdownLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  useEffect(() => {
    if (isCustomersDropdownLoaded) {
      getStatuses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomersDropdownLoaded]);

  const handleDateChange = ({ startDate: start, endDate: end }) => {
    const isInitialDateRange = !dateRange;

    const range = {
      start,
      end,
    };

    isInitialDateRange ? setInitialDateRange(range) : setDateRange(range);
  };

  const onSelectAllCustomers = (_allSelected: any, selectedAdvertisers: any) => {
    setTimeout(() => {
      setIsAllAdvertisersSelected(true);
      setAdvertisersOwIds((selectedAdvertisers || []).join(','));
    }, 1);
  };

  const onLoadCustomersDropdown = (allSelected: number[], selectedAdvertisers: any) => {
    setIsAllAdvertisersSelected(true);
    setIsCustomersDropdownEmpty(!get(allSelected, 'length'));
    if (hasNoCustomers) {
      openToast(NO_CUSTOMER_ASSIGNED);
    }
    onSelectAllCustomers(allSelected, selectedAdvertisers);
    setTimeout(() => {
      setIsCustomersDropdownLoaded(true);
      setIsAllAdvertisersSelected(true);
      getStatuses();
    }, 1);
  };

  const onErrorCustomerDropdown = () => {
    openToast('Something went wrong. Please try again over a few minutes.');
    if (!isCustomersDropdownLoaded) {
      setIsCustomersDropdownEmpty(true);
    }
    setIsCustomersDropdownError(true);
    setIsCustomersDropdownLoaded(true);
  };

  const onChangeAdvertiserSelection = (selectedAdvertisers: any) => {
    setAdvertisersOwIds(selectedAdvertisers);
    getStatuses();
  };

  const onChangeSelection = (selection: any) => {
    if (get(selection, 'length') !== get(prevCustomersSelection.current, 'length')) {
      setIsAllAdvertisersSelected(false);
    }

    prevCustomersSelection.current = selection;
  };

  const selectedOwIds = useMemo(() => {
    return (advertisersOwIds || '').length
      ? `${advertisersOwIds}${(workspacesOwIds || '') && ','}${workspacesOwIds || ''}`
      : workspacesOwIds || '';
  }, [workspacesOwIds, advertisersOwIds]);

  if (!isAuth) {
    return null;
  }

  if (
    !timezone &&
    !selectedDashboard &&
    profile.userData &&
    profile.userData.timezoneId &&
    timezones.length
  ) {
    const userTimezone = timezones.find((tz) => tz.id === profile.userData.timezoneId);
    if (userTimezone) {
      selectTimezone(userTimezone);
    }
  }

  const dateRangeNew =
    defaultDateRangeWasUpdated && dateRange
      ? {
          startDate: dateRange.start.valueOf(),
          endDate: dateRange.end.valueOf(),
        }
      : null;

  return (
    <TimezoneProvider timezone={timezone}>
      <div className={className}>
        <div
          className={`dashboard-header__customers-select ${
            isCustomersDropdownLoaded ? '' : '_no-click'
          }`}
        >
          {!isDashboardsLoading &&
          isCustomersDropdownMounted &&
          !selectedDashboard &&
          dashboards.length ? (
            <NestedCustomerSelect
              onChangeSelection={onChangeSelection}
              onChangeAdvertiserSelection={
                isCustomersDropdownLoaded ? onChangeAdvertiserSelection : undefined
              }
              onChangeWorkspaceSelection={
                isCustomersDropdownLoaded ? setWorkspacesOwIds : undefined
              }
              stopClickPropagation={false}
              hideCurrentUser={true}
              selectAllOnLoad={true}
              selectAdvertisersOnWorkspaceSelect={true}
              customerStatusIds="1,4"
              disableSafariAutoCorrect
              onSelectAll={
                !isCustomersDropdownLoaded ? onLoadCustomersDropdown : onSelectAllCustomers
              }
              onLoadError={onErrorCustomerDropdown}
              openerProps={{
                disabled: !isCustomersDropdownLoaded,
                contentClassName: 'dashboard-header__customers-select__opener-content',
              }}
            />
          ) : null}
          {!isDashboardsLoading &&
          isCustomersDropdownMounted &&
          (selectedDashboard || !dashboards.length) ? (
            <NestedCustomerSelect
              onChangeSelection={
                isCustomersDropdownLoaded ? onChangeSelection : (_selection: any) => {}
              }
              onChangeAdvertiserSelection={
                isCustomersDropdownLoaded ? onChangeAdvertiserSelection : undefined
              }
              onChangeWorkspaceSelection={
                isCustomersDropdownLoaded ? setWorkspacesOwIds : undefined
              }
              stopClickPropagation={false}
              hideCurrentUser={true}
              selectAllOnLoad={isAllAdvertisersSelected}
              selectAdvertisersOnWorkspaceSelect={true}
              customerStatusIds="1,4"
              disableSafariAutoCorrect
              onSelectAll={
                !isCustomersDropdownLoaded ? onLoadCustomersDropdown : onSelectAllCustomers
              }
              onLoad={
                !isAllAdvertisersSelected
                  ? (allSelected: any) => {
                      setIsCustomersDropdownLoaded(true);
                      setIsCustomersDropdownEmpty(!get(allSelected, 'data.length'));
                    }
                  : undefined
              }
              onLoadError={onErrorCustomerDropdown}
              openerProps={{
                disabled: !isCustomersDropdownLoaded,
                contentClassName: 'dashboard-header__customers-select__opener-content',
              }}
              selectedOwIds={
                isAllAdvertisersSelected || isCustomersDropdownLoaded ? undefined : selectedOwIds
              }
              selectedWorkspaceOwIds={
                isAllAdvertisersSelected || isCustomersDropdownLoaded ? undefined : workspacesOwIds
              }
              selectedAdvertiserOwIds={
                isAllAdvertisersSelected || isCustomersDropdownLoaded ? undefined : advertisersOwIds
              }
            />
          ) : null}
        </div>
        <div className="dashboard-header__timezone-picker">
          <TimezonePicker
            onTimezoneChange={selectTimezone}
            tooltipParams={{
              label: 'Dashboard days will start at 12 am and end at 12 am in the selected timezone',
              auto: false,
              position: 'bottom',
            }}
          />
        </div>
        <EpochDateRangePicker
          dateRange={dateRangeNew}
          dateFormat="MM/DD/YYYY"
          onDateRangeChange={handleDateChange}
          datePickerProps={{
            numberOfCalendars: 2,
            singleDateRange: true,
          }}
          preselected={PRESELECTED}
          insidePreselectors
          defaultDateRange={getDefaultDateRange()}
          tooltipParams={{
            label: 'Timeframe for the data to include in the Dashboard',
            auto: false,
            position: 'left',
          }}
          calendarMinimumDate={calendarMinimumDate}
          calendarMaximumDate={calendarMaximumDate}
          dataDogApplyButtonLabel="Apply Date Range"
          getDatesOnMinMaxUpdate={getDatesOnMinMaxUpdate}
          removeSafariSelectionIssue
        />
      </div>
    </TimezoneProvider>
  );
};

const mapState = (state: AppState) => ({
  dateRange: state.filter.dateRange,
  timezone: state.filter.timezone,
  timezones: state.filter.timezones,
  selectedDashboard: state.dashboards.selectedDashboard,
  profile: state.auth,
  defaultDateRangeWasUpdated: state.filter.defaultDateRangeWasUpdated,
  isAuth: state.auth.authorized,
  isProfileLoaded: state.auth.isProfileLoaded,
  isCustomersDropdownAllowed: state.auth.userData.isWorkspaceOwnerOrg,
  isCustomersDropdownLoaded: state.filter.isCustomersDropdownLoaded,
  hasNoCustomers: hasNoCustomers(state),
  advertisersOwIds: state.filter.advertisersOwIds,
  workspacesOwIds: state.filter.workspacesOwIds,
  isAllAdvertisersSelected: state.filter.isAllAdvertisersSelected,
  isDashboardsLoading:
    state.dashboards.loading || state.reports.dimensionsMetricsLoading === LoadingStatus.LOADING,
  dashboards: state.dashboards.dashboards,
  isCustomersDropdownMounted: state.filter.isCustomersDropdownMounted,
});

const mapAction = {
  setDateRange: filterActions.setDateRange,
  setInitialDateRange: filterActions.setInitialDateRange,
  selectTimezone: filterActions.selectTimezone,
  getRelatedData: commonActions.getRelatedData,
  getStatuses: filterActions.getStatuses,
  getCreativeTypes: filterActions.getCreativeTypes,
  setAdvertisersOwIds: filterActions.setAdvertisersOwIds,
  setWorkspacesOwIds: filterActions.setWorkspacesOwIds,
  openToast: toastActions.open,
  setIsCustomersDropdownLoaded: filterActions.setIsCustomersDropdownLoaded,
  setFilteredCampaignsIds: tableActions.setFilteredCampaignsIds,
  setIsCustomersDropdownError: filterActions.setIsCustomersDropdownError,
  setIsCustomersDropdownEmpty: filterActions.setIsCustomersDropdownEmpty,
  setIsAllAdvertisersSelected: filterActions.setIsAllAdvertisersSelected,
  setIsCustomersDropdownMounted: filterActions.setIsCustomersDropdownMounted,
};

export const DatesContainer = connect(mapState, mapAction)(DatesContainerComponent);
