import { Dispatch } from 'redux';
import { createAction } from '../../utils/actions';
import { toastConstants } from './constants';

export const toastActions = {
  open: function(message: string | JSX.Element) {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction(toastConstants.TOAST_OPEN, message));
    };
  },
  close: function() {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction(toastConstants.TOAST_CLOSE));
    };
  },
};

export interface Open {
  open: (message: string | JSX.Element) => void;
}
