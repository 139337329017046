import { reducerFromMap } from '../../utils/actions';
import { filterConstants } from './constants';
import { Action } from '../../models/Action';
import {
  defaultsortingColumnOptions,
  Option,
  Select,
  StickedOption,
  OptionID,
  sortingColumnOptions,
  defaultSortingWorkspacesColumns,
  defaultSortingAdvertisersColumns,
  defaultSortingExchangesColumns,
  getDefaultSortingInsertionOrdersColumns,
  getColumns,
  flattenOptions,
} from '../../models/Option';
import { CampaignStatusType } from '../../models/Campaign';
import { Graph1DefaultMetricOption, Graph2DefaultMetricOption } from '../../models/Graph';
import { Application } from '../../models/Application';
import { GraphsNames } from '../../models/GraphsNames';
import { TableLevel } from '../../models/Table';
import { LoadingStatus } from '../../models/LoadingStatus';
import { DropdownGroupsList } from '../../models/CampaignGroup';
export { TableLevel } from '../../models/Table';

export type DateRange = {
  start: number;
  end: number;
};

export interface Template {
  label: string;
  value: number;
  data: FilterState;
}

export interface GraphSelectChangeValue {
  key: GraphsNames;
  value: Option;
}

export interface Freeze {
  columns: number;
  rows: number;
}

export interface FilterState {
  initialDateRange: DateRange | null;
  dateRange: DateRange | null;
  defaultDateRangeWasUpdated: boolean;
  advertisers: Select;
  timezone: OptionID;
  timezones: OptionID[];
  tableLevel: Option;
  search: string;
  status: Option<CampaignStatusType>;
  statusOptions: Option<CampaignStatusType>[];
  [GraphsNames.graph1]: Option;
  [GraphsNames.graph2]: Option;
  sortingColumns: StickedOption[];
  sortingWorkspacesColumns?: StickedOption[];
  sortingAdvertisersColumns?: StickedOption[];
  sortingExchangesColumns?: StickedOption[];
  sortingInsertionOrdersColumns: StickedOption[];
  freeze: Freeze;
  creativeTypes: Option[];
  selectedCreativeTypes: Option[];
  customersOwIds: string;
  advertisersOwIds: string;
  isAllAdvertisersSelected: boolean | null;
  workspacesOwIds: string;
  isCustomersDropdownLoaded: boolean;
  isCustomersDropdownError: boolean;
  isCustomersDropdownEmpty: boolean;
  isCustomersDropdownMounted: boolean;
  allowedApplications: Application[];
  selectedInsertionOrders: number[] | null;
  selectedCampaigns: number[];
  campaignOptions: DropdownGroupsList[];
  campaignOptionsLoading: LoadingStatus;
  ioBudgetTypes: number[];
  campaignTypeIds: number[];
}

export const DefaultTableLevels: Option<TableLevel>[] = [
  {
    label: 'Campaigns',
    value: TableLevel.Campaigns,
  },
  {
    label: 'Insertion Orders',
    value: TableLevel.InsertionOrders,
  },
];
const WorkspacesTableLevel: Option<TableLevel>[] = [
  {
    label: 'Workspaces',
    value: TableLevel.Workspaces,
  },
];
const AdvertisersTableLevel: Option<TableLevel>[] = [
  {
    label: 'Advertisers',
    value: TableLevel.Advertisers,
  },
];
const ExchangesTableLevel: Option<TableLevel>[] = [
  {
    label: 'Exchanges',
    value: TableLevel.Exchanges,
  },
];

export const TableLevels = [
  ...DefaultTableLevels,
  ...AdvertisersTableLevel,
  ...WorkspacesTableLevel,
  ...ExchangesTableLevel,
];
export const IQMSuperTableLevels = [...TableLevels];
export const WorkspaceOwnerTableLevels = [...DefaultTableLevels, ...AdvertisersTableLevel];

export const defaultFilterState: FilterState = {
  initialDateRange: null,
  dateRange: null,
  defaultDateRangeWasUpdated: false,
  advertisers: null,
  timezone: {
    label: 'US/Eastern',
    value: 'US/Eastern',
    id: 29,
  },
  timezones: [],
  tableLevel: TableLevels[0],
  search: '',
  status: {
    label: 'Running',
    value: CampaignStatusType.running,
  },
  statusOptions: [],
  [GraphsNames.graph1]: Graph1DefaultMetricOption,
  [GraphsNames.graph2]: Graph2DefaultMetricOption,
  sortingColumns: defaultsortingColumnOptions.map((i) => ({
    ...i,
    active: true,
  })),
  sortingWorkspacesColumns: defaultSortingWorkspacesColumns.map((i) => ({
    ...i,
    active: true,
  })),
  sortingAdvertisersColumns: defaultSortingAdvertisersColumns.map((i) => ({
    ...i,
    active: true,
  })),
  sortingExchangesColumns: defaultSortingExchangesColumns.map((i) => ({
    ...i,
    active: true,
  })),
  sortingInsertionOrdersColumns: getDefaultSortingInsertionOrdersColumns({
    isPlatformOwnerOrg: true,
    isWorkspaceOwnerOrg: true,
    isVldEnabled: true,
    hadVldGenerated: true,
  }).map((i) => ({
    ...i,
    active: true,
  })),
  freeze: {
    columns: 0,
    rows: 0,
  },
  creativeTypes: [],
  selectedCreativeTypes: [],
  customersOwIds: '',
  advertisersOwIds: '',
  isAllAdvertisersSelected: true,
  workspacesOwIds: '',
  isCustomersDropdownLoaded: false,
  isCustomersDropdownError: false,
  isCustomersDropdownEmpty: false,
  isCustomersDropdownMounted: false,
  allowedApplications: [],
  selectedInsertionOrders: null,
  selectedCampaigns: [],
  campaignOptions: [],
  campaignOptionsLoading: LoadingStatus.PENDING,
  ioBudgetTypes: [],
  campaignTypeIds: [],
};

function setInitialDateRange(state: FilterState, action: Action<DateRange>): FilterState {
  return {
    ...state,
    initialDateRange: action.payload,
    defaultDateRangeWasUpdated: true,
    dateRange: action.payload,
  };
}

function setDateRange(state: FilterState, action: Action<DateRange>): FilterState {
  return {
    ...state,
    dateRange: action.payload,
  };
}

function selectTimezone(state: FilterState, action: Action<OptionID>): FilterState {
  return {
    ...state,
    timezone: action.payload,
  };
}

function getTimezones(state: FilterState, action: Action<OptionID[]>): FilterState {
  return {
    ...state,
    timezones: action.payload,
  };
}

function changeTableLevel(state: FilterState, action: Action<Option>): FilterState {
  if (
    action.payload.value === TableLevel.Workspaces ||
    action.payload.value === TableLevel.Advertisers
  ) {
    return {
      ...state,
      tableLevel: action.payload,
    };
  } else if (action.payload.value === TableLevel.InsertionOrders) {
    return {
      ...state,
      tableLevel: action.payload,
    };
  } else {
    const alwaysEnabledColumns = sortingColumnOptions.filter((opt) => opt.alwaysEnabled);

    const allCols = flattenOptions(
      getColumns({
        isBetaUser: true,
        isPlatformOwnerOrg: true,
        isWorkspaceOwnerOrg: true,
        isBidShadingEnabled: true,
        isVldEnabled: true,
        hadVldGenerated: true,
      }),
    ).map((col) => col.value);
    const campaignsSpecificSelectedColumns = state.sortingColumns.filter(
      (opt) => allCols.includes(opt.value) && !opt.alwaysEnabled,
    );

    return {
      ...state,
      sortingColumns: [...alwaysEnabledColumns, ...campaignsSpecificSelectedColumns],
      tableLevel: action.payload,
    };
  }
}

function updateSearch(state: FilterState, action: Action<string>): FilterState {
  return {
    ...state,
    search: action.payload,
  };
}

function setStatus(state: FilterState, action: Action<Option<CampaignStatusType>[]>): FilterState {
  const statusOptions = action.payload;
  const selectedValue = state.status.value;
  const selected = statusOptions.find((i) => i.value === selectedValue);

  return {
    ...state,
    status: selected || defaultFilterState.status,
    statusOptions,
  };
}

function updateStatus(state: FilterState, action: Action<Option>): FilterState {
  return {
    ...state,
    status: action.payload,
  };
}

function updateGraphSelect(
  state: FilterState,
  action: Action<GraphSelectChangeValue>,
): FilterState {
  return {
    ...state,
    [action.payload.key]: action.payload.value,
  };
}

function changeSortingColumns(state: FilterState, action: Action<StickedOption[]>): FilterState {
  return {
    ...state,
    sortingColumns: action.payload,
  };
}

function changeSortingWorkspacesColumns(
  state: FilterState,
  action: Action<StickedOption[]>,
): FilterState {
  return {
    ...state,
    sortingWorkspacesColumns: action.payload,
  };
}

function changeSortingAdvertisersColumns(
  state: FilterState,
  action: Action<StickedOption[]>,
): FilterState {
  return {
    ...state,
    sortingAdvertisersColumns: action.payload,
  };
}

function changeSortingExchangesColumns(
  state: FilterState,
  action: Action<StickedOption[]>,
): FilterState {
  return {
    ...state,
    sortingExchangesColumns: action.payload,
  };
}

function changeSortingInsertionOrdersColumns(
  state: FilterState,
  action: Action<StickedOption[]>,
): FilterState {
  return {
    ...state,
    sortingInsertionOrdersColumns: action.payload,
  };
}

function updateFreeze(state: FilterState, action: Action<Freeze>): FilterState {
  return {
    ...state,
    freeze: action.payload,
  };
}

function updateFilters(state: FilterState, action: Action<any>): FilterState {
  return {
    ...state,
    ...action.payload,
    dateRange: null,
  };
}

function setCampaignTypes(state: FilterState, action: Action<Option[]>): FilterState {
  return {
    ...state,
    creativeTypes: action.payload,
  };
}

function selectCreativeType(state: FilterState, action: Action<Option[]>): FilterState {
  return {
    ...state,
    selectedCreativeTypes: action.payload,
  };
}

function setCustomersOwIds(state: FilterState, action: Action<string>): FilterState {
  return {
    ...state,
    customersOwIds: action.payload,
  };
}

function setAdvertisersOwIds(state: FilterState, action: Action<string>): FilterState {
  return {
    ...state,
    advertisersOwIds: action.payload,
  };
}

function setIsAllAdvertisersSelected(state: FilterState, action: Action<boolean>): FilterState {
  return {
    ...state,
    isAllAdvertisersSelected: action.payload,
  };
}

function setWorkspacesOwIds(state: FilterState, action: Action<string>): FilterState {
  return {
    ...state,
    workspacesOwIds: action.payload,
  };
}

function resetFilters(): FilterState {
  return {
    ...defaultFilterState,
  };
}

function setIsCustomersDropdownLoaded(state: FilterState, action: Action<boolean>): FilterState {
  return {
    ...state,
    isCustomersDropdownLoaded: action.payload,
  };
}

function setIsCustomersDropdownError(state: FilterState, action: Action<boolean>): FilterState {
  return {
    ...state,
    isCustomersDropdownError: action.payload,
  };
}

function setIsCustomersDropdownEmpty(state: FilterState, action: Action<boolean>): FilterState {
  return {
    ...state,
    isCustomersDropdownEmpty: action.payload,
  };
}

function setIsCustomersDropdownMounted(state: FilterState, action: Action<boolean>): FilterState {
  return {
    ...state,
    isCustomersDropdownMounted: action.payload,
  };
}

function setAllowedApplications(state: FilterState, action: Action<Application[]>): FilterState {
  return {
    ...state,
    allowedApplications: action.payload,
  };
}

function setSelectedCampaigns(state: FilterState, action: Action<number[]>) {
  return {
    ...state,
    selectedCampaigns: action.payload,
  };
}

function campaignOptionsStartLoading(state: FilterState): FilterState {
  return {
    ...state,
    campaignOptionsLoading: LoadingStatus.LOADING,
    campaignOptions: [],
  };
}

function campaignOptionsFinishLoading(
  state: FilterState,
  action: Action<{ status: LoadingStatus; list: DropdownGroupsList[] }>,
): FilterState {
  return {
    ...state,
    campaignOptionsLoading: action.payload.status,
    campaignOptions: action.payload.list,
  };
}

function setCampaignOptionsLoading(state: FilterState): FilterState {
  return {
    ...state,
    campaignOptionsLoading: LoadingStatus.LOADING,
  };
}

function setSelectedInsertionOrders(state: FilterState, action: Action<number[]>): FilterState {
  return {
    ...state,
    selectedInsertionOrders: action.payload,
  };
}

function setIoBudgetTypes(state: FilterState, action: Action<number[]>): FilterState {
  return {
    ...state,
    ioBudgetTypes: action.payload,
  };
}

function setCampaignTypeIds(state: FilterState, action: Action<number[]>): FilterState {
  return {
    ...state,
    campaignTypeIds: action.payload,
  };
}

const reducer = reducerFromMap(defaultFilterState, {
  [filterConstants.SET_DATE_RANGE]: setDateRange,
  [filterConstants.SET_INITIAL_DATE_RANGE]: setInitialDateRange,
  [filterConstants.SELECT_TIMEZONE]: selectTimezone,
  [filterConstants.GET_TIMEZONES]: getTimezones,
  [filterConstants.CHANGE_TABLE_LEVEL]: changeTableLevel,
  [filterConstants.UPDATE_SEARCH]: updateSearch,
  [filterConstants.SET_STATUS]: setStatus,
  [filterConstants.UPDATE_STATUS]: updateStatus,
  [filterConstants.UPDATE_GRAPH_SELECT]: updateGraphSelect,
  [filterConstants.CHANGE_SORTING_COLUMNS]: changeSortingColumns,
  [filterConstants.CHANGE_SORTING_WORKSPACES_COLUMNS]: changeSortingWorkspacesColumns,
  [filterConstants.CHANGE_SORTING_ADVERTISERS_COLUMNS]: changeSortingAdvertisersColumns,
  [filterConstants.CHANGE_SORTING_EXCHANGES_COLUMNS]: changeSortingExchangesColumns,
  [filterConstants.CHANGE_SORTING_INSERTION_ORDERS_COLUMNS]: changeSortingInsertionOrdersColumns,
  [filterConstants.UPDATE_FREEZE_TABLE_PART]: updateFreeze,
  [filterConstants.UPDATE_FILTERS]: updateFilters,
  [filterConstants.SET_CREATIVE_TYPES]: setCampaignTypes,
  [filterConstants.SELECT_CREATIVE_TYPE]: selectCreativeType,
  [filterConstants.SET_CUSTOMERS_OWIDS]: setCustomersOwIds,
  [filterConstants.SET_WORKSPACES_OWIDS]: setWorkspacesOwIds,
  [filterConstants.SET_ADVERTISERS_OWIDS]: setAdvertisersOwIds,
  [filterConstants.SET_IS_ALL_ADVERTISERS_SELECTED]: setIsAllAdvertisersSelected,
  [filterConstants.RESET_FILTERS]: resetFilters,
  [filterConstants.SET_IS_CUSTOMERS_DROPDOWN_LOADED]: setIsCustomersDropdownLoaded,
  [filterConstants.SET_IS_CUSTOMERS_DROPDOWN_ERROR]: setIsCustomersDropdownError,
  [filterConstants.SET_IS_CUSTOMERS_DROPDOWN_EMPTY]: setIsCustomersDropdownEmpty,
  [filterConstants.SET_IS_CUSTOMERS_DROPDOWN_MOUNTED]: setIsCustomersDropdownMounted,
  [filterConstants.SET_ALLOWED_APPLICATIONS]: setAllowedApplications,
  [filterConstants.SET_SELECTED_CAMPAIGNS]: setSelectedCampaigns,
  [filterConstants.CAMPAIGN_OPTIONS_START_LOADING]: campaignOptionsStartLoading,
  [filterConstants.CAMPAIGN_OPTIONS_FINISH_LOADING]: campaignOptionsFinishLoading,
  [filterConstants.SET_CAMPAIGN_OPTIONS_LOADING]: setCampaignOptionsLoading,
  [filterConstants.SET_SELECTED_INSERTION_ORDERS]: setSelectedInsertionOrders,
  [filterConstants.SET_IO_BUDGET_TYPES]: setIoBudgetTypes,
  [filterConstants.SET_CAMPAIGN_TYPE_IDS]: setCampaignTypeIds,
});

export const filter = (state: FilterState, action: Action<any>) => reducer(state, action);
