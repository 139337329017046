import { Dispatch } from 'redux';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import axios from 'axios';
import { ApiService } from 'iqm-framework';

import {
  DateRange,
  GraphSelectChangeValue,
  Template,
  Freeze,
  TableLevel,
  TableLevels,
} from './reducers';
import { createAction } from '../../utils/actions';
import { filterConstants } from './constants';
import { AppState } from '../index';
import { Option, StickedOption, OptionID } from '../../models/Option';
import { Application } from '../../models/Application';
import { CampaignStatusType } from '../../models/Campaign';
import { tableConstants } from '../table/constants';
import { GraphsNames } from '../../models/GraphsNames';
import { API } from '../../api';
import { CampaignCountParams } from '../../api/Campaigns';
import { LoadingStatus } from '../../models/LoadingStatus';
import {
  DEFAULT_IO_TABLE_SORTING,
  getInsertionOrderCampaignListParams,
} from '../../models/InsertionOrder';
import {
  DropdownGroupsList,
  convertCampaignGroupDTOtoCampaignGroupWithPayloads,
} from '../../models/CampaignGroup';
import { defaultTableState } from '../table/reducers';
import { LambdaResponse } from '../../models/Response';
import { Dashboard } from '../../models/Dashboards';
import { dashboardConstants } from '../dashboards/constants';

export const TEMPLATES = 'user_templates';

export interface SelectTimezone {
  selectTimezone: (data: OptionID) => void;
}

export interface SelectTemplate {
  selectTemplate: (template: Template) => void;
  clearTemplate: () => void;
}

export interface ChangeSortingColumns {
  changeSortingColumns: (columns: StickedOption[]) => void;
  changeSortingWorkspacesColumns: (columns: StickedOption[]) => void;
  changeSortingAdvertisersColumns: (columns: StickedOption[]) => void;
  changeSortingExchangesColumns: (columns: StickedOption[]) => void;
  changeSortingInsertionOrdersColumns: (columns: StickedOption[]) => void;
}

export interface SaveTemplate {
  saveTemplate: (name: string) => void;
}

export interface UpdateFreeze {
  updateFreeze: (data: Freeze) => void;
}

export interface GetCreativeTypes {
  getCreativeTypes: () => void;
}

export interface SelectCreativeType {
  selectCreativeType: (data: Option[]) => void;
}

export interface FilterActions {
  setCustomersOwIds: (owIds: string) => void;
}

export interface SetWorkspacesOwIds {
  setWorkspacesOwIds: (owIds: string) => void;
}

export interface SetAdvertisersOwIds {
  setAdvertisersOwIds: (owIds: string) => void;
}

export interface SetIsAllAdvertisersSelected {
  setIsAllAdvertisersSelected: (isAllSelected: boolean) => void;
}

export interface SetIsCustomersDropdownLoaded {
  setIsCustomersDropdownLoaded: (isLoaded: boolean) => void;
}

export interface SetIsCustomersDropdownError {
  setIsCustomersDropdownError: (isError: boolean) => void;
}

export interface SetIsCustomersDropdownEmpty {
  setIsCustomersDropdownEmpty: (isEmpty: boolean) => void;
}

export interface GetAllowedApplications {
  getAllowedApplications: () => void;
}

export interface GetStatuses {
  getStatuses: () => void;
}

export interface SetCampaignOptionsLoading {
  setCampaignOptionsLoading: (ls: LoadingStatus) => void;
}

export interface SetSelectedCampaigns {
  setSelectedCampaigns: (campaigns: number[]) => void;
}

export interface LoadCampaignOptions {
  loadCampaignOptions: (
    initialSelectedCampaignIds: number[] | null,
    dashboard?: Dashboard | null,
  ) => void;
}

export interface SetIoBudgetTypes {
  setIoBudgetTypes: (budgetTypeIds: number[]) => void;
}

export interface SetCampaignTypeIds {
  setCampaignTypeIds: (campaignTypeIds: number[]) => void;
}

export const filterActions = {
  setInitialDateRange: (dateRange: DateRange) => {
    return (dispatch: Dispatch) => {
      dispatch(createAction<DateRange>(filterConstants.SET_INITIAL_DATE_RANGE, dateRange));
    };
  },
  setDateRange: (dateRange: DateRange) => {
    return (dispatch: Dispatch) => {
      dispatch(createAction<DateRange>(filterConstants.SET_DATE_RANGE, dateRange));
    };
  },
  selectTimezone: (data: OptionID) => {
    return (dispatch: Dispatch) => {
      dispatch(createAction<OptionID>(filterConstants.SELECT_TIMEZONE, data));
    };
  },
  getTimezones: () => {
    return async (dispatch: Dispatch) => {
      try {
        const response = await API.timezone.getTimezones();
        dispatch(createAction<OptionID[]>(filterConstants.GET_TIMEZONES, response));
      } catch (e) {
        console.log("[can't fetch timezones]: ", e);
      }
    };
  },
  changeTableLevel: (value: Option) => {
    return (dispatch: Dispatch, getState: () => AppState) => {
      const { statusOptions } = getState().filter;
      const allStatusOption = statusOptions.find(
        (item: Option<CampaignStatusType>) => item.value === 'all',
      );

      dispatch(createAction<number[]>(filterConstants.SET_SELECTED_INSERTION_ORDERS, []));
      dispatch(createAction<number[]>(filterConstants.SET_IO_BUDGET_TYPES, []));
      dispatch(createAction<number[]>(filterConstants.SET_CAMPAIGN_TYPE_IDS, []));
      dispatch(createAction<Option>(filterConstants.CHANGE_TABLE_LEVEL, value));
      dispatch(createAction<Option[]>(filterConstants.SELECT_CREATIVE_TYPE, []));

      dispatch(
        createAction<Option<CampaignStatusType>>(
          filterConstants.UPDATE_STATUS,
          allStatusOption || ({ label: 'All', value: 'all' } as Option<CampaignStatusType>),
        ),
      );
      dispatch(createAction<string>(filterConstants.UPDATE_SEARCH, ''));

      if (value && value.value === TableLevel.InsertionOrders) {
        dispatch(
          createAction(tableConstants.TABLE_UPDATE_SORTING_PARAMS, DEFAULT_IO_TABLE_SORTING),
        );
      } else {
        dispatch(
          createAction(tableConstants.TABLE_UPDATE_SORTING_PARAMS, defaultTableState.sorting),
        );
      }
      dispatch(createAction<LambdaResponse[]>(tableConstants.TABLE_SELECT_INSERTION_ORDERS, []));
    };
  },
  openCampaignsByInsertionOrder: (ioId: number) => {
    return (dispatch: Dispatch, getState: () => AppState) => {
      const { statusOptions } = getState().filter;

      const allStatusOption = statusOptions.find(
        (item: Option<CampaignStatusType>) => item.value === 'all',
      );

      dispatch(
        createAction<number[]>(filterConstants.SET_SELECTED_INSERTION_ORDERS, [ioId]),
      );
      dispatch(
        createAction<Option>(
          filterConstants.CHANGE_TABLE_LEVEL,
          TableLevels.find((opt) => opt.value === TableLevel.Campaigns),
        ),
      );
      dispatch(createAction<Option[]>(filterConstants.SELECT_CREATIVE_TYPE, []));

      dispatch(
        createAction<Option<CampaignStatusType>>(
          filterConstants.UPDATE_STATUS,
          allStatusOption || ({ label: 'All', value: 'all' } as Option<CampaignStatusType>),
        ),
      );
      dispatch(createAction<string>(filterConstants.UPDATE_SEARCH, ''));

      dispatch(createAction(tableConstants.TABLE_UPDATE_SORTING_PARAMS, defaultTableState.sorting));
    };
  },
  updateSearch: (value: string) => {
    return createAction<string>(filterConstants.UPDATE_SEARCH, value);
  },
  getStatuses: () => {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      try {
        const state = getState();
        const { auth, filter } = state;

        if (!auth.userData.userId || !filter.isCustomersDropdownLoaded) {
          return;
        }
        const params: CampaignCountParams = {
          sortType: 'asc',
          owIds: filter.advertisersOwIds,
        };
        const response = await API.campaigns.campaignsCount(params);

        if (!response || !response.length) {
          return;
        }

        const options: Option<string>[] = response
          .sort((a, b) => a.order - b.order)
          .map(
            (data): Option => ({
              label: `${capitalize(data.status_label || '')} (${data.status_count})`,
              value: data.status_key,
            }),
          );
        dispatch(createAction<Option<string>[]>(filterConstants.SET_STATUS, options));
      } catch (e) {
        console.log("[can't fetch statuses from server]: ", e);
      }
    };
  },
  updateStatus: (value: Option<CampaignStatusType>) => {
    return (dispatch: Dispatch) => {
      dispatch(createAction<Option<CampaignStatusType>>(filterConstants.UPDATE_STATUS, value));
      dispatch(createAction<void>(tableConstants.TABLE_CLEAR_SELECTED_CAMPAIGN));
      dispatch(createAction<LambdaResponse[]>(tableConstants.TABLE_SELECT_INSERTION_ORDERS, []));
    };
  },
  changeGraphSelectValue: (value: Option, key: GraphsNames) => {
    return (dispatch: Dispatch) => {
      dispatch(
        createAction<GraphSelectChangeValue>(filterConstants.UPDATE_GRAPH_SELECT, { key, value }),
      );
    };
  },
  changeSortingColumns: (columns: StickedOption[]) => {
    return (dispatch: Dispatch) => {
      dispatch(createAction<StickedOption[]>(filterConstants.CHANGE_SORTING_COLUMNS, columns));
    };
  },
  changeSortingWorkspacesColumns: (columns: StickedOption[]) => {
    return (dispatch: Dispatch) => {
      dispatch(
        createAction<StickedOption[]>(filterConstants.CHANGE_SORTING_WORKSPACES_COLUMNS, columns),
      );
    };
  },
  changeSortingAdvertisersColumns: (columns: StickedOption[]) => {
    return (dispatch: Dispatch) => {
      dispatch(
        createAction<StickedOption[]>(filterConstants.CHANGE_SORTING_ADVERTISERS_COLUMNS, columns),
      );
    };
  },
  changeSortingExchangesColumns: (columns: StickedOption[]) => {
    return (dispatch: Dispatch) => {
      dispatch(
        createAction<StickedOption[]>(filterConstants.CHANGE_SORTING_EXCHANGES_COLUMNS, columns),
      );
    };
  },
  changeSortingInsertionOrdersColumns: (columns: StickedOption[]) => {
    return createAction<StickedOption[]>(
      filterConstants.CHANGE_SORTING_INSERTION_ORDERS_COLUMNS,
      columns,
    );
  },
  updateFreeze: (data: Freeze) => {
    return (dispatch: Dispatch) => {
      dispatch(createAction<Freeze>(filterConstants.UPDATE_FREEZE_TABLE_PART, data));
    };
  },
  updateFilters: (data) => {
    return (dispatch: Dispatch) => {
      dispatch(createAction<any>(filterConstants.UPDATE_FILTERS, data));
    };
  },
  getCreativeTypes: () => {
    return async (dispatch: Dispatch) => {
      try {
        const response = await API.creativeTypes.getCreativeTypes();
        dispatch(
          createAction<Option[]>(
            filterConstants.SET_CREATIVE_TYPES,
            response.map((d) => ({
              value: d.id,
              label: d.name,
            })),
          ),
        );
      } catch (e) {
        console.log('Error while get reports-types data', e);
        return Promise.reject(false);
      }
    };
  },
  selectCreativeType: (data: Option[]) => {
    return (dispatch: Dispatch) => {
      dispatch(createAction<Option[]>(filterConstants.SELECT_CREATIVE_TYPE, data));
    };
  },
  setCustomersOwIds: (owIds: string) => {
    return (dispatch: Dispatch) => {
      dispatch(createAction<string>(filterConstants.SET_CUSTOMERS_OWIDS, owIds));
    };
  },
  setAdvertisersOwIds: (owIds: string) => {
    return (dispatch: Dispatch) => {
      dispatch(createAction<string>(filterConstants.SET_ADVERTISERS_OWIDS, owIds));
    };
  },
  setIsAllAdvertisersSelected: (isAllSelected: boolean) => {
    return createAction<boolean>(filterConstants.SET_IS_ALL_ADVERTISERS_SELECTED, isAllSelected);
  },
  setWorkspacesOwIds: (owIds: string) => {
    return (dispatch: Dispatch) => {
      dispatch(createAction<string>(filterConstants.SET_WORKSPACES_OWIDS, owIds));
    };
  },
  setIsCustomersDropdownLoaded: (isLoaded: boolean) => {
    return createAction<boolean>(filterConstants.SET_IS_CUSTOMERS_DROPDOWN_LOADED, isLoaded);
  },
  setIsCustomersDropdownError: (isError: boolean) => {
    return createAction<boolean>(filterConstants.SET_IS_CUSTOMERS_DROPDOWN_ERROR, isError);
  },
  setIsCustomersDropdownEmpty: (isEmpty: boolean) => {
    return createAction<boolean>(filterConstants.SET_IS_CUSTOMERS_DROPDOWN_EMPTY, isEmpty);
  },
  setIsCustomersDropdownMounted: (isMounted: boolean) => {
    return createAction<boolean>(filterConstants.SET_IS_CUSTOMERS_DROPDOWN_MOUNTED, isMounted);
  },
  getAllowedApplications: () => {
    return async (dispatch: Dispatch) => {
      try {
        const allowedApps = await ApiService.fetchUserApps();
        dispatch(
          createAction<Application[]>(filterConstants.SET_ALLOWED_APPLICATIONS, allowedApps || []),
        );
      } catch (e) {
        console.log("[can't fetch allowed applications from server]: ", e);
      }
    };
  },
  setSelectedCampaigns: (campaigns: number[]) => {
    return createAction<number[]>(filterConstants.SET_SELECTED_CAMPAIGNS, campaigns);
  },
  setCampaignOptionsLoading: (loadingStatus: LoadingStatus) => {
    return createAction<LoadingStatus>(filterConstants.SET_CAMPAIGN_OPTIONS_LOADING, loadingStatus);
  },
  loadCampaignOptions: (
    initialSelectedCampaignIds: number[] | null,
    dashboard?: Dashboard | null,
  ) => {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      try {
        const state = getState();
        const { filter, auth } = state;
        const { isPlatformOwnerOrg, isWorkspaceOwnerOrg } = auth.userData;
        dispatch(createAction(filterConstants.CAMPAIGN_OPTIONS_START_LOADING));

        API.InsertionOrder.cancelGetIOCampaigns();

        if (!filter.isCustomersDropdownLoaded && (isPlatformOwnerOrg || isWorkspaceOwnerOrg)) {
          return;
        }

        const params = getInsertionOrderCampaignListParams(state);
        const res = await API.InsertionOrder.getIOCampaigns(params);

        const groupedData: DropdownGroupsList[] = convertCampaignGroupDTOtoCampaignGroupWithPayloads(
          get(res, 'data.ioCampaignsList', []),
        );

        if (initialSelectedCampaignIds) {
          const newSelection = groupedData
            .map((grp: DropdownGroupsList) => grp.options || [])
            .flat(1)
            .filter((opt) => initialSelectedCampaignIds.includes(+opt.id))
            .map((opt) => +opt.id);

          // Update currently-selected dashboard's saved selected campaign IDs to remove those no longer
          // in the fetched campaigns list (i.e. whose statuses have changed)
          if (dashboard && get(getState(), 'dashboards.selectedDashboard.id') === dashboard.id) {
            dispatch(
              createAction<Dashboard>(dashboardConstants.UPDATE_DASHBOARD, {
                ...dashboard,
                data: { ...dashboard.data, filteredCampaignsIds: newSelection },
              }),
            );
          }

          dispatch(filterActions.setSelectedCampaigns(newSelection));
          dispatch(
            createAction<{ status: LoadingStatus; list: DropdownGroupsList[] }>(
              filterConstants.CAMPAIGN_OPTIONS_FINISH_LOADING,
              { status: LoadingStatus.SUCCESS, list: groupedData as DropdownGroupsList[] },
            ),
          );
        } else {
          dispatch(filterActions.setSelectedCampaigns([]));
          dispatch(
            createAction<{ status: LoadingStatus; list: DropdownGroupsList[] }>(
              filterConstants.CAMPAIGN_OPTIONS_FINISH_LOADING,
              { status: LoadingStatus.SUCCESS, list: groupedData as DropdownGroupsList[] },
            ),
          );
        }
      } catch (err) {
        if (!axios.isCancel(get(err, 'response.data'))) {
          dispatch(
            createAction<{ status: LoadingStatus; list: DropdownGroupsList[] }>(
              filterConstants.CAMPAIGN_OPTIONS_FINISH_LOADING,
              { status: LoadingStatus.ERROR, list: [] as DropdownGroupsList[] },
            ),
          );
        }
      }
    };
  },
  setSelectedInsertionOrders: (ios: number[]) => {
    return createAction<number[]>(filterConstants.SET_SELECTED_INSERTION_ORDERS, ios);
  },
  setIoBudgetTypes: (budgetTypeIds: number[]) => {
    return createAction<number[]>(filterConstants.SET_IO_BUDGET_TYPES, budgetTypeIds);
  },
  setCampaignTypeIds: (campaignTypeIds: number[]) => {
    return createAction<number[]>(filterConstants.SET_CAMPAIGN_TYPE_IDS, campaignTypeIds);
  },
};
