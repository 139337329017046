import React from 'react';
import { Icon } from 'factor';
import styles from './styles.module.scss';

export const ErrorLabel = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.iconWrapper}>
        <Icon name="AlertTriangle" className={styles.icon} />
      </div>
      <div className={styles.text}>
        Oops, something went wrong. Please try again after some time.
      </div>
    </div>
  );
};
