import axios, { AxiosInstance } from 'axios';
import { getHeaders } from 'iqm-framework';

import { InstanceConfig } from '../index';
import {
  HEADER_BASE_URL_NAME,
  LAMBDA_API_URL,
  LAMBDA_API_URL_DAS,
  LAMBDA_BASE_URL_HEADER,
} from '../../config';
import { createDashboard, getDashboards, updateDashboard, removeDashboard } from './Dashboards';
import { getData, cancelGetData } from './CommonData';
import { getGraphData, cancelGetGraphData } from './GraphData';
import { getReportsGraphData, cancelGetReportsGraphData } from './ReportsGraphData';

let lambdaInstance: AxiosInstance;
let lambdaInstanceDas: AxiosInstance;

export function createLambdaInstance(config: InstanceConfig) {
  const instance = axios.create({
    baseURL: LAMBDA_API_URL,
    headers: {
      [HEADER_BASE_URL_NAME]: LAMBDA_BASE_URL_HEADER,
      ...getHeaders({ apiToken: config.token }),
    },
  });

  const instanceDas = axios.create({
    baseURL: LAMBDA_API_URL_DAS,
    headers: {
      [HEADER_BASE_URL_NAME]: LAMBDA_BASE_URL_HEADER,
      ...getHeaders({ apiToken: config.token }),
    },
  });

  instanceDas.interceptors.response.use(
    (response: any) => {
      return response;
    },
    (response: any) => {
      if (axios.isCancel(response)) {
        // eslint-disable-next-line no-throw-literal
        throw {
          response: {
            data: response,
          },
        };
      }
      return Promise.reject(response);
    },
  );

  instance.interceptors.response.use(
    (response: any) => {
      return response;
    },
    (response: any) => {
      if (axios.isCancel(response)) {
        // eslint-disable-next-line no-throw-literal
        throw {
          response: {
            data: response,
          },
        };
      }
      return Promise.reject(response);
    },
  );

  lambdaInstance = instance;
  lambdaInstanceDas = instanceDas;
}

export function getLambdaInstance(): AxiosInstance {
  return lambdaInstance || axios;
}

export function getLambdaInstanceDas(): AxiosInstance {
  return lambdaInstanceDas || axios;
}

export const lambdaAPI = {
  dashboards: {
    create: createDashboard,
    get: getDashboards,
    update: updateDashboard,
    remove: removeDashboard,
  },
  common: {
    get: getData,
    cancel: cancelGetData,
  },
  graph: {
    get: getGraphData,
    cancel: cancelGetGraphData,
  },
  reportsGraph: {
    get: getReportsGraphData,
    cancel: cancelGetReportsGraphData,
  },
};
