import React from 'react';
import styles from './styles.module.scss';

interface Props {
  progressPercent: number | null;
  fillClassName: string;
  className?: string;
}

export const PacingProgressBar = (props: Props) => {
  const { progressPercent, fillClassName, className } = props;

  return (
    <div className={`${styles.progressBar} ${className || ''}`}>
      <div
        className={`${styles.progressBarFill} ${fillClassName}`}
        style={{
          width: `${progressPercent}%`,
        }}
      />
    </div>
  );
};
