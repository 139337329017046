import React from 'react';
import { WithMiddleEllipses, Icon } from 'factor';
import { IOBudgetTypeMapper } from 'iqm-framework';
import styles from './styles.module.scss';

interface Props {
  ioId: number;
  ioName: string;
  ioBudgetTypeId: number;
}

export const SelectCampaignsOptionHeader = (props: Props) => {
  const { ioId, ioName, ioBudgetTypeId } = props;
  const { icon: budgetIcon } = IOBudgetTypeMapper[ioBudgetTypeId] || {};

  return (
    <div className={styles.wrapper}>
      {!!budgetIcon && <Icon name={budgetIcon} className="mr-1" />}
      <WithMiddleEllipses className={styles.ioName} text={ioName} />
      <div className={styles.separator} />
      <div className={styles.info}>
        <span className={styles.label}>ID</span>
        <span className={styles.value}>{ioId}</span>
      </div>
    </div>
  );
};
