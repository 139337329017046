import { AdvertiserResponse } from '../models/Advertisers';
import { getInstance } from './index';
import { ParamsDTO } from '../models/Params';
import { CustomerConfig } from '../models/CustomerConfig';
import { WithResponse } from '../models/Response';

export function getAdvertisers(params: ParamsDTO): Promise<AdvertiserResponse> {
  return getInstance()
    .get('/advertisers', {
      params,
    })
    .then((response) => response.data)
    .catch((err) => {
      console.log('err', err);
      return Promise.reject(err);
    });
}

type CustomerConfigResponse = WithResponse<CustomerConfig>;

export function getCustomerConfig(owId: number): Promise<CustomerConfigResponse> {
  return getInstance()
    .get(`/v3/ua/customer/config/${owId}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log('err', err);
      return Promise.reject(err);
    });
}
