import React from 'react';
import { connect } from 'react-redux';
import { DialogContent, Button } from 'factor';
import get from 'lodash/get';

import { AppState } from '../../../../../../../store';
import { TableComponentInstance } from '../../../Table';
import { tableActions } from '../../../../../../../store/table/actions';
import { LambdaResponse } from '../../../../../../../models/Response';
import { toastActions } from '../../../../../../../store/toast/actions';
import { pluralize } from '../../../../../../../utils/pluralize';
import { filterActions } from '../../../../../../../store/filter/actions';
import {
  statisticsActions,
  GetStatisticsTotalStart,
} from '../../../../../../../store/statistics/actions';
import { ToastContent } from '../../../../../../../components/toastContent/ToastContent';
import { Spinner } from '../../../../../../../components/Spinner';

interface Props extends GetStatisticsTotalStart {
  selectedCampaigns: LambdaResponse[];
  onClose: () => void;
  clearSelected: () => void;
  pause: () => Promise<void>;
  openToast: (name: string | JSX.Element) => void;
  getStatuses: () => void;
}

interface State {
  changingStatus: 'loading' | 'success' | 'fail' | null;
}

class PauseDialogContentComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      changingStatus: null,
    };
  }

  pauseHandler = () => {
    const {
      pause,
      openToast,
      getStatuses,
      getStatisticsTotalStart,
      selectedCampaigns,
      clearSelected,
    } = this.props;

    this.setState({ changingStatus: 'loading' });

    pause()
      .then(() => {
        TableComponentInstance.setState({
          selected: [],
          loading: true,
        });
        getStatisticsTotalStart();
        TableComponentInstance.getNewData();
        getStatuses();
        const toastMessageCampaign =
          selectedCampaigns.length > 1
            ? 'Campaigns'
            : `Campaign ${selectedCampaigns[0].campaignName}`;
        openToast(`${toastMessageCampaign} status changed`);
        this.setState(
          { changingStatus: 'success' },
          () => {
            setTimeout(() => clearSelected(), 1100);
          }, // animation time + .1s
        );
      })
      .catch((e) => {
        if (e.errorMessages) {
          openToast(<ToastContent messages={[e.errorMessages]} />);
        } else {
          const errorMsg = get(
            e,
            'response.data.responseObject.errorMsg',
            'Something went wrong. Please try again later.',
          );
          openToast(errorMsg);
        }

        this.setState(
          { changingStatus: 'fail' },
          () => {
            setTimeout(() => clearSelected(), 1100);
          }, // animation time + .1s
        );
        if (e.isModified) {
          getStatisticsTotalStart();
          TableComponentInstance.getNewData();
        }
      });
  };

  render() {
    const { onClose, selectedCampaigns } = this.props;
    const { changingStatus } = this.state;

    return (
      <DialogContent>
        <h3 className="title-card mb-4">
          Pause {pluralize('Campaign', selectedCampaigns.length)}?
        </h3>
        {selectedCampaigns.length === 1 ? (
          <h5 className="title-card-subtitle mb-4">
            Pause the <span className="_black">{selectedCampaigns[0].campaignName}</span> campaign?
          </h5>
        ) : (
          <h5 className="title-card-subtitle mb-4">
            Pause the <span className="_black">{selectedCampaigns.length}</span> campaigns?
          </h5>
        )}
        <div className="d-flex justify-content-end mt-2">
          <Button className="btn-square _conflower-blue mr-2 _md" onClick={onClose}>
            Cancel
          </Button>
          <Button className="btn-square _conflower-blue _filled _md" onClick={this.pauseHandler}>
            Pause
          </Button>
        </div>
        {changingStatus ? <Spinner status={changingStatus} onClose={onClose} /> : null}
      </DialogContent>
    );
  }
}

const mapState = (state: AppState) => ({
  selectedCampaigns: state.table.selectedTableCampaigns,
});

const mapActions = {
  clearSelected: tableActions.clearSelectedCampaigns,
  pause: tableActions.pauseCampaigns,
  openToast: toastActions.open,
  getStatuses: filterActions.getStatuses,
  getStatisticsTotalStart: statisticsActions.getStatisticsTotalStart,
};

export const PauseDialogContent = connect(mapState, mapActions)(PauseDialogContentComponent);
