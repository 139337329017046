import { convertFilterToDashboardData } from '../../models/Dashboards';
import { AppState } from '../index';

export const getIsAllAdvertisersSelected = (state: AppState) => {
  let isAllAdvertisersSelected: boolean | null = true;
  if (state.auth.userData.isPlatformOwnerOrg || state.auth.userData.isWorkspaceOwnerOrg) {
    isAllAdvertisersSelected =
      typeof state.filter.isAllAdvertisersSelected !== 'boolean' ||
      state.filter.isAllAdvertisersSelected;
  }

  return isAllAdvertisersSelected;
};

export const getDashboardData = (state: AppState) => {
  const { filter, reports, table, auth, app } = state;
  const { isPlatformOwnerOrg, isWorkspaceOwnerOrg } = auth.userData;
  const { sidebarOpened } = app;

  const isAllAdvertisersSelected = getIsAllAdvertisersSelected(state);

  const newDashboardData = convertFilterToDashboardData({
    ...filter,
    dimension: reports.dimension,
    metric: reports.metric,
    filteredCampaignsIds: table.filteredCampaignsIds,
    sortingAdvertisersColumns:
      isPlatformOwnerOrg || isWorkspaceOwnerOrg ? filter.sortingAdvertisersColumns : undefined,
    sortingWorkspacesColumns: isPlatformOwnerOrg ? filter.sortingWorkspacesColumns : undefined,
    sortingExchangesColumns: isPlatformOwnerOrg ? filter.sortingExchangesColumns : undefined,
    advertisersOwIds:
      !isAllAdvertisersSelected && (isPlatformOwnerOrg || isWorkspaceOwnerOrg)
        ? filter.advertisersOwIds
        : '',
    workspacesOwIds:
      !isAllAdvertisersSelected && (isPlatformOwnerOrg || isWorkspaceOwnerOrg)
        ? filter.workspacesOwIds
        : '',
    isAllAdvertisersSelected,
    sidebarOpened,
  });

  return newDashboardData;
};
