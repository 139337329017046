import { localStorageService } from '../services/localStorage';
import { User } from '../models/User';

export enum UserType {
  SUPER = 'SUPER',
  WORKSPACE = 'WORKSPACE',
  ADVERTISER = 'ADVERTISER',
}

const userData = localStorageService.getBaseInfo<User>();

export const currentUserType = ((): UserType => {
  if (userData) {
    const { isPlatformOwnerOrg, isWorkspaceOwnerOrg } = userData;
    if (isPlatformOwnerOrg) {
      return UserType.SUPER;
    }

    if (!isPlatformOwnerOrg && isWorkspaceOwnerOrg) {
      return UserType.WORKSPACE;
    }

    return UserType.ADVERTISER;
  } else {
    return UserType.ADVERTISER;
  }
})();
