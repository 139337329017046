import React, { useState, useEffect } from 'react';
import { CurrencyFormat } from '../../../utils/format';
import { API } from '../../../api';
import { Tooltip } from 'factor';

export const Balance = (props) => {
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    const success = ({ availableBalance }) => {
      setBalance(availableBalance);
    };
    const error = () => {
      setBalance(0);
    };

    API.profile
      .getAvailableBalance()
      .then(success)
      .catch(error);
  }, []);

  return (
    <div className="nav-bar__balance">
      <div className="nav-bar__balance-info">
        <Tooltip label="Available Balance">
          <span
            className={`nav-bar__balance-value ${
              balance < 0 ? 'nav-bar__balance-value-negative' : 'nav-bar__balance-value-positive'
            }`}
          >
            {CurrencyFormat.format(balance)}
          </span>
        </Tooltip>
      </div>
    </div>
  );
};
