import React from 'react';
import { connect } from 'react-redux';
import { Select, Icon } from 'factor';
import { IOBudgetTypeMapper } from 'iqm-framework';
import { AppState } from '../../../../../../store/index';
import { filterActions, SetIoBudgetTypes } from '../../../../../../store/filter/actions';
import { Option } from '../../../../../../models/Option';
import { TableLevel } from '../../../../../../models/Table';
import styles from './styles.module.scss';

interface StateProps {
  ioBudgetTypes: number[];
  tableLevel: Option;
}

interface DispatchProps extends SetIoBudgetTypes {}

interface OwnProps {
  className?: string;
}

interface Props extends StateProps, DispatchProps, OwnProps {}

const SelectIoBudgetTypeWrapperComponent = (props: Props) => {
  const { ioBudgetTypes, setIoBudgetTypes, tableLevel, className = '' } = props;

  const options = Object.entries(IOBudgetTypeMapper).map((entry: any) => ({
    value: +entry[0],
    label: entry[1].label,
    reactLabel: (
      <div className={styles.option}>
        <Icon name={entry[1].icon} className="mr-2" />
        {entry[1].label}
      </div>
    ),
  }));

  const value = ioBudgetTypes
    .map((bt) =>
      IOBudgetTypeMapper[bt]
        ? {
            label: IOBudgetTypeMapper[bt].label,
            value: bt,
          }
        : null,
    )
    .filter((opt) => opt);

  const onChange = (opts: Option[]) => {
    setIoBudgetTypes(opts.map((opt) => opt.value));
  };

  const label =
    tableLevel.value === TableLevel.InsertionOrders ? 'Insertion Order Type' : 'Campaign Type';

  return (
    <Select
      isMulti
      isClearable
      hideSelectAllWhenEmpty
      options={options}
      onChange={onChange}
      value={value}
      placeholder={label}
      label={label}
      className={className}
    />
  );
};

const mapState = (state: AppState) => ({
  ioBudgetTypes: state.filter.ioBudgetTypes,
  tableLevel: state.filter.tableLevel,
});

const mapAction = {
  setIoBudgetTypes: filterActions.setIoBudgetTypes,
};

export const SelectIoBudgetTypeWrapper = connect<any, any, any, any>(
  mapState,
  mapAction,
)(SelectIoBudgetTypeWrapperComponent);
