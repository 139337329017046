export const filterConstants = {
  SET_DATE_RANGE: 'SET_DATE_RANGE',
  UPDATE_SELECTS: 'UPDATE_SELECTS',
  SELECT_TIMEZONE: 'SELECT_TIMEZONE',
  GET_TIMEZONES: 'GET_TIMEZONES',
  CHANGE_TABLE_LEVEL: 'CHANGE_TABLE_LEVEL',
  UPDATE_SEARCH: 'UPDATE_SEARCH',
  SET_STATUS: 'SET_STATUS',
  UPDATE_STATUS: 'UPDATE_STATUS',
  UPDATE_GRAPH_SELECT: 'UPDATE_GRAPH_SELECT',
  CHANGE_SORTING_COLUMNS: 'CHANGE_SORTING_COLUMNS',
  CHANGE_SORTING_WORKSPACES_COLUMNS: 'CHANGE_SORTING_WORKSPACES_COLUMNS',
  CHANGE_SORTING_ADVERTISERS_COLUMNS: 'CHANGE_SORTING_ADVERTISERS_COLUMNS',
  CHANGE_SORTING_EXCHANGES_COLUMNS: 'CHANGE_SORTING_EXCHANGES_COLUMNS',
  CHANGE_SORTING_INSERTION_ORDERS_COLUMNS: 'CHANGE_SORTING_INSERTION_ORDERS_COLUMNS',
  UPDATE_FREEZE_TABLE_PART: 'UPDATE_FREEZE_TABLE_PART',
  UPDATE_FILTERS: 'UPDATE_FILTERS',
  SET_CREATIVE_TYPES: 'SET_CREATIVE_TYPES',
  SELECT_CREATIVE_TYPE: 'SELECT_CREATIVE_TYPE',
  SET_INITIAL_DATE_RANGE: 'SET_INITIAL_DATE_RANGE',
  SET_CUSTOMERS_OWIDS: 'SET_CUSTOMERS_OWIDS',
  SET_ADVERTISERS_OWIDS: 'SET_ADVERTISERS_OWIDS',
  SET_IS_ALL_ADVERTISERS_SELECTED: 'SET_IS_ALL_ADVERTISERS_SELECTED',
  SET_WORKSPACES_OWIDS: 'SET_WORKSPACES_OWIDS',
  RESET_FILTERS: 'RESET_FILTERS',
  SET_IS_CUSTOMERS_DROPDOWN_LOADED: 'SET_IS_CUSTOMERS_DROPDOWN_LOADED',
  SET_IS_CUSTOMERS_DROPDOWN_ERROR: 'SET_IS_CUSTOMERS_DROPDOWN_ERROR',
  SET_IS_CUSTOMERS_DROPDOWN_EMPTY: 'SET_IS_CUSTOMERS_DROPDOWN_EMPTY',
  SET_IS_CUSTOMERS_DROPDOWN_MOUNTED: 'SET_IS_CUSTOMERS_DROPDOWN_MOUNTED',
  SET_ALLOWED_APPLICATIONS: 'SET_ALLOWED_APPLICATIONS',
  SET_SELECTED_INSERTION_ORDERS: 'SET_SELECTED_INSERTION_ORDERS',
  SET_SELECTED_CAMPAIGNS: 'SET_SELECTED_CAMPAIGNS',
  CAMPAIGN_OPTIONS_START_LOADING: 'CAMPAIGN_OPTIONS_START_LOADING',
  CAMPAIGN_OPTIONS_FINISH_LOADING: 'CAMPAIGN_OPTIONS_FINISH_LOADING',
  SET_CAMPAIGN_OPTIONS_LOADING: 'SET_CAMPAIGN_OPTIONS_LOADING',
  SET_IO_BUDGET_TYPES: 'SET_IO_BUDGET_TYPES',
  SET_CAMPAIGN_TYPE_IDS: 'SET_CAMPAIGN_TYPE_IDS',
};
