import React, { useState } from 'react';
import { IOBudgetTypeMapper } from 'iqm-framework';
import { LambdaResponse } from '../../../../../models/Response';
import { TableDialogWrapper } from '../TableDialogWrapper';
import { LoadingStatus } from '../../../../../models/LoadingStatus';
import { Option } from '../../../../../models/Option';
import { CampaignStatusType } from '../../../../../models/Campaign';
import { Filters } from './Filters/Filters';
import { Table } from './Table';
import { TFilters } from './types';

interface Props {
  insertionOrder: LambdaResponse;
  onClose: () => void;
}

const INITIAL_STATUS = { label: 'All', value: 'all' } as Option<CampaignStatusType>;

export const ViewCampaignsDialog = (props: Props) => {
  const { onClose, insertionOrder } = props;
  const { ioBudgetTypeId, ioName, campaignsCount, ioId, owId } = insertionOrder;
  const { icon: ioBudgetIcon } = IOBudgetTypeMapper[ioBudgetTypeId as number] || {};
  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.PENDING);
  const [filters, setFilters] = useState<TFilters>({
    searchField: '',
    status: INITIAL_STATUS.value,
    creativeTypes: [] as Option[],
  });

  return (
    <TableDialogWrapper
      onClose={onClose}
      title={ioName || ''}
      subtitle={`(${campaignsCount} Campaign${!campaignsCount || campaignsCount > 1 ? 's' : ''})`}
      titleIcon={ioBudgetIcon}
      loading={loading}
    >
      <Filters
        filters={filters}
        setFilters={setFilters}
        ioId={ioId as number}
        owId={owId as number}
      />
      <Table
        insertionOrder={insertionOrder}
        filters={filters}
        loading={loading}
        setLoading={setLoading}
      />
    </TableDialogWrapper>
  );
};
