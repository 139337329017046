export const tableConstants = {
  TABLE_UPDATE_CAMPAIGN_NAME: 'TABLE_UPDATE_CAMPAIGN_NAME',
  TABLE_ADD_SELECTED_CAMPAIGN: 'TABLE_ADD_SELECTED_CAMPAIGN',
  TABLE_REMOVE_SELECTED_CAMPAIGN: 'TABLE_REMOVE_SELECTED_CAMPAIGN',
  TABLE_CLEAR_SELECTED_CAMPAIGN: 'TABLE_CLEAR_SELECTED_CAMPAIGN',
  TABLE_UPDATE_SORTING_PARAMS: 'TABLE_UPDATE_SORTING_PARAMS',
  TABLE_SELECT_ALL_CAMPAIGNS: 'TABLE_SELECT_ALL_CAMPAIGNS',
  TABLE_CLEAR_CAMPAIGNS_DATA: 'TABLE_CLEAR_CAMPAIGNS_DATA',
  TABLE_SET_FILTERED_CAMPAIGNS: 'TABLE_SET_FILTERED_CAMPAIGNS',
  TABLE_SET_FILTERED_CAMPAIGNS_IDS: 'TABLE_SET_FILTERED_CAMPAIGNS_IDS',
  TABLE_SET_CAMPAIGNS: 'TABLE_SET_CAMPAIGNS',
  UPDATE_CAMPAIGNS_LIST: 'UPDATE_CAMPAIGNS_LIST',
  TABLE_CHANGE_SORTING: 'TABLE_CHANGE_SORTING',
  TABLE_SET_TOTAL_ITEMS: 'TABLE_SET_TOTAL_ITEMS',
  ADD_CAMPAIGN_AUDIENCE_WARNINGS: 'ADD_CAMPAIGN_AUDIENCE_WARNINGS',
  CLEAR_CAMPAIGN_AUDIENCE_WARNINGS: 'CLEAR_CAMPAIGN_AUDIENCE_WARNINGS',
  TABLE_SELECT_INSERTION_ORDERS: 'TABLE_SELECT_INSERTION_ORDERS',
};
