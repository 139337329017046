import axios, { Canceler } from 'axios';
import { getInstance } from '..';
import { LambdaV3GraphResponse, WithResponse, ResponseV3 } from '../../models/Response';

let cancelCampaigns: Canceler;

export function cancelGetCampaignStatistics() {
  if (cancelCampaigns) {
    cancelCampaigns('Canceled by the user');
  }
}

export function getCampaignStatistics(query): Promise<ResponseV3<LambdaV3GraphResponse>> {
  if (cancelCampaigns) {
    cancelCampaigns('Canceled by the user');
  }
  return getInstance()
    .post('/v3/das/report/by-campaign', query, {
      cancelToken: new axios.CancelToken((c) => {
        cancelCampaigns = c;
      }),
    })
    .then((response) => response.data)
    .catch((err) => Promise.reject(err));
}

let cancelAdvertisers: Canceler;

export function cancelGetAdvertisersStatistics() {
  if (cancelAdvertisers) {
    cancelAdvertisers('Canceled by the user');
  }
}

export function getAdvertisersStatistics(
  query,
): Promise<WithResponse<ResponseV3<LambdaV3GraphResponse>>> {
  if (cancelAdvertisers) {
    cancelAdvertisers('Canceled by the user');
  }
  return getInstance()
    .post('/v3/das/report/by-customer', query, {
      cancelToken: new axios.CancelToken((c) => {
        cancelAdvertisers = c;
      }),
    })
    .then((response) => response.data)
    .catch((err) => Promise.reject(err));
}

let cancelWorkspaces: Canceler;

export function cancelGetWorkspacesStatistics() {
  if (cancelWorkspaces) {
    cancelWorkspaces('Canceled by the user');
  }
}

export function getWorkspacesStatistics(
  query,
): Promise<WithResponse<ResponseV3<LambdaV3GraphResponse>>> {
  if (cancelWorkspaces) {
    cancelWorkspaces('Canceled by the user');
  }
  return getInstance()
    .post('/v3/das/report/by-workspace', query, {
      cancelToken: new axios.CancelToken((c) => {
        cancelWorkspaces = c;
      }),
    })
    .then((response) => response.data)
    .catch((err) => Promise.reject(err));
}

let cancelExchanges: Canceler;

export function cancelGetExchangesStatistics() {
  if (cancelExchanges) {
    cancelExchanges('Canceled by the user');
  }
}

export function getExchangesStatistics(
  query,
): Promise<WithResponse<ResponseV3<LambdaV3GraphResponse>>> {
  if (cancelExchanges) {
    cancelExchanges('Canceled by the user');
  }
  return getInstance()
    .post('/v3/das/report/by-exchange', query, {
      cancelToken: new axios.CancelToken((c) => {
        cancelExchanges = c;
      }),
    })
    .then((response) => response.data)
    .catch((err) => Promise.reject(err));
}

let cancelInsertionOrders: Canceler;

export function cancelGetInsertionOrdersStatistics() {
  if (cancelInsertionOrders) {
    cancelInsertionOrders('Canceled by the user');
  }
}

export function getInsertionOrdersStatistics(query): Promise<ResponseV3<LambdaV3GraphResponse>> {
  if (cancelInsertionOrders) {
    cancelInsertionOrders('Canceled by the user');
  }
  return getInstance()
    .post('/v3/das/report/by-io', query, {
      cancelToken: new axios.CancelToken((c) => {
        cancelInsertionOrders = c;
      }),
    })
    .then((response) => response.data)
    .catch((err) => Promise.reject(err));
}

let cancelPrevCampaigns: Canceler;

export function cancelGetCampaignPrevStatistics() {
  if (cancelPrevCampaigns) {
    cancelPrevCampaigns('Canceled by the user');
  }
}

export function getCampaignPrevStatistics(query): Promise<ResponseV3<LambdaV3GraphResponse>> {
  cancelGetCampaignPrevStatistics();
  return getInstance()
    .post('/v3/das/report/by-campaign', query, {
      cancelToken: new axios.CancelToken((c) => {
        cancelPrevCampaigns = c;
      }),
    })
    .then((response) => response.data)
    .catch((err) => Promise.reject(err));
}

let cancelPrevAdvertisers: Canceler;

export function cancelGetAdvertisersPrevStatistics() {
  if (cancelPrevAdvertisers) {
    cancelPrevAdvertisers('Canceled by the user');
  }
}

export function getAdvertisersPrevStatistics(
  query,
): Promise<WithResponse<ResponseV3<LambdaV3GraphResponse>>> {
  cancelGetAdvertisersPrevStatistics();
  return getInstance()
    .post('/v3/das/report/by-customer', query, {
      cancelToken: new axios.CancelToken((c) => {
        cancelPrevAdvertisers = c;
      }),
    })
    .then((response) => response.data)
    .catch((err) => Promise.reject(err));
}

let cancelPrevWorkspaces: Canceler;

export function cancelGetWorkspacesPrevStatistics() {
  if (cancelPrevWorkspaces) {
    cancelPrevWorkspaces('Canceled by the user');
  }
}

export function getWorkspacesPrevStatistics(
  query,
): Promise<WithResponse<ResponseV3<LambdaV3GraphResponse>>> {
  cancelGetWorkspacesPrevStatistics();
  return getInstance()
    .post('/v3/das/report/by-workspace', query, {
      cancelToken: new axios.CancelToken((c) => {
        cancelPrevWorkspaces = c;
      }),
    })
    .then((response) => response.data)
    .catch((err) => Promise.reject(err));
}

let cancelPrevExchanges: Canceler;

export function cancelGetExchangesPrevStatistics() {
  if (cancelPrevExchanges) {
    cancelPrevExchanges('Canceled by the user');
  }
}

export function getExchangesPrevStatistics(
  query,
): Promise<WithResponse<ResponseV3<LambdaV3GraphResponse>>> {
  cancelGetExchangesPrevStatistics();
  return getInstance()
    .post('/v3/das/report/by-exchange', query, {
      cancelToken: new axios.CancelToken((c) => {
        cancelPrevExchanges = c;
      }),
    })
    .then((response) => response.data)
    .catch((err) => Promise.reject(err));
}

let cancelPrevInsertionOrders: Canceler;

export function cancelGetInsertionOrdersPrevStatistics() {
  if (cancelPrevInsertionOrders) {
    cancelPrevInsertionOrders('Canceled by the user');
  }
}

export function getInsertionOrdersPrevStatistics(
  query,
): Promise<ResponseV3<LambdaV3GraphResponse>> {
  cancelGetInsertionOrdersPrevStatistics();
  return getInstance()
    .post('/v3/das/report/by-io', query, {
      cancelToken: new axios.CancelToken((c) => {
        cancelPrevInsertionOrders = c;
      }),
    })
    .then((response) => response.data)
    .catch((err) => Promise.reject(err));
}
