import { getInstance } from './index';
import { OptionID } from '../models/Option';

export function getTimezones(): Promise<OptionID[]> {
  return getInstance()
    .get('/v2/adv/static/timezones')
    .then((response) => response.data.responseObject)
    .then((data: [{ id: number; label: string; name: string }]) => {
      return data.map((item) => {
        return {
          label: item.label,
          value: item.name,
          id: item.id,
        };
      });
    })
    .catch(() => {
      return [];
    });
}
