import { logDataDogAction } from 'iqm-framework';
import { SERVICE } from '../constants';
import { TableLevel } from '../../../models/Table';

export interface LoggableTableSorting {
  label: React.ReactNode;
  sortingKey: string;
  textLabel?: string;
}

export const App = {
  sortMainTable: (info: LoggableTableSorting, tableLevel: TableLevel) => {
    logDataDogAction('Sort Main Dashboard Table', {
      action: `${SERVICE}/sortMainTable`,
      info: {
        columnLabel: typeof info.label === 'string' ? info.label : info.textLabel,
        sortingKey: info.sortingKey,
        table: tableLevel,
      },
    });
  },
  toggleSidebar: (info: { isOpening: boolean }) => {
    const { isOpening } = info;
    logDataDogAction(`Toggle Sidebar ${isOpening ? 'Open' : 'Close'}`, {
      action: `${SERVICE}/toggleSidebar`,
      info,
    });
  },
};
