export const allowedEmailsBiddingInsights = [
  'desh.s+stageadv@iqm.com',
  'meera.v+slack1@iqm.com',
  'bharat.b+signupcust1',
  'meera.v+preprod@iqm.com',
  'meera.v+pre-slack2@iqm.com',
  'meera.v+pre-slack2@iqm.com',
  'rohit.p+RohitPrepodAdv1@iqm.com',
  'rohit.p+RohitPrepodAdv1@iqm.com',
  'vishakha.k+cust1@iqm.com',
  'vishakha.k+cust1@iqm.com',
  'hardik.v+preprod@iqm.com',
  'hardik.v+preprod@iqm.com',
  'patik.t@iqm.com',
  'patik.t@iqm.com',
  'demo@iqm.com',
  'demo@iqm.com',
  'meera.v@iqm.com',
  'meera.v@iqm.com',
  'meera.v+w23@iqm.com',
  'meera.v+w23@iqm.com',
  'rohit.p+w23@iqm.com',
  'rohit.p+w23@iqm.com',
  'bharat.b@iqm.com',
  'bharat.b@iqm.com',
  'vishakha.k+w23@iqm.com',
  'vishakha.k+w23@iqm.com',
  'vishakha.k+3112@iqm.com',
  'vishakha.k+3112@iqm.com',
  'hardik.v+w23@iqm.com',
  'hardik.v+w23@iqm.com',
  'pratik.t@iqm.com',
  'pratik.t@iqm.com',
  'darshan.k@iqm.com',
  'darshan.k@iqm.com',
  'darshil.c@iqm.com',
  'dhimant.b@iqm.com',
  'pooja.r@iqm.com',
  'meera.v+w24@iqm.com',
  'rohit.p+w24@iqm.com',
  'vishakha.k+w24@iqm.com',
  'hardik.v+w24@iqm.com',
  'deepshikha.v+w24@iqm.com',
].map((one) => one.toLocaleLowerCase());
