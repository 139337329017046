import React from 'react';
import { Icon, Tooltip } from 'factor';
import styles from './styles.module.scss';
import pacingStyles from '../styles.module.scss';

const getPacingText = (txt) => `Pacing is ${txt}% of the expected spent`;

const pacingPercentageInfo = [
  {
    text: getPacingText('>120'),
    className: pacingStyles.pacingPurple,
  },
  {
    text: getPacingText('>90'),
    className: pacingStyles.pacingGreen,
  },
  {
    text: getPacingText('>60'),
    className: pacingStyles.pacingYellow,
  },
  {
    text: getPacingText('<60'),
    className: pacingStyles.pacingRed,
  },
];

interface Props {
  title: 'Campaign Pacing' | 'Daily Pacing' | 'IO Pacing';
  footerLink?: string;
}

const TooltipContents = (props: Props) => (
  <div
    className={styles.tooltipContents}
    onClick={(e) => e.stopPropagation()}
    onMouseMove={(e) => e.stopPropagation()}
  >
    <div className={`${styles.tooltipTitle} ${styles.demi}`}>{props.title}</div>
    <div className={styles.tooltipBody}>
      <div className="mb-3 mt-3">
        <span className={styles.demi}>Number%</span> signifies the actual budget{' '}
        <span className={styles.demi}>spent%</span>.
      </div>
      <div className="mb-3">
        Colors denote the pacing % with respect to the time and actual budget.
      </div>
      <div>
        {pacingPercentageInfo.map((info) => (
          <div className={styles.pacingInfoItem}>
            <div className={`${styles.colorSample} ${info.className}`} />
            <div>{info.text}</div>
          </div>
        ))}
        {(props.title === 'Campaign Pacing' || props.title === 'IO Pacing') && (
          <div className={styles.pacingInfoItem}>
            <div className={`${styles.colorSample} ${pacingStyles.pacingUnknown}`} />
            <div>End date is not provided. Hence, pacing cannot be calculated.</div>
          </div>
        )}
      </div>
      {!!props.footerLink && (
        <div className={styles.footerLink}>
          <a href={props.footerLink} rel="noreferrer" target="_blank">
            Know more
          </a>
        </div>
      )}
    </div>
  </div>
);

const HeaderTooltip = (props: Props) => {
  return (
    <Tooltip
      className={styles.tooltip}
      portal
      auto
      adjustPortalPosition
      label={<TooltipContents title={props.title} footerLink={props.footerLink} />}
      mouseoutDelay={300}
    >
      <Icon name="Info" className={styles.tooltipIcon} />
    </Tooltip>
  );
};

export const CampaignPacingHeaderTooltip = () => (
  <HeaderTooltip
    title="Campaign Pacing"
    footerLink="https://help.iqm.com/en/articles/8672915-a-guide-to-io-pacing-campaign-pacing-daily-pacing-in-iqm-s-platform"
  />
);

export const DailyPacingHeaderTooltip = () => (
  <HeaderTooltip
    title="Daily Pacing"
    footerLink="https://help.iqm.com/en/articles/8672915-a-guide-to-io-pacing-campaign-pacing-daily-pacing-in-iqm-s-platform"
  />
);

export const IOPacingHeaderTooltip = () => (
  <HeaderTooltip
    title="IO Pacing"
    footerLink="https://help.iqm.com/en/articles/8672915-a-guide-to-io-pacing-campaign-pacing-daily-pacing-in-iqm-s-platform"
  />
);

export const StatInfoTooltip = ({ infoLabel = '' }) => {
  return (
    <Tooltip portal auto label={infoLabel} adjustPortalPosition>
      <Icon name="Info" className={styles.statInfoTooltipIcon} />
    </Tooltip>
  );
};
