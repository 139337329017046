import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Select } from 'factor';

import { Option } from '../../../../../models/Option';
import { TableSortingParams } from '../../../../../models/Table';
import {
  DefaultTableLevels,
  WorkspaceOwnerTableLevels,
  IQMSuperTableLevels,
  TableLevel,
} from '../../../../../store/filter/reducers';
import { AppState } from '../../../../../store';
import { filterActions } from '../../../../../store/filter/actions';
import { tableActions } from '../../../../../store/table/actions';
import { defaultTableState } from '../../../../../store/table/reducers';
import { DEFAULT_IO_TABLE_SORTING } from '../../../../../models/InsertionOrder';

interface Props {
  level: Option;
  isPlatformOwnerOrg: boolean;
  isWorkspaceOwnerOrg: boolean;
  changeLevel: (value: Option) => void;
  clearCampaignsData: () => void;
  getStatuses: () => void;
  clearSelectedCampaigns: () => void;
  setFilteredCampaignsIds: (ids: number[]) => void;
  updateSorting: (sorting: TableSortingParams) => void;
  isProfileLoaded: boolean;
  setSelectedInsertionOrders: (ios: number[]) => void;
  setSelectedCampaigns: (cmps: number[]) => void;
}

const SelectGroupByWrapperComponent = (props: Props) => {
  const {
    level,
    isPlatformOwnerOrg,
    isWorkspaceOwnerOrg,
    changeLevel,
    clearCampaignsData,
    getStatuses,
    setFilteredCampaignsIds,
    updateSorting,
    isProfileLoaded,
    setSelectedInsertionOrders,
    setSelectedCampaigns,
  } = props;

  const [items, setItems] = useState<Option[]>(DefaultTableLevels);

  useEffect(() => {
    if (isProfileLoaded) {
      if (isPlatformOwnerOrg) {
        // IQM Super can access Advertisers and Workspaces
        setItems(IQMSuperTableLevels);
      } else if (isWorkspaceOwnerOrg) {
        // Workspace owner can access Advertisers but not Workspaces
        setItems(WorkspaceOwnerTableLevels);
      } else {
        setItems(DefaultTableLevels);
      }
    }
  }, [isPlatformOwnerOrg, isWorkspaceOwnerOrg, isProfileLoaded]);

  const onChangeHandler = useCallback(
    (v: Option) => {
      const item = v ? items.find((i) => i.value === v.value) : items[0];

      if (item) {
        changeLevel(item);
        if (item.value === TableLevel.Advertisers || item.value === TableLevel.Workspaces) {
          setFilteredCampaignsIds([]);
        }
        setSelectedInsertionOrders([]);
        setSelectedCampaigns([]);
        if (item.value === TableLevel.InsertionOrders) {
          updateSorting(DEFAULT_IO_TABLE_SORTING as TableSortingParams);
        } else {
          updateSorting({ ...defaultTableState.sorting });
        }

        if (item.value === 'campaigns') {
          clearCampaignsData();
          getStatuses();
        }
      }
    },
    [
      items,
      changeLevel,
      setSelectedInsertionOrders,
      setSelectedCampaigns,
      clearCampaignsData,
      getStatuses,
      setFilteredCampaignsIds,
      updateSorting,
    ],
  );

  return (
    <Select
      name="levelSwitcher"
      onChange={onChangeHandler}
      value={level}
      options={items}
      label="View By"
    />
  );
};

const mapState = (state: AppState) => {
  return {
    level: state.filter.tableLevel,
    isWorkspaceOwnerOrg: state.auth.userData.isWorkspaceOwnerOrg,
    isPlatformOwnerOrg: state.auth.userData.isPlatformOwnerOrg,
    isProfileLoaded: state.auth.isProfileLoaded,
  };
};

const mapActions = {
  changeLevel: filterActions.changeTableLevel,
  getStatuses: filterActions.getStatuses,
  clearCampaignsData: tableActions.clearCampaignsData,
  clearSelectedCampaigns: tableActions.clearSelectedCampaigns,
  setFilteredCampaignsIds: tableActions.setFilteredCampaignsIds,
  updateSorting: tableActions.updateSorting,
  setSelectedInsertionOrders: filterActions.setSelectedInsertionOrders,
  setSelectedCampaigns: filterActions.setSelectedCampaigns,
};

export const SelectGroupByWrapper = connect(mapState, mapActions)(SelectGroupByWrapperComponent);
