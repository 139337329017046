import React from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import xor from 'lodash/xor';
import get from 'lodash/get';
import { Button, Dialog, Tooltip, Icon } from 'factor';

import { AppState } from '../../../store';
import { Dashboard } from '../../../models/Dashboards';
import { FilterState, defaultFilterState } from '../../../store/filter/reducers';
import {
  ChangeSortingColumns,
  filterActions,
  SetIoBudgetTypes,
} from '../../../store/filter/actions';
import { tableActions, SetFilteredCampaignsIds } from '../../../store/table/actions';
import {
  dashboardsActions,
  SelectDashboard,
  ClearSelectedDashboard,
  IndicateDashboardHasReset,
} from '../../../store/dashboards/actions';
import { reportsActions } from '../../../store/reports/actions';
import { defaultReportState } from '../../../store/reports/reducers';
import { ResetDialogContent } from './resetButton/ResetDialogContent';
import {
  DimensionOption,
  MetricOption,
  DimensionOptions,
  MetricOptions,
  getDimension,
  getMetric,
} from '../../../models/Report';
import { LoadingStatus } from '../../../models/LoadingStatus';
import { getDefaultSortingInsertionOrdersColumns } from '../../../models/Option';
import { appActions } from '../../../store/app/actions';
import { defaultAppState } from '../../../store/app/reducers';

import './resetButton/styles.scss';

interface Props
  extends SetFilteredCampaignsIds,
    SetIoBudgetTypes,
    SelectDashboard,
    ClearSelectedDashboard,
    IndicateDashboardHasReset {
  filters: FilterState;
  userTimezone: string;
  selectedDimension: DimensionOption | null;
  selectedMetric: MetricOption | null;
  selectedDashboard: Dashboard | null;
  filteredCampaignsIds: number[];
  updateFilters: (data: FilterState) => void;
  changeMetric: (metric: MetricOption | null) => void;
  changeDimension: (dimension: DimensionOption | null) => void;
  dimensionsMetricsLoading: LoadingStatus;
  dimensionOptions: DimensionOptions;
  metricOptions: MetricOptions;
  isWorkspaceOwnerOrg: boolean;
  isPlatformOwnerOrg: boolean;
  changeSortingInsertionOrdersColumns: ChangeSortingColumns['changeSortingInsertionOrdersColumns'];
  setSidebarOpen: (open: boolean) => void;
  isVldEnabled: boolean;
  hadVldGenerated: boolean;
}

interface State {
  dialogIsOpen: boolean;
}

class ResetButtonComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      dialogIsOpen: false,
    };
  }

  getStartDate = (tz) => {
    return moment
      .tz(moment(), tz)
      .startOf('day')
      .unix();
  };

  getEndDate = (tz) => {
    return moment
      .tz(moment(), tz)
      .endOf('day')
      .unix();
  };

  get defaultFilters() {
    const {
      filters,
      userTimezone,
      isPlatformOwnerOrg,
      isWorkspaceOwnerOrg,
      isVldEnabled,
      hadVldGenerated,
    } = this.props;
    return {
      timezone: filters.timezones.find((tz) => tz.value === userTimezone) || filters.timezone,
      tableLevel: defaultFilterState.tableLevel,
      status: defaultFilterState.status.value,
      sortingColumns: defaultFilterState.sortingColumns.map((i) => i.value),
      sortingInsertionOrdersColumns: getDefaultSortingInsertionOrdersColumns({
        isWorkspaceOwnerOrg,
        isPlatformOwnerOrg,
        isVldEnabled,
        hadVldGenerated,
      }).map((i) => i.value),
      selectedCreativeTypes: defaultFilterState.selectedCreativeTypes,
      search: defaultFilterState.search,
      graph1: defaultFilterState.graph1,
      graph2: defaultFilterState.graph2,
      selectedInsertionOrders: defaultFilterState.selectedInsertionOrders,
      ioBudgetTypes: defaultFilterState.ioBudgetTypes,
      isAllAdvertisersSelected: defaultFilterState.isAllAdvertisersSelected,
      workspacesOwIds: defaultFilterState.workspacesOwIds,
      advertisersOwIds: defaultFilterState.advertisersOwIds,
    };
  }

  get updatedFilters() {
    const { filters, isPlatformOwnerOrg, isWorkspaceOwnerOrg } = this.props;
    return {
      timezone: filters.timezone,
      tableLevel: filters.tableLevel,
      status: filters.status.value,
      sortingColumns: filters.sortingColumns.map((i) => i.value),
      sortingInsertionOrdersColumns: filters.sortingInsertionOrdersColumns
        .map((i) => i.value)
        .filter((v) => v)
        .sort(),
      selectedCreativeTypes: filters.selectedCreativeTypes,
      search: filters.search,
      graph1: filters.graph1,
      graph2: filters.graph2,
      selectedInsertionOrders: filters.selectedInsertionOrders,
      ioBudgetTypes: filters.ioBudgetTypes,
      isAllAdvertisersSelected: filters.isAllAdvertisersSelected,
      workspacesOwIds:
        !filters.isAllAdvertisersSelected && (isPlatformOwnerOrg || isWorkspaceOwnerOrg)
          ? filters.workspacesOwIds
          : '',
      advertisersOwIds:
        !filters.isAllAdvertisersSelected && (isPlatformOwnerOrg || isWorkspaceOwnerOrg)
          ? filters.advertisersOwIds
          : '',
    };
  }

  get savedFilters() {
    const { selectedDashboard, isPlatformOwnerOrg, isWorkspaceOwnerOrg } = this.props;
    const isAllAdvertisersSelected =
      (isPlatformOwnerOrg || isWorkspaceOwnerOrg) && selectedDashboard
        ? typeof selectedDashboard.data.isAllAdvertisersSelected !== 'boolean' ||
          selectedDashboard.data.isAllAdvertisersSelected
        : true;
    return (
      selectedDashboard && {
        timezone: selectedDashboard.data.timezone,
        tableLevel: selectedDashboard.data.tableLevel,
        status: selectedDashboard.data.status.value,
        sortingColumns: selectedDashboard.data.sortingColumns.map((i) => i.value),
        sortingInsertionOrdersColumns: (selectedDashboard.data.sortingInsertionOrdersColumns || [])
          .map((i) => i.value)
          .filter((v) => v)
          .sort(),
        selectedCreativeTypes: selectedDashboard.data.selectedCreativeTypes,
        search: selectedDashboard.data.search,
        graph1: selectedDashboard.data.graph1,
        graph2: selectedDashboard.data.graph2,
        selectedInsertionOrders: selectedDashboard.data.selectedInsertionOrders,
        ioBudgetTypes: selectedDashboard.data.ioBudgetTypes,
        isAllAdvertisersSelected,
        advertisersOwIds:
          !isAllAdvertisersSelected &&
          selectedDashboard &&
          (isPlatformOwnerOrg || isWorkspaceOwnerOrg)
            ? selectedDashboard.data.advertisersOwIds || ''
            : '',
        workspacesOwIds:
          !isAllAdvertisersSelected &&
          selectedDashboard &&
          (isPlatformOwnerOrg || isWorkspaceOwnerOrg)
            ? selectedDashboard.data.workspacesOwIds || ''
            : '',
      }
    );
  }

  get shouldShowResetButton() {
    const {
      filteredCampaignsIds,
      selectedDimension,
      selectedMetric,
      selectedDashboard,
      dimensionOptions,
      metricOptions,
    } = this.props;
    if (selectedDashboard) {
      return (
        xor(filteredCampaignsIds, selectedDashboard.data.filteredCampaignsIds).length ||
        !isEqual(
          get(selectedDimension, 'value'),
          get(getDimension(selectedDashboard.data.dimension, dimensionOptions), 'value'),
        ) ||
        !isEqual(
          get(selectedMetric, 'value'),
          get(getMetric(selectedDashboard.data.metric, metricOptions), 'value'),
        ) ||
        !isEqual(this.updatedFilters, this.savedFilters)
      );
    }

    return (
      !!filteredCampaignsIds.length ||
      !isEqual(selectedDimension, getDimension(defaultReportState.dimension, dimensionOptions)) ||
      !isEqual(selectedMetric, getMetric(defaultReportState.metric, metricOptions)) ||
      !isEqual(this.defaultFilters, this.updatedFilters)
    );
  }

  openDialog = () => {
    this.setState({ dialogIsOpen: true });
  };

  closeDialog = () => {
    this.setState({ dialogIsOpen: false });
  };

  handleReset = () => {
    if (this.props.selectedDashboard) {
      this.props.selectDashboard({ ...this.props.selectedDashboard });
      this.props.indicateDashboardHasReset();
    } else {
      this.props.updateFilters({
        ...this.props.filters,
        ...this.defaultFilters,
        status:
          this.props.filters.statusOptions.find(
            (s) => s.value === defaultFilterState.status.value,
          ) || defaultFilterState.status,
        sortingColumns: defaultFilterState.sortingColumns,
        sortingInsertionOrdersColumns: getDefaultSortingInsertionOrdersColumns({
          isWorkspaceOwnerOrg: this.props.isWorkspaceOwnerOrg,
          isPlatformOwnerOrg: this.props.isPlatformOwnerOrg,
          isVldEnabled: this.props.isVldEnabled,
          hadVldGenerated: this.props.hadVldGenerated,
        }).map((opt) => ({ ...opt, active: true })),
        sortingAdvertisersColumns: defaultFilterState.sortingAdvertisersColumns,
        sortingWorkspacesColumns: defaultFilterState.sortingWorkspacesColumns,
        sortingExchangesColumns: defaultFilterState.sortingExchangesColumns,
        isCustomersDropdownMounted: defaultFilterState.isCustomersDropdownMounted,
        isAllAdvertisersSelected: defaultFilterState.isAllAdvertisersSelected,
        workspacesOwIds: defaultFilterState.workspacesOwIds,
        advertisersOwIds: defaultFilterState.advertisersOwIds,
        isCustomersDropdownLoaded: defaultFilterState.isCustomersDropdownLoaded,
        isCustomersDropdownError: defaultFilterState.isCustomersDropdownError,
        isCustomersDropdownEmpty: defaultFilterState.isCustomersDropdownEmpty,
      });
      this.props.changeMetric(defaultReportState.metric);
      this.props.changeDimension(defaultReportState.dimension);
      this.props.setFilteredCampaignsIds([]);
      this.props.setIoBudgetTypes([]);
      this.props.setSidebarOpen(defaultAppState.sidebarOpened);
    }

    this.closeDialog();
  };

  render() {
    const { selectedDashboard, dimensionsMetricsLoading } = this.props;
    const { dialogIsOpen } = this.state;
    const showResetButton = this.shouldShowResetButton;

    const dialog = (
      <Dialog open={dialogIsOpen} onClickOutside={this.closeDialog}>
        <ResetDialogContent
          onReset={this.handleReset}
          onClose={this.closeDialog}
          isRevertingToSaved={!!selectedDashboard}
        />
      </Dialog>
    );

    return showResetButton ? (
      <React.Fragment>
        <Tooltip
          label={`Reset all the Dashboard parameters to ${
            selectedDashboard ? 'saved' : 'default'
          } values`}
          portal
        >
          <Button
            className="btn btn-square btn-reset _gray"
            onClick={this.openDialog}
            disabled={dimensionsMetricsLoading !== LoadingStatus.SUCCESS}
          >
            <Icon name="Refresh" />
            Reset Dashboard
          </Button>
        </Tooltip>
        {createPortal(dialog, document.body)}
      </React.Fragment>
    ) : null;
  }
}

const mapState = (state: AppState) => ({
  loading: state.dashboards.loading,
  dashboardsLength: state.dashboards.dashboards.length,
  selectedDashboard: state.dashboards.selectedDashboard,
  selectedDimension: state.reports.dimension,
  selectedMetric: state.reports.metric,
  filters: state.filter,
  filteredCampaignsIds: state.table.filteredCampaignsIds,
  userTimezone: state.auth.userData.timezoneName,
  dimensionsMetricsLoading: state.reports.dimensionsMetricsLoading,
  dimensionOptions: state.reports.dimensionOptions,
  metricOptions: state.reports.metricOptions,
  isWorkspaceOwnerOrg: state.auth.userData.isWorkspaceOwnerOrg,
  isPlatformOwnerOrg: state.auth.userData.isPlatformOwnerOrg,
  isVldEnabled: state.auth.userData.isVldEnabled,
  hadVldGenerated: state.auth.userData.hadVldGenerated,
});

const mapActions = {
  updateFilters: filterActions.updateFilters,
  changeMetric: reportsActions.changeReportMetric,
  changeDimension: reportsActions.changeReportDimension,
  setFilteredCampaignsIds: tableActions.setFilteredCampaignsIds,
  selectDashboard: dashboardsActions.selectDashboard,
  clearSelectedDashboard: dashboardsActions.clearSelectedDashboard,
  indicateDashboardHasReset: dashboardsActions.indicateDashboardHasReset,
  changeSortingInsertionOrdersColumns: filterActions.changeSortingInsertionOrdersColumns,
  setIoBudgetTypes: filterActions.setIoBudgetTypes,
  setSidebarOpen: appActions.toggleSidebar,
};

export const ResetButton = connect(mapState, mapActions)(ResetButtonComponent);
