import { Dispatch } from 'redux';
import { lambdaV3API } from '../../api/lambdaV3API';
import { AppState } from '../index';
import { reportsConstants } from './constants';
import { createAction } from '../../utils/actions';
import { Option } from '../../models/Option';
import { toastConstants } from '../toast/constants';
import {
  DimensionOptions,
  MetricOptions,
  transformMetricOptions,
  transformDimensionOptions,
  MetricOption,
  DimensionOption,
  getDimension,
  getMetric,
} from '../../models/Report';
import { GENERAL_API_ERROR } from '../../constants/tooltips';

export const reportsActions = {
  getDimensionsAndMetrics: function() {
    return async (dispatch: Dispatch<any>, getState: () => AppState) => {
      try {
        dispatch(createAction<void>(reportsConstants.LOADING_DIMENSIONS_METRICS__START));
        const res = await lambdaV3API.reportsGraph.getDimensionsAndMetrics();
        const { isPlatformOwnerOrg, isWorkspaceOwnerOrg } = getState().auth.userData;
        const dimensionOptions = transformDimensionOptions(
          res.dimensions,
          isPlatformOwnerOrg,
          isWorkspaceOwnerOrg,
        );
        const metricOptions = transformMetricOptions(res.metrics);

        dispatch(
          createAction<{ dimensionOptions: DimensionOptions; metricOptions: MetricOptions }>(
            reportsConstants.LOADING_DIMENSIONS_METRICS__SUCCESS,
            {
              dimensionOptions,
              metricOptions,
            },
          ),
        );
        return { dimensionOptions, metricOptions };
      } catch (err) {
        dispatch(createAction<void>(reportsConstants.LOADING_DIMENSIONS_METRICS__ERROR));
        dispatch(createAction(toastConstants.TOAST_OPEN, GENERAL_API_ERROR));
        return Promise.reject(err);
      }
    };
  },

  changeReportDimension: function(dimension: DimensionOption | Option | null) {
    return (dispatch: Dispatch<any>, getState: () => AppState) => {
      const { dimensionOptions } = getState().reports;
      dispatch(
        createAction<DimensionOption | null>(
          reportsConstants.CHANGE_DIMENSION,
          getDimension(dimension, dimensionOptions),
        ),
      );
    };
  },

  changeReportMetric: function(metric: MetricOption | Option | null) {
    return (dispatch: Dispatch<any>, getState: () => AppState) => {
      const { metricOptions } = getState().reports;
      dispatch(
        createAction<MetricOption | null>(
          reportsConstants.CHANGE_METRIC,
          getMetric(metric, metricOptions),
        ),
      );
    };
  },
};
