import React from 'react';
import { connect } from 'react-redux';
import { Select } from 'factor';
import { ResponsiveBar } from '@nivo/bar';
import { createPortal } from 'react-dom';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';

import { ChartMetric } from '../../../../../../models/ChartMetric';
import { Option } from '../../../../../../models/Option';
import { LoadingStatus } from '../../../../../../models/LoadingStatus';
import {
  DimensionOption,
  MetricOption,
  DimensionOptions,
  MetricOptions,
  filterMetricsForDimension,
  DEFAULT_DIMENSION_KEY,
  percentFormatMetrics,
  integerFormatMetrics,
  currencyFormatMetrics,
  getMetric,
} from '../../../../../../models/Report';
import { reportsActions } from '../../../../../../store/reports/actions';
import { AppState } from '../../../../../../store/index';
import { StatisticReport } from '../../../../../../store/reports/reducers';
import { SkeletonChart } from '../SkeletonChart';
import CustomBarChartBarItem from './CustomBarChartBarItem';
import CustomBarChartTick from './CustomBarChartTick';
import { ChartTooltip } from '../barWithLineChart/ChartTooltip';
import { NoDataChart } from '../NoDataChart';
import { CurrencyFormat, formatNumberWithSuffix } from '../../../../../../utils/format';
import { DateRange } from '../../../../../../store/filter/reducers';
import { TableLevel } from '../../../../../../models/Table';
import { hasNoCustomers } from '../../../../../../utils/errors';
import { Open, toastActions } from '../../../../../../store/toast/actions';
import { GENERAL_API_ERROR, NO_CUSTOMER_ASSIGNED } from '../../../../../../constants/tooltips';
import { useReportData } from '../../../../../../hooks/useReportData';

interface BarChartOption<T> {
  value: string;
  label: string;
  isGroupHeader?: boolean;
  options?: T[];
}

const DIMENSION_KEYS_MAPPER = {
  [TableLevel.Advertisers]: 'organizationName',
  [TableLevel.Workspaces]: 'organizationName',
};

interface Props {
  className?: string;
  data: StatisticReport[];
  dateRange: DateRange | null;
  metrics: MetricOptions;
  dimensions: DimensionOptions;
  selectedMetric: MetricOption | null;
  selectedDimension: DimensionOption | null;
  loading: boolean;
  changeMetric: (metric: MetricOption | null) => void;
  changeDimension: (dimension: DimensionOption) => void;
  isPlatformOwnerOrg: boolean;
  isWorkspaceOwnerOrg: boolean;
  dimensionsMetricsLoading: LoadingStatus;
  dashboardsListLoading: boolean;
  dashboardCount: number;
  errorMsg: string | null;
  userHasNoCustomers: boolean;
  openToast: Open['open'];
}

interface State {
  chart1Metric1?: ChartMetric | null;
  chart1Metric2?: ChartMetric | null;
  label: any;
  value: any;
  coordX: any;
  coordY: any;
  tooltipIsActive: any;
  dimensionOptions: BarChartOption<DimensionOption>[];
  metricOptions: BarChartOption<MetricOption>[];
  defaultDimension: DimensionOption | null;
  hasInitEmptyDashList: boolean;
}

const BarWrapper = (props: any) => <ResponsiveBar {...props} />;

const HorizontalBarChartComponentWrapper = (
  props: Omit<Props, 'loading' | 'data' | 'errorMsg'>,
) => {
  const reportQuery = useReportData();

  const errorMsg =
    !reportQuery.data && reportQuery.error
      ? (reportQuery.error as any)?.response?.data?.errorObjects?.[0]?.error || GENERAL_API_ERROR
      : null;

  return (
    <HorizontalBarChartComponent
      {...props}
      errorMsg={errorMsg}
      loading={reportQuery.isLoading}
      data={reportQuery.data || []}
    />
  );
};

class HorizontalBarChartComponent extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      chart1Metric1: null,
      chart1Metric2: null,
      label: null,
      value: null,
      coordX: null,
      coordY: null,
      tooltipIsActive: null,
      dimensionOptions: [],
      metricOptions: [],
      defaultDimension: null,
      hasInitEmptyDashList: false,
    };
  }

  findDefaultDimension = () => {
    const defaultDimension =
      this.state.dimensionOptions
        .reduce((acc, curr) => {
          return [...acc, ...(curr.options ? curr.options : [])];
        }, [] as DimensionOption[])
        .find((opt) => opt.key === DEFAULT_DIMENSION_KEY) || null;
    this.setState({ defaultDimension });
  };

  getHeaderOptions = (list: DimensionOptions | MetricOptions) => {
    return (list as any[])
      .map((grp) => ({
        label: Object.keys(grp)[0],
        value: Object.keys(grp)[0],
        isGroupHeader: true,
        options: cloneDeep(Object.values(grp)[0]),
      }))
      .filter((grp) => grp.options && grp.options.length);
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {
      dateRange,
      isPlatformOwnerOrg,
      isWorkspaceOwnerOrg,
      selectedMetric,
      selectedDimension,
      changeMetric,
      dimensions,
      metrics,
      dashboardsListLoading,
      dashboardCount,
    } = this.props;
    const { defaultDimension, hasInitEmptyDashList, dimensionOptions } = this.state;

    if (
      dateRange &&
      prevProps.dateRange &&
      (this.state.defaultDimension !== prevState.defaultDimension ||
        dateRange.start.valueOf() !== prevProps.dateRange.start.valueOf() ||
        dateRange.end.valueOf() !== prevProps.dateRange.end.valueOf()) &&
      this.isDateRangeMoreThanQuarter()
    ) {
      this.handleChangeDimension(this.state.defaultDimension);
    }

    if (!isEqual(prevProps.dimensions, dimensions) && dimensions && dimensions.length) {
      this.setState({
        dimensionOptions: this.getHeaderOptions(dimensions),
      });
    }

    if (
      !isEqual(prevState.dimensionOptions, this.state.dimensionOptions) &&
      get(this.state, 'dimensionOptions[0].options.length')
    ) {
      this.findDefaultDimension();
    }

    if (
      (prevProps.dashboardsListLoading !== dashboardsListLoading ||
        !isEqual(prevState.defaultDimension, defaultDimension)) &&
      defaultDimension &&
      !dashboardsListLoading &&
      !hasInitEmptyDashList &&
      get(dimensionOptions, 'length') &&
      !dashboardCount
    ) {
      this.setState({ hasInitEmptyDashList: true });
      this.handleChangeDimension(this.state.defaultDimension);
    }

    if (
      (!isEqual(prevProps.selectedDimension, selectedDimension) ||
        !isEqual(prevProps.metrics, metrics)) &&
      metrics &&
      metrics.length &&
      selectedDimension
    ) {
      const newMetricOptions = filterMetricsForDimension(
        selectedDimension,
        metrics,
        isPlatformOwnerOrg,
        isWorkspaceOwnerOrg,
      );
      const keepSelectedMetric = newMetricOptions.reduce((keep, optionGroup) => {
        if (
          selectedMetric &&
          Object.values(optionGroup)[0] &&
          Object.values(optionGroup)[0].find((option) => option.value === selectedMetric.value)
        ) {
          return keep || true;
        }
        return keep || false;
      }, false);

      this.setState(
        {
          metricOptions: this.getHeaderOptions(
            filterMetricsForDimension(
              selectedDimension,
              metrics,
              isPlatformOwnerOrg,
              isWorkspaceOwnerOrg,
            ),
          ),
        },
        () => {
          if (!keepSelectedMetric) {
            changeMetric(getMetric(null, metrics));
          }
        },
      );
    }
  }

  isDateRangeMoreThanQuarter = () => {
    const { dateRange } = this.props;
    return dateRange
      ? moment(dateRange.end).diff(moment(dateRange.start).add(1, 'd'), 'month') > 2
      : false;
  };

  formatData = (data): Option[] => {
    const { selectedMetric, selectedDimension } = this.props;

    if (!data || !selectedDimension || !selectedMetric) {
      return [];
    }

    let result = data.map((d, index) => {
      const dimensionKey =
        DIMENSION_KEYS_MAPPER[selectedDimension.value] || selectedDimension.value;
      return {
        // for showing 0 values
        value: +d[selectedMetric.value] + 100,
        label: `${d[dimensionKey]}${index}`,
      };
    });

    return result.reverse();
  };

  checkForCustomers = () => {
    const { userHasNoCustomers, openToast } = this.props;
    if (userHasNoCustomers) {
      openToast(NO_CUSTOMER_ASSIGNED);
    }
  };

  handleChangeDimension = (dimension) => {
    const { changeDimension, selectedDimension } = this.props;
    if (selectedDimension ? selectedDimension.value !== dimension.value : 1) {
      changeDimension(dimension);
      this.checkForCustomers();
    }
  };

  handleChangeMetric = (metric) => {
    const { changeMetric, selectedMetric } = this.props;
    if (!selectedMetric || selectedMetric.value !== metric.value) {
      changeMetric(metric);
      this.checkForCustomers();
    }
  };

  getCustomBarTooltipOption = (data) => {
    const { selectedMetric } = this.props;
    if (!selectedMetric) {
      return {};
    }
    let value: string | number = data.value - 100;

    if (percentFormatMetrics.includes(selectedMetric.value)) {
      value = `${(+value).toFixed(2)}%`;
    } else if (integerFormatMetrics.includes(selectedMetric.value)) {
      value = `${formatNumberWithSuffix(value)}`;
    } else if (currencyFormatMetrics.includes(selectedMetric.value)) {
      value = `${CurrencyFormat.format(value)}`;
    }

    return {
      label: data.indexValue,
      value: `${selectedMetric.label}: ${value}`,
    };
  };

  mouseMoveHandler = (event, label, value?) => {
    this.setState({
      tooltipIsActive: true,
      coordX: event.clientX + 10,
      coordY: event.clientY + 15,
      label: label,
      value: value,
    });
  };

  closeTooltip = () => {
    this.setState({ tooltipIsActive: false });
    this.forceUpdate();
  };

  renderCustomAxisLeftTick = (tick) => (
    <CustomBarChartTick
      key={tick.value}
      tick={tick}
      onMouseLeave={this.closeTooltip}
      onMouseMove={(event) =>
        this.mouseMoveHandler({ clientX: event.clientX, clientY: event.clientY }, tick.value)
      }
      onMouseEnter={(event) =>
        this.mouseMoveHandler({ clientX: event.clientX, clientY: event.clientY }, tick.value)
      }
    />
  );

  renderCustomBarItem = (barItem) => (
    <CustomBarChartBarItem
      key={barItem.data?.indexValue}
      barItem={barItem}
      onMouseLeave={this.closeTooltip}
      onMouseMove={(event) => {
        const customBarTooltipOption = this.getCustomBarTooltipOption(barItem.data);
        this.mouseMoveHandler(
          { clientX: event.clientX, clientY: event.clientY },
          customBarTooltipOption.label,
          customBarTooltipOption.value,
        );
      }}
      onMouseEnter={(event) => {
        const customBarTooltipOption = this.getCustomBarTooltipOption(barItem.data);
        this.mouseMoveHandler(
          { clientX: event.clientX, clientY: event.clientY },
          customBarTooltipOption.label,
          customBarTooltipOption.value,
        );
      }}
    />
  );

  renderTooltip = () => {
    const { label, value, coordX, coordY, tooltipIsActive } = this.state;

    return tooltipIsActive ? (
      <ChartTooltip label={label.slice(0, -1)} value1={value} coordX={coordX} coordY={coordY} />
    ) : null;
  };

  render() {
    const {
      className,
      data,
      selectedMetric,
      selectedDimension,
      loading,
      dimensionsMetricsLoading,
      errorMsg,
    } = this.props;

    const formattedData = this.formatData(data);
    let options = [] as BarChartOption<DimensionOption>[];
    if (dimensionsMetricsLoading === LoadingStatus.SUCCESS) {
      if (this.isDateRangeMoreThanQuarter() && this.state.defaultDimension) {
        options = [this.state.defaultDimension];
      } else {
        options = this.state.dimensionOptions;
      }
    }

    return (
      <div className={`chart-block ${className}`}>
        <header className="chart-header">
          <div className="chart-header__controls">
            <div className="chart-header__select">
              <Select
                options={options}
                value={selectedDimension}
                onChange={this.handleChangeDimension}
                placeholder={
                  dimensionsMetricsLoading === LoadingStatus.ERROR ? 'Loading Failed' : 'Group By'
                }
                isReadOnly={dimensionsMetricsLoading === LoadingStatus.ERROR}
                label="Group By"
                tooltipParams={{
                  label:
                    'Select aspect or feature of the campaign, audience, technology, delivery, or location; to group measured data by them. For example, selecting Zip Code will give you data break-down by each Zip Code where the campaigns were serving',
                  position: 'bottom-left',
                  auto: false,
                  labelMaxWidth: 304,
                }}
              />
            </div>
            <div className="chart-header__select">
              <Select
                options={
                  dimensionsMetricsLoading === LoadingStatus.SUCCESS ? this.state.metricOptions : []
                }
                value={selectedMetric}
                onChange={this.handleChangeMetric}
                placeholder={
                  dimensionsMetricsLoading === LoadingStatus.ERROR ? 'Loading Failed' : 'Metric'
                }
                isReadOnly={dimensionsMetricsLoading === LoadingStatus.ERROR}
                label="Metric"
                tooltipParams={{
                  label:
                    'Metric or Key Performance Indicator (KPI) is the measured value that you can use to understand the effectiveness of your campaigns',
                  position: 'bottom-right',
                  auto: false,
                  labelMaxWidth: 304,
                }}
              />
            </div>
          </div>
        </header>

        {loading ? (
          <div className="chart-block__main _horizontal">
            <SkeletonChart />
          </div>
        ) : (
          <>
            {data && data.length && selectedMetric && selectedDimension ? (
              <>
                <div className="chart-block__main" onMouseLeave={this.closeTooltip}>
                  <BarWrapper
                    data={formattedData}
                    layout="horizontal"
                    keys={['value']}
                    indexBy="label"
                    barComponent={this.renderCustomBarItem}
                    isInteractive={true}
                    margin={{
                      top: 0,
                      right: 75,
                      bottom: 0,
                      left: 85,
                    }}
                    borderRadius={3}
                    padding={0}
                    colorBy="index"
                    colors={['#28bc97']}
                    axisBottom={{
                      tickSize: 0,
                    }}
                    axisLeft={{
                      tickSize: 0,
                      tickPadding: 12,
                      renderTick: this.renderCustomAxisLeftTick,
                    }}
                    theme={{
                      selectedMetric: selectedMetric,
                    }}
                    enableGridX={false}
                    enableGridY={false}
                  />
                </div>
                <p className="chart-block__footnote ml-0 mb-0">Only top 5 are displayed</p>
                {createPortal(this.renderTooltip(), document.body)}
              </>
            ) : (
              <NoDataChart message={errorMsg} />
            )}
          </>
        )}
      </div>
    );
  }
}

const mapState = (state: AppState) => {
  return {
    selectedMetric: state.reports.metric,
    selectedDimension: state.reports.dimension,
    metrics: state.reports.metricOptions,
    dimensions: state.reports.dimensionOptions,
    dateRange: state.filter.dateRange,
    isPlatformOwnerOrg: state.auth.userData.isPlatformOwnerOrg,
    isWorkspaceOwnerOrg: state.auth.userData.isWorkspaceOwnerOrg,
    dimensionsMetricsLoading: state.reports.dimensionsMetricsLoading,
    dashboardsListLoading: state.dashboards.loading,
    dashboardCount: get(state.dashboards.dashboards, 'length') || 0,
    userHasNoCustomers: hasNoCustomers(state),
  };
};

const mapActions = {
  changeMetric: reportsActions.changeReportMetric,
  changeDimension: reportsActions.changeReportDimension,
  openToast: toastActions.open,
};

export default connect(mapState, mapActions)(HorizontalBarChartComponentWrapper);
