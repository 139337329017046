import * as React from 'react';
import { Stat, StatFormat } from '../models/Statistics';
import { CurrencyFormat, formatNumberWithSuffix, NumberFormat } from '../utils/format';
import { ReactComponent as PercentArrowIcon } from './statValue/percent-arrow.svg';
import { StatInfoTooltip } from '../pages/dashboard/dashboardContent/tableWrapper/cellTypes/BudgetPacingCell/HeaderTooltip/HeaderTooltip';

export const StatValue = ({
  title,
  format,
  value = 0,
  prevValue,
  reverse,
  infoIcon = false,
  infoLabel = '',
}: Stat) => {
  let fixedValue, diff, positive, equal;
  if (format === StatFormat.currency) {
    fixedValue = CurrencyFormat.format(+value);
  } else if (format === StatFormat.percent) {
    fixedValue = `${(+value || 0).toFixed(2)}%`;
  } else {
    fixedValue = NumberFormat.format(+value);
  }

  if (prevValue !== undefined) {
    diff = +prevValue === 0 ? 100 : ((+value - +prevValue) / +prevValue) * 100;
    positive = value > prevValue;
    equal = value === prevValue;
  }

  const showValue = typeof value === 'string' || !isNaN(value);
  const showPrevValue =
    !equal && prevValue !== undefined && (typeof prevValue === 'string' || !isNaN(prevValue));

  return (
    <React.Fragment>
      <div className="dashboard-indicators__name d-flex align-items-center">
        {title}
        {infoIcon && <StatInfoTooltip infoLabel={infoLabel} />}
      </div>
      <div className="dashboard-indicators__numbers">
        <div className="dashboard-indicators__value">{showValue ? fixedValue : '\u2014'}</div>
        {showPrevValue && (
          <div
            className={`dashboard-indicators__percent ${positive ? '_up' : '_down'} ${
              reverse ? '_reverse' : ''
            }`}
          >
            <PercentArrowIcon />
            <span>{`${formatNumberWithSuffix(diff || 0, true)}%`}</span>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
