import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import maxBy from 'lodash/maxBy';

import { AppState } from '../../../store';
import { Dashboard } from '../../../models/Dashboards';
import {
  dashboardsActions,
  SelectDashboard,
  CreateDashboard,
  DeleteDashboard,
  ClearSelectedDashboard,
  UpdateDashboard,
} from '../../../store/dashboards/actions';
import { SelectDashboards } from './selectContainer/SelectDashboard';
import { toastActions, Open } from '../../../store/toast/actions';
import { BASE_URL } from '../../../config';

interface OuterProps {
  className?: string;
}

interface Props
  extends SelectDashboard,
    DeleteDashboard,
    Open,
    CreateDashboard,
    ClearSelectedDashboard,
    UpdateDashboard,
    RouteComponentProps,
    OuterProps {
  selectedDashboard: Dashboard | null;
  dashboards: Dashboard[];
}

class SelectContainerComponent extends React.Component<Props> {
  handleCreate = (name) => {
    const { open, createDashboard } = this.props;
    if (name) {
      createDashboard(name)
        .then(() => {
          this.props.history.push(BASE_URL);
          open(`${name} has been created!`);
        })
        .catch(() => {
          open(`${name} hasn't been created!`);
        });
    }
  };

  handleChange = (dashboard) => {
    this.props.selectDashboard(dashboard);
    this.props.history.push(BASE_URL);
  };

  handleDelete = (dashboard) => {
    const { deleteDashboard, open } = this.props;
    deleteDashboard(dashboard)
      .then((res) => {
        open(`${res.label} has been deleted.`);
      })
      .catch((e) => {
        open("Dashboard hasn't been deleted.");
      });
  };

  isQueryParamIncludeStatus = () => {
    const { search } = this.props.location;
    return search && queryString.parse(search).action === 'changeStatus';
  };

  componentDidMount() {
    if (!this.isQueryParamIncludeStatus() && this.props.dashboards) {
      const mostRecentDash = maxBy(
        this.props.dashboards,
        (dash: Dashboard) => dash.data?.lastModified || 0,
      );
      this.props.selectDashboard(mostRecentDash);
    }
  }

  render() {
    const { className, clearSelectedDashboard, updateDashboard, open } = this.props;

    return (
      <React.Fragment>
        <div className={className}>
          <SelectDashboards
            clearDashboard={clearSelectedDashboard}
            selected={this.handleChange}
            deleted={this.handleDelete}
            onCreated={this.handleCreate}
            updateDashboard={updateDashboard}
            open={open}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapState = (state: AppState) => ({
  dashboards: state.dashboards.dashboards,
});

const mapAction = {
  selectDashboard: dashboardsActions.selectDashboard,
  clearSelectedDashboard: dashboardsActions.clearSelectedDashboard,
  deleteDashboard: dashboardsActions.deleteDashboard,
  createDashboard: dashboardsActions.createDashboard,
  updateDashboard: dashboardsActions.updateDashboard,
  open: toastActions.open,
};

export const SelectContainer = withRouter<RouteComponentProps & OuterProps, any>(
  connect(mapState, mapAction)(SelectContainerComponent),
);
