import { StatisticsReportDTO, StatisticsTotalResult } from '../../models/Statistics';
import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { statisticsConstants } from './constants';
import { CancellarError } from '../../models/Response';

export interface StatisticsState {
  report: StatisticsReportDTO;
  total: StatisticsTotalResult | null;
  totalPrev: StatisticsTotalResult | null;
  loading: boolean;
  totalLoading: boolean;
  totalPrevLoading: boolean;
  error: string | Object;
  totalError: string | Object;
  totalPrevError: string | Object;
}

const defaultStatisticsState: StatisticsState = {
  report: null,
  total: null,
  totalPrev: null,
  loading: false,
  totalLoading: false,
  totalPrevLoading: false,
  error: '',
  totalError: '',
  totalPrevError: '',
};

function getTotalStart(state: StatisticsState): StatisticsState {
  return {
    ...state,
    loading: true,
    totalLoading: true,
    error: state.totalPrevError,
    totalError: '',
  };
}

function getTotalPrevStart(state: StatisticsState): StatisticsState {
  return {
    ...state,
    loading: true,
    totalPrevLoading: true,
    error: state.totalError,
    totalPrevError: '',
  };
}

function getTotalSuccess(
  state: StatisticsState,
  action: Action<StatisticsTotalResult | undefined>,
): StatisticsState {
  return {
    ...state,
    loading: state.totalPrevLoading,
    totalLoading: false,
    total: action.payload || state.total,
  };
}

function getTotalPrevSuccess(
  state: StatisticsState,
  action: Action<StatisticsTotalResult>,
): StatisticsState {
  return {
    ...state,
    loading: state.totalLoading,
    totalPrevLoading: false,
    totalPrev: action.payload,
  };
}

function getTotalError(
  state: StatisticsState,
  action: Action<CancellarError<Response>>,
): StatisticsState {
  return {
    ...state,
    loading: state.totalPrevLoading,
    totalLoading: action.payload.response ? !action.payload.response.status : false,
    error: action.payload,
    totalError: action.payload,
    total: null,
  };
}

function getTotalPrevError(
  state: StatisticsState,
  action: Action<CancellarError<Response>>,
): StatisticsState {
  return {
    ...state,
    loading: state.totalLoading,
    totalPrevLoading: action.payload.response ? !action.payload.response.status : false,
    error: action.payload,
    totalPrevError: action.payload,
    totalPrev: null,
  };
}

function clearTotal(state: StatisticsState): StatisticsState {
  return {
    ...state,
    total: null,
    totalPrev: null,
  };
}

function clearLoadingState(state: StatisticsState) {
  return {
    ...state,
    loading: false,
    totalPrevLoading: false,
    totalLoading: false,
    totalPrevError: '',
    totalError: '',
    error: '',
  };
}

const reducer = reducerFromMap(defaultStatisticsState, {
  [statisticsConstants.GET_TOTAL__START]: getTotalStart,
  [statisticsConstants.GET_TOTAL_PREV__START]: getTotalPrevStart,
  [statisticsConstants.GET_TOTAL__SUCCESS]: getTotalSuccess,
  [statisticsConstants.GET_TOTAL_PREV__SUCCESS]: getTotalPrevSuccess,
  [statisticsConstants.GET_TOTAL_PREV__ERROR]: getTotalPrevError,
  [statisticsConstants.GET_TOTAL__ERROR]: getTotalError,
  [statisticsConstants.CLEAR_TOTAL]: clearTotal,
  [statisticsConstants.CLEAR_LOADING_STATE]: clearLoadingState,
});

export const statistics = (state: StatisticsState, action: Action<any>) => reducer(state, action);
