import { Dispatch } from 'redux';
import get from 'lodash/get';
import { lambdaAPI } from '../../api/lambdaAPI/index';
import { AppState } from '../';
import { createAction } from '../../utils/actions';
import { Dashboard, DashboardData, getAdditionalDashboardFields } from '../../models/Dashboards';
import { dashboardConstants } from './constants';
import {
  Option,
  getColumns,
  getInsertionOrderColumnOptions,
  flattenOptions,
  StickedOption,
} from '../../models/Option';
import { tableConstants } from '../table/constants';
import { filterConstants } from '../filter/constants';
import { defaultFilterState, TableLevel, TableLevels } from '../filter/reducers';
import { reportsActions } from '../reports/actions';
import { GraphsNames } from '../../models/GraphsNames';
import { LoadingStatus } from '../../models/LoadingStatus';
import { getDashboardData } from './selectors';
import { defaultAppState } from '../app/reducers';
import { appActions } from '../app/actions';

export const dashboardsActions = {
  selectDashboard(dashboard: Dashboard | null) {
    return (dispatch: Dispatch<any>, getState: () => AppState) => {
      if (dashboard) {
        const state = getState();
        const { dimension, metric, tableLevel } = dashboard.data;
        const {
          isPlatformOwnerOrg,
          isWorkspaceOwnerOrg,
          isBetaUser,
          isVldEnabled = false,
          hadVldGenerated = false,
        } = state.auth.userData;
        const resetForAuthChange =
          (!isPlatformOwnerOrg && tableLevel.value === TableLevel.Workspaces) ||
          (!isPlatformOwnerOrg && tableLevel.value === TableLevel.Exchanges) ||
          (!isPlatformOwnerOrg &&
            !isWorkspaceOwnerOrg &&
            tableLevel.value === TableLevel.Advertisers);
        const resetForTableLevel = !TableLevels.find((tl) => tl.value === tableLevel.value);

        const allowedSortingColumns = getColumns({
          isBetaUser,
          isPlatformOwnerOrg,
          isWorkspaceOwnerOrg,
          isBidShadingEnabled: true,
          isVldEnabled,
          hadVldGenerated,
        }).reduce((prev, current) => {
          if (current.options && current.options.length) {
            current.options.forEach((opt) => prev.push(opt));
          }
          prev.push(current);
          return prev;
        }, [] as Option[]);
        let filteredSortingColumns = dashboard.data.sortingColumns
          .filter(
            (i) => i.alwaysEnabled || allowedSortingColumns.find((col) => col.value === i.value),
          )
          .map((i) => ({ ...i, active: true }));
        const withNewCols = allowedSortingColumns
          .filter(
            (col) =>
              col.alwaysEnabled &&
              !filteredSortingColumns.find((filteredCol) => filteredCol.value === col.value),
          )
          .map((col) => ({ ...col, active: true }));
        const spliceIndex = filteredSortingColumns.findIndex((col) => !col.alwaysEnabled);
        filteredSortingColumns = [
          ...filteredSortingColumns.slice(0, spliceIndex),
          ...withNewCols,
          ...filteredSortingColumns.slice(spliceIndex),
        ];

        const allowedInsertionOrdersColumns = flattenOptions(
          getInsertionOrderColumnOptions({
            isWorkspaceOwnerOrg,
            isPlatformOwnerOrg,
            isVldEnabled,
            hadVldGenerated,
          }) as StickedOption[],
        );

        const isAllAdvertisersSelected =
          isPlatformOwnerOrg || isWorkspaceOwnerOrg
            ? typeof dashboard.data.isAllAdvertisersSelected !== 'boolean' ||
              dashboard.data.isAllAdvertisersSelected
            : true;

        const dashboardData = {
          ...dashboard.data,
          sortingColumns: filteredSortingColumns,
          sortingWorkspacesColumns: dashboard.data.sortingWorkspacesColumns
            ? dashboard.data.sortingWorkspacesColumns.map((i) => ({ ...i, active: true }))
            : defaultFilterState.sortingWorkspacesColumns,
          sortingAdvertisersColumns: dashboard.data.sortingAdvertisersColumns
            ? dashboard.data.sortingAdvertisersColumns.map((i) => ({ ...i, active: true }))
            : defaultFilterState.sortingAdvertisersColumns,
          sortingExchangesColumns: dashboard.data.sortingExchangesColumns
            ? dashboard.data.sortingExchangesColumns.map((i) => ({ ...i, active: true }))
            : defaultFilterState.sortingExchangesColumns,
          sortingInsertionOrdersColumns: dashboard.data.sortingInsertionOrdersColumns
            ? [
                ...allowedInsertionOrdersColumns.filter((col) => col.alwaysEnabled),
                ...dashboard.data.sortingInsertionOrdersColumns.filter((col) => !col.alwaysEnabled),
              ]
                .map((i) => ({
                  ...i,
                  active: true,
                  order: get(
                    allowedInsertionOrdersColumns.find((col) => col.value === i.value),
                    'order',
                    i.order,
                  ),
                }))
                .filter(
                  (col) =>
                    !!allowedInsertionOrdersColumns.find(
                      (allowedCol) => allowedCol.value === col.value,
                    ),
                )
            : allowedInsertionOrdersColumns,
          tableLevel:
            resetForAuthChange || resetForTableLevel ? defaultFilterState.tableLevel : tableLevel,
          [GraphsNames.graph1]:
            resetForAuthChange || resetForTableLevel
              ? defaultFilterState[GraphsNames.graph1]
              : dashboard.data[GraphsNames.graph1],
          [GraphsNames.graph2]:
            resetForAuthChange || resetForTableLevel
              ? defaultFilterState[GraphsNames.graph2]
              : dashboard.data[GraphsNames.graph2],
          selectedCampaigns: dashboard.data.selectedCampaigns
            ? dashboard.data.selectedCampaigns
            : ([] as number[]),
          selectedInsertionOrders: dashboard.data.selectedInsertionOrders
            ? dashboard.data.selectedInsertionOrders
            : ([] as number[]),
          ioBudgetTypes: dashboard.data.ioBudgetTypes || ([] as number[]),
          campaignTypeIds: dashboard.data.campaignTypeIds || ([] as number[]),
          advertisersOwIds:
            !isAllAdvertisersSelected && (isPlatformOwnerOrg || isWorkspaceOwnerOrg)
              ? dashboard.data.advertisersOwIds || ''
              : '',
          workspacesOwIds:
            !isAllAdvertisersSelected && (isPlatformOwnerOrg || isWorkspaceOwnerOrg)
              ? dashboard.data.workspacesOwIds || ''
              : '',
          isAllAdvertisersSelected,
          ...(resetForTableLevel
            ? {
                selectedCreativeTypes: defaultFilterState.selectedCreativeTypes,
                search: defaultFilterState.search,
                status: defaultFilterState.status,
              }
            : {}),
          selectedCreativeTypes:
            dashboard.data.selectedCreativeTypes || dashboard.data.selectedCampaignTypes,
        };

        if (isPlatformOwnerOrg || isWorkspaceOwnerOrg) {
          dispatch(createAction<boolean>(filterConstants.SET_IS_CUSTOMERS_DROPDOWN_MOUNTED, false));
          dispatch(createAction<boolean>(filterConstants.SET_IS_CUSTOMERS_DROPDOWN_EMPTY, false));
          dispatch(createAction<boolean>(filterConstants.SET_IS_CUSTOMERS_DROPDOWN_LOADED, false));
          dispatch(createAction<boolean>(filterConstants.SET_IS_CUSTOMERS_DROPDOWN_ERROR, false));
        }

        const sidebarOpened =
          typeof dashboard.data.sidebarOpened === 'boolean'
            ? dashboard.data.sidebarOpened
            : defaultAppState.sidebarOpened;

        dispatch(appActions.toggleSidebar(sidebarOpened));

        dispatch(
          createAction<number[]>(
            tableConstants.TABLE_SET_FILTERED_CAMPAIGNS_IDS,
            dashboard.data.filteredCampaignsIds,
          ),
        );
        dispatch(createAction<Dashboard>(dashboardConstants.SELECT_DASHBOARD, dashboard));
        dispatch(createAction<DashboardData>(filterConstants.UPDATE_FILTERS, dashboardData));

        const needReportReset =
          resetForAuthChange &&
          dimension &&
          (dimension.value === TableLevel.Advertisers || dimension.value === TableLevel.Workspaces);
        if (!needReportReset) {
          dispatch(reportsActions.changeReportDimension(dimension));
          dispatch(reportsActions.changeReportMetric(metric));
        } else {
          dispatch(reportsActions.changeReportDimension(null));
          dispatch(reportsActions.changeReportMetric(null));
        }
      } else {
        dispatch(dashboardsActions.clearSelectedDashboard());
      }
    };
  },

  createDashboard(name: string) {
    return async (dispatch: Dispatch<any>, getState: () => AppState) => {
      const state = getState();
      const { reports } = state;
      const value = Date.now();

      if (
        !reports.dimension ||
        !reports.metric ||
        reports.dimensionsMetricsLoading !== LoadingStatus.SUCCESS
      ) {
        throw new Error('Failed to load report dimensions and metrics');
      }

      const newDashboard: Dashboard = {
        value,
        label: name,
        id: '',
        data: {
          ...getDashboardData(state),
          ...getAdditionalDashboardFields(),
        },
      };

      try {
        const response = await lambdaAPI.dashboards.create(newDashboard);
        dispatch(createAction<Dashboard>(dashboardConstants.CREATE_DASHBOARD, response));
        dispatch(dashboardsActions.selectDashboard(response));
        return Promise.resolve(response);
      } catch (e) {
        console.log('[dashboard had not created]', e);
        return Promise.reject(e);
      }
    };
  },

  getDashboards: () => {
    return async (dispatch: Dispatch<any>, getState: any) => {
      try {
        createAction<void>(dashboardConstants.DASHBOARD_LOADING_START);
        const response = await lambdaAPI.dashboards.get();
        dispatch(createAction<Dashboard[]>(dashboardConstants.GET_DASHBOARDS, response));
        dispatch(createAction<void>(dashboardConstants.DASHBOARD_LOADING_END));
        return response;
      } catch (e) {
        console.log('[dashboards had not fetched]', e);
        dispatch(createAction<void>(dashboardConstants.DASHBOARD_LOADING_END));
        return Promise.reject();
      }
    };
  },

  updateDashboard: () => {
    return async (dispatch: Dispatch<any>, getState: () => AppState) => {
      const state = getState();
      const { reports } = state;
      const { selectedDashboard } = state.dashboards;
      if (
        !reports.dimension ||
        !reports.metric ||
        reports.dimensionsMetricsLoading !== LoadingStatus.SUCCESS
      ) {
        throw new Error('Failed to load report dimensions and metrics');
      }

      const newDashboardData = getDashboardData(state);

      if (selectedDashboard) {
        const updatedDashboard: Dashboard = {
          value: selectedDashboard.value,
          label: selectedDashboard.label,
          id: selectedDashboard.id,
          data: {
            ...newDashboardData,
            ...getAdditionalDashboardFields(),
          } as any,
        };

        try {
          await lambdaAPI.dashboards.update(selectedDashboard.id, updatedDashboard);
          dispatch(createAction<Dashboard>(dashboardConstants.UPDATE_DASHBOARD, updatedDashboard));
          return Promise.resolve(updatedDashboard);
        } catch (e) {
          console.log('[dashboard had not updated]', e);
          return Promise.reject(e);
        }
      }
    };
  },

  deleteDashboard: (dashboard: Dashboard) => {
    return async (dispatch: Dispatch<any>) => {
      try {
        await lambdaAPI.dashboards.remove(dashboard.id);
        dispatch(createAction<Dashboard>(dashboardConstants.DELETE_DASHBOARD, dashboard));
        return Promise.resolve(dashboard);
      } catch (e) {
        console.log('[dashboard had not deleted]', e);
        return Promise.reject(e);
      }
    };
  },

  indicateDashboardHasReset: () => {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<void>(dashboardConstants.INDICATE_DASHBOARD_HAS_RESET));
    };
  },

  clearSelectedDashboard: () => {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<string>(dashboardConstants.CLEAR_SELECTED_DASHBOARD));
    };
  },
};

export interface CreateDashboard {
  createDashboard: (name: string) => Promise<any>;
}

export interface GetDashboards {
  getDashboards: () => void;
}

export interface UpdateDashboard {
  updateDashboard: () => Promise<any>;
}

export interface DeleteDashboard {
  deleteDashboard: (dashboard: Dashboard) => Promise<any>;
}

export interface SelectDashboard {
  selectDashboard: (dashboard: Dashboard) => void;
}

export interface ClearSelectedDashboard {
  clearSelectedDashboard: () => void;
}

export interface IndicateDashboardHasReset {
  indicateDashboardHasReset: () => void;
}
