import React from 'react';
import { Icon, WithMiddleEllipses, Tooltip } from 'factor';
import { IOBudgetTypeMapper, IOStatusMapper } from 'iqm-framework';
import styles from './styles.module.scss';

export const InsertionOrderOptionComponent = (props: {
  id: number;
  name: string;
  ioStatusId: number;
  ioBudgetTypeId: number;
  disablePadding?: boolean;
}) => {
  const { id, name, ioStatusId, ioBudgetTypeId, disablePadding } = props;

  const { icon: statusIcon, label: statusLabel } = IOStatusMapper[ioStatusId as any] || {};
  const { icon: budgetIcon } = IOBudgetTypeMapper[ioBudgetTypeId] || {};

  return (
    <div className={`${styles.optionWrapper} ${disablePadding ? styles.noPadding : ''}`}>
      <div className={styles.row}>
        {!!budgetIcon && <Icon name={budgetIcon} className={styles.budgetIcon} />}
        <div className={styles.col}>
          <div className={`${styles.ioName} ${styles.row}`}>
            <Tooltip label={name} portal auto adjustPortalPosition className={styles.row}>
              <WithMiddleEllipses text={name} />
            </Tooltip>
          </div>
          <div className={`${styles.row} ${styles.infoRow}`}>
            <span className={styles.infoLabel}>ID</span>
            <span className={styles.infoValue}>{id}</span>
            <div className={styles.divider} />
            {!!statusIcon && (
              <Icon
                name={statusIcon}
                className={`${styles.statusIcon} ${
                  statusIcon === 'StatusReadyColor' ? styles.icon14 : ''
                }`}
              />
            )}
            <span className={styles.infoValue}>{statusLabel || ''}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
