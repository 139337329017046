export interface AudienceItem {
  audienceId: number;
  audienceStatus: string;
}

export type CampaignTargetedAudiences = {
  [key: string]: {
    included?: AudienceItem[];
    excluded?: AudienceItem[];
  };
};

export enum AudienceWarning {
  NONE = 'NONE',
  SOME_UNAPPROVED = 'SOME_UNAPPROVED',
  ALL_UNAPPROVED = 'ALL_UNAPPROVED',
}

export const getAudienceWarning = (audiences: AudienceItem[]) => {
  if (!audiences || !audiences.length) {
    return AudienceWarning.NONE;
  }

  if (
    audiences.some((aud) => aud.audienceStatus !== 'approved') &&
    audiences.some((aud) => aud.audienceStatus === 'approved')
  ) {
    return AudienceWarning.SOME_UNAPPROVED;
  } else if (audiences.every((aud) => aud.audienceStatus !== 'approved')) {
    return AudienceWarning.ALL_UNAPPROVED;
  } else {
    return AudienceWarning.NONE;
  }
};
