import React from 'react';
import { withDataDogCallbackLogging } from 'iqm-framework';
import { connect } from 'react-redux';

import { AppState } from '../../../../../store';
import { filterActions } from '../../../../../store/filter/actions';
import { SearchField } from '../../../../../components/SearchField';

interface OwnProps {
  placeholder?: string;
  focusOnMount?: boolean;
}

const mapState = (state: AppState) => {
  return {
    search: state.filter.search,
  };
};

const mapActions = {
  updateSearch: filterActions.updateSearch,
};

type StateProps = ReturnType<typeof mapState>;
type DispatchProps = typeof mapActions;

const dataDogLoggerHOC = withDataDogCallbackLogging('Search', 'updateSearch', 1000);
const connector = connect<StateProps, DispatchProps, AppState, any>(mapState, mapActions);

export const SearchWrapper = (connector<any>(
  dataDogLoggerHOC(SearchField),
) as unknown) as React.FunctionComponent<OwnProps>;
