import React from 'react';
import { Icon, Tooltip } from 'factor';
import { IOBudgetTypeMapper } from 'iqm-framework';
import styles from './styles.module.scss';

interface Props {
  ioName: string;
  onClick?: () => void;
  ioBudgetTypeId: number;
  tooltipText?: string;
}

export const InsertionOrderNameCell = (props: Props) => {
  const { onClick, ioName, ioBudgetTypeId, tooltipText } = props;
  const budgetIcon = IOBudgetTypeMapper[ioBudgetTypeId]
    ? IOBudgetTypeMapper[ioBudgetTypeId].icon
    : null;

  const textSpan = (
    <span
      role={onClick ? 'button' : undefined}
      onClick={onClick}
      className={`${styles.title} ${onClick ? styles.clickable : ''}`}
    >
      {ioName}
    </span>
  );

  return (
    <div className={styles.wrapper}>
      {!!budgetIcon && <Icon name={budgetIcon} className={styles.icon} />}
      {!!tooltipText ? (
        <Tooltip label={tooltipText} auto portal useTooltipWrapperDimensions>
          {textSpan}
        </Tooltip>
      ) : (
        textSpan
      )}
    </div>
  );
};
