import React from 'react';
import { createPortal } from 'react-dom';
import {
  withClickOutside,
  Icon,
  TextFieldWithIcon,
  TextField,
  Button,
  Dialog,
  DialogContent,
  Tooltip,
} from 'factor';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import omit from 'lodash/omit';

import {
  Dashboard,
  DashboardData,
  getAdditionalDashboardFields,
} from '../../../../models/Dashboards';
import { DeleteDashboardDialogContent } from './selectDashboard/components/DeleteDashboardDialogContent';
import { FilterState } from '../../../../store/filter/reducers';
import { LoadingStatus } from '../../../../models/LoadingStatus';
import { UpdateDashboard } from '../../../../store/dashboards/actions';
import { Open } from '../../../../store/toast/actions';
import { AppState } from '../../../../store';
import { getDashboardData } from '../../../../store/dashboards/selectors';
import { toValues } from '../../../../models/Option';
import './selectDashboard/style.scss';

const TOOLTIP_MESSAGE =
  'Save frequently used combinations of parameters, filters and widgets as Dashboards and quickly switch between them.';

interface Props extends UpdateDashboard, Open {
  selectedDashboard: Dashboard | null;
  clearDashboard: () => void;
  dashboards: Dashboard[];
  selected: (dashboard: Dashboard | null) => void;
  deleted: (dashboard: Dashboard) => void;
  onCreated: (name: string) => void;
  filterState: FilterState;
  dimensionsMetricsLoading: LoadingStatus;
  dashboardFromFilter: DashboardData;
}

interface State {
  isOpen: boolean;
  creatingStarted: boolean;
  btnSelectSearch: string;
  newDashboardName: string;
  dialogIsOpen: boolean;
  deletingDashboard: Dashboard | null;
  isScrollable: boolean;
  dashboardSaveUpdatesModal: {
    isOpen: boolean;
    dashboard: Dashboard | null;
  };
}

class SelectDashboardsComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      creatingStarted: false,
      btnSelectSearch: '',
      newDashboardName: '',
      dialogIsOpen: false,
      deletingDashboard: null,
      isScrollable: false,
      dashboardSaveUpdatesModal: {
        isOpen: false,
        dashboard: null,
      },
    };
  }

  _inputRef: null | HTMLInputElement = null;
  _selectListRef: null | HTMLElement = null;

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.state.isOpen && this.state.isOpen !== prevState.isOpen) ||
      prevProps.dashboards.length !== this.props.dashboards.length
    ) {
      if (
        this._selectListRef &&
        this._selectListRef.clientHeight < this._selectListRef.scrollHeight
      ) {
        this.setState({ isScrollable: true });
      } else {
        this.setState({ isScrollable: false });
      }
    }
  }

  toggleSelect = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleClickOutside = () => {
    if (this.state.isOpen) {
      this.toggleSelect();
    }
  };

  handleSelect = (dashboard: Dashboard) => {
    if (this.isDashboardUpdated) {
      this.setState({
        dashboardSaveUpdatesModal: {
          isOpen: true,
          dashboard: dashboard,
        },
      });
    } else {
      this.props.selected(dashboard);
      this.setState({ isOpen: false });
    }
  };

  openDialog = (event, dashboard: Dashboard) => {
    event.stopPropagation();
    this.setState({
      isOpen: false,
      dialogIsOpen: true,
      deletingDashboard: dashboard,
    });
  };

  handleCreate = () => {
    const { creatingStarted, btnSelectSearch, newDashboardName } = this.state;
    if (!creatingStarted) {
      this.setState(
        {
          creatingStarted: true,
          newDashboardName: btnSelectSearch,
        },
        () => this._inputRef && this._inputRef.select(),
      );
      return;
    }

    this.props.onCreated(newDashboardName.trim());
    this.setState({
      creatingStarted: false,
      btnSelectSearch: '',
      isOpen: false,
    });
  };

  handleDelete = () => {
    const { deleted } = this.props;
    const { deletingDashboard } = this.state;
    deletingDashboard && deleted(deletingDashboard);
    this.closeDialog();
  };

  handleChangeSearch = ({ target }) => {
    const { value } = target;

    this.setState({
      btnSelectSearch: value,
    });
  };

  renderList = () => {
    let { dashboards, selectedDashboard } = this.props;
    const { btnSelectSearch, isScrollable } = this.state;

    dashboards = btnSelectSearch
      ? dashboards.filter((d) => d.label.toLowerCase().includes(btnSelectSearch.toLowerCase()))
      : dashboards;

    return (
      <div className={`select__list-container ${isScrollable ? '_scrollable' : ''}`}>
        <div
          className="select__list"
          ref={(el) => {
            this._selectListRef = el;
          }}
        >
          {dashboards.map((dashboard) => (
            <div
              className={`select__option ${
                selectedDashboard && selectedDashboard.id === dashboard.id ? '_active' : ''
              }`}
              key={dashboard.id}
              onClick={() => this.handleSelect(dashboard)}
            >
              <div className="select__option-label">{dashboard.label}</div>
              <div
                className="select__option-delete-icon"
                onClick={(e) => this.openDialog(e, dashboard)}
              >
                <Icon name="Delete" />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  onCancel = () => {
    this.setState({
      creatingStarted: false,
      newDashboardName: '',
    });
  };

  closeDialog = () => {
    this.setState({
      dialogIsOpen: false,
    });
  };

  clearSelected = (event) => {
    event.stopPropagation();
    this.props.clearDashboard();
  };

  get isDashboardUpdated() {
    const { selectedDashboard, filterState, dashboardFromFilter } = this.props;
    let dashboardWasUpdated = false;

    if (selectedDashboard && filterState.dateRange) {
      const formatedSelectedDashboardData = omit(
        {
          campaignTypeIds: [],
          ...selectedDashboard.data,
        },
        Object.keys(getAdditionalDashboardFields()),
      );

      const fromFilters = toValues(omitBy(dashboardFromFilter, isNil));
      const fromDashboard = toValues(omitBy(formatedSelectedDashboardData, isNil));

      fromDashboard.selectedCreativeTypes =
        fromDashboard.selectedCampaignTypes || fromDashboard.selectedCreativeTypes;

      delete fromDashboard.selectedCampaignTypes;
      delete fromFilters.isSelectingCampaignsByGroup;
      delete fromDashboard.isSelectingCampaignsByGroup;
      delete fromFilters.filteredGroupsIds;
      delete fromDashboard.filteredGroupsIds;

      dashboardWasUpdated = !isEqual(fromFilters, fromDashboard);

      return dashboardWasUpdated;
    }

    return false;
  }

  saveDashboard = (callback) => {
    const { open, updateDashboard, selectedDashboard } = this.props;
    if (selectedDashboard) {
      updateDashboard()
        .then((response) => {
          open(`${response.label} has been saved!`);
          if (callback) {
            callback();
          }
        })
        .catch(() => open(`${selectedDashboard.label} hasn't been saved!`));
    }
  };

  handleSaveDashboardButtonsClick = (isSaveChanges?) => () => {
    const { dashboardSaveUpdatesModal } = this.state;

    if (isSaveChanges === true) {
      this.saveDashboard(() => this.props.selected(dashboardSaveUpdatesModal.dashboard));
    }
    if (isSaveChanges === false) {
      this.props.selected(dashboardSaveUpdatesModal.dashboard);
    }

    this.setState({
      isOpen: false,
      dashboardSaveUpdatesModal: {
        isOpen: false,
        dashboard: null,
      },
    });
  };

  render() {
    const { selectedDashboard, dimensionsMetricsLoading } = this.props;
    const {
      isOpen,
      btnSelectSearch,
      creatingStarted,
      newDashboardName,
      dialogIsOpen,
      deletingDashboard,
      dashboardSaveUpdatesModal,
    } = this.state;
    const dialog = deletingDashboard && (
      <Dialog open={dialogIsOpen} onClickOutside={this.closeDialog}>
        <DeleteDashboardDialogContent
          dashboard={deletingDashboard}
          onClose={this.closeDialog}
          onDelete={this.handleDelete}
        />
      </Dialog>
    );
    let selectLabelTitle = selectedDashboard ? 'Dashboard' : 'Select Dashboard';
    if (isOpen && creatingStarted && !selectedDashboard) {
      selectLabelTitle = 'Create Dashboard';
    }

    const saveChangesDialog = (
      <Dialog
        open={dashboardSaveUpdatesModal.isOpen}
        handleEnterKeyEvent={this.handleSaveDashboardButtonsClick(true)}
      >
        <DialogContent>
          <h3 className="title-card mb-4">Do you want to save changes?</h3>
          <div className="d-flex justify-content-end">
            <Button
              className="btn-square _conflower-blue mr-3 _md"
              onClick={this.handleSaveDashboardButtonsClick()}
            >
              Cancel
            </Button>
            <Button
              className="btn-square _persimmon mr-3 _md"
              onClick={this.handleSaveDashboardButtonsClick(false)}
            >
              No
            </Button>
            <Button
              className="btn-square _conflower-blue _filled _md"
              onClick={this.handleSaveDashboardButtonsClick(true)}
            >
              Yes
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );

    return (
      <>
        <div className={`select js-select ${isOpen && '_opened'}`}>
          <div className="select__outer-wrapper">
            <div className="select__wrapper">
              <Tooltip
                className="select__btn"
                handleClick={this.toggleSelect}
                label={TOOLTIP_MESSAGE}
                auto={false}
                position="right"
              >
                <div className={`select__label ${selectedDashboard ? '_up' : ''}`}>
                  {selectLabelTitle}
                </div>
                <div className={`select__value ${selectedDashboard ? '_selected' : ''}`}>
                  {selectedDashboard ? selectedDashboard.label : ''}
                </div>
                {selectedDashboard && (
                  <div className="select__clear" onClick={this.clearSelected}>
                    <Icon name="Close" />
                  </div>
                )}
                <div className="select__indicator"></div>
              </Tooltip>
              <div className="select__list-wrapper">
                {!creatingStarted ? (
                  <div className="select__search btn-select__search">
                    <TextFieldWithIcon
                      iconName="Search"
                      onChange={this.handleChangeSearch}
                      value={btnSelectSearch}
                      placeholder="Search"
                      spellcheck={false}
                      autocomplete="off"
                      autocorrect="off"
                      autocapitalize="off"
                    />
                  </div>
                ) : (
                  <div className="select__input mt-3">
                    <TextField
                      name="newDashboardName"
                      label="Dashboard Name"
                      onChange={(v) => {
                        this.setState({
                          newDashboardName: v,
                        });
                      }}
                      value={newDashboardName}
                      inputRef={(e) => (this._inputRef = e)}
                      autofocus={true}
                    />
                  </div>
                )}
                <div className="select__buttons d-flex mt-3 mb-3">
                  {creatingStarted && (
                    <Button
                      className="create-group-container__button btn-square _conflower-blue _md mr-2 ml-0"
                      onClick={this.onCancel}
                    >
                      Cancel
                    </Button>
                  )}
                  <Button
                    className={`create-group-container__button btn-square ${
                      creatingStarted ? ' _filled mr-0' : ' '
                    } _conflower-blue _md`}
                    disabled={
                      (creatingStarted && !newDashboardName.trim().length) ||
                      dimensionsMetricsLoading !== LoadingStatus.SUCCESS
                    }
                    onClick={this.handleCreate}
                  >
                    Create New Dashboard
                  </Button>
                </div>
                {this.renderList()}
              </div>
            </div>
          </div>
          {createPortal(dialog, document.body)}
          {createPortal(saveChangesDialog, document.body)}
        </div>
      </>
    );
  }
}

const mapState = (state: AppState) => {
  const dashboardFromFilter = getDashboardData(state);

  return {
    dashboards: state.dashboards.dashboards,
    selectedDashboard: state.dashboards.selectedDashboard,
    filterState: state.filter,
    dimensionsMetricsLoading: state.reports.dimensionsMetricsLoading,
    dashboardFromFilter,
  };
};

export const SelectDashboards = connect<any, any, any, any>(mapState)(
  withClickOutside(SelectDashboardsComponent),
);
