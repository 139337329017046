import { Dispatch } from 'redux';
import moment from 'moment';
import get from 'lodash/get';

import { TableNameUpdateParams } from '../../models/Table';
import { createAction } from '../../utils/actions';
import { pluralize } from '../../utils/pluralize';
import { tableConstants } from './constants';
import { API } from '../../api';
import { lambdaV3API } from '../../api/lambdaV3API';
import {
  UpdateEndTimeParams as UpdateIOEndTimeParams,
  UpdateBudgetParams as UpdateIOBudgetParams,
} from '../../api/InsertionOrder';
import { Campaign, CampaignBudgetType } from '../../models/Campaign';
import { TableSortingParams } from '../../models/Table';
import { UpdateBudgetResponse, LambdaResponse } from '../../models/Response';
import { AudienceWarning, getAudienceWarning } from '../../models/Audience';
import { AppState } from '../index';
import { toastConstants } from '../toast/constants';
import { DataDogLogger } from '../../services/DataDog';
import { toDataDogCampaignAction } from '../../services/DataDog/Campaigns';
import {
  logUpdatedCampaigns,
  getBudgetFailureTransformation,
  getEndDateFailureTransformation,
} from '../../services/DataDog/Campaigns/helpers';
import {
  GENERAL_API_ERROR,
  BUDGET_INPUT_TOO_HIGH,
  BUDGET_TOTAL_TOO_HIGH,
} from '../../constants/tooltips';
import { MAX_VALID_BUDGET } from '../../constants/validation';

export const tableActions = {
  updateCampaignName: function(
    name: string,
    id: string | number,
    editableCampaign: LambdaResponse,
  ) {
    return async (dispatch: Dispatch) => {
      try {
        if (name.trim().length > 1024) {
          DataDogLogger.Campaigns.editNameFailed(
            toDataDogCampaignAction({ ...editableCampaign, campaignName: name }),
          );
          return dispatch(
            createAction<String>(
              toastConstants.TOAST_OPEN,
              'Campaign name must be less than 1024 characters.',
            ),
          );
        }
        const response = await API.campaigns.updateCampaign(id, {
          campaignName: name,
        });

        if (response.success) {
          const latency =
            response.responseTimestamp && response.requestTimestamp
              ? response.responseTimestamp - response.requestTimestamp
              : null;
          DataDogLogger.Campaigns.editNameSucceeded(
            toDataDogCampaignAction({ ...editableCampaign, latency, campaignName: name }),
          );
          const modifiedCampaign: TableNameUpdateParams = {
            id,
            name,
          };

          dispatch(
            createAction<TableNameUpdateParams>(
              tableConstants.TABLE_UPDATE_CAMPAIGN_NAME,
              modifiedCampaign,
            ),
          );

          if (response.data) {
            dispatch(createAction<String>(toastConstants.TOAST_OPEN, response.data.message));
          }
        } else if (response.errorObjects && response.errorObjects.length) {
          dispatch(createAction<String>(toastConstants.TOAST_OPEN, response.errorObjects[0].error));
          throw response;
        }
      } catch (e) {
        const latency =
          (e.responseTimestamp || e.data.responseTimestamp) &&
          (e.requestTimestamp || e.data.requestTimestamp)
            ? (e.responseTimestamp || e.data.responseTimestamp) -
              (e.requestTimestamp || e.data.requestTimestamp)
            : null;
        DataDogLogger.Campaigns.editNameFailed(
          toDataDogCampaignAction({ ...editableCampaign, latency, campaignName: name }),
        );
        dispatch(
          createAction<String>(
            toastConstants.TOAST_OPEN,
            e.response &&
              e.response.data &&
              e.response.data.errorObjects &&
              e.response.data.errorObjects.length
              ? e.response.data.errorObjects[0].error
              : 'Error while updating campaign name',
          ),
        );
      }
    };
  },
  addSelectedCampaign: function(data) {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<Campaign>(tableConstants.TABLE_ADD_SELECTED_CAMPAIGN, data));
    };
  },
  removeSelectedCampaign: function(data) {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<Campaign>(tableConstants.TABLE_REMOVE_SELECTED_CAMPAIGN, data));
    };
  },
  clearSelectedCampaigns: function() {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<void>(tableConstants.TABLE_CLEAR_SELECTED_CAMPAIGN));
    };
  },
  updateSorting(data) {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<TableSortingParams>(tableConstants.TABLE_UPDATE_SORTING_PARAMS, data));
    };
  },
  addSelectedAllCampaign: function() {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<void>(tableConstants.TABLE_SELECT_ALL_CAMPAIGNS));
    };
  },
  clearCampaignsData: function() {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<void>(tableConstants.TABLE_CLEAR_CAMPAIGNS_DATA));
    };
  },
  duplicateWithIO: (startTime: number, endTime: number | null, ioId: number, timezone: number) => {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      const state = getState();
      const { selectedTableCampaigns } = state.table;
      try {
        const query = {
          existingCampaignIds: selectedTableCampaigns.map((i) => i.campaignId).join(','),
          startDate: startTime,
          endDate: endTime || 0,
          ioId: ioId,
          timeZoneId: timezone,
        };
        const response = await API.campaigns.duplicateCampaigns(query);
        const errors = get(response, 'responseObject.errorMsg', []);
        const latency = response.responseTimestamp - response.requestTimestamp;
        selectedTableCampaigns.forEach((campaign) => {
          if (errors.find((reason) => +reason.id === +campaign.campaignId)) {
            DataDogLogger.Campaigns.duplicateFailed(
              toDataDogCampaignAction({
                ...campaign,
                latency,
                startTime: startTime,
                endTime: endTime,
              }),
            );
          } else {
            DataDogLogger.Campaigns.duplicateSucceeded(
              toDataDogCampaignAction({
                ...campaign,
                latency,
                startTime: startTime,
                endTime: endTime,
              }),
            );
          }
        });

        if (errors.length) {
          const ids = errors.map((e) => e.id);
          const errorMessage = `Campaign(s) with id ${ids.join(', ')} ${
            ids.length > 1 ? 'are' : 'is'
          } not duplicated ${errors[0].errorMessage ? 'as ' + errors[0].errorMessage : ''}`;
          return Promise.reject(errorMessage);
        }
        dispatch(createAction<void>(tableConstants.TABLE_CLEAR_SELECTED_CAMPAIGN));
        return Promise.resolve();
      } catch (e) {
        const latency =
          e.responseTimestamp && e.requestTimestamp
            ? e.responseTimestamp - e.requestTimestamp
            : null;
        if (selectedTableCampaigns) {
          selectedTableCampaigns.forEach((campaign) => {
            DataDogLogger.Campaigns.duplicateFailed(
              toDataDogCampaignAction({
                ...campaign,
                latency,
                startTime: startTime,
                endTime: endTime,
              }),
            );
          });
        }

        console.log("[can't duplicate campaigns from server]: ", e);
        const msg = get(e, 'response.data.responseObject.errorMsg');
        return Promise.reject(msg || 'Campaign duplication failed');
      }
    };
  },
  duplicate: function(start: number, end: number) {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      const state = getState();
      const { selectedTableCampaigns } = state.table;
      const { auth } = state;
      try {
        const query = {
          existingCampaignIds: selectedTableCampaigns.map((i) => i.campaignId).join(','),
          dspId: auth.userData.userId,
          advertiserId: auth.userData.userId,
          startDate: start,
          endDate: end,
        };
        const response = await API.campaigns.duplicateCampaigns(query);
        const errors = get(response, 'responseObject.reason', []);

        const latency = response.responseTimestamp - response.requestTimestamp;
        selectedTableCampaigns.forEach((campaign) => {
          if (errors.find((reason) => +reason.id === +campaign.campaignId)) {
            DataDogLogger.Campaigns.duplicateFailed(
              toDataDogCampaignAction({
                ...campaign,
                latency,
                startTime: start,
                endTime: end,
              }),
            );
          } else {
            DataDogLogger.Campaigns.duplicateSucceeded(
              toDataDogCampaignAction({
                ...campaign,
                latency,
                startTime: start,
                endTime: end,
              }),
            );
          }
        });

        if (errors.length) {
          const ids = errors.map((e) => e.id);
          const errorMessage = `Campaign(s) with id ${ids.join(', ')} ${
            ids.length > 1 ? 'are' : 'is'
          } not duplicated ${errors[0].errorMessage ? 'as ' + errors[0].errorMessage : ''}`;
          return Promise.reject(errorMessage);
        }
        dispatch(createAction<void>(tableConstants.TABLE_CLEAR_SELECTED_CAMPAIGN));
        return Promise.resolve();
      } catch (e) {
        const latency =
          e.responseTimestamp && e.requestTimestamp
            ? e.responseTimestamp - e.requestTimestamp
            : null;
        if (selectedTableCampaigns) {
          selectedTableCampaigns.forEach((campaign) => {
            DataDogLogger.Campaigns.duplicateFailed(
              toDataDogCampaignAction({
                ...campaign,
                latency,
                startTime: start,
                endTime: end,
              }),
            );
          });
        }

        console.log("[can't duplicate campaigns from server]: ", e);
        const msg = get(e, 'response.data.responseObject.errorMsg');
        return Promise.reject(msg || 'Campaign duplication failed');
      }
    };
  },
  pauseCampaigns: function() {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      const state = getState();
      const { selectedTableCampaigns } = state.table;
      const { auth } = state;
      try {
        const query = {
          campaignIds: selectedTableCampaigns.map((i) => i.campaignId).join(','),
          dspId: auth.userData.userId,
          advertiserId: auth.userData.userId,
        };
        const response = await API.campaigns.pauseCampaign(query);
        const errors = get(response, 'data.responseObject.failedData', []);
        const responseTimestamp = get(response, 'data.responseTimestamp');
        const requestTimestamp = get(response, 'data.requestTimestamp');
        const latency =
          responseTimestamp && requestTimestamp ? responseTimestamp - requestTimestamp : null;

        selectedTableCampaigns.forEach((campaign) => {
          DataDogLogger.Campaigns.pauseSucceeded(
            toDataDogCampaignAction({
              ...campaign,
              latency,
            }),
          );
        });

        const modifiedData = get(response, 'data.responseObject.modifiedData', []);
        if (errors.length) {
          const errorMessages = errors.map((err) => err.message);
          return Promise.reject({
            errorMessages,
            isModified: modifiedData.length,
          });
        }
        return Promise.resolve();
      } catch (e) {
        const latency =
          e.responseTimestamp && e.requestTimestamp
            ? e.responseTimestamp - e.requestTimestamp
            : null;
        if (selectedTableCampaigns) {
          selectedTableCampaigns.forEach((campaign) => {
            DataDogLogger.Campaigns.pauseFailed(
              toDataDogCampaignAction({
                ...campaign,
                latency,
              }),
            );
          });
        }

        console.log("[can't update campaign status from server]: ", e);
        return Promise.reject(e);
      }
    };
  },
  deleteCampaigns: () => {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      const state = getState();
      const { selectedTableCampaigns } = state.table;
      const { auth } = state;

      try {
        const params = {
          advertiserId: auth.userData.userId,
          dspId: auth.userData.userId,
          status: 'deleted',
          campaignIds: selectedTableCampaigns.map((i) => i.campaignId).join(','),
        };
        const response = await API.campaigns.deleteCampaign(params);
        const updatedCampaignIds = get(response, 'data.responseObject.updatedData', []).map(
          (campaign) => +campaign.campaignId,
        );

        logUpdatedCampaigns(
          updatedCampaignIds,
          selectedTableCampaigns,
          'deletion',
          response.data.responseTimestamp - response.data.requestTimestamp,
        );

        const errors = get(response, 'data.responseObject.reason', null);
        if (errors) {
          const errorsMapper = errors.reduce((acc, i) => {
            const foundIndex = acc.findIndex((a) => a.errorMessage === i.errorMessage);
            foundIndex > -1
              ? acc[foundIndex].id.push(i.id)
              : acc.push({
                  errorMessage: i.errorMessage,
                  id: [i.id],
                });
            return acc;
          }, []);

          const toastMessages = errorsMapper.map((e) => {
            const { errorMessage, id } = e;
            return `${pluralize('Campaing', id.length, {
              single: `Campaign with id ${id.join(', ')} is`,
              multiple: `Campaigns with id ${id.join(', ')} are`,
            })} not updated! ${errorMessage ? 'Reason: ' + errorMessage : ''}`;
          });

          return Promise.reject(toastMessages);
        }
      } catch (e) {
        const latency =
          e.data.requestTimestamp && e.data.responseTimestamp
            ? e.responseTimestamp - e.requestTimestamp
            : null;
        logUpdatedCampaigns([], selectedTableCampaigns, 'deletion', latency);
        console.log("[can't fetching statuses from server]: ", e);
        return Promise.reject(e);
      }
    };
  },
  restoreOrRunCampaigns: (action: 'run' | 'restore', endDate?: number) => {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      const state = getState();
      const { selectedTableCampaigns } = state.table;
      const { advertisersOwIds, dateRange, timezone } = state.filter;
      const { auth } = state;
      const transformFailureItems = endDate ? getEndDateFailureTransformation(endDate) : undefined;

      try {
        const campaignAction = action === 'run' ? 'runCampaign' : 'restoreCampaign';

        const response = await API.campaigns[campaignAction]({
          advertiserId: auth.userData.userId,
          dspId: auth.userData.userId,
          campaignIds: selectedTableCampaigns.map((c) => c.campaignId).join(','),
          endDate,
        });

        const modifiedData = get(response, 'data.responseObject.updatedData', []);
        const responseTimestamp = get(response, 'data.responseTimestamp');
        const requestTimestamp = get(response, 'data.requestTimestamp');
        const latency =
          responseTimestamp && requestTimestamp ? responseTimestamp - requestTimestamp : null;

        let updatedCampaigns;
        if (modifiedData && modifiedData.length) {
          try {
            const query = {
              startDate: dateRange ? `${moment(dateRange.start).valueOf()}` : moment().valueOf(),
              endDate: dateRange ? `${moment(dateRange.end).valueOf()}` : moment().valueOf(),
              timezoneId: timezone ? timezone.id : null,
              campaignIds: modifiedData.map((cmp) => cmp.campaignId).join(','),
              pageNo: 1,
              noOfEntries: modifiedData.length,
              owIds: advertisersOwIds,
            } as any;
            const updatedCampaignsResult = await lambdaV3API.supplemental.getCampaignsData(query);
            updatedCampaigns = updatedCampaignsResult.data
              ? updatedCampaignsResult.data.data
              : null;
          } catch (err) {
            DataDogLogger.Campaigns.failSupplementalRequest(campaignAction);
          }
        }

        logUpdatedCampaigns(
          modifiedData.map((campaign) => +campaign.campaignId),
          selectedTableCampaigns,
          action,
          latency,
          updatedCampaigns ||
            modifiedData.map((cmp) => ({
              ...cmp,
              budgetDay: cmp.budgetPacing ? 'unknown' : cmp.budgetDay,
            })),
          transformFailureItems,
        );

        return Promise.resolve(response);
      } catch (e) {
        const responseTimestamp = get(e, 'data.responseTimestamp');
        const requestTimestamp = get(e, 'data.requestTimestamp');
        const latency =
          responseTimestamp && requestTimestamp ? responseTimestamp - requestTimestamp : null;

        logUpdatedCampaigns(
          [],
          selectedTableCampaigns,
          action,
          latency,
          undefined,
          transformFailureItems,
        );
        return Promise.reject(e);
      }
    };
  },
  updateCampaignsBudget: (updateBudgetParams: UpdateBudgetParams) => {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      const state = getState();
      const { selectedTableCampaigns } = state.table;
      const { auth } = state;
      const {
        budgetType,
        value,
        id,
        distributionMethod,
        editableCampaign,
        editType,
      } = updateBudgetParams;
      const transformFailureItems = getBudgetFailureTransformation(
        budgetType,
        value,
        selectedTableCampaigns,
        distributionMethod,
        id,
      );

      if (
        budgetType === 'totalBudget' &&
        selectedTableCampaigns &&
        selectedTableCampaigns.length &&
        value &&
        ((distributionMethod === 'addition' &&
          selectedTableCampaigns.some(
            (cmp) => cmp.budgetTotal && +cmp.budgetTotal + +value > MAX_VALID_BUDGET,
          )) ||
          (distributionMethod === 'distribution' &&
            value / selectedTableCampaigns.length > MAX_VALID_BUDGET))
      ) {
        logUpdatedCampaigns(
          [],
          editableCampaign ? [editableCampaign] : selectedTableCampaigns,
          budgetType,
          null,
          undefined,
          transformFailureItems,
          editType,
        );
        return Promise.reject(new Error(BUDGET_TOTAL_TOO_HIGH));
      } else if (value > MAX_VALID_BUDGET) {
        logUpdatedCampaigns(
          [],
          editableCampaign ? [editableCampaign] : selectedTableCampaigns,
          budgetType,
          null,
          undefined,
          transformFailureItems,
          editType,
        );
        return Promise.reject(new Error(BUDGET_INPUT_TOO_HIGH));
      }

      try {
        const params = {
          advertiserId: auth.userData.userId,
          dspId: auth.userData.userId,
          totalBudgetUpdateType: distributionMethod || 'change',
          campaignIds: id || selectedTableCampaigns.map((i) => i.campaignId).join(','),
          [budgetType]: value,
          budgetTypeId: editableCampaign
            ? editableCampaign.budgetTypeId
            : selectedTableCampaigns[0].budgetTypeId,
          campaignTypeId: editableCampaign
            ? editableCampaign.campaignTypeId
            : selectedTableCampaigns[0].campaignTypeId,
        };
        const response = await API.campaigns.updateCampaignBudget(params);

        const modifiedData = get(response, 'responseObject.modified_data', []);
        logUpdatedCampaigns(
          modifiedData.map((item) => item.campaignId),
          editableCampaign ? [editableCampaign] : selectedTableCampaigns,
          budgetType,
          response.responseTimestamp - response.requestTimestamp,
          modifiedData,
          transformFailureItems,
          editType,
        );

        return Promise.resolve(response);
      } catch (e) {
        const requestTimestamp = get(e, 'data.requestTimestamp');
        const responseTimestamp = get(e, 'data.responseTimestamp');
        const latency =
          requestTimestamp && responseTimestamp ? responseTimestamp - requestTimestamp : null;
        const budgetType = get(updateBudgetParams, 'budgetType');
        const editableCampaign = get(updateBudgetParams, 'editableCampaign');

        logUpdatedCampaigns(
          [],
          editableCampaign ? [editableCampaign] : selectedTableCampaigns,
          budgetType,
          latency,
          undefined,
          transformFailureItems,
          editType,
        );

        const errorMsg = get(e, 'response.data.responseObject.errorMsg', GENERAL_API_ERROR);
        console.log("[can't fetching statuses from server]: ", errorMsg);
        return Promise.reject(e);
      }
    };
  },
  updateCampaignsEndDate: (endDate: number) => {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      const state = getState();
      const { selectedTableCampaigns } = state.table;
      const { auth } = state;

      try {
        const campaignIdsArr = selectedTableCampaigns.map((cmp) => cmp.campaignId);
        const response = await API.campaigns.updateEndTime({
          advertiserId: auth.userData.userId,
          dspId: auth.userData.userId,
          campaignIds: campaignIdsArr.join(','),
          endDate,
        });

        const modifiedData = get(response, 'responseObject.modified_data', []);
        const latency =
          response.responseTimestamp && response.requestTimestamp
            ? response.responseTimestamp - response.requestTimestamp
            : null;

        logUpdatedCampaigns(
          modifiedData.map((item) => item.campaignId),
          selectedTableCampaigns,
          'endDate',
          latency,
          modifiedData,
          getEndDateFailureTransformation(endDate),
        );

        return Promise.resolve(response);
      } catch (e) {
        const requestTimestamp = get(e, 'data.requestTimestamp');
        const responseTimestamp = get(e, 'data.responseTimestamp');
        const latency =
          responseTimestamp && requestTimestamp ? responseTimestamp - requestTimestamp : null;

        logUpdatedCampaigns(
          [],
          selectedTableCampaigns,
          'endDate',
          latency,
          undefined,
          getEndDateFailureTransformation(endDate),
        );

        return Promise.reject(e);
      }
    };
  },
  setFilteredCampaignsIds: function(ids: number[]) {
    return createAction(tableConstants.TABLE_SET_FILTERED_CAMPAIGNS_IDS, ids);
  },
  setCampaigns: function(campaigns: LambdaResponse[]) {
    return createAction<LambdaResponse[]>(tableConstants.TABLE_SET_CAMPAIGNS, campaigns);
  },
  updateCampaignList: function(campaigns: LambdaResponse[]) {
    return createAction<LambdaResponse[]>(tableConstants.UPDATE_CAMPAIGNS_LIST, campaigns);
  },
  setTotalItems: function(totalItems: number | null) {
    return createAction<number | null>(tableConstants.TABLE_SET_TOTAL_ITEMS, totalItems);
  },
  addCampaignAudienceWarnings: function(campaigns: LambdaResponse[]) {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      try {
        const campaignIds = campaigns.reduce((acc, cmp) => {
          if (cmp.status === 'running' && cmp.campaignId) {
            acc.push(cmp.campaignId);
          }
          return acc;
        }, [] as number[]);

        if (campaignIds.length) {
          const audiencesResponse = await API.audiences.getAudiences(campaignIds.join(','));
          const audienceWarnings = audiencesResponse.data;
          Object.entries(audiencesResponse.data).forEach(([key, value]) => {
            const warning = getAudienceWarning([
              ...(audienceWarnings[key].included || []),
              ...(audienceWarnings[key].excluded || []),
            ]);

            if (warning === AudienceWarning.NONE) {
              delete audienceWarnings[key];
            } else {
              audienceWarnings[key] = warning;
            }
          });
          dispatch(
            createAction<{ [key: string]: AudienceWarning }>(
              tableConstants.ADD_CAMPAIGN_AUDIENCE_WARNINGS,
              audienceWarnings,
            ),
          );
        }
      } catch (err) {}
    };
  },
  clearCampaignAudienceWarnings: function() {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      API.audiences.cancelAllGetAudiences();
      dispatch(createAction(tableConstants.CLEAR_CAMPAIGN_AUDIENCE_WARNINGS));
    };
  },
  setSelectedInsertionOrders: (ios: LambdaResponse[]) => {
    return createAction<LambdaResponse[]>(tableConstants.TABLE_SELECT_INSERTION_ORDERS, ios);
  },
  updateInsertionOrdersEndTime: (
    params: Omit<UpdateIOEndTimeParams, 'ioIdsList'>,
    insertionOrders?: LambdaResponse[],
  ) => {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      try {
        const state = getState();
        const { selectedTableInsertionOrders } = state.table;
        const ios = insertionOrders || selectedTableInsertionOrders;
        const isSuperOrWorkspace =
          state.auth.userData.isPlatformOwnerOrg || state.auth.userData.isWorkspaceOwnerOrg;
        const ioIdsList = ios.map((io) => io.ioId) as number[];
        const response = await API.InsertionOrder.updateEndTime({
          ...params,
          ioIdsList,
          ...(isSuperOrWorkspace ? { ioOwId: ios[0].owId } : {}),
        });

        const msg =
          ios.length === 1
            ? `End Date updated successfully for ${ios[0].ioName || 'insertion order.'}`
            : `End Date updated successfully for ${ios.length} Insertion Orders.`;
        dispatch(createAction(toastConstants.TOAST_OPEN, msg));

        return response;
      } catch (err) {
        const message = get(err, 'data.errorObjects[0].error', GENERAL_API_ERROR);
        dispatch(createAction(toastConstants.TOAST_OPEN, message));
        return Promise.reject(err);
      }
    };
  },
  updateInsertionOrdersBudget: (
    params: Omit<UpdateIOBudgetParams, 'ioIdsList'>,
    insertionOrders?: LambdaResponse[],
  ) => {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      try {
        const { selectedTableInsertionOrders } = getState().table;
        const ios = insertionOrders || selectedTableInsertionOrders;
        const ioIdsList = ios.map((io) => io.ioId) as number[];
        const response = await API.InsertionOrder.updateBudget({
          ...params,
          ioIdsList,
          ioBudgetTypeId: ios[0].ioBudgetTypeId,
        });
        const msg =
          ios.length === 1
            ? `Total Budget updated successfully for ${ios[0].ioName || 'insertion order.'}`
            : `Total Budget updated successfully for ${ios.length} Insertion Orders.`;
        dispatch(createAction(toastConstants.TOAST_OPEN, msg));

        return response;
      } catch (err) {
        const message = get(err, 'data.errorObjects[0].error', GENERAL_API_ERROR);
        dispatch(createAction(toastConstants.TOAST_OPEN, message));
        return Promise.reject(err);
      }
    };
  },
};

export interface SetCampaigns {
  setCampaigns: (campaigns: LambdaResponse[]) => void;
}

export interface SetFilteredCampaigns {
  setFilteredCampaigns: (campaigns: LambdaResponse[]) => void;
}

export interface SetFilteredCampaignsIds {
  setFilteredCampaignsIds: (ids: number[]) => void;
}

export interface DeleteCampaigns {
  deleteCampaigns: () => void;
}

export interface SetTotalItems {
  setTotalItems: (totalItems: number | null) => void;
}

interface UpdateBudgetParams {
  budgetType: CampaignBudgetType;
  value: number;
  id?: number;
  distributionMethod?: 'change' | 'addition' | 'distribution';
  editableCampaign?: Campaign | null;
  editType: 'inline' | 'bulk';
}

export interface UpdateCampaignsBudget {
  updateCampaignsBudget: (params: UpdateBudgetParams) => Promise<UpdateBudgetResponse<any>>;
}

export interface UpdateCampaignName {
  updateCampaignName: (name: string, id: string | number, campaign: LambdaResponse) => Promise<any>;
}

export interface UpdateCampaignsList {
  updateCampaignsList: (campaigns: LambdaResponse[]) => void;
}

export interface ClearSelectedCampaigns {
  clearSelectedCampaigns: () => void;
}

export interface AddCampaignAudienceWarnings {
  addCampaignAudienceWarnings: (campaigns: LambdaResponse[]) => void;
}

export interface ClearCampaignAudienceWarnings {
  clearCampaignAudienceWarnings: () => void;
}

export interface UpdateCampaignsEndDate {
  updateCampaignsEndDate: (endDate: number) => Promise<UpdateBudgetResponse<any>>;
}

export interface RestoreOrRunCampaigns {
  restoreOrRunCampaigns: (
    action: 'restore' | 'run',
    endDate?: number,
  ) => Promise<UpdateBudgetResponse<any>>;
}

export interface SetSelectedInsertionOrders {
  setSelectedInsertionOrders: (ios: LambdaResponse[]) => void;
}

export interface UpdateInsertionOrdersEndTime {
  updateInsertionOrdersEndTime: (
    params: Omit<UpdateIOEndTimeParams, 'ioIdsList'>,
    insertionOrders?: LambdaResponse[],
  ) => void;
}

export interface UpdateInsertionOrdersBudget {
  updateInsertionOrdersBudget: (
    params: Omit<UpdateIOBudgetParams, 'ioIdsList'>,
    insertionOrders?: LambdaResponse[],
  ) => void;
}
