import { logDataDogAction } from 'iqm-framework';
import { CampaignBudgetType } from '../../../models/Campaign';
import { SERVICE } from '../constants';

export interface DataDogCampaignAction {
  id: string | number;
  name: string;
  start_date: number;
  end_date?: number;
  timezone: string;
  total_budget?: number;
  daily_budget?: number | string;
  max_bid?: number;
  creative_type?: string;
  latency: number | null;
  type?: 'inline' | 'bulk';
}

export type DataDogCampaignUpdateActionTypes =
  | 'deletion'
  | CampaignBudgetType
  | 'endDate'
  | 'run'
  | 'restore';

export const toDataDogCampaignAction = (item: any) => {
  return {
    id: item.campaignId || '',
    name: item.campaignName || '',
    start_date: item.startTime || 0,
    end_date: item.endTime,
    timezone: item.campaignTimezone || '',
    total_budget: item.budgetTotal,
    daily_budget: item.budgetDay,
    max_bid: item.maxBid,
    creative_type:
      typeof item.creativeType === 'string'
        ? item.creativeType.toLowerCase()
        : item.creativeType
        ? `${item.creativeType}`
        : undefined,
    latency: item.latency || null,
    edit_type: item.editType,
    success: item.success,
  };
};

export const Campaigns = {
  duplicateSucceeded: (campaign: DataDogCampaignAction) => {
    logDataDogAction('duplicate of Campaign Succeeded', campaign);
  },
  duplicateFailed: (campaign: DataDogCampaignAction) => {
    logDataDogAction('duplicate of Campaign Failed', campaign);
  },
  deletionSucceeded: (campaign: DataDogCampaignAction) => {
    logDataDogAction('deletion of Campaign Succeeded', campaign);
  },
  deletionFailed: (campaign: DataDogCampaignAction) => {
    logDataDogAction('deletion of Campaign Failed', campaign);
  },
  totalBudgetSucceeded: (campaign: DataDogCampaignAction) => {
    logDataDogAction('setting of Campaign Total Budget Succeeded', campaign);
  },
  totalBudgetFailed: (campaign: DataDogCampaignAction) => {
    logDataDogAction('setting of Campaign Total Budget Failed', campaign);
  },
  clickOnTotalBudget: () => {
    logDataDogAction('click on Set Total Budget');
  },
  dailyBudgetSucceeded: (campaign: DataDogCampaignAction) => {
    logDataDogAction('setting of Campaign Daily Budget Succeeded', campaign);
  },
  dailyBudgetFailed: (campaign: DataDogCampaignAction) => {
    logDataDogAction('setting of Campaign Daily Budget Failed', campaign);
  },
  clickOnDailyBudget: () => {
    logDataDogAction('click on Set Daily Budget');
  },
  maxBidSucceeded: (campaign: DataDogCampaignAction) => {
    logDataDogAction('setting of Campaign Max Bid Price Succeeded', {
      ...campaign,
      action: `${SERVICE}/setMaxBidPrice`,
    });
  },
  maxBidFailed: (campaign: DataDogCampaignAction) => {
    logDataDogAction('setting of Campaign Max Bid Price Failed', {
      ...campaign,
      action: `${SERVICE}/setMaxBidPrice`,
    });
  },
  clickOnMaxBid: () => {
    logDataDogAction('click on Set Max Bid');
  },
  pauseSucceeded: (campaign: DataDogCampaignAction) => {
    logDataDogAction('pause of Campaign Succeeded', campaign);
  },
  pauseFailed: (campaign: DataDogCampaignAction) => {
    logDataDogAction('pause of Campaign Failed', campaign);
  },
  clickOnNewCampaign: () => {
    logDataDogAction('click on New Campaign');
  },
  clickOnEditCampaign: () => {
    logDataDogAction('click on Edit Campaign');
  },
  endDateSucceeded: (campaign: DataDogCampaignAction) => {
    logDataDogAction('setting of Campaign End Date Succeeded', campaign);
  },
  endDateFailed: (campaign: DataDogCampaignAction) => {
    logDataDogAction('setting of Campaign End Date Failed', campaign);
  },
  clickOnRunCampaign: () => {
    logDataDogAction('click on Run Campaign');
  },
  runSucceeded: (campaign: DataDogCampaignAction) => {
    logDataDogAction('resuming of Campaign Succeeded', campaign);
  },
  runFailed: (campaign: DataDogCampaignAction) => {
    logDataDogAction('resuming of Campaign Failed', campaign);
  },
  failSupplementalRequest: (message?: string) => {
    const msg = `: ${message}`;
    logDataDogAction(`failure of Supplemental Request${message ? msg : ''}`);
  },
  clickOnSetCampaignName: () => {
    logDataDogAction('click on Set Campaign Name');
  },
  editNameSucceeded: (campaign: DataDogCampaignAction) => {
    logDataDogAction('setting of Campaign Name Succeeded', campaign);
  },
  editNameFailed: (campaign: DataDogCampaignAction) => {
    logDataDogAction('setting of Campaign Name Failed', campaign);
  },
  clickOnRestoreCampaign: () => {
    logDataDogAction('click on Restore Campaign');
  },
  restoreSucceeded: (campaign: DataDogCampaignAction) => {
    logDataDogAction('restore of Campaign Succeeded', campaign);
  },
  restoreFailed: (campaign: DataDogCampaignAction) => {
    logDataDogAction('restore of Campaign Failed', campaign);
  },
  openCreativesDialog: () => {
    logDataDogAction('Open Creatives Dialog', {
      action: `${SERVICE}/viewCreativesByCampaignDialog`,
    });
  },
};
