import React, { useMemo } from 'react';
import { Select, Icon } from 'factor';
import { Option } from '../../../../../../models/Option';
import { creativeStatusOptions } from '../../../../../../constants/creatives';
import styles from './styles.module.scss';

interface Props {
  onSelect: (statuses: Option) => void;
  selected: Option;
}

export const StatusFilter = (props: Props) => {
  const { onSelect, selected } = props;

  const options = useMemo(
    () =>
      creativeStatusOptions.map((opt) => ({
        ...opt,
        reactLabel: (
          <div className={styles.option}>
            <Icon className="mr-2" name={opt.icon} />
            {opt.label}
          </div>
        ),
      })),
    [],
  );

  const selectedOption = { ...selected, reactLabel: `Status - ${selected.label}` };
  return <Select options={options} value={selectedOption} onChange={onSelect} />;
};
