import { getIntercomEventData, putIntercomEventData } from '../api/Intercom';

export const trackIntercomEvent = async () => {
  const response = await getIntercomEventData('user_first_visit_dashboard');
  const userInteractionEvents = response && response.data && response.data.userInteractionEvents;
  if (Array.isArray(userInteractionEvents) && userInteractionEvents.length === 0) {
    // eslint-disable-next-line
    // @ts-ignore
    window && window.Intercom && window.Intercom('trackEvent', 'user_first_visit');
  }
  await putIntercomEventData({
    eventName: 'user_first_visit_dashboard',
    meta: { app_name: 'dashboard' },
  });
};
