import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import get from 'lodash/get';
import { Dialog, DialogHeader, DialogFooter, DialogContent, Button, Icon } from 'factor';
import {
  TimezonePicker,
  TimezoneProvider,
  EpochSingleDateRangePicker,
  IO_STATUS_ID,
} from 'iqm-framework';
import { pluralize } from '../../../../../../../utils/pluralize';
import { AppState } from '../../../../../../../store';
import { tableActions } from '../../../../../../../store/table/actions';
import { toastActions } from '../../../../../../../store/toast/actions';
import {
  statisticsActions,
  GetStatisticsTotalStart,
} from '../../../../../../../store/statistics/actions';
import { filterActions, LoadCampaignOptions } from '../../../../../../../store/filter/actions';
import { Campaign } from '../../../../../../../models/Campaign';
import { API } from '../../../../../../../api';
import { GetInsertionOrderListParams } from '../../../../../../../api/InsertionOrder';
import { TableComponentInstance } from '../../../Table';
import { OptionID } from '../../../../../../../models/Option';
import './duplicateDialogContent/styles.scss';
import { Spinner } from '../../../../../../../components/Spinner';
import { creativeStringIconMap } from '../../../../../../../models/Campaign';
import { InsertionOrderOption } from '../../../../../../../models/InsertionOrder';
import { SelectInsertionOrdersWrapper } from './duplicateWithIODialog/SelectInsertionOrdersWrapper';
import { InsertionOrderOptionComponent } from '../../../../../../../components/InsertionOrderOptionComponent';
import { nearestFutureMinutes } from '../../../../../../../utils/date';
import styles from './duplicateWithIODialog/styles.module.scss';

const DATE_FORMAT = 'MM/DD/YYYY hh:mm A';

interface Props extends GetStatisticsTotalStart, LoadCampaignOptions {
  selectedCampaigns: Campaign[];
  timezone: OptionID;
  timezones: OptionID[];
  onClose: () => void;
  duplicate: (
    startTime: number,
    endTime: number | null,
    ioId: number | undefined,
    timezone: number,
  ) => Promise<any>;
  openToast: (name: string) => void;
  className?: string;
  filterCampaigns: number[];
}

interface State {
  startDate: number;
  endDate: number | null;
  changingStatus: 'loading' | 'success' | 'fail' | null;
  requireEndDate: boolean;
  selectedInsertionOrder: InsertionOrderOption | null;
  apiBodyParams: Partial<GetInsertionOrderListParams>;
}

class DuplicateDialogContentComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const { selectedCampaigns } = this.props;

    let startDate = this.getDefaultStartDate();
    if (selectedCampaigns.length === 1) {
      startDate = this.isCampaignDateInFuture(selectedCampaigns[0], 'startTime')
        ? get(selectedCampaigns, '[0].startTime') * 1000
        : startDate;
    }

    this.state = {
      startDate,
      endDate: null,
      changingStatus: null,
      requireEndDate: selectedCampaigns.some((cmp) => cmp.budgetPacing),
      apiBodyParams: {
        ioBudgetTypeIdsList: [selectedCampaigns[0].budgetTypeId],
        ioStatusIdsList: [IO_STATUS_ID.ACTIVE, IO_STATUS_ID.EXPIRED],
      },
      selectedInsertionOrder: selectedCampaigns.every(
        (cmp) => cmp.ioId === selectedCampaigns[0].ioId,
      )
        ? ({
            ioId: selectedCampaigns[0].ioId,
            ioName: selectedCampaigns[0].ioName,
            ioTimezone: selectedCampaigns[0].ioTimezone,
            ioStartTime: selectedCampaigns[0].ioStartTime,
            ioEndTime: selectedCampaigns[0].ioEndTime,
            label: selectedCampaigns[0].ioName,
            value: selectedCampaigns[0].ioId,
            id: selectedCampaigns[0].ioId,
            ioStatusId: selectedCampaigns[0].ioStatusId,
            reactLabel: (
              <InsertionOrderOptionComponent
                disablePadding
                name={selectedCampaigns[0].ioName as string}
                id={selectedCampaigns[0].ioId as number}
                ioStatusId={(selectedCampaigns[0] as any).ioStatusId as any}
                ioBudgetTypeId={selectedCampaigns[0].budgetTypeId}
              />
            ),
          } as InsertionOrderOption)
        : null,
    };
  }

  get timezone() {
    const { timezone, timezones } = this.props;
    let campaignTz = timezone;
    const selectedCampaignsTimezones = this.selectedCampaignsTimezones();

    if (selectedCampaignsTimezones.length === 1) {
      campaignTz = timezones.find((tz) => tz.value === selectedCampaignsTimezones[0]) || timezone;
    }

    return campaignTz;
  }

  isCampaignDateInFuture = (campaign: Campaign, dateType: 'startTime' | 'endTime') => {
    const campaignTimezone = campaign.campaignTimezone;
    return (
      moment
        .tz(campaign[dateType] * 1000, campaignTimezone)
        .diff(moment.tz(moment(), campaignTimezone), 'minutes') >= 1
    );
  };

  selectedCampaignsTimezones = () => {
    const { selectedCampaigns } = this.props;
    return selectedCampaigns.reduce((acc: string[], campaign) => {
      if (!acc.includes(campaign.campaignTimezone)) {
        acc.push(campaign.campaignTimezone);
      }
      return acc;
    }, []);
  };

  getCampaignDetails = (ids: number[]) => {
    const detailsPromises = ids.map((id) => API.campaigns.getCampaignDetails(id));
    return Promise.all(detailsPromises);
  };

  duplicateHandler = () => {
    const {
      duplicate,
      openToast,
      getStatisticsTotalStart,
      loadCampaignOptions,
      selectedCampaigns,
      filterCampaigns,
      timezone,
    } = this.props;
    const { startDate, endDate, selectedInsertionOrder } = this.state;
    if (!selectedInsertionOrder) {
      return;
    }
    const startValue = Math.round(startDate / 1000);
    let endValue: number = 0;

    this.setState({ changingStatus: 'loading' });

    if (endDate) {
      endValue = Math.round(endDate / 1000);
    }

    duplicate(
      startValue,
      endValue || null,
      selectedInsertionOrder ? selectedInsertionOrder.ioId : undefined,
      timezone.id,
    )
      .then(() => {
        const selectedCampaignsIds = this.props.selectedCampaigns.map((i) => i.campaignId);
        const single = `${selectedCampaigns[0].campaignName} campaign duplicated successfully.`;
        const multiple = `${selectedCampaignsIds.length} Campaigns duplicated in ${selectedInsertionOrder.ioName}`;
        const toastMessage = pluralize('', selectedCampaigns.length, {
          single,
          multiple,
        });
        openToast(toastMessage);
        this.setState({ changingStatus: 'success' });
        TableComponentInstance.setState({
          selected: [],
          loading: true,
        });
        loadCampaignOptions(filterCampaigns);
        getStatisticsTotalStart();
        TableComponentInstance.getNewData();
      })
      .catch((e) => {
        openToast(e);
        this.setState({ changingStatus: 'fail' });
      });
  };

  handleChangeDateRange = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate });
  };

  getDefaultStartDate = () => {
    return moment
      .tz(nearestFutureMinutes(10, moment().add(10, 'minutes')), this.timezone.value)
      .valueOf();
  };

  setSelectedInsertionOrder = (io: InsertionOrderOption) => {
    this.setState({ selectedInsertionOrder: io });
  };

  render() {
    const { onClose, selectedCampaigns, openToast, timezones } = this.props;
    const {
      startDate,
      endDate,
      changingStatus,
      requireEndDate,
      selectedInsertionOrder,
      apiBodyParams,
    } = this.state;

    const ioTimezone = selectedInsertionOrder
      ? get(
          timezones.find((tz) => tz.id === selectedInsertionOrder.ioTimezone),
          'value',
        )
      : null;

    const ioInfo =
      selectedInsertionOrder && ioTimezone
        ? {
            ...(selectedInsertionOrder.ioStartTime
              ? {
                  'IO Start Date': moment
                    .unix(selectedInsertionOrder.ioStartTime / 1000)
                    .tz(ioTimezone)
                    .format(DATE_FORMAT),
                }
              : {}),
            ...(selectedInsertionOrder.ioEndTime
              ? {
                  'IO End Date': moment
                    .unix(selectedInsertionOrder.ioEndTime / 1000)
                    .tz(ioTimezone)
                    .format(DATE_FORMAT),
                }
              : {}),
            ...(ioTimezone ? { 'IO Timezone': ioTimezone } : {}),
          }
        : null;

    return (
      <Dialog headerFooterBorders open className={styles.duplicateDialog} onClickOutside={onClose}>
        <DialogHeader headerFooterBorders>
          <h3 className="title-card">{`Duplicate ${pluralize(
            'Campaign',
            selectedCampaigns.length,
          )}`}</h3>
        </DialogHeader>
        <DialogContent headerFooterBorders>
          {selectedCampaigns.length === 1 ? (
            <h5 className={`${styles.subtitle} mb-4 d-flex`}>
              <span className={`${styles.light} mr-1`}>Duplicate the campaign </span>
              {selectedCampaigns[0].creativeType &&
              creativeStringIconMap[(selectedCampaigns[0].creativeType as string).toLowerCase()] ? (
                <Icon
                  name={
                    creativeStringIconMap[
                      (selectedCampaigns[0].creativeType as string).toLowerCase()
                    ]
                  }
                  className="mr-2 ml-2"
                />
              ) : null}
              <span className={styles.bold}>{selectedCampaigns[0].campaignName}</span>
            </h5>
          ) : (
            <h5 className={`${styles.subtitle} ${styles.light} mb-2`}>
              Duplicate the {selectedCampaigns.length} campaigns
            </h5>
          )}
          <TimezoneProvider timezone={this.timezone}>
            <div className="d-flex pt-2 align-items-center">
              <SelectInsertionOrdersWrapper
                className={`mr-3 ${styles.insertionOrderSelect}`}
                selectedInsertionOrder={
                  selectedInsertionOrder && selectedInsertionOrder.ioStatusId !== 3
                    ? selectedInsertionOrder
                    : null
                }
                setSelectedInsertionOrder={this.setSelectedInsertionOrder}
                owIds={[selectedCampaigns[0].owId]}
                apiBodyParams={apiBodyParams}
              />
              <EpochSingleDateRangePicker
                startLabel="Campaign Start Date"
                endLabel="Campaign End Date"
                startDate={startDate}
                endDate={endDate}
                onDateRangeChanged={this.handleChangeDateRange}
                onError={(e) => openToast(e)}
                className="d-flex"
                disableRelativeMaxDate
                calendarMaximumDate={null}
                calendarMinimumDate={(utcDate) => utcDate}
                startDateTooltipParams={{ label: 'Date and Time when the Campaign will start' }}
                endDateTooltipParams={{ label: 'Date and Time when the Campaign will end' }}
                endDatePlaceholder={requireEndDate ? '(Required)' : '(Optional)'}
              />
              <TimezonePicker
                onTimezoneChange={null}
                className={`${styles.timezonePickerWrapper} ml-3 mr-4`}
                label="Campaign Time Zone"
              />
            </div>
            <div className={styles.divider} />
            {ioInfo && (
              <div className={styles.ioInfo}>
                {Object.keys(ioInfo).map((key) => (
                  <div className={styles.ioInfoItem}>
                    <span className={styles.ioInfoKey}>{key}</span>
                    <span className={styles.ioInfoValue}>{ioInfo[key]}</span>
                    <span className={styles.circle} />
                  </div>
                ))}
              </div>
            )}

            <div className={styles.alert}>
              <Icon name="Info" className="mr-2" />
              The duplicated campaign(s) will be added as draft campaigns
            </div>
          </TimezoneProvider>
          {changingStatus ? <Spinner status={changingStatus} onClose={onClose} /> : null}
        </DialogContent>
        <DialogFooter headerFooterBorders>
          <div className="d-flex mt-2 justify-content-end">
            <Button
              disabled={changingStatus}
              className="btn-square _conflower-blue mr-2 _md"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              className="btn-square _conflower-blue _filled _md"
              onClick={requireEndDate && !endDate ? undefined : this.duplicateHandler}
              disabled={
                changingStatus ||
                (requireEndDate && !endDate) ||
                !selectedInsertionOrder ||
                (selectedInsertionOrder && selectedInsertionOrder.ioStatusId === 3)
              }
            >
              Duplicate
            </Button>
          </div>
        </DialogFooter>
      </Dialog>
    );
  }
}

const mapState = (state: AppState) => ({
  selectedCampaigns: state.table.selectedTableCampaigns,
  filterCampaigns: state.filter.selectedCampaigns,
  timezone: state.filter.timezone,
  timezones: state.filter.timezones,
});

const mapActions = {
  duplicate: tableActions.duplicateWithIO,
  openToast: toastActions.open,
  getStatisticsTotalStart: statisticsActions.getStatisticsTotalStart,
  loadCampaignOptions: filterActions.loadCampaignOptions,
};

export const DuplicateWithIODialog = connect(mapState, mapActions)(DuplicateDialogContentComponent);
