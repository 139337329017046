import { getInstance } from './index';
import { WithResponse } from '../models/Response';

interface IntercomEven {
  userInteractionEvent: string;
  metaData: any;
  occurrenceCount: number;
}

interface IntercomEventList {
  totalRecords?: number;
  filteredRecords?: number;
  userInteractionEvents?: IntercomEven[];
}

export async function getIntercomEventData(
  eventName: string,
): Promise<WithResponse<IntercomEventList>> {
  try {
    const response: any = await getInstance().get(`/v3/ua/interaction/events`, {
      params: { userInteractionEvent: eventName },
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function putIntercomEventData(event: {
  eventName: string;
  meta?: any;
}): Promise<WithResponse<IntercomEventList>> {
  try {
    const response: any = await getInstance().put(`/v3/ua/interaction/event`, {
      userInteractionEvent: event.eventName,
      metaData: event.meta,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
}
