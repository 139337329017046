import React from 'react';
import { connect } from 'react-redux';
import { DialogContent, Button, Checkbox } from 'factor';
import get from 'lodash/get';

import { pluralize } from '../../../../../../../utils/pluralize';
import { AppState } from '../../../../../../../store';
import { Campaign } from '../../../../../../../models/Campaign';
import { tableActions } from '../../../../../../../store/table/actions';
import { TableComponentInstance } from '../../../Table';
import { DeleteCampaigns } from '../../../../../../../store/table/actions';
import { localStorageService } from '../../../../../../../services/localStorage';
import { toastActions } from '../../../../../../../store/toast/actions';
import { filterActions } from '../../../../../../../store/filter/actions';
import {
  statisticsActions,
  GetStatisticsTotalStart,
} from '../../../../../../../store/statistics/actions';
import { Spinner } from '../../../../../../../components/Spinner';

interface Props extends DeleteCampaigns, GetStatisticsTotalStart {
  selectedCampaigns: Campaign[];
  onClose: () => void;
  clearSelected: () => void;
  openToast: (message: string) => void;
  getStatuses: () => void;
}

interface State {
  remember: boolean;
  changingStatus: 'loading' | 'success' | 'fail' | null;
}

const STORAGE_NAME = 'deleteCampaign';

class DeleteDialogContentComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      remember: false,
      changingStatus: null,
    };
  }

  deleteHandler = async () => {
    const {
      openToast,
      selectedCampaigns,
      getStatuses,
      clearSelected,
      getStatisticsTotalStart,
    } = this.props;
    this.setState({ changingStatus: 'loading' });
    try {
      const toastMessage = `${pluralize('Campaign', selectedCampaigns.length, {
        single: 'Campaign has',
        multiple: 'Campaigns have',
      })} been deleted`;
      await this.props.deleteCampaigns();
      if (this.state.remember) {
        localStorageService.saveLocal<boolean>(STORAGE_NAME, true);
      }
      TableComponentInstance.setState({
        selected: [],
        loading: true,
      });
      getStatisticsTotalStart();
      TableComponentInstance.getNewData();
      openToast(toastMessage);
      this.setState(
        { changingStatus: 'success' },
        () => {
          setTimeout(() => clearSelected(), 1100);
        }, // animation time + .1s
      );
      getStatuses();
    } catch (e) {
      const errorMsg = get(
        e,
        'response.data.responseObject.errorMsg',
        'Something went wrong. Please try again later.',
      );
      openToast(errorMsg);
      this.setState(
        { changingStatus: 'fail' },
        () => {
          setTimeout(() => clearSelected(), 1100);
        }, // animation time + .1s
      );
    }
  };

  handleChBoxChange = (value: boolean) => {
    this.setState({
      remember: value,
    });
  };

  render() {
    const { onClose, selectedCampaigns } = this.props;
    const { remember, changingStatus } = this.state;

    return (
      <DialogContent>
        <h3 className="title-card mb-4">{`Delete ${pluralize(
          'Campaign',
          selectedCampaigns.length,
        )}?`}</h3>
        {selectedCampaigns.length === 1 ? (
          <h5 className="title-card-subtitle mb-4">
            Delete the <span className="_black">{selectedCampaigns[0].campaignName}</span> campaign?
          </h5>
        ) : (
          <h5 className="title-card-subtitle mb-4">
            Delete the <span className="_black">{selectedCampaigns.length}</span> campaigns?
          </h5>
        )}
        <div className="d-flex justify-content-end align-items-center mt-2">
          <div className="mr-3 flex-grow-1">
            <Checkbox
              label="Don't ask me again"
              checked={remember}
              onChange={this.handleChBoxChange}
            />
          </div>
          <Button className="btn-square _conflower-blue mr-2 _md" onClick={onClose}>
            Cancel
          </Button>
          <Button className="btn-square _persimmon _filled _md" onClick={this.deleteHandler}>
            Delete
          </Button>
        </div>
        {changingStatus ? <Spinner status={changingStatus} onClose={onClose} /> : null}
      </DialogContent>
    );
  }
}

const mapState = (state: AppState) => ({
  selectedCampaigns: state.table.selectedTableCampaigns,
});

const mapActions = {
  clearSelected: tableActions.clearSelectedCampaigns,
  deleteCampaigns: tableActions.deleteCampaigns,
  openToast: toastActions.open,
  getStatuses: filterActions.getStatuses,
  getStatisticsTotalStart: statisticsActions.getStatisticsTotalStart,
};

export const DeleteDialogContent = connect(mapState, mapActions)(DeleteDialogContentComponent);
