import React, { useEffect, useRef, useState } from 'react';
import { Select, EmptyDropdownLabel } from 'factor';
import { Canceler } from 'axios';
import upperFirst from 'lodash/upperFirst';
import get from 'lodash/get';
import { Option } from '../../../../../../../models/Option';
import { CampaignStatusType } from '../../../../../../../models/Campaign';
import { LoadingStatus } from '../../../../../../../models/LoadingStatus';
import { API } from '../../../../../../../api';

interface Props {
  setStatus: (status: Option) => void;
  status: string;
  ioId: number;
  owId: number;
}

export const SelectStatus = (props: Props) => {
  const { status, setStatus, ioId, owId } = props;
  const [statusOptions, setStatusOptions] = useState<Option<CampaignStatusType>[]>([]);
  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.PENDING);
  const canceler = useRef<Canceler | null>(null);

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        setLoading(LoadingStatus.LOADING);
        const res = await API.campaigns.campaignsCount(
          {
            ioIds: `${ioId}`,
            sortType: 'asc',
            owIds: `${owId}`,
          },
          (c: Canceler) => {
            canceler.current = c;
          },
        );
        if (Array.isArray(res)) {
          const newStatusOptions = res
            .filter((statusOpt) => statusOpt.status_key !== 'budget_paused')
            .map((statusOpt) => ({
              label: `${statusOpt.status_label} (${statusOpt.status_count})`,
              value: statusOpt.status_key as CampaignStatusType,
            }));
          setStatusOptions(newStatusOptions);
          const selectedStatus = newStatusOptions.find((opt) => opt.value === status);
          if (selectedStatus) {
            setStatus(selectedStatus);
          }
        }
        setLoading(LoadingStatus.PENDING);
      } catch (err) {
        setLoading(LoadingStatus.ERROR);
      }
    };
    fetchStatuses();

    return () => {
      if (canceler.current) {
        canceler.current('Cancelled by the user');
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ioId, owId]);

  const selectedOption = {
    label: get(
      statusOptions.find((opt) => opt.value === status),
      'label',
      upperFirst((status || '').replace('_', ' ')),
    ),
    value: status,
  };

  return (
    <Select
      onChange={setStatus}
      value={selectedOption}
      options={statusOptions || []}
      className="mr-2"
      label="Status"
      emptyDropdownLabel={
        <EmptyDropdownLabel
          isLoading={loading === LoadingStatus.LOADING}
          isError={loading === LoadingStatus.ERROR}
        />
      }
    />
  );
};
