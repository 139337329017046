import { Dispatch } from 'redux';
import axios from 'axios';
import moment from 'moment';
import get from 'lodash/get';
import { lambdaV3API } from '../../api/lambdaV3API';
import { createAction } from '../../utils/actions';
import { hasNoCustomers } from '../../utils/errors';
import { statisticsConstants } from './constants';
import { StatisticsTotalResult } from '../../models/Statistics';
import { AppState } from '..';
import { LambdaResponse, CancellarError } from '../../models/Response';
import { toastConstants } from '../toast/constants';
import { TableLevel } from '../filter/reducers';
import { NO_CUSTOMER_ASSIGNED } from '../../constants/tooltips';
import { promisifiedDebounce } from '../../utils/debounce';

const setNewTotal = (total: any) => {
  return function(dispatch: Dispatch) {
    if (total) {
      const totalStats: StatisticsTotalResult = {
        impressions: +total.impressions,
        eCPM: +total.eCPM,
        eCPC: +total.eCPC,
        clicks: +total.clicks,
        spent: +total.spent,
        eCTR: +total.CTR,
        winRate: +total.winRate,
        mediaSpent: +total.mediaSpent,
      };
      dispatch(
        createAction<StatisticsTotalResult>(statisticsConstants.GET_TOTAL__SUCCESS, totalStats),
      );
    }
  };
};

const getPrevStatisticsDebounced = promisifiedDebounce(
  async (query, tableLevel) => {
    let response;
    switch (tableLevel.value) {
      case TableLevel.Workspaces:
        response = await lambdaV3API.common.getWorkspacesPrevStatistics(query);
        break;
      case TableLevel.Advertisers:
        response = await lambdaV3API.common.getAdvertisersPrevStatistics(query);
        break;
      case TableLevel.Exchanges:
        response = await lambdaV3API.common.getExchangesPrevStatistics(query);
        break;
      case TableLevel.InsertionOrders:
        response = await lambdaV3API.common.getInsertionOrdersPrevStatistics(query);
        break;
      default:
        response = await lambdaV3API.common.getCampaignPrevStatistics(query);
        break;
    }

    return response;
  },
  500,
  () => {
    lambdaV3API.common.cancelGetAdvertisersPrevStatistics();
    lambdaV3API.common.cancelGetWorkspacesPrevStatistics();
    lambdaV3API.common.cancelGetCampaignPrevStatistics();
    lambdaV3API.common.cancelGetExchangesPrevStatistics();
    lambdaV3API.common.cancelGetInsertionOrdersPrevStatistics();
  },
);

export const statisticsActions = {
  setNewTotal,
  getPrevTotal() {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      dispatch(createAction<StatisticsTotalResult>(statisticsConstants.GET_TOTAL_PREV__START));
      dispatch(createAction(statisticsConstants.CLEAR_TOTAL));
      try {
        const state = getState();
        const { auth } = state;
        const {
          dateRange,
          timezone,
          selectedCreativeTypes,
          status,
          search,
          tableLevel,
          advertisersOwIds,
          selectedInsertionOrders,
          selectedCampaigns,
          ioBudgetTypes,
          campaignTypeIds,
        } = state.filter;

        if (hasNoCustomers(state)) {
          dispatch(createAction(toastConstants.TOAST_OPEN, NO_CUSTOMER_ASSIGNED));
          return;
        }

        const useCampaignIds =
          tableLevel.value !== TableLevel.Workspaces &&
          tableLevel.value !== TableLevel.Advertisers &&
          tableLevel.value !== TableLevel.InsertionOrders;
        const useToken = tableLevel.value === TableLevel.Campaigns;

        if (dateRange) {
          const { start, end } = dateRange;
          const diff = moment(end).unix() - moment(start).unix();
          const startDate = `${moment(start)
            .clone()
            .subtract(diff, 'seconds')
            .valueOf()}`;
          const endDate = `${moment(start).valueOf()}`;

          let query = {
            startDate,
            endDate,
            timezoneId: timezone ? timezone.id : null,
            owIds: advertisersOwIds,
            ...(tableLevel.value !== TableLevel.InsertionOrders
              ? {
                  campaignStatus: status.value,
                  creativeTypeIds: selectedCreativeTypes.map((c) => c.value).join(','),
                }
              : {}),
            searchField: search,
            resultType: 'total',
            ...(useCampaignIds
              ? {
                  campaignIds:
                    selectedCampaigns && selectedCampaigns.length
                      ? selectedCampaigns.join(',')
                      : undefined,
                }
              : {}),
            ...(useToken
              ? {
                  token: `${auth.userData.apiToken}`,
                }
              : {}),
            ...((tableLevel.value === TableLevel.InsertionOrders ||
              tableLevel.value === TableLevel.Campaigns) &&
            !selectedCampaigns?.length
              ? { ioIdsList: selectedInsertionOrders, budgetTypeIdList: ioBudgetTypes }
              : {}),
            ...((tableLevel.value === TableLevel.Campaigns ||
              tableLevel.value === TableLevel.Advertisers ||
              tableLevel.value === TableLevel.Workspaces) &&
            campaignTypeIds.length
              ? { campaignTypeIds }
              : {}),
          };

          const response = await getPrevStatisticsDebounced(query, tableLevel);

          const responseTotal = get(response, 'data.recordsTotal', null);

          if (responseTotal) {
            const totalStats: StatisticsTotalResult = {
              impressions: +responseTotal.impressions,
              eCPM: +responseTotal.eCPM,
              eCPC: +responseTotal.eCPC,
              clicks: +responseTotal.clicks,
              spent: +responseTotal.spent,
              eCTR: +responseTotal.CTR,
              winRate: +responseTotal.winRate,
              mediaSpent: +responseTotal.mediaSpent,
            };
            dispatch(
              createAction<StatisticsTotalResult>(
                statisticsConstants.GET_TOTAL_PREV__SUCCESS,
                totalStats,
              ),
            );
          }
        }
      } catch (e) {
        const errorResponse = get(e, 'response.data', null);

        if (!axios.isCancel(errorResponse)) {
          dispatch(createAction(statisticsConstants.GET_TOTAL_PREV__ERROR, e));
          dispatch(
            createAction(
              toastConstants.TOAST_OPEN,
              'Something went wrong. Please try again over a few minutes.',
            ),
          );
        }
      }
    };
  },
  getTotal(andSetTotal: boolean = false) {
    return async (dispatch: Dispatch<any>, getState: () => AppState) => {
      dispatch(createAction<StatisticsTotalResult>(statisticsConstants.GET_TOTAL__START));
      try {
        const state = getState();
        const { auth } = state;
        const {
          dateRange,
          timezone,
          selectedCreativeTypes,
          status,
          search,
          tableLevel,
          advertisersOwIds,
          selectedCampaigns,
          selectedInsertionOrders,
          ioBudgetTypes,
          campaignTypeIds,
        } = state.filter;

        if (hasNoCustomers(state)) {
          dispatch(createAction(toastConstants.TOAST_OPEN, NO_CUSTOMER_ASSIGNED));
          return;
        }

        const useCampaignIds =
          tableLevel.value !== TableLevel.Workspaces &&
          tableLevel.value !== TableLevel.Advertisers &&
          tableLevel.value !== TableLevel.InsertionOrders;
        const useToken = tableLevel.value === TableLevel.Campaigns;

        if (dateRange) {
          let { start, end } = dateRange;
          const startDate = moment(start)
            .valueOf()
            .toString();
          const endDate = moment(end)
            .valueOf()
            .toString();

          let query = {
            startDate,
            endDate,
            timezoneId: timezone ? timezone.id : null,
            owIds: advertisersOwIds,
            campaignStatus: status.value,
            creativeTypeIds: selectedCreativeTypes.map((c) => c.value).join(','),
            searchField: search,
            resultType: 'total',
            ...(useCampaignIds
              ? {
                  campaignIds:
                    selectedCampaigns && selectedCampaigns.length
                      ? selectedCampaigns.join(',')
                      : undefined,
                  token: `${auth.userData.apiToken}`,
                }
              : {}),
            ...(useToken
              ? {
                  token: `${auth.userData.apiToken}`,
                }
              : {}),
            ...((tableLevel.value === TableLevel.InsertionOrders ||
              tableLevel.value === TableLevel.Campaigns) &&
            !selectedCampaigns?.length
              ? { ioIdsList: selectedInsertionOrders, budgetTypeIdList: ioBudgetTypes }
              : {}),
            ...((tableLevel.value === TableLevel.Campaigns ||
              tableLevel.value === TableLevel.Advertisers ||
              tableLevel.value === TableLevel.Workspaces) &&
            campaignTypeIds.length
              ? { campaignTypeIds }
              : {}),
          };

          lambdaV3API.common.cancelGetAdvertisersStatistics();
          lambdaV3API.common.cancelGetWorkspacesStatistics();
          lambdaV3API.common.cancelGetCampaignStatistics();
          lambdaV3API.common.cancelGetExchangesStatistics();
          lambdaV3API.common.cancelGetInsertionOrdersStatistics();

          let response;
          switch (tableLevel.value) {
            case TableLevel.Workspaces:
              response = await lambdaV3API.common.getWorkspacesStatistics(query);
              break;
            case TableLevel.Advertisers:
              response = await lambdaV3API.common.getAdvertisersStatistics(query);
              break;
            case TableLevel.Exchanges:
              response = await lambdaV3API.common.getExchangesStatistics(query);
              break;
            case TableLevel.InsertionOrders:
              response = await lambdaV3API.common.getInsertionOrdersStatistics(query);
              break;
            default:
              response = await lambdaV3API.common.getCampaignStatistics(query);
              break;
          }

          if (andSetTotal) {
            dispatch(setNewTotal(response.data.total));
          } else {
            dispatch(createAction<void>(statisticsConstants.GET_TOTAL__SUCCESS));
          }

          return get(response, 'data.recordsTotal', null);
        }
      } catch (e) {
        const errorResponse = get(e, 'response.data', null);

        if (!axios.isCancel(errorResponse)) {
          dispatch(createAction(statisticsConstants.GET_TOTAL__ERROR, e));
          dispatch(
            createAction(
              toastConstants.TOAST_OPEN,
              'Something went wrong. Please try again over a few minutes.',
            ),
          );
        }
      }
    };
  },
  getStatisticsTotalStart() {
    return createAction(statisticsConstants.GET_TOTAL__START);
  },
  getStatisticsTotalError(err: CancellarError<any>) {
    return createAction(statisticsConstants.GET_TOTAL__ERROR, err);
  },
};

export interface SetNewTotal {
  setNewTotal: (total: LambdaResponse) => void;
}

export interface GetTotal {
  getTotal: (andSetTotal?: boolean) => Promise<LambdaResponse>;
}

export interface GetStatisticsTotalStart {
  getStatisticsTotalStart: () => void;
}

export interface GetStatisticsTotalError {
  getStatisticsTotalError: (err: CancellarError<any>) => void;
}
