import React from 'react';
import { connect } from 'react-redux';
import { Icon, Tooltip } from 'factor';
import { getPathNumber } from 'iqm-framework';

import { Campaign } from '../../../../../../../models/Campaign';
import { AppState } from '../../../../../../../store';
import { INSIGHTS_URL } from '../../../../../../../constants/url';
import styles from './styles.module.scss';

interface Props {
  selectedCampaigns: Campaign[];
  isAppAllowed: boolean;
  isAdvertiserUser: boolean;
  isVldEnabled: boolean;
  hadVldGenerated: boolean;
}

const VLDInsightsItemComponent = (props: Props) => {
  const {
    selectedCampaigns,
    isAppAllowed,
    isAdvertiserUser,
    isVldEnabled,
    hadVldGenerated,
  } = props;

  const isSingleSelect = selectedCampaigns.length === 1;

  const handleClick = () => {
    const selectedCampaign = selectedCampaigns[0];
    const link = `${INSIGHTS_URL}/u/${getPathNumber()}/#/voter-level-data/create?campaignId=${
      selectedCampaign.campaignId
    }`;
    window.open(link, '_blank');
  };

  if (!isAppAllowed || !isAdvertiserUser || (!isVldEnabled && !hadVldGenerated)) {
    return null;
  }

  const hadVldToggledOff = !isVldEnabled && hadVldGenerated;

  const tooltip = (() => {
    if (hadVldToggledOff) {
      return 'Voter Level Data generation is disabled for your account.';
    }

    return null;
  })();

  const btn = (
    <button
      className={`btn-select__btn ${styles.listBtn}`}
      onClick={handleClick}
      disabled={
        !isSingleSelect || hadVldToggledOff || !selectedCampaigns[0].isEligibleForVLDInsightsReport
      }
    >
      <Icon name="Insights" className={styles.icon} />
      Generate VLD Insights
    </button>
  );

  return tooltip ? (
    <Tooltip portal auto label={tooltip}>
      {btn}
    </Tooltip>
  ) : (
    btn
  );
};

const mapState = (state: AppState) => ({
  userData: state.auth.userData,
  selectedCampaigns: state.table.selectedTableCampaigns,
  isAdvertiserUser:
    !state.auth.userData.isPlatformOwnerOrg && !state.auth.userData.isWorkspaceOwnerOrg,
  isVldEnabled: state.auth.userData.isVldEnabled,
  hadVldGenerated: state.auth.userData.hadVldGenerated,
});

export const VLDInsightsItem = connect<any, any, any, any>(
  mapState,
  null,
)(VLDInsightsItemComponent);
