import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Select, EmptyDropdownLabel } from 'factor';
import { connect } from 'react-redux';
import { Canceler } from 'axios';
import { debounce, get } from 'lodash';
import { API } from '../../../../../../../../api';
import { LoadingStatus } from '../../../../../../../../models/LoadingStatus';
import { ListCampaign } from '../../../../../../../../models/Campaign';
import { SelectCampaignsWrapperOption } from '../../../../../../../../components/SelectCampaignsWrapperOption/SelectCampaignsWrapperOption';
import { AppState } from '../../../../../../../../store';
import { OptionIDWithPayload } from '../../../../../../../../models/Option';

type CampaignOption = ListCampaign | OptionIDWithPayload<undefined>;

interface Props {
  selectedCampaigns: CampaignOption[];
  setSelectedCampaigns: (campaigns: CampaignOption[]) => void;
  ioId: number;
  isPlatformOwnerOrg: boolean;
  isWorkspaceOwnerOrg: boolean;
  className?: string;
}

export const SelectCampaignsComponent = (props: Props) => {
  const {
    selectedCampaigns,
    setSelectedCampaigns,
    ioId,
    isPlatformOwnerOrg,
    isWorkspaceOwnerOrg,
    className,
  } = props;
  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.PENDING);
  const [options, setOptions] = useState<CampaignOption[]>([]);
  const [searchField, setSearchField] = useState('');
  const apiCancelToken = useRef<Canceler | null>(null);
  const isInitialLoad = useRef(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSearchFieldDebounced = useCallback(
    debounce((searchStr: string) => {
      setSearchField(searchStr);
    }, 500),
    [],
  );

  const changeSearchHandler = (searchStr: string) => {
    if (searchStr !== searchField) {
      setLoading(LoadingStatus.LOADING);
      setSearchFieldDebounced(searchStr);
    }
  };

  useEffect(() => {
    const fetchResults = async () => {
      try {
        setLoading(LoadingStatus.LOADING);

        if (apiCancelToken.current) {
          apiCancelToken.current('Cancelled by the user');
        }
        const results = await API.campaigns.getCampaignsDropdownList(
          {
            ioIdsList: [ioId],
            searchField,
            pageNo: -1,
          },
          (ct: any) => (apiCancelToken.current = ct),
        );

        const campaignsArr = get(results, 'data.data', []);
        const campaignOptions = campaignsArr.map((cmp) => ({
          ...cmp,
          label: cmp.name,
          value: cmp.id,
          reactLabel: (
            <SelectCampaignsWrapperOption
              searchField=""
              campaign={cmp}
              showOrganization={isPlatformOwnerOrg || isWorkspaceOwnerOrg}
            />
          ),
        }));

        setOptions(campaignOptions);

        if (isInitialLoad.current) {
          setSelectedCampaigns(campaignOptions);
        }
        isInitialLoad.current = false;

        setLoading(LoadingStatus.PENDING);
      } catch (err) {
        isInitialLoad.current = false;
        setLoading(LoadingStatus.ERROR);
      }
    };
    fetchResults();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchField]);

  const handleSelection = (selectedOptions: CampaignOption[], clickedOption: CampaignOption) => {
    if (!clickedOption) {
      setSelectedCampaigns(selectedOptions);
    } else {
      if (selectedCampaigns.find((opt) => opt.id === clickedOption.id)) {
        setSelectedCampaigns(selectedCampaigns.filter((cmp) => cmp.id !== clickedOption.id));
      } else {
        setSelectedCampaigns([...selectedCampaigns, clickedOption]);
      }
    }
  };

  return (
    <Select
      className={className || ''}
      placeholder="Select Campaigns"
      searchPlaceholder="Search by ID, Name, Status"
      label="Campaigns"
      isSearchable={!isInitialLoad.current}
      isSearchClearable
      searchByValue
      isMulti
      isClearable={selectedCampaigns && selectedCampaigns.length}
      hideClearAllButton={!selectedCampaigns || !selectedCampaigns.length}
      allSelectable
      value={selectedCampaigns}
      options={loading !== LoadingStatus.LOADING ? options : []}
      onChange={handleSelection}
      showLabelAlways
      emptyDropdownLabel={
        <EmptyDropdownLabel
          searchTerm={searchField}
          isLoading={loading === LoadingStatus.LOADING}
          isError={loading === LoadingStatus.ERROR}
        />
      }
      changeSearchHandler={changeSearchHandler}
      virtualizationProps={{
        height: 300,
        width: 500,
        itemSize: 50,
        overscanCount: 5,
      }}
      showControlLabel
      withCheckbox
      tooltipParams={{
        label: 'Search and filter data by the specific Campaigns',
      }}
      hideSelectAllWhenEmpty
    />
  );
};

const mapState = (state: AppState) => ({
  isPlatformOwnerOrg: state.auth.userData.isPlatformOwnerOrg,
  isWorkspaceOwnerOrg: state.auth.userData.isWorkspaceOwnerOrg,
});

export const SelectCampaigns = connect<any, any, any, any>(
  mapState,
  null,
)(SelectCampaignsComponent);
