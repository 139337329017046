import axios, { Canceler, AxiosResponse } from 'axios';
import { getInstance } from './index';

let cancel: Canceler;

interface Params {
  advertiserId: number;
  dspId: number;
}

export function getCampaignsList(params: Params): Promise<AxiosResponse> {
  if (cancel) {
    cancel('Canceled by the user');
  }
  return getInstance()
    .get('/campaigns', {
      params,
      cancelToken: new axios.CancelToken((c) => {
        cancel = c;
      }),
    })
    .then((response) => response.data)
    .catch((err) => Promise.reject(err));
}
