import moment from 'moment';
import { EpochDateRangePicker } from 'iqm-framework';
import { OptionID } from '../../../../models/Option';

interface PreselectedValues {
  key: string;
  title: string;
  start: (tz: string) => moment.Moment;
  end: (tz: string) => moment.Moment;
}

export const PRESELECTED: PreselectedValues[] = [
  EpochDateRangePicker.PRESELECT_KEYS.TODAY,
  EpochDateRangePicker.PRESELECT_KEYS.YESTERDAY,
  EpochDateRangePicker.PRESELECT_KEYS.LAST_WEEK,
  EpochDateRangePicker.PRESELECT_KEYS.LAST_MONTH,
  EpochDateRangePicker.PRESELECT_KEYS.LAST_THREE_MONTHS,
];

export const getCalendarMin = (timezone: OptionID | null) => {
  if (timezone) {
    const activeTimezoneCurrentDate = moment.tz(moment().utc(), 'UTC').tz(timezone.value);
    const activeTimezoneStartDate = activeTimezoneCurrentDate
      .clone()
      .add(-2, 'year')
      .startOf('day');
    const calendarMinimumDate = moment.tz(
      activeTimezoneStartDate.format('YYYY-MM-DD HH:mm:ss'),
      moment.tz.guess(),
    );

    return calendarMinimumDate;
  }

  return null;
};

export const getCalendarMax = (timezone: OptionID | null) => {
  if (timezone) {
    const activeTimezoneCurrentDate = moment.tz(moment().utc(), 'UTC').tz(timezone.value);
    const activeTimezoneEndDate = activeTimezoneCurrentDate.clone().endOf('day');
    const calendarMaximumDate = moment.tz(
      activeTimezoneEndDate.format('YYYY-MM-DD HH:mm:ss'),
      moment.tz.guess(),
    );

    return calendarMaximumDate;
  }

  return null;
};

export const getDatesOnMinMaxUpdate = (
  startDate: number,
  endDate: number,
  minDate: Date,
  maxDate: Date,
) => {
  let newStart = startDate;
  let newEnd = endDate;
  if (endDate > maxDate.valueOf()) {
    newEnd = maxDate.valueOf();
    if (newStart > newEnd) {
      newStart = moment(newStart)
        .add(-1, 'day')
        .valueOf();
    }
  } else if (startDate < minDate.valueOf()) {
    newStart = minDate.valueOf();
    if (newStart > newEnd) {
      newEnd = moment(newEnd)
        .add(1, 'day')
        .valueOf();
    }
  }
  return { startDate: newStart, endDate: newEnd };
};

export const getDefaultDateRange = () => {
  return {
    getStartDate: (tz) => {
      const startDate = moment
        .tz(moment().utc(), 'UTC')
        .tz(tz)
        .startOf('day');
      return moment.tz(startDate.format('YYYY-MM-DD HH:mm:ss'), moment.tz.guess());
    },
    getEndDate: (tz) => {
      const startDate = moment
        .tz(moment().utc(), 'UTC')
        .tz(tz)
        .endOf('day');
      return moment.tz(startDate.format('YYYY-MM-DD HH:mm:ss'), moment.tz.guess());
    },
  };
};
