import { getInstance } from './index';
import { StatisticsTotalResult } from '../models/Statistics';

export function getTotalResult(params: any): Promise<StatisticsTotalResult> {
  return getInstance()
    .get('/campaigns/results', {
      params,
    })
    .then((response) => response.data)
    .catch((err) => Promise.reject(err.response.data));
}
