import * as React from 'react';
import { Tooltip } from 'factor';

interface Props {
  label?: string;
  portal?: boolean;
  auto?: boolean;
  children: React.ReactElement;
}

export const AutoTooltip = (props: Props) => {
  const { children, label, ...rest } = props;

  return !!label ? (
    <Tooltip label={label} {...rest}>
      {children}
    </Tooltip>
  ) : (
    children || null
  );
};
