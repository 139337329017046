import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import { auth, AuthState } from './auth2/reducer';
import { app, AppState as IAppState } from './app/reducers';
import { statistics, StatisticsState } from './statistics/reducers';
import { table, TableState } from './table/reducers';
// import { advertisers, AdvertisersState } from './advertisers/reducers';
import { filter, FilterState } from './filter/reducers';
import { toast, ToastState } from './toast/reducers';
import { reports, ReportsState } from './reports/reducers';
import { dashboards, DashboardState } from './dashboards/reducers';

// export interface AppState {
//   auth: AuthState,
//   profile: ProfileState,
//   statistics: StatisticsState,
//   table: TableState,
//   advertisers: AdvertisersState,
//   filter: FilterState,
// }

export interface AppState {
  app: IAppState;
  auth: AuthState;
  filter: FilterState;
  statistics: StatisticsState;
  table: TableState;
  toast: ToastState;
  reports: ReportsState;
  dashboards: DashboardState;
}

const reducer = combineReducers({
  auth,
  app,
  statistics,
  table,
  // advertisers,
  filter,
  toast,
  reports,
  dashboards,
});

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore<any, any, any, any>(
  reducer,
  composeEnhancers(applyMiddleware(thunk)),
);
