import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Query, useQuery } from '@tanstack/react-query';

import { getReportDataApiParameters } from '../store/reports/selectors';
import { lambdaV3API } from '../api/lambdaV3API';
import { getCampaignReportDataKeys, getReportDataKeys } from '../api/QueryKeys';
import { AppState } from '../store';
import { createAction } from '../utils/actions';
import { toastConstants } from '../store/toast/constants';
import { TableLevel } from '../models/Table';
import {
  GetCampaignReportDataParameters,
  GetReportDataParameters,
} from '../api/lambdaV3API/ReportsGraphData';
import { GENERAL_API_ERROR } from '../constants/tooltips';
import { LoadingStatus } from '../models/LoadingStatus';
import { queryClient } from '../cache';
import { promisifiedDebounce } from '../utils/debounce';
import { LAMBDA_DEBOUNCE } from '../constants/debounce';

export const useReportData = () => {
  const parameters = useSelector(getReportDataApiParameters);
  const isSidebarOpen = useSelector((state: AppState) => state.app.sidebarOpened);
  const isCustomersDropdownLoaded = useSelector(
    (state: AppState) => state.filter.isCustomersDropdownLoaded,
  );
  const isDimensionsMetricsLoading = useSelector(
    (state: AppState) => state.reports.dimensionsMetricsLoading === LoadingStatus.LOADING,
  );
  const dimension = useSelector((state: AppState) => state.reports.dimension?.value);

  const isCampaignReport =
    dimension !== TableLevel.Advertisers && dimension !== TableLevel.Workspaces;

  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCampaignReports = React.useCallback(
    promisifiedDebounce(
      lambdaV3API.reportsGraph.getCampaignReportsGraphData,
      LAMBDA_DEBOUNCE,
      () => {
        queryClient.cancelQueries({
          predicate: (query: Query<any, any, any, any>) =>
            query.queryKey?.[0]?.scope === 'getCampaignReportData',
        });
      },
    ),
    [],
  ) as typeof lambdaV3API.reportsGraph.getCampaignReportsGraphData;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedReports = React.useCallback(
    promisifiedDebounce(lambdaV3API.reportsGraph.getReportsGraphData, LAMBDA_DEBOUNCE),
    [],
  ) as typeof lambdaV3API.reportsGraph.getReportsGraphData;

  return useQuery({
    // @ts-ignore
    queryFn: isCampaignReport ? debouncedCampaignReports : debouncedReports,
    queryKey: isCampaignReport
      ? getCampaignReportDataKeys.keys({
          scope: 'getCampaignReportData',
          dimension: dimension!,
          ...(parameters as GetCampaignReportDataParameters),
        })
      : getReportDataKeys.keys({
          scope: 'getReportData',
          dimension: dimension!,
          ...(parameters as GetReportDataParameters),
        }),
    enabled:
      !isDimensionsMetricsLoading &&
      !!dimension &&
      !!parameters &&
      !!isSidebarOpen &&
      isCustomersDropdownLoaded,
    onError: (e) => {
      const errorMsg = e?.response?.data?.errorObjects?.[0]?.error || GENERAL_API_ERROR;
      dispatch(createAction(toastConstants.TOAST_OPEN, errorMsg));
    },
    cacheTime: 5 * 60 * 1000,
    select:
      dimension === TableLevel.Advertisers || dimension === TableLevel.Workspaces
        ? (data) =>
            data.map((item) => ({
              ...item,
              platformEarnings: item.platformMediaEarning,
              workspaceEarnings: item.workspaceMediaEarning,
            }))
        : undefined,
  });
};
