import axios, { AxiosError, AxiosResponse, Canceler } from 'axios';
import get from 'lodash/get';
import { WithResponse } from '../models/Response';
import { getInstance } from './index';
import { IOBudgetActions, InsertionOrder, InsertionOrderBasic } from '../models/InsertionOrder';

export interface GetInsertionOrderListParams {
  owIdList: number[];
  ioIdList: number[];
  searchField: string;
  ioBudgetTypeIdsList?: number[];
  ioStatusIdsList?: number[];
  sortBy?: string;
}

interface GetInsertionOrderListResponse {
  success: boolean;
  data: {
    totalRecords: number;
    filteredRecords: number;
    ioBasicDetailsList: InsertionOrderBasic[];
  };
}

let ioListCancel: Canceler;
export function cancelGetInsertionOrderList() {
  if (ioListCancel) {
    ioListCancel('Canceled by the user');
  }
}

export const getInsertionOrderList = async (
  params: GetInsertionOrderListParams,
): Promise<GetInsertionOrderListResponse> => {
  try {
    const res = await getInstance().post('/v3/cmp/io/basic/list', {
      params,
      cancelToken: new axios.CancelToken((c) => {
        ioListCancel = c;
      }),
    });
    console.log(res);
    return res.data;
  } catch (err) {
    return Promise.reject(err as AxiosError);
  }
};

export interface IOCampaignsParams {
  owIds?: number[];
  status?: string[];
  creativeTypeIds?: number[];
  ioIds?: number[];
  campaignTypeIds?: number[];
  ioBudgetTypeIds?: number[];
}

interface IOCampaignsResponse {
  ioCampaignsList: InsertionOrder[];
  totalRecords: number;
  filteredRecords: number;
}

let ioCampaignsCancel: Canceler;
export function cancelGetIOCampaigns() {
  if (ioCampaignsCancel) {
    ioCampaignsCancel('Canceled by the user');
  }
}

export async function getIOCampaigns(
  params: IOCampaignsParams,
): Promise<WithResponse<IOCampaignsResponse>> {
  cancelGetIOCampaigns();
  return getInstance()
    .post(
      '/v3/cmp/io/campaign/basic/list',
      { ...params, pageNo: -1 },
      {
        cancelToken: new axios.CancelToken((c) => {
          ioCampaignsCancel = c;
        }),
      },
    )
    .then((response) => response.data)
    .catch((err) => Promise.reject(err));
}

let ioCampaignsSearchCancel: Canceler;
export function cancelGetIOCampaignsSearch() {
  if (ioCampaignsSearchCancel) {
    ioCampaignsSearchCancel('Canceled by the user');
  }
}

export async function getIOCampaignsSearch(
  params: IOCampaignsParams & { searchField: string },
): Promise<WithResponse<IOCampaignsResponse>> {
  cancelGetIOCampaignsSearch();
  return getInstance()
    .post(
      '/v3/cmp/io/campaign/basic/list',
      { ...params, pageNo: -1 },
      {
        cancelToken: new axios.CancelToken((c) => {
          ioCampaignsSearchCancel = c;
        }),
      },
    )
    .then((response) => response.data)
    .catch((err) => Promise.reject(err));
}

export interface UpdateEndTimeParams {
  ioIdsList: number[];
  ioEndTime: number;
  ioTimezoneId?: number;
}

export const updateEndTime = async (params: UpdateEndTimeParams): Promise<WithResponse<string>> => {
  try {
    const response: AxiosResponse<WithResponse<string>> = await getInstance().patch(
      '/v3/cmp/io/update-end-date',
      params,
    );
    return response.data;
  } catch (err) {
    return Promise.reject(get(err, 'response', err));
  }
};

export interface UpdateBudgetParams {
  ioIdsList: number[];
  budget?: number;
  budgetUpdateType: IOBudgetActions;
  ioOwId?: number;
  ioTotalImpressions?: number;
  ioBudgetTypeId?: number;
}

export const updateBudget = async (params: UpdateBudgetParams) => {
  try {
    const response: AxiosResponse<WithResponse<string>> = await getInstance().patch(
      '/v3/cmp/io/update-budget',
      params,
    );
    return response.data;
  } catch (err) {
    return Promise.reject(get(err, 'response', err));
  }
};

interface DeleteIOParams {
  ioIdsList: number[];
}

export const deleteIO = async (params: DeleteIOParams) => {
  try {
    const response: AxiosResponse<WithResponse<string>> = await getInstance().post(
      '/v3/cmp/io/delete',
      params,
      {
        headers: { Referer: 'https://iqm.stage.applift.com/' },
      },
    );
    return response.data;
  } catch (err) {
    return Promise.reject(get(err, 'response', err));
  }
};

interface DuplicateIOParams {
  ioExistingCampaignIds: number[];
  ioId: number;
  ioTimeZoneId: number;
  ioStartTime: number;
  ioEndTime?: number;
}

interface DuplicateIOResponse {
  duplicatedIOId: number;
  duplicatedCampaignsIds: number[];
}

export const duplicateIO = async (params: DuplicateIOParams) => {
  try {
    const response: AxiosResponse<WithResponse<DuplicateIOResponse>> = await getInstance().post(
      '/v3/cmp/io/duplicate',
      params,
    );
    return response.data;
  } catch (err) {
    return Promise.reject(get(err, 'response', err));
  }
};
