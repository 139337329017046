import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Dialog, DialogHeader, DialogFooter, DialogContent, Spinner, Button, Icon } from 'factor';
import {
  TimezoneProvider,
  TimezonePicker,
  IOBudgetTypeMapper,
  EpochDatePicker,
  IO_STATUS_ID,
} from 'iqm-framework';
import { ListCampaign } from '../../../../../../../models/Campaign';
import { OptionID, OptionIDWithPayload } from '../../../../../../../models/Option';
import { ChangingStatus } from '../../../../../../../models/LoadingStatus';
import { AppState } from '../../../../../../../store';
import { Open, toastActions } from '../../../../../../../store/toast/actions';
import { SelectCampaigns } from './SelectCampaigns';
import { API } from '../../../../../../../api';
import styles from './styles.module.scss';

type CampaignOption = ListCampaign | OptionIDWithPayload<undefined>;

interface StateProps {
  timezones: OptionID[];
}

interface DispatchProps {
  openToast: Open['open'];
}

interface OwnProps {
  onClose: () => void;
  onDuplicateSuccess: () => void;
  insertionOrder: {
    ioId: number;
    ioName: string;
    ioTimezone: number;
    ioStartTime: number;
    ioEndTime: number | null;
    ioBudgetTypeId: number;
  };
}

interface Props extends StateProps, DispatchProps, OwnProps {}

export const DuplicateInsertionOrderDialogComponent = (props: Props) => {
  const { onClose, insertionOrder = {} as any, timezones, openToast, onDuplicateSuccess } = props;
  const [loading, setLoading] = useState<ChangingStatus>(null);
  const [selectedCampaigns, setSelectedCampaigns] = useState<CampaignOption[]>([]);
  const [timezone, setTimezone] = useState<OptionID | null>(null);
  const [ioStartTime, _setIoStartTime] = useState<number | null>(
    typeof insertionOrder.ioStartTime === 'number' &&
      moment(insertionOrder.ioStartTime).isAfter(moment())
      ? insertionOrder.ioStartTime
      : moment()
          .add(30, 'm')
          .second(0)
          .millisecond(0)
          .valueOf(),
  );
  const ioStartTimeDebug = useRef<number | null>(null);
  const [ioEndTime, _setIoEndTime] = useState<number | null | undefined>();
  const ioEndTimeDebug = useRef<number | null>(null);
  const { icon: budgetIcon } = IOBudgetTypeMapper[insertionOrder.ioBudgetTypeId] || {};

  useEffect(() => {
    if (ioStartTime === null && ioStartTimeDebug.current) {
      _setIoStartTime(ioStartTimeDebug.current);
      ioStartTimeDebug.current = null;
    }
  }, [ioStartTime]);

  const setIoStartTime = (time: number) => {
    ioStartTimeDebug.current = time;
    _setIoStartTime(null);
  };

  useEffect(() => {
    if (ioEndTime === null && ioEndTimeDebug.current) {
      _setIoEndTime(ioEndTimeDebug.current);
      ioEndTimeDebug.current = null;
    }
  }, [ioEndTime]);

  const setIoEndTime = (time: number) => {
    ioEndTimeDebug.current = time;
    _setIoEndTime(null);
  };

  useEffect(() => {
    if (insertionOrder) {
      const tz = timezones.find((tz) => tz.id === insertionOrder.ioTimezone) || null;
      setTimezone(tz);
    }
  }, [timezones, insertionOrder]);

  const handleSetStartTime = (startTime: number) => {
    const isBeforeEndTime =
      !ioEndTime ||
      moment(startTime)
        .add(30, 'm')
        .isBefore(moment(ioEndTime));
    const isInFuture = moment()
      .add(30, 'm')
      .isBefore(moment(startTime));
    let errorToast = '';

    if (!isInFuture) {
      setIoStartTime(
        moment()
          .add(30, 'm')
          .second(0)
          .millisecond(0)
          .valueOf(),
      );
      errorToast = 'Start time must be at least 30 minutes in the future and was corrected.';
    } else if (!isBeforeEndTime) {
      setIoStartTime(startTime);
      setIoEndTime(
        moment(startTime)
          .add(30, 'm')
          .valueOf(),
      );
      errorToast =
        'Start time and end time should have at least 30 minutes difference and end time was corrected.';
    } else {
      setIoStartTime(startTime);
    }

    if (errorToast) {
      openToast(errorToast);
    }
  };

  const handleSetEndTime = (endTime: number) => {
    const isAfterStartTime = moment(ioStartTime)
      .add(30, 'm')
      .isBefore(moment(endTime));
    let errorToast = '';
    if (!isAfterStartTime) {
      errorToast =
        'Start time and end time should have at least 30 minutes difference and end time was corrected.';
      setIoEndTime(
        moment(ioStartTime)
          .add(30, 'm')
          .valueOf(),
      );
    } else {
      setIoEndTime(endTime);
    }

    if (errorToast) {
      openToast(errorToast);
    }
  };

  const handleDuplicate = async () => {
    if (!timezone) {
      return;
    }

    try {
      setLoading('loading');
      await API.InsertionOrder.duplicateIO({
        ioExistingCampaignIds: selectedCampaigns.map((cmp) => cmp.id),
        ioId: insertionOrder.ioId,
        ioTimeZoneId: timezone.id,
        ioStartTime: ioStartTime as number,
        ioEndTime: ioEndTime || undefined,
      });
      setLoading('success');
      openToast(`${insertionOrder.ioName} IO duplicated successfully.`);
      onDuplicateSuccess();
    } catch (err) {
      setLoading('fail');
      openToast(`${insertionOrder.ioName} IO failed to duplicate.`);
    }
  };

  const handleTimezoneChange = (tz: OptionID) => {
    setTimezone(tz);
    setIoStartTime(ioStartTime as number);
    if (ioEndTime) {
      setIoEndTime(ioEndTime);
    }
  };

  return (
    <Dialog open onClickOutside={onClose} className={styles.dialog}>
      <DialogHeader headerFooterBorders onClickClose={onClose}>
        <h3 className="title-card">Duplicate Insertion Order</h3>
      </DialogHeader>
      <DialogContent>
        {loading ? (
          <div className="ml-auto mr-auto">
            <Spinner status={loading} onClose={onClose} />
          </div>
        ) : (
          <div className={styles.dialogContent}>
            <div className={styles.bodyText}>
              Duplicate the <Icon name={budgetIcon} className={styles.budgetIcon} />{' '}
              <span className={styles.demi}>{insertionOrder.ioName}</span> insertion order
            </div>

            <div className={`row g-0 mb-4 ${styles.inputs}`}>
              <TimezoneProvider timezone={timezone}>
                <div className="col-3 pl-0">
                  <EpochDatePicker
                    singleDate={ioStartTime}
                    onDateChanged={handleSetStartTime}
                    calendarMinimumDate={moment()}
                    label="IO Start Date"
                    placeholder="IO Start Date"
                    dateFormat="MM/DD/YYYY hh:mm A"
                    datePickerProps={{
                      numberOfCalendars: 1,
                    }}
                    withTimePicker
                    singleDateMode
                  />
                </div>
                <div className="col-3">
                  <EpochDatePicker
                    singleDate={ioEndTime}
                    onDateChanged={handleSetEndTime}
                    calendarMinimumDate={moment()}
                    label="IO End Date"
                    placeholder={`Enter End Date${
                      insertionOrder.ioStatusId === IO_STATUS_ID.EXPIRED ? '' : ' (Optional)'
                    }`}
                    dateFormat="MM/DD/YYYY hh:mm A"
                    datePickerProps={{
                      numberOfCalendars: 1,
                    }}
                    withTimePicker
                    singleDateMode
                  />
                </div>
                <div className={`col-3 ${styles.timezonePickerCol}`}>
                  <TimezonePicker onTimezoneChange={handleTimezoneChange} label="IO Time Zone" />{' '}
                </div>
                <div className="col-3">
                  <SelectCampaigns
                    ioId={insertionOrder.ioId}
                    selectedCampaigns={selectedCampaigns}
                    setSelectedCampaigns={setSelectedCampaigns}
                    className={styles.selectCampaigns}
                  />
                </div>
              </TimezoneProvider>
            </div>
            <div className={styles.info}>
              <Icon name="Info" className={styles.infoIcon} />
              <div>All the campaigns that are selected will be added as draft campaigns.</div>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogFooter headerFooterBorders>
        <div className="d-flex justify-content-end">
          <Button className="_md btn-square _conflower-blue mr-3" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={
              !timezone || (insertionOrder.ioStatusId === IO_STATUS_ID.EXPIRED && !ioEndTime)
            }
            onClick={handleDuplicate}
            className="_md btn-square _conflower-blue _filled"
          >
            Duplicate
          </Button>
        </div>
      </DialogFooter>
    </Dialog>
  );
};

const mapState = (state: AppState) => ({
  timezones: state.filter.timezones,
});

const mapAction = {
  openToast: toastActions.open,
};

export const DuplicateInsertionOrderDialog = connect<any, any, any, any>(
  mapState,
  mapAction,
)(DuplicateInsertionOrderDialogComponent);
