import React from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import { EditableText, Icon, Tooltip } from 'factor';
import cloneDeep from 'lodash/cloneDeep';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import moment, { Moment } from 'moment';
import {
  TableComponent as KTable,
  TableHeaderMapping,
  IOBudgetTypeMapper,
  IO_BUDGET_TYPE_ID,
  IO_STATUS_ID,
} from 'iqm-framework';
import { EM_DASH } from '../../../../constants/text';
import { DialogAmountContentWrapper } from './table/DialogAmountContentWrapper';
import { ConfirmSortingDialog } from '../tableWrapper/filters/selectedCampaigns/components/ConfirmSortingDialog';
import {
  SetCampaigns,
  tableActions,
  UpdateCampaignName,
  UpdateCampaignsBudget,
  UpdateCampaignsList,
  SetTotalItems,
  AddCampaignAudienceWarnings,
  SetSelectedInsertionOrders,
  UpdateInsertionOrdersEndTime,
  UpdateInsertionOrdersBudget,
} from '../../../../store/table/actions';
import { AppState } from '../../../../store';
import { TableLevel } from '../../../../store/filter/reducers';
import { filterActions, UpdateFreeze } from '../../../../store/filter/actions';
import { toastActions } from '../../../../store/toast/actions';
import {
  Option,
  OptionID,
  ivtClicksCol,
  ivtImpressionsCol,
  platformVldInsightsEarningsCol,
  vldInsightsCostCol,
  workspaceVldInsightsEarningsCol,
} from '../../../../models/Option';
import { AudienceWarning } from '../../../../models/Audience';
import {
  CAMPAIGN_AUDIENCE_SOME_UNAPPROVED,
  CAMPAIGN_AUDIENCE_ALL_UNAPPROVED,
  AUTO_SUM_IO_BUDGET_WARNING_SINGLE,
  IMPRESSION_IO_BUDGET_WARNING_SINGLE,
  IMPRESSION_CAMPAIGN_BUDGET_WARNING_SINGLE,
  PG_CAMPAIGN_TOTAL_BUDGET_WARNING,
} from '../../../../constants/tooltips';
import { InsertionOrderNameCell } from './cellTypes/InsertionOrderNameCell';
import { InsertionOrderStatusCell } from './cellTypes/InsertionOrderStatusCell';
import { CreativesCountCell } from './cellTypes/CreativesCountCell';
import {
  CampaignPacingHeaderTooltip,
  DailyPacingHeaderTooltip,
  IOPacingHeaderTooltip,
} from './cellTypes/BudgetPacingCell/HeaderTooltip';

import { CurrencyFormat, DateFormat, NumberFormat } from '../../../../utils/format';
import { pluralize } from '../../../../utils/pluralize';
import {
  statisticsActions,
  SetNewTotal,
  GetTotal,
  GetStatisticsTotalError,
} from '../../../../store/statistics/actions';
import { LambdaResponse, TableResponse } from '../../../../models/Response';
import { ToastContent } from '../../../../components/toastContent/ToastContent';
import { TableSortingParams } from '../../../../models/Table';
import { CampaignType, campaignTypeIconMap, statusIconMap } from '../../../../models/Campaign';
import { DataDogLogger } from '../../../../services/DataDog';
import { CAMPAIGN_BUDGET_TYPE } from '../../../../constants';
import { PARSE_TIME_FORMAT } from '../../../../constants/time';
import { IOBudgetActions } from '../../../../models/InsertionOrder';
import { BudgetLabelIcon } from '../../../../components/BudgetLabelIcon';
import { CreativeTypeIconMapper } from '../../../../constants/creatives';
import { ClickableCountCell } from './cellTypes/ClickableCountCell';
import { IconTextCell } from './cellTypes/IconTextCell';
import { DailyPacingCell } from './cellTypes/BudgetPacingCell/DailyPacingCell';
import { CampaignPacingCell } from './cellTypes/BudgetPacingCell/CampaignPacingCell';
import { IOPacingCell } from './cellTypes/BudgetPacingCell/IOPacingCell';
import './styles.scss';
import { LoggableTableSorting } from '../../../../services/DataDog/App';
import { LAMBDA_DEBOUNCE } from '../../../../constants/debounce';

const PARTLY_EDITING_STATUSES = ['expired', 'deleted'];

const IO_DATE_FORMAT = 'MM/DD/YYYY hh:mm A';

interface Props
  extends SetCampaigns,
    GetStatisticsTotalError,
    UpdateCampaignsBudget,
    UpdateFreeze,
    SetNewTotal,
    UpdateCampaignName,
    UpdateCampaignsList,
    GetTotal,
    SetTotalItems,
    AddCampaignAudienceWarnings,
    RouteComponentProps,
    UpdateInsertionOrdersEndTime,
    UpdateInsertionOrdersBudget {
  addSelectedCampaign: (data: LambdaResponse[]) => void;
  data: LambdaResponse[];
  headerMapper: {
    [key: string]: any;
  };
  rowKeyExtractor: (data: any, index: number) => string;
  changeLevel: (value: Option) => void;
  clearCampaignsData: () => void;
  isCampaigns: boolean;
  isWorkspaces: boolean;
  isAdvertisers: boolean;
  isExchanges: boolean;
  isInsertionOrders: boolean;
  selectedTableCampaigns: ({ campaignId: string } & any)[];
  filteredCampaigns: LambdaResponse[];
  skeleton: {
    rows: number;
    columns: number;
  };
  freezeRows: number;
  freezeColumns: number;
  idField: string;
  openToast: (message: string | JSX.Element) => void;
  closeToast: () => void;
  status: Option;
  advertiserId: number | null;
  sorting: TableSortingParams | null;
  updateSorting: (sorting: TableSortingParams) => void;
  currentTableLevel: TableLevel;
  isPlatformOwnerOrg: boolean;
  isVirtualizedListOpen: boolean;
  campaignAudienceWarnings: { [key: string]: AudienceWarning };
  isCustomersDropdownLoaded: boolean;
  selectedCampaigns: number[];
  openCampaignsByInsertionOrder: (ioId: number) => void;
  timezones: OptionID[];
  isWorkspaceOwnerOrg: boolean;
  setSelectedTableInsertionOrders: SetSelectedInsertionOrders['setSelectedInsertionOrders'];
  selectedTableInsertionOrders: LambdaResponse[];
  search: string;
  onOpenCreativesDialog: (cmp: LambdaResponse) => void;
  onOpenCampaignsDialog: (io: LambdaResponse) => void;
  ioBudgetTypes: number[];
}

interface State {
  newSorting: TableSortingParams | null;
  confirmSorting: boolean;
  timezoneMapper: Map<number, string>;
  isBreadcrumbHidden: boolean;
}

export let TableComponentInstance;

class TableComponent extends React.Component<Props, State> {
  breadcrumbObserver;

  onBreadcrumbChange = () => {
    this.setState({ isBreadcrumbHidden: !document.body.classList.contains('breadcrumb-added') });
  };

  constructor(props) {
    super(props);

    this.breadcrumbObserver = new MutationObserver(this.onBreadcrumbChange);
    this.breadcrumbObserver.observe(document.body, {
      subtree: false,
      childList: false,
      attributeFilter: ['class'],
    });

    this.state = {
      confirmSorting: false,
      newSorting: null,
      timezoneMapper: new Map<number, string>(),
      isBreadcrumbHidden: !document.body.classList.contains('breadcrumb-added'),
    };
  }

  componentDidMount() {
    const { search } = this.props.location;
    if (search && queryString.parse(search).action === 'changeStatus') {
      this.props.updateSorting({ field: 'campaignId', direction: 'desc' });
    }
    this.props.closeToast();
    if (this.props.timezones && this.props.timezones.length) {
      this.setState({
        timezoneMapper: new Map(this.props.timezones.map((tz) => [tz.id, tz.value])),
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (
      prevProps.timezones !== this.props.timezones &&
      this.props.timezones &&
      this.props.timezones.length
    ) {
      this.setState({
        timezoneMapper: new Map(this.props.timezones.map((tz) => [tz.id, tz.value])),
      });
    }
  }

  componentWillUnmount() {
    this.breadcrumbObserver.disconnect();
  }

  handleTableCampaignSelection = (data) => {
    this.props.addSelectedCampaign(data);
  };

  handleTableInsertionOrderSelection = (data) => {
    this.props.setSelectedTableInsertionOrders(data);
  };

  handleSetRef = (ref) => {
    TableComponentInstance = ref;

    if (!this.props.isCustomersDropdownLoaded && ref && !ref.state.loadingData) {
      ref.setState({ loadingData: true, loading: true });
    }
  };

  handleCampaignChange = (data: LambdaResponse[], total: LambdaResponse[]) => {
    const {
      setCampaigns,
      setNewTotal,
      updateCampaignsList,
      isWorkspaces,
      isAdvertisers,
      isExchanges,
      selectedCampaigns,
      currentTableLevel,
    } = this.props;
    if (
      !isWorkspaces &&
      !isAdvertisers &&
      !isExchanges &&
      currentTableLevel !== TableLevel.InsertionOrders
    ) {
      setCampaigns(data);

      if (!selectedCampaigns.length) {
        updateCampaignsList(data);
      }
    }

    setNewTotal(total[0]);
  };

  handleDataLoaded = async (response: TableResponse<LambdaResponse>) => {
    const {
      setTotalItems,
      isCampaigns,
      addCampaignAudienceWarnings,
      status,
      isInsertionOrders,
    } = this.props;
    const totalItems = get(
      response,
      isInsertionOrders || isCampaigns ? 'data.filteredRecords' : 'data.totalCount',
      null,
    );
    setTotalItems(totalItems);

    const statusValue = get(status, 'value', 'all');
    const responseData = get(response, 'data.recordsList', null);

    if (isCampaigns && responseData && (statusValue === 'all' || statusValue === 'running')) {
      addCampaignAudienceWarnings(responseData);
    }
  };

  handleFilterCampaigns = (data: LambdaResponse[]) => {
    const { filteredCampaigns } = this.props;
    const ids = filteredCampaigns.map((c) => c.campaignId);
    return data.filter((v) => ids.includes(v.campaignId));
  };

  confirmNewValue = async (budgetType, value, id, editableCampaign = null) => {
    const { updateCampaignsBudget, openToast, data, setCampaigns } = this.props;
    let toastMessage = `${this.toastName} has been changed`;

    try {
      const response = await updateCampaignsBudget({
        budgetType,
        value,
        id,
        editableCampaign,
        editType: 'inline',
      });
      if (get(response, 'responseObject.modified_data', null)) {
        const modifiedData = get(response, 'responseObject.modified_data', []).reduce(
          (acc, campaign) => {
            acc[campaign.campaignId] = {};
            for (let key in campaign) {
              acc[campaign.campaignId][key] = campaign[key];
            }
            return acc;
          },
          {},
        );
        const modifiedDataIds: string[] = Object.keys(modifiedData);
        data.forEach((campaignData) => {
          if (modifiedDataIds.includes(String(campaignData.campaignId))) {
            campaignData = Object.assign(campaignData, modifiedData[campaignData.campaignId]);
          }
          return campaignData;
        });

        setCampaigns(data);
      }

      if (!get(response, 'responseObject.status', null)) {
        const errorsMapper = response.responseObject.reason.reduce((acc, i) => {
          const foundIndex = acc.findIndex((a) => a.errorMessage === i.errorMessage);
          foundIndex > -1
            ? acc[foundIndex].id.push(i.id)
            : acc.push({
                errorMessage: i.errorMessage,
                id: [i.id],
              });
          return acc;
        }, []);

        const toastMessages = errorsMapper.map((e) => {
          const { errorMessage, id } = e;
          return `${pluralize('Campaing', id.length, {
            single: `Campaign with id ${id.join(', ')} is`,
            multiple: `Campaigns with id ${id.join(', ')} are`,
          })} not updated! ${errorMessage ? 'Reason: ' + errorMessage : ''}`;
        });

        openToast(<ToastContent messages={toastMessages} />);
      } else {
        openToast(toastMessage);
        this.props.getTotal().then((res) => {
          TableComponentInstance &&
            TableComponentInstance.setState({ totalData: [{ ...res, total: true }] });
        });
      }
    } catch (err) {
      let errorMessage = 'Error updating budget';
      const error = get(err, 'response.data.responseObject.errorMsg', (err as any).message || null);
      if (error) {
        errorMessage = error;
      }
      openToast(errorMessage);
    }
  };

  cellRenderWithEditTooltip = (
    campaignStatus: string,
    dataToRender: string | React.ReactNode,
    budgetTypeId?: number,
    disableTooltip: boolean = false,
  ) => {
    const wrapperClassName = 'flex-grow-1 w-100 d-flex align-items-center justify-content-end';

    if (PARTLY_EDITING_STATUSES.includes(campaignStatus) || disableTooltip) {
      return (
        <div className={wrapperClassName}>
          <BudgetLabelIcon budgetTypeId={budgetTypeId as any} className="td-content__text" />
          {dataToRender}
        </div>
      );
    }
    return (
      <Tooltip className={wrapperClassName} label="Edit" portal useTooltipWrapperDimensions>
        <BudgetLabelIcon budgetTypeId={budgetTypeId as any} className="td-content__text" />
        <span
          style={{ display: 'block', overflow: 'hidden', textOverflow: 'ellipsis' }}
          className="td-content__text"
        >
          {dataToRender}
        </span>
      </Tooltip>
    );
  };

  renderDisabledBudgetCell = (
    dataToRender: string,
    budgetTypeId: number,
    tooltipText: string,
    showTooltip = true,
  ) => {
    const wrapperClassName = 'flex-grow-1 w-100 d-flex align-items-center justify-content-end';

    return showTooltip ? (
      <Tooltip className={wrapperClassName} label={tooltipText} portal adjustPortalPosition>
        <BudgetLabelIcon budgetTypeId={budgetTypeId} className="td-content__text disabledText" />
        <span className="td-content__text disabledText">{dataToRender}</span>
      </Tooltip>
    ) : (
      <div className={wrapperClassName}>
        <BudgetLabelIcon budgetTypeId={budgetTypeId} className="td-content__text disabledText" />
        <span className="td-content__text disabledText">{dataToRender}</span>
      </div>
    );
  };

  getEndDateUnixInCampaignTimezone = (campaign) => {
    return campaign.campaign_timezone
      ? moment.unix(campaign.endTime).tz(campaign.campaignTimezone)
      : moment.unix(campaign.endTime);
  };

  getNewEndDateInOldTime = (newStr, oldDate) => {
    const newMoment = moment(newStr);
    if (oldDate) {
      return oldDate
        .year(newMoment.year())
        .month(newMoment.month())
        .date(newMoment.date());
    } else {
      return newMoment
        .hour(23)
        .minute(59)
        .second(59);
    }
  };

  toastName = '';

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  editableTextModalProps = {
    onContextMenu: this.stopPropagation,
    onClick: this.stopPropagation,
  };

  renderHeaderBudgetCell = (value: number, table: TableLevel, budgetType: 'daily' | 'total') => {
    const format = IOBudgetTypeMapper[IO_BUDGET_TYPE_ID.DOLLAR_BASED].format;
    const tooltipLabel = `${upperFirst(
      budgetType,
    )} budget information is currently only available for $ based ${
      table === TableLevel.InsertionOrders ? 'IOs' : 'campaigns'
    }.`;

    return (
      <div className="header-total-budget-cell">
        <div className="header-total-budget-cell-value">
          <BudgetLabelIcon budgetTypeId={IO_BUDGET_TYPE_ID.DOLLAR_BASED} />
          <div className="header-total-budget-cell-value-formatted">{format(value)}</div>
        </div>
        <Tooltip
          label={tooltipLabel}
          portal
          auto
          adjustPortalPosition
          className="header-total-budget-cell-info"
        >
          <Icon name="Info" />
        </Tooltip>
      </div>
    );
  };

  renderCampaignNameCell = (data) => {
    let classesArray = ['svg-icon__wrapper'];

    if (this.props.status.value !== 'all') {
      if (+data.percentageOfTotalSpent > 80) {
        +data.percentageOfTotalSpent > 95
          ? classesArray.push('budget-is-expiring-red')
          : classesArray.push('budget-is-expiring');
      }
    }

    if (data.ioStatusId === IO_STATUS_ID.DELETED) {
      classesArray.push('show-campaign-status-icon');
    }

    const audienceError =
      data.status === 'running' ? this.props.campaignAudienceWarnings[data.campaignId] : null;
    const creativeIcon = CreativeTypeIconMapper[camelCase(data.creativeType || '')];

    return (
      <div
        className={`campaign-name-cell-wrapper ${
          data.ioStatusId === IO_STATUS_ID.DELETED ? 'disable-interaction' : ''
        }`}
      >
        {data.creativeType && !!creativeIcon ? (
          <div
            data-campaign-status={data.status}
            data-creative-type={data.creativeType}
            className={classesArray.join(' ')}
          >
            <Tooltip
              label={`${data.creativeType} Campaign (${data.status})`}
              position="right"
              auto={false}
            >
              <Icon name={creativeIcon} />
            </Tooltip>
          </div>
        ) : null}
        {data.ioStatusId === IO_STATUS_ID.DELETED ? (
          <div className="td-campaign-name">
            <span className="td-campaign-name__text">{data.campaignName}</span>
          </div>
        ) : (
          <Tooltip className="td-campaign-name" label="Edit" portal useTooltipWrapperDimensions>
            <span className="td-campaign-name__text">{data.campaignName}</span>
          </Tooltip>
        )}

        {audienceError && audienceError !== AudienceWarning.NONE ? (
          <div className="td-campaign-audience-error">
            <Tooltip
              position="top"
              label={
                audienceError === AudienceWarning.ALL_UNAPPROVED
                  ? CAMPAIGN_AUDIENCE_ALL_UNAPPROVED
                  : CAMPAIGN_AUDIENCE_SOME_UNAPPROVED
              }
            >
              <Icon name="WarningTriangle" />
            </Tooltip>
          </div>
        ) : null}
      </div>
    );
  };

  getCampaignsBodyMapperCampaignName = () => ({
    campaignName: {
      key: (data) => this.renderCampaignNameCell(data),
      click: (data) => {
        if (data.ioStatusId === IO_STATUS_ID.DELETED) {
          return (
            <div className="d-flex align-items-center">{this.renderCampaignNameCell(data)}</div>
          );
        }

        if (data.total) {
          return null;
        }
        return (
          <div onContextMenu={(e) => e.stopPropagation()} className="_campaign-name-editable-text">
            <EditableText
              className="w-250-650"
              value={data.campaignName}
              Popup={{
                title: 'Change Campaign name',
                modalProps: this.editableTextModalProps,
                dataDogApplyLabel: 'Apply Campaign Name',
              }}
              confirm={async (value) => {
                this.props.updateCampaignName(value, data.campaignId, data).then(() => {
                  TableComponentInstance &&
                    TableComponentInstance.setState({ data: this.props.data });
                });
              }}
            >
              <div>Change campaign name?</div>
            </EditableText>
          </div>
        );
      },
      isFocusOnClick: true,
      clickFocusRefPropName: 'inputRef',
      className: '_campaign-name _editable w-250-650',
    },
  });

  renderInsertionOrderNameCell = (ioName: string, ioBudgetTypeId: number) => {
    const budgetIcon = IOBudgetTypeMapper[ioBudgetTypeId]
      ? IOBudgetTypeMapper[ioBudgetTypeId].icon
      : null;
    return (
      <div className="td-insertion-order-name">
        {!!budgetIcon && <Icon name={budgetIcon} />}
        <div className="td-insertion-order-name-text">{ioName}</div>
      </div>
    );
  };

  renderRawNumber = (value?: number | null) => {
    if (typeof value !== 'number') {
      return EM_DASH;
    }
    return value;
  };

  reachCol = {
    key: (data) => {
      const parsed = Number.parseInt(`${data.reach}`, 10);
      if (Number.isNaN(parsed)) {
        return '—';
      }

      return NumberFormat.format(parsed);
    },
    className: 'w-65-120 _right',
  };

  frequencyCol = {
    key: (data) => {
      const parsed = Number.parseFloat(`${data.frequency}`);
      if (Number.isNaN(parsed)) {
        return '—';
      }

      return NumberFormat.format(+parsed.toFixed(2));
    },
    className: 'w-100-100 _right',
  };

  ivtImpressionsBody = {
    [ivtImpressionsCol.value]: {
      key: (data) => this.renderRawNumber(data.ivtImpressions),
      className: 'w-100-200 _right',
    },
  };

  ivtClicksBody = {
    [ivtClicksCol.value]: {
      key: (data) => this.renderRawNumber(data.ivtClicks),
      className: 'w-100-200 _right',
    },
  };

  renderCurrencyValue = (value?: number) => {
    if (typeof value !== 'number') {
      return EM_DASH;
    }
    return CurrencyFormat.format(value);
  };

  renderVldInsightsCol = (field: string) => (data) => {
    const value = data[field];
    if (typeof value === 'number') {
      return this.renderCurrencyValue(value);
    }
    if (data.rowClassName === 'tr-unsorted') {
      return EM_DASH;
    }
    return (
      <Tooltip
        auto
        portal
        adjustPortalPosition
        label={
          this.props.currentTableLevel === TableLevel.Campaigns
            ? 'The campaign is not eligible for VLD Insights generation'
            : 'There are no campaigns eligible for VLD Insight generation'
        }
      >
        {EM_DASH}
      </Tooltip>
    );
  };

  getVldInsightsCostBody = () => ({
    [vldInsightsCostCol.value]: {
      key: this.renderVldInsightsCol(vldInsightsCostCol.value),
      className: 'w-100-200 _right w-100-col',
    },
  });

  platformVldInsightsEarningsBody = {
    [platformVldInsightsEarningsCol.value]: {
      key: this.renderVldInsightsCol(platformVldInsightsEarningsCol.value),
      className: 'w-150-300 _right w-100-col',
    },
  };

  workspaceVldInsightsEarningsBody = {
    [workspaceVldInsightsEarningsCol.value]: {
      key: this.renderVldInsightsCol(workspaceVldInsightsEarningsCol.value),
      className: 'w-150-300 _right w-100-col',
    },
  };

  campaignsBodyMapper = {
    campaignId: {
      key: (data) => data.campaignId,
      hover: null,
      className: '_id w-40-60 _right',
    },
    organizationName: {
      key: (data) => data.organizationName,
      className: 'w-250-300',
    },
    ioName: {
      key: (data) => this.renderInsertionOrderNameCell(data.ioName, data.budgetTypeId),
      className: 'w-160-400 _io-name',
    },
    ioEndTime: {
      key: (data) => {
        return data.ioEndTime
          ? data.ioTimeZoneName
            ? moment
                .unix(data.ioEndTime / 1000)
                .tz(data.ioTimeZoneName)
                .format(DateFormat)
            : moment.unix(data.ioEndTime / 1000).format(DateFormat)
          : '—';
      },
    },
    campaignTypeId: {
      key: (data) => {
        const { campaignTypeId, rowClassName } = data;
        if (rowClassName === 'tr-unsorted') {
          return null;
        }

        if (campaignTypeId === CampaignType.ADVANCED) {
          return (
            <IconTextCell
              className="pr-4"
              iconName={campaignTypeIconMap[CampaignType.ADVANCED] || ''}
              text="Advanced"
            />
          );
        }
        if (campaignTypeId === CampaignType.PG) {
          return (
            <IconTextCell
              className="pr-4"
              iconName={campaignTypeIconMap[CampaignType.PG] || ''}
              text="PG"
            />
          );
        }
        return null;
      },
      className: 'w-100-200',
    },
    status: {
      key: (data) => (
        <IconTextCell
          iconName={statusIconMap[data.status]}
          text={upperFirst(data.status || '—')}
          className="pr-3"
        />
      ),
      className: 'w-100-200 _campaign-status',
    },
    creativesCount: {
      key: (data) => (
        <CreativesCountCell
          campaign={{
            ...data,
            creativesCount: data.creativesCount,
          }}
          onClick={() => {
            DataDogLogger.Campaigns.openCreativesDialog();
            this.props.onOpenCreativesDialog(data);
          }}
          rumLogActionName="Open Creatives Dialog"
        />
      ),
      className: 'w-100-200  _right',
    },
    budgetDay: {
      key: (data) => {
        if (data.rowClassName === 'tr-unsorted' && this.props.ioBudgetTypes.length !== 1) {
          return EM_DASH;
        }

        let { budgetTypeId } = data;
        if (this.props.ioBudgetTypes.length === 1) {
          budgetTypeId = this.props.ioBudgetTypes[0];
        }

        const formatFn = IOBudgetTypeMapper[budgetTypeId]
          ? IOBudgetTypeMapper[budgetTypeId].format
          : (_value: any) => '—';
        const dataToRender = formatFn(
          budgetTypeId === IO_BUDGET_TYPE_ID.DOLLAR_BASED ? data.budgetDay : data.dailyImpression,
        );

        if (data.rowClassName === 'tr-unsorted') {
          return this.cellRenderWithEditTooltip(data.status, dataToRender, budgetTypeId, true);
        }

        if (data.campaignTypeId === CampaignType.PG) {
          return (
            <Tooltip
              label="Daily budget is not available for PG campaigns"
              portal
              adjustPortalPosition
            >
              <span className="td-content__text disabledText">{EM_DASH}</span>
            </Tooltip>
          );
        }

        if (data.budgetTypeId === CAMPAIGN_BUDGET_TYPE.IMPRESSION_BASED || !budgetTypeId) {
          return this.renderDisabledBudgetCell(
            dataToRender,
            budgetTypeId,
            IMPRESSION_CAMPAIGN_BUDGET_WARNING_SINGLE,
            !!budgetTypeId,
          );
        }

        return this.cellRenderWithEditTooltip(data.status, dataToRender, budgetTypeId);
      },
      click: (data) => {
        return data.total ||
          PARTLY_EDITING_STATUSES.includes(data.status) ||
          data.budgetTypeId === CAMPAIGN_BUDGET_TYPE.IMPRESSION_BASED ||
          data.campaignTypeId === CampaignType.PG ? (
          data.budgetDay ? (
            CurrencyFormat.format(data.budgetDay)
          ) : (
            '—'
          )
        ) : (
          <div onContextMenu={(e) => e.stopPropagation()}>
            <EditableText
              className="w-150-200 _right"
              value={String(data.budgetDay)}
              type="amount"
              confirm={(newValue) => {
                this.toastName = 'Daily Budget';
                this.confirmNewValue('dailyBudget', +newValue, data.campaignId, data);
              }}
              Popup={{
                title: `Change Daily Budget?`,
                modalProps: this.editableTextModalProps,
                dataDogApplyLabel: 'Apply Daily Budget',
              }}
            >
              {(old, newValue) => {
                return (
                  <DialogAmountContentWrapper value={+newValue} prev={+old} onChange={() => {}} />
                );
              }}
            </EditableText>
          </div>
        );
      },
      isFocusOnClick: true,
      clickFocusRefPropName: 'inputRef',
      className: 'w-100-200 _editable _right',
    },
    budgetTotal: {
      key: (data) => {
        if (data.rowClassName === 'tr-unsorted' && this.props.ioBudgetTypes.length !== 1) {
          return EM_DASH;
        }

        let { budgetTypeId } = data;
        if (this.props.ioBudgetTypes.length === 1) {
          budgetTypeId = this.props.ioBudgetTypes[0];
        }

        const formatFn = IOBudgetTypeMapper[budgetTypeId]
          ? IOBudgetTypeMapper[budgetTypeId].format
          : (_value: any) => '—';
        const dataToRender = formatFn(
          budgetTypeId === IO_BUDGET_TYPE_ID.DOLLAR_BASED
            ? data.budgetTotal
            : data.targetImpression,
        );

        if (data.rowClassName === 'tr-unsorted') {
          return this.cellRenderWithEditTooltip(data.status, dataToRender, budgetTypeId, true);
        }

        if (data.budgetTypeId === CAMPAIGN_BUDGET_TYPE.IMPRESSION_BASED || !budgetTypeId) {
          return this.renderDisabledBudgetCell(
            dataToRender,
            budgetTypeId,
            IMPRESSION_CAMPAIGN_BUDGET_WARNING_SINGLE,
            !!budgetTypeId,
          );
        }

        if (data.campaignTypeId === CampaignType.PG) {
          return this.renderDisabledBudgetCell(
            dataToRender,
            budgetTypeId,
            PG_CAMPAIGN_TOTAL_BUDGET_WARNING,
            true,
          );
        }

        return this.cellRenderWithEditTooltip(data.status, dataToRender, budgetTypeId);
      },
      click: (data) => {
        return data.total ||
          PARTLY_EDITING_STATUSES.includes(data.status) ||
          data.budgetTypeId === CAMPAIGN_BUDGET_TYPE.IMPRESSION_BASED ||
          data.campaignTypeId === CampaignType.PG ? (
          data.budgetTotal ? (
            CurrencyFormat.format(data.budgetTotal)
          ) : (
            '—'
          )
        ) : (
          <div onContextMenu={(e) => e.stopPropagation()}>
            <EditableText
              className="w-150-200 _right"
              value={String(data.budgetTotal)}
              type="amount"
              confirm={(newValue) => {
                this.toastName = 'Budget';
                this.confirmNewValue('totalBudget', +newValue, data.campaignId, data);
              }}
              Popup={{
                title: `Change Budget?`,
                modalProps: this.editableTextModalProps,
                dataDogApplyLabel: 'Apply Total Budget',
              }}
            >
              {(old, newValue) => {
                return (
                  <DialogAmountContentWrapper value={+newValue} prev={+old} onChange={() => {}} />
                );
              }}
            </EditableText>
          </div>
        );
      },
      isFocusOnClick: true,
      clickFocusRefPropName: 'inputRef',
      className: 'w-100-200 _editable _right',
    },
    maxBid: {
      key: (data) => {
        const dataToRender = data.maxBid ? CurrencyFormat.format(data.maxBid) : '—';
        if (data.rowClassName === 'tr-unsorted') {
          return dataToRender;
        }
        return this.cellRenderWithEditTooltip(data.status, dataToRender);
      },
      click: (data) => {
        if (!data.maxBid || data.rowClassName === 'tr-unsorted') {
          return '—';
        }
        return data.total || PARTLY_EDITING_STATUSES.includes(data.status) ? (
          CurrencyFormat.format(data.maxBid)
        ) : (
          <div onContextMenu={(e) => e.stopPropagation()}>
            <EditableText
              className="_right"
              value={String(data.maxBid)}
              type="amount"
              confirm={(newValue) => {
                this.toastName = 'Max bid';
                this.confirmNewValue('maxBid', +newValue, data.campaignId, data);
              }}
              Popup={{
                title: `Change Max Bid Price?`,
                modalProps: this.editableTextModalProps,
                dataDogApplyLabel: 'Apply Max Bid',
              }}
            >
              {(old, newValue) => {
                return (
                  <DialogAmountContentWrapper value={+newValue} prev={+old} onChange={() => {}} />
                );
              }}
            </EditableText>
          </div>
        );
      },
      isFocusOnClick: true,
      clickFocusRefPropName: 'inputRef',
      className: 'w-80-80 _editable _right',
    },
    pacingPercentage: {
      key: (data) =>
        data.rowClassName !== 'tr-unsorted' ? <CampaignPacingCell campaign={data} /> : EM_DASH,
      className: 'w-150-300 td-pacing-percentage _right',
    },
    dailyPacingPercentage: {
      key: (data) => {
        if (data.rowClassName === 'tr-unsorted') {
          return EM_DASH;
        }

        return <DailyPacingCell campaign={data} />;
      },
      className: 'w-150-300 td-pacing-percentage _right',
    },
    timezone: {
      key: (data) => data.campaignTimezone || '—',
      className: 'w-100-160',
    },
    reach: this.reachCol,
    frequency: this.frequencyCol,
    workspaceMediaEarning: {
      key: (data) =>
        typeof data.platformMediaEarning === 'number'
          ? CurrencyFormat.format(data.workspaceMediaEarning)
          : '-',
      className: 'w-100-100 _right',
    },
    platformMediaEarning: {
      key: (data) =>
        typeof data.platformMediaEarning === 'number'
          ? CurrencyFormat.format(data.platformMediaEarning)
          : '-',
      className: 'w-100-100 _right',
    },
    percentageOfTotalSpent: {
      key: (data) => `${(+data.percentageOfTotalSpent || 0).toFixed(2)}%`,
      className: (data) => {
        return `_right w-160-400 ${
          +data.percentageOfTotalSpent > 80 && !data.budgetPacing && data.status === 'running'
            ? +data.percentageOfTotalSpent > 95
              ? '_red'
              : '_orange'
            : ''
        }`;
      },
    },
    workspaceSpent: {
      key: (data) =>
        typeof data.workspaceSpent === 'number' ? CurrencyFormat.format(data.workspaceSpent) : '-',
      className: 'w-100-200 _right',
    },
    platformSpent: {
      key: (data) =>
        typeof data.platformSpent === 'number' ? CurrencyFormat.format(data.platformSpent) : '-',
      className: 'w-100-200 _right',
    },
    ...this.ivtImpressionsBody,
    ...this.ivtClicksBody,
    ...this.getVldInsightsCostBody(),
    ...this.platformVldInsightsEarningsBody,
    ...this.workspaceVldInsightsEarningsBody,
  };

  workspacesBodyMapper = {
    workspaceId: {
      key: (data) => data.workspaceId,
      hover: null,
      className: '_id w-40-60 _right',
    },
    workspaceName: {
      key: (data) => data.organizationName,
      hover: null,
      className: '_campaign-name w-250-650',
    },
    workspaceSpent: {
      key: (data) =>
        typeof data.workspaceSpent === 'number' ? CurrencyFormat.format(data.workspaceSpent) : '-',
      className: 'w-100-200 _right',
    },
    platformSpent: {
      key: (data) =>
        typeof data.platformSpent === 'number' ? CurrencyFormat.format(data.platformSpent) : '-',
      className: 'w-100-200 _right',
    },
    workspaceMediaEarning: {
      key: (data) =>
        typeof data.workspaceMediaEarning === 'number'
          ? CurrencyFormat.format(data.workspaceMediaEarning)
          : '-',
      className: 'w-100-200 _right',
    },
    platformMediaEarning: {
      key: (data) =>
        typeof data.platformMediaEarning === 'number'
          ? CurrencyFormat.format(data.platformMediaEarning)
          : '-',
      className: 'w-100-200 _right',
    },
    ...this.getVldInsightsCostBody(),
    ...this.platformVldInsightsEarningsBody,
    ...this.workspaceVldInsightsEarningsBody,
  };

  advertisersBodyMapper = {
    owId: {
      key: (data) => data.owId,
      hover: null,
      className: '_id w-40-60 _right',
    },
    organizationName: {
      key: (data) => data.organizationName,
      hover: null,
      className: '_campaign-name w-250-650',
    },
    workspaceSpent: {
      key: (data) =>
        typeof data.workspaceSpent === 'number' ? CurrencyFormat.format(data.workspaceSpent) : '-',
      className: 'w-100-200 _right',
    },
    platformSpent: {
      key: (data) =>
        typeof data.platformSpent === 'number' ? CurrencyFormat.format(data.platformSpent) : '-',
      className: 'w-100-200 _right',
    },
    workspaceMediaEarning: {
      key: (data) =>
        typeof data.workspaceMediaEarning === 'number'
          ? CurrencyFormat.format(data.workspaceMediaEarning)
          : '-',
      className: 'w-100-200 _right',
    },
    platformMediaEarning: {
      key: (data) =>
        typeof data.platformMediaEarning === 'number'
          ? CurrencyFormat.format(data.platformMediaEarning)
          : '-',
      className: 'w-100-200 _right',
    },
    ...this.getVldInsightsCostBody(),
    ...this.platformVldInsightsEarningsBody,
    ...this.workspaceVldInsightsEarningsBody,
  };

  exchangesBodyMapper = {
    exchangeId: {
      key: (data) => data.exchangeId,
      hover: null,
      className: '_id w-40-60 _right',
    },
    exchangeName: {
      key: (data) => data.exchangeName,
      hover: null,
      className: '_campaign-name w-250-650',
    },
    workspaceSpent: {
      key: (data) =>
        typeof data.workspaceSpent === 'number' ? CurrencyFormat.format(data.workspaceSpent) : '-',
      className: 'w-100-200 _right',
    },
    platformSpent: {
      key: (data) =>
        typeof data.platformSpent === 'number' ? CurrencyFormat.format(data.platformSpent) : '-',
      className: 'w-100-200 _right',
    },
    ...this.ivtImpressionsBody,
    ...this.ivtClicksBody,
  };

  handleUpdateIOEndDate = (data) => async (date: Moment) => {
    try {
      const ioEndTime = moment
        .tz(date.format(PARSE_TIME_FORMAT), PARSE_TIME_FORMAT, data.campaignTimezone)
        .valueOf();
      await this.props.updateInsertionOrdersEndTime(
        {
          ioEndTime,
        },
        [data],
      );
      if (TableComponentInstance && TableComponentInstance.state.data) {
        const newData = TableComponentInstance.state.data.map((io) => {
          if (data.ioId === io.ioId) {
            return {
              ...io,
              ioEndTime,
            };
          }
          return io;
        });
        TableComponentInstance.setState({
          data: newData,
        });
      }
    } catch (err) {
      // Error handled in thunk
    }
  };

  confirmNewIOTotalBudget = async (newBudget: number, insertionOrder: LambdaResponse) => {
    const {
      isPlatformOwnerOrg,
      isWorkspaceOwnerOrg,
      updateInsertionOrdersBudget,
      getTotal,
      selectedTableInsertionOrders,
      setSelectedTableInsertionOrders,
    } = this.props;

    try {
      await updateInsertionOrdersBudget(
        {
          ioBudgetTypeId: insertionOrder.ioBudgetTypeId,
          ...(insertionOrder.ioBudgetTypeId === IO_BUDGET_TYPE_ID.DOLLAR_BASED
            ? { budget: Number(newBudget) }
            : { ioTotalImpressions: Number(newBudget) }),
          budgetUpdateType: IOBudgetActions.SET,
          ...(isPlatformOwnerOrg || isWorkspaceOwnerOrg
            ? { ioOwId: (insertionOrder as any).owId }
            : {}),
        },
        [insertionOrder],
      );

      const updater = (io) => {
        if (io.ioId === insertionOrder.ioId) {
          return {
            ...io,
            ioTotalBudget: newBudget,
          };
        }
        return io;
      };

      if (TableComponentInstance && TableComponentInstance.state.data) {
        TableComponentInstance.setState({
          data: TableComponentInstance.state.data.map(updater),
        });
        getTotal().then((res) => {
          TableComponentInstance.setState({ totalData: [{ ...res, total: true }] });
        });
      }

      setSelectedTableInsertionOrders(selectedTableInsertionOrders.map(updater));
    } catch (err) {
      // Handled in thunk
    }
  };

  insertionOrdersBodyMapper = {
    ioId: {
      key: (data) => data.ioId,
      hover: null,
      className: '_id w-40-60 _right',
    },
    ioName: {
      key: (data) => (
        <InsertionOrderNameCell
          ioName={data.ioName}
          ioBudgetTypeId={data.ioBudgetTypeId}
          onClick={() => this.props.openCampaignsByInsertionOrder(data.ioId)}
          tooltipText="Click to view campaigns within this IO"
        />
      ),
      hover: null,
      className: 'w-250-650 _io-name',
    },
    organizationName: {
      key: (data) => data.organizationName,
      hover: null,
      className: '_campaign-name w-250-650',
    },
    ioStatusId: {
      key: (data) => <InsertionOrderStatusCell ioStatusId={data.ioStatusId} />,
      hover: null,
      className: 'w-100-200',
    },
    campaignsCount: {
      key: (data) => (
        <ClickableCountCell
          onClick={() => {
            DataDogLogger.InsertionOrders.openCampaignsDialog();
            this.props.onOpenCampaignsDialog(data);
          }}
          value={data.campaignsCount}
          tooltip="Click to view campaigns"
          rowClassName={data.rowClassName}
          rumLogActionName="Open Campaigns Dialog"
        />
      ),
      hover: null,
      className: 'w-100-200 _right',
    },
    ioTotalBudget: {
      key: (data) => {
        if (data.rowClassName === 'tr-unsorted' && this.props.ioBudgetTypes.length !== 1) {
          return EM_DASH;
        }

        let { ioBudgetTypeId } = data;
        if (this.props.ioBudgetTypes.length === 1) {
          ioBudgetTypeId = this.props.ioBudgetTypes[0];
        }

        const formatFn = IOBudgetTypeMapper[ioBudgetTypeId]
          ? IOBudgetTypeMapper[ioBudgetTypeId].format
          : (_value: any) => '—';
        const dataToRender = formatFn(
          ioBudgetTypeId === IO_BUDGET_TYPE_ID.DOLLAR_BASED
            ? data.ioTotalBudget
            : data.targetImpression,
        );

        let tooltip = '';
        if (data.ioStatusId === IO_STATUS_ID.EXPIRED) {
          tooltip = `Total budget can't be edited for Expired IOs`;
        } else if (data.ioStatusId === IO_STATUS_ID.DELETED) {
          tooltip = `Total budget can't be edited for Deleted IOs`;
        } else if (ioBudgetTypeId === IO_BUDGET_TYPE_ID.IMPRESSIONS_BASED) {
          tooltip = IMPRESSION_IO_BUDGET_WARNING_SINGLE;
        } else if (data.isAutoSumIoTotalBudget) {
          tooltip = AUTO_SUM_IO_BUDGET_WARNING_SINGLE;
        }

        return !!tooltip || !ioBudgetTypeId
          ? this.renderDisabledBudgetCell(dataToRender, ioBudgetTypeId, tooltip, !!ioBudgetTypeId)
          : this.cellRenderWithEditTooltip('', dataToRender, ioBudgetTypeId);
      },
      click: (data) => {
        if (!data.ioBudgetTypeId) {
          return '—';
        }

        if (
          data.isAutoSumIoTotalBudget ||
          data.ioBudgetTypeId === 2 ||
          data.ioStatusId === IO_STATUS_ID.EXPIRED ||
          data.ioStatusId === IO_STATUS_ID.DELETED
        ) {
          return typeof data.ioTotalBudget === 'number'
            ? CurrencyFormat.format(data.ioTotalBudget)
            : '—';
        }
        return (
          <div onContextMenu={(e) => e.stopPropagation()}>
            <EditableText
              className="w-150-200 _right"
              value={String(data.ioTotalBudget)}
              type="amount"
              confirm={(newValue) => {
                this.toastName = 'Budget';
                this.confirmNewIOTotalBudget(+newValue, data);
              }}
              Popup={{
                title: `Change Budget?`,
                modalProps: this.editableTextModalProps,
                dataDogApplyLabel: 'Apply Insertion Order Total Budget',
                // disableApply: (_defaultValue, value) => +value < data.spent,
              }}
            >
              {(old, newValue) => {
                return (
                  <>
                    <DialogAmountContentWrapper value={+newValue} prev={+old} onChange={() => {}} />
                    {/*newValue < data.spent ? (
                      <div className="changing-io-total-budget-alert">
                        <Icon className="changing-io-total-budget-icon" name="ErrorTriangle" />
                        <span>
                          The total budget can not be less than the total spent (
                          {CurrencyFormat.format(data.spent)}) of {data.ioName}
                        </span>
                      </div>
                          ) : null*/}
                  </>
                );
              }}
            </EditableText>
          </div>
        );
      },
      isFocusOnClick: true,
      clickFocusRefPropName: 'inputRef',
      className: 'w-100-200 _editable _right',
    },
    ioStartTime: {
      key: (data) => {
        return data.ioStartTime
          ? data.campaignTimezone
            ? moment(data.ioStartTime)
                .tz(data.campaignTimezone)
                .format(IO_DATE_FORMAT)
            : moment(data.ioStartTime).format(IO_DATE_FORMAT)
          : '—';
      },
    },
    ioEndTime: {
      key: (data) => {
        return data.ioEndTime
          ? data.campaignTimezone
            ? moment(data.ioEndTime)
                .tz(data.campaignTimezone)
                .format(IO_DATE_FORMAT)
            : moment(data.ioEndTime).format(IO_DATE_FORMAT)
          : '—';
      },
      className: 'w-250-250',
    },
    reach: this.reachCol,
    frequency: this.frequencyCol,
    ioPacingPercentage: {
      key: (data) => {
        if (data.rowClassName === 'tr-unsorted') {
          return EM_DASH;
        }

        return <IOPacingCell insertionOrder={data} />;
      },
      className: 'w-150-300 td-pacing-percentage _right',
    },
    ...this.ivtImpressionsBody,
    ...this.ivtClicksBody,
    ...this.getVldInsightsCostBody(),
  };

  fetchDataErrorHandler = (err) => {
    this.props.getStatisticsTotalError(err);
    if (err && Object.keys(err).length) {
      this.props.openToast('Something went wrong. Please try again over a few minutes.');
      if (err.message && err.message.includes('401')) {
        // AuthService.logout();
      }
    }
  };

  onChangeSort = ({ sorting }: { sorting: TableSortingParams }) => {
    if (
      this.props.selectedTableCampaigns.length ||
      this.props.selectedTableInsertionOrders.length
    ) {
      this.setState({
        confirmSorting: true,
        newSorting: sorting,
      });
    } else {
      this.props.updateSorting(sorting);
    }
  };

  closeConfirmSortingDialog = () => {
    this.setState({
      confirmSorting: false,
    });
  };

  getBodyMapper = () => {
    const { isPlatformOwnerOrg, currentTableLevel } = this.props;

    switch (currentTableLevel) {
      case TableLevel.Advertisers:
        const bodyMapper = { ...this.advertisersBodyMapper };
        if (!isPlatformOwnerOrg) {
          // @ts-ignore
          delete bodyMapper.platformSpent;
          // @ts-ignore
          delete bodyMapper.platformMediaEarning;
        }
        return bodyMapper;
      case TableLevel.Workspaces:
        return { ...this.workspacesBodyMapper };
      case TableLevel.Exchanges:
        return { ...this.exchangesBodyMapper };
      case TableLevel.InsertionOrders:
        return { ...this.insertionOrdersBodyMapper };
      default:
        return {
          ...this.campaignsBodyMapper,
          ...this.getCampaignsBodyMapperCampaignName(),
        };
    }
  };

  transformInsertionOrderData = (data) => {
    const { timezones } = this.props;
    return data.map((d) => ({
      ...d,
      // startTime: d.ioStartTime / 1000,
      // endTime: d.ioEndTime / 1000,
      campaignTimezone: get(
        timezones.find((tz) => tz.id === d.ioTimezone),
        'value',
      ),
    }));
  };

  rumLogChangeSort = (headerObj: LoggableTableSorting) => {
    DataDogLogger.App.sortMainTable(headerObj, this.props.currentTableLevel);
  };

  render() {
    const {
      isCampaigns,
      isInsertionOrders,
      skeleton,
      updateFreeze,
      freezeRows,
      freezeColumns,
      sorting,
      idField,
      rowKeyExtractor,
      isVirtualizedListOpen,
      headerMapper,
      isCustomersDropdownLoaded,
      isPlatformOwnerOrg,
      isWorkspaceOwnerOrg,
      currentTableLevel,
      search,
    } = this.props;

    const { newSorting, confirmSorting, isBreadcrumbHidden } = this.state;

    const bodyMapper = cloneDeep(this.getBodyMapper());

    return (
      <React.Fragment>
        <KTable
          className={`dashboard__table ${isCampaigns ? '_with-checkbox' : ''} ${
            isVirtualizedListOpen ? '_virtualization-debug' : ''
          }`}
          onSelect={
            isCampaigns
              ? this.handleTableCampaignSelection
              : this.handleTableInsertionOrderSelection
          }
          dataPath="data.recordsList"
          totalPath="data.recordsTotal"
          headerMapping={headerMapper}
          bodyMapping={{ ...bodyMapper }}
          offsetTop={isBreadcrumbHidden ? 60 : 100}
          tableParams={{
            freezeRows,
            freezeColumns: freezeColumns === 1 && isCampaigns ? 2 : freezeColumns,
            freezeUpdateHandler: updateFreeze,
            rowKeyExtractor,
            windowFreeResizeEvent: true,
            onChange: this.onChangeSort,
            preventNavigationByScroll: true,
            tbodyRowHeight:
              currentTableLevel === TableLevel.Campaigns ||
              currentTableLevel === TableLevel.InsertionOrders
                ? 46
                : undefined,
            onChangeSort: this.rumLogChangeSort,
          }}
          innerRef={this.handleSetRef}
          onDataChanged={this.handleCampaignChange}
          skeleton={skeleton}
          checkboxInHeader={true}
          checkbox={isCampaigns || currentTableLevel === TableLevel.InsertionOrders}
          transformRequestParams={(query, tableState) => {
            const { pgno: pageNo, no_of_entries: noOfEntries } = query;
            delete query.pgno;
            delete query.no_of_entries;
            delete query.sort_by;
            delete query.sort_type;

            return {
              ...query,
              pageNo: tableState.isAllItemsLoaded ? -1 : pageNo,
              noOfEntries,
            };
          }}
          idField={idField}
          emptyTableLabel={
            search
              ? 'No results found. Try changing search parameters.'
              : isCampaigns
              ? 'No Campaigns. Change Dashboard Parameters.'
              : 'No Data. Change Dashboard Parameters.'
          }
          countPath="data.filteredRecords"
          onFetchDataError={
            isCustomersDropdownLoaded && (isPlatformOwnerOrg || isWorkspaceOwnerOrg)
              ? this.fetchDataErrorHandler
              : undefined
          }
          defaultSorting={sorting}
          onDataLoaded={this.handleDataLoaded}
          showLoader={!isCustomersDropdownLoaded && (isPlatformOwnerOrg || isWorkspaceOwnerOrg)}
          transformData={isInsertionOrders ? this.transformInsertionOrderData : undefined}
          dataFetchDebounce={LAMBDA_DEBOUNCE}
        />
        {confirmSorting &&
          createPortal(
            <ConfirmSortingDialog newSorting={newSorting} close={this.closeConfirmSortingDialog} />,
            document.body,
          )}
      </React.Fragment>
    );
  }
}

const ivtImpressionsHeader = {
  [ivtImpressionsCol.value]: {
    label: [ivtImpressionsCol.label],
    sortingKey: ivtImpressionsCol.value,
    className: 'w-100-200',
  },
};

const ivtClicksHeader = {
  [ivtClicksCol.value]: {
    label: ivtClicksCol.label,
    sortingKey: ivtClicksCol.value,
    className: 'w-100-200',
  },
};

const vldInsightsCostHeader = {
  [vldInsightsCostCol.value]: {
    label: 'VLD Insights Cost',
    tooltip: 'Amount spent to generate Voter Level Data Insights',
    sortingKey: vldInsightsCostCol.value,
    className: 'w-100-200',
  },
};

const platformVldInsightsEarningHeader = {
  [platformVldInsightsEarningsCol.value]: {
    label: 'Platform VLD Insights Earnings',
    className: 'w-150-300 _right',
    sortingKey: platformVldInsightsEarningsCol.value,
    tooltip: 'The amount earned by platform over Voter Level Data Insights.',
  },
};

const workspaceVldInsightsEarningHeader = {
  [workspaceVldInsightsEarningsCol.value]: {
    label: 'Workspace VLD Insights Earnings',
    className: 'w-150-300 _right',
    sortingKey: workspaceVldInsightsEarningsCol.value,
    tooltip: 'The amount earned by workspace over Voter Level Data Insights.',
  },
};

const campaignsHeaderMapper = {
  campaignId: {
    ...TableHeaderMapping.campaignId,
    className: '_id w-40-60',
    label: 'ID',
  },
  campaignName: {
    ...TableHeaderMapping.campaignName,
    className: '_campaign-name w-250-650',
  },
  organizationName: {
    sortingKey: 'organizationName',
    label: 'Organization',
    alwaysEnabled: true,
    draggableGroup: 1,
    className: 'w-100-200',
  },
  status: {
    sortingKey: 'status',
    label: 'Status',
    alwaysEnabled: true,
    draggableGroup: 1,
    className: 'w-100-200',
  },
  creativesCount: {
    sortingKey: 'creativesCount',
    label: 'Creatives',
    alwaysEnabled: true,
    draggableGroup: 1,
    className: 'w-100-200',
    tooltip: 'Number of ad creatives available in the campaign',
  },
  ioName: {
    label: 'Insertion Order Name',
    sortingKey: 'ioName',
    className: 'w-160-400',
    draggableGroup: 'group1',
    alwaysEnabled: true,
  },
  ioEndTime: {
    label: 'IO End Date',
    sortingKey: 'ioEndTime',
    className: 'w-160-400',
    draggableGroup: 'group1',
  },
  campaignTypeId: {
    label: 'Campaign Type',
    className: 'w-100-200',
    draggableGroup: 'group1',
  },
  timezone: {
    sortingKey: 'campaignTimezone',
    label: 'Time Zone',
    className: 'w-80-120',
    tooltip: 'Campaign Time Zone',
    draggableGroup: 'group1',
  },
  pacingPercentage: {
    sortingKey: 'pacingPercentage',
    label: (
      <div className="d-flex align-items-center">
        Campaign Pacing
        <CampaignPacingHeaderTooltip />
      </div>
    ),
    className: 'w-150-300',
    textLabel: 'Campaign Pacing',
  },
  dailyPacingPercentage: {
    sortingKey: 'dailyPacingPercentage',
    label: (
      <div className="d-flex align-items-center">
        Daily Pacing
        <DailyPacingHeaderTooltip />
      </div>
    ),
    className: 'w-150-300',
    textLabel: 'Daily Pacing',
  },
  workspaceMediaEarning: {
    sortingKey: 'workspaceMediaEarning',
    label: 'Workspace Media Earnings',
    className: 'w-150-300',
    textLabel: 'Workspace Media Earnings',
    tooltip: 'The amount earned by workspace over campaign media spent.',
  },
  platformMediaEarning: {
    sortingKey: 'platformMediaEarning',
    label: 'Platform Media Earnings',
    className: 'w-150-300',
    textLabel: 'Platform Media Earnings',
    tooltip: 'The amount earned by the platform over campaign media spent.',
  },
  workspaceSpent: {
    label: 'Workspace Spent',
    className: 'w-100-200 _right',
    sortingKey: 'workspaceSpent',
  },
  platformSpent: {
    label: 'Platform Spent',
    className: 'w-100-200 _right',
    sortingKey: 'platformSpent',
  },
  ...ivtImpressionsHeader,
  ...ivtClicksHeader,
  ...vldInsightsCostHeader,
  ...platformVldInsightsEarningHeader,
  ...workspaceVldInsightsEarningHeader,
};

const workspaceSpent = {
  label: 'Workspace Spent',
  className: 'w-100-200 _right',
  sortingKey: 'workspaceSpent',
};
const platformSpent = {
  label: 'Platform Spent',
  className: 'w-100-200 _right',
  sortingKey: 'platformSpent',
};
const workspaceMediaEarning = {
  label: 'Workspace Media Earning',
  className: 'w-100-200 _right',
  sortingKey: 'workspaceMediaEarning',
  tooltip: 'The amount earned by workspace over campaign media spent.',
};
const platformMediaEarning = {
  label: 'Platform Media Earning',
  className: 'w-100-200 _right',
  sortingKey: 'platformMediaEarning',
  tooltip: 'The amount earned by the platform over campaign media spent.',
};

const workspaceHeaderMapper = {
  workspaceId: {
    label: 'ID',
    sortingKey: 'workspaceId',
    className: '_id w-40-60 _right',
    draggableGroup: 'group1',
    alwaysEnabled: true,
  },
  workspaceName: {
    label: 'Workspace Name',
    sortingKey: 'organizationName',
    className: 'w-250-650',
    draggableGroup: 'group1',
    alwaysEnabled: true,
  },
  workspaceSpent,
  platformSpent,
  workspaceMediaEarning,
  platformMediaEarning,
  ...vldInsightsCostHeader,
  ...platformVldInsightsEarningHeader,
  ...workspaceVldInsightsEarningHeader,
};

const advertiserHeaderMapper = {
  owId: {
    label: 'ID',
    sortingKey: 'owId',
    className: '_id w-40-60 _right',
    draggableGroup: 'group1',
    alwaysEnabled: true,
  },
  organizationName: {
    label: 'Advertiser Name',
    sortingKey: 'organizationName',
    className: 'w-250-650',
    draggableGroup: 'group1',
    alwaysEnabled: true,
  },
  workspaceSpent,
  platformSpent,
  workspaceMediaEarning,
  platformMediaEarning,
  ...vldInsightsCostHeader,
  ...platformVldInsightsEarningHeader,
  ...workspaceVldInsightsEarningHeader,
};

const exchangesHeaderMapper = {
  exchangeId: {
    label: 'ID',
    sortingKey: 'exchangeId',
    className: '_id w-40-60 _right',
    draggableGroup: 'group1',
    alwaysEnabled: true,
  },
  exchangeName: {
    label: 'Exchange Name',
    sortingKey: 'exchangeName',
    className: 'w-250-650',
    draggableGroup: 'group1',
    alwaysEnabled: true,
  },
  workspaceSpent,
  platformSpent,
  ...ivtImpressionsHeader,
  ...ivtClicksHeader,
};

const insertionOrderHeaderMapper = {
  ioId: {
    label: 'ID',
    sortingKey: 'ioId',
    className: '_id w-40-60 _right',
    draggableGroup: 'group1',
    alwaysEnabled: true,
  },
  ioName: {
    label: 'Insertion Order Name',
    sortingKey: 'ioName',
    className: 'w-250-650',
    draggableGroup: 'group1',
    alwaysEnabled: true,
  },
  organizationName: {
    label: 'Organization Name',
    sortingKey: 'organizationName',
    className: '_campaign-name w-250-650',
    draggableGroup: 'group1',
    alwaysEnabled: true,
  },
  ioStatusId: {
    label: 'Status',
    className: 'w-100-200',
    draggableGroup: 'group1',
    alwaysEnabled: true,
  },
  campaignsCount: {
    label: 'Campaigns',
    className: 'w-100-200',
    draggableGroup: 'group1',
    alwaysEnabled: true,
  },
  ioTotalBudget: {
    label: 'Total Budget',
    sortingKey: 'ioTotalBudget',
    className: 'w-100-200',
    tooltip:
      'The amount set as the total spending limit including media cost, data cost and pre-bid cost',
  },
  ioStartTime: {
    label: 'Start Date',
    sortingKey: 'ioStartTime',
    className: 'w-100-200',
  },
  ioEndTime: {
    label: 'End Date',
    sortingKey: 'ioEndTime',
    className: 'w-250-250',
  },
  ioPacingPercentage: {
    label: (
      <div className="d-flex align-items-center">
        IO Pacing
        <IOPacingHeaderTooltip />
      </div>
    ),
    sortingKey: 'ioPacingPercentage',
    className: 'w-150-300',
    textLabel: 'IO Pacing',
  },
  ...ivtImpressionsHeader,
  ...ivtClicksHeader,
  ...vldInsightsCostHeader,
};

const skeletonClasses = {
  ioId: 'w-40-60',
  ioName: 'w-250-650',
  ioTotalBudget: 'w-100-200',
  campaignId: 'w-40-60',
  checkbox: 'w-50',
  campaignName: 'w-250-650',
  owId: 'w-40-60',
  workspaceId: 'w-40-60',
  organizationName: 'w-250-650',
  workspaceName: 'w-250-650',
  maxBid: 'w-80-80',
  dailyBudget: 'w-100-200',
  totalBudget: 'w-100-200',
  totalSpent: 'w-150-200',
  timezone: 'w-120-120',
  startDate: ' w-80-80',
  endTime: 'w-80-80',
  impressions: 'w-90-90',
  reach: 'w-100-200',
  frequency: 'w-100-100',
  pixalateViewAbility: 'w-100-200',
  created: 'w-100-200',
  modified: 'w-100-200',
  type: 'w-60-60',
  ecpm: 'w-100-200',
  ecpc: 'w-100-200',
  clicks: 'w-60-60',
  winrate: 'w-90-90',
  ctr: 'w-60-60',
  dataCost: 'w-90-90',
  mediaSpent: 'w-100-200',
  percentageOfTotalSpent: 'w-160-400',
  workspaceSpent: 'w-100-200',
  workspaceMediaEarning: 'w-100-200',
  platformSpent: 'w-100-200',
  platformMediaEarning: 'w-100-200',
  exchangeId: 'w-40-60',
  exchangeName: 'w-250-650',
  [ivtImpressionsCol.value]: 'w-100-200',
  [ivtClicksCol.value]: 'w-100-200',
  [vldInsightsCostCol.value]: 'w-100-200',
};

const mapClasses = ({ value }) => {
  return skeletonClasses[value] || '';
};

const mapState = (state: AppState) => {
  const isCampaigns = state.filter.tableLevel.value === TableLevel.Campaigns;
  const isWorkspaces = state.filter.tableLevel.value === TableLevel.Workspaces;
  const isAdvertisers = state.filter.tableLevel.value === TableLevel.Advertisers;
  const isExchanges = state.filter.tableLevel.value === TableLevel.Exchanges;
  const currentTableLevel = state.filter.tableLevel.value;
  const filteredCampaignsIds = state.table.filteredCampaignsIds;
  const filteredCampaigns = state.table.data.filter(
    (campaign) => campaign.campaignId && filteredCampaignsIds.includes(+campaign.campaignId),
  );
  const { selectedTableCampaigns, data, campaignAudienceWarnings } = state.table;
  const {
    sortingColumns,
    sortingWorkspacesColumns = [],
    sortingAdvertisersColumns = [],
    sortingExchangesColumns = [],
    sortingInsertionOrdersColumns = [],
    freeze,
    status,
    isCustomersDropdownLoaded,
  } = state.filter;
  const { isPlatformOwnerOrg } = state.auth.userData;
  const isVirtualizedListOpen = state.app.isVirtualizedListOpen;

  let headerMapper, skeletonCols, skeletonClasses, idField, rowKeyExtractor;
  switch (currentTableLevel) {
    case TableLevel.Workspaces:
      headerMapper = workspaceHeaderMapper;
      skeletonCols = sortingWorkspacesColumns.length;
      skeletonClasses = sortingWorkspacesColumns.map(mapClasses);
      idField = 'workspaceId';
      rowKeyExtractor = (data, index) => +`${data.owId}${index}`;
      break;
    case TableLevel.Advertisers:
      headerMapper = { ...advertiserHeaderMapper };
      if (!isPlatformOwnerOrg) {
        delete headerMapper.platformSpent;
        delete headerMapper.platformMediaEarning;
      }
      skeletonCols = sortingAdvertisersColumns.length;
      skeletonClasses = sortingAdvertisersColumns.map(mapClasses);
      idField = 'owId';
      rowKeyExtractor = (data, index) => +`${data.owId}${index}`;
      break;
    case TableLevel.Exchanges:
      headerMapper = { ...exchangesHeaderMapper };
      skeletonCols = sortingExchangesColumns.length;
      skeletonClasses = sortingExchangesColumns.map(mapClasses);
      idField = 'exchangeId';
      rowKeyExtractor = (data, index) => `${data.exchangeId}${index}`;
      break;
    case TableLevel.InsertionOrders:
      headerMapper = { ...insertionOrderHeaderMapper };
      skeletonCols = sortingInsertionOrdersColumns.length;
      skeletonClasses = sortingInsertionOrdersColumns.map(mapClasses);
      idField = 'ioId';
      rowKeyExtractor = (data, index) => `${data.ioId}i${index}`;
      break;
    default:
      headerMapper = campaignsHeaderMapper;
      skeletonCols = sortingColumns.length;
      skeletonClasses = sortingColumns.map(mapClasses);
      idField = 'campaignId';
      rowKeyExtractor = (data, index) => +`${data.campaignId}${index}`;
      break;
  }

  return {
    data,
    headerMapper,
    isWorkspaces,
    isAdvertisers,
    isCampaigns,
    isExchanges,
    isInsertionOrders: currentTableLevel === TableLevel.InsertionOrders,
    filteredCampaigns,
    skeleton: {
      rows: 10,
      columns: skeletonCols,
      classes: skeletonClasses,
    },
    freezeRows: freeze.rows,
    freezeColumns: freeze.columns,
    status,
    selectedTableCampaigns,
    advertiserId: state.auth.userData.userId,
    sorting: state.table.sorting,
    idField,
    currentTableLevel,
    isPlatformOwnerOrg,
    isWorkspaceOwnerOrg: state.auth.userData.isWorkspaceOwnerOrg,
    rowKeyExtractor,
    isVirtualizedListOpen,
    campaignAudienceWarnings,
    isCustomersDropdownLoaded,
    selectedCampaigns: state.filter.selectedCampaigns || [],
    timezones: state.filter.timezones || [],
    selectedTableInsertionOrders: state.table.selectedTableInsertionOrders,
    search: state.filter.search,
    ioBudgetTypes: state.filter.ioBudgetTypes,
  };
};

const mapActions = {
  addSelectedCampaign: tableActions.addSelectedCampaign,
  clearCampaignsData: tableActions.clearCampaignsData,
  changeLevel: filterActions.changeTableLevel,
  updateFreeze: filterActions.updateFreeze,
  setCampaigns: tableActions.setCampaigns,
  openToast: toastActions.open,
  closeToast: toastActions.close,
  updateCampaignsBudget: tableActions.updateCampaignsBudget,
  setNewTotal: statisticsActions.setNewTotal,
  getTotal: statisticsActions.getTotal,
  updateCampaignName: tableActions.updateCampaignName,
  updateCampaignsList: tableActions.updateCampaignList,
  updateSorting: tableActions.updateSorting,
  setTotalItems: tableActions.setTotalItems,
  addCampaignAudienceWarnings: tableActions.addCampaignAudienceWarnings,
  getStatisticsTotalError: statisticsActions.getStatisticsTotalError,
  openCampaignsByInsertionOrder: filterActions.openCampaignsByInsertionOrder,
  setSelectedTableInsertionOrders: tableActions.setSelectedInsertionOrders,
  updateInsertionOrdersEndTime: tableActions.updateInsertionOrdersEndTime,
  updateInsertionOrdersBudget: tableActions.updateInsertionOrdersBudget,
};

export const Table = withRouter(connect<any, any, any, any>(mapState, mapActions)(TableComponent));
