import { ORIGIN_URL } from 'iqm-framework';

export const isProd = process.env.REACT_APP_ENVIRONMENT === 'production';

export const HEADER_TOKEN_NAME = 'X-IAA-OW-ID';
export const HEADER_BASE_URL_NAME = 'Base-Url';
export const IQM_BASE_URL = process.env.REACT_APP_IQM_BASE_URL || '';
export const IQM_LOGIN_BASE_URL = process.env.REACT_APP_IQM_LOGIN_BASE_URL || '';
export const PANDA_BASE_URL = process.env.REACT_APP_PANDA_BASE_URL || '';
export const BASE_URL = '/';

export const IQM_API_URL = `${IQM_BASE_URL}/api/v2`;
export const IQM_API_URL_V3 = `${ORIGIN_URL}/api/v3`;
export const IQM_LOGIN_URL = `${IQM_LOGIN_BASE_URL}/api/v2`;
export const PANDA_API_URL = `${PANDA_BASE_URL}/api/v1`;

export const DOWNLOAD_CSV_URL = process.env.REACT_APP_REPORT_DOWNLOAD_CSV_BASE_URL;
export const LAMBDA_API_URL = `${ORIGIN_URL}/api/v2/rb`;
export const LAMBDA_API_URL_DAS = `${ORIGIN_URL}/api/v2/das`;
export const LAMBDA_API_URL_V3 = `${ORIGIN_URL}/api/v3/report`;
export const LAMBDA_BASE_URL_HEADER = ORIGIN_URL.replace('https://', '');
