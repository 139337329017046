import { AppState } from '../store';
import { CampaignStatusType, IOCampaign } from './Campaign';
import { Option } from './Option';
import { getSelectedCreativeTypes, getFilterStatus } from '../store/filter/selectors';
import { IOCampaignsParams } from '../api/InsertionOrder';

export interface InsertionOrderBasic {
  ioId: number;
  ioName: string;
  ioStartTime: number;
  ioEndTime: number;
  ioTimeZoneId: number;
  ioTotalBudget: number;
  ioBudgetDistributionMethodId: number;
  isBudgetAutoSum: boolean;
  ioStatusId: number;
  ioBudgetTypeId: number;
}

export interface InsertionOrder {
  ioName: string;
  ioId: number;
  ioBudgetTypeId: number;
  campaignCount: number;
  lastModified: number;
  campaigns: IOCampaign[];
}

export interface InsertionOrderOption {
  ioId: number;
  ioName: string;
  ioTimezone: number;
  ioStartTime: number;
  ioEndTime: number;
  value: number;
  label: string;
  id: number;
  ioStatusId: number;
}

export const getInsertionOrderCampaignListParams = (state: AppState) => {
  const { filter, auth } = state;

  const creativeTypeIds = getSelectedCreativeTypes(state).map((option: Option) => option.value);
  const status = getFilterStatus(state).value !== 'all' ? getFilterStatus(state).value : '';
  const ioIds = filter.selectedInsertionOrders || ([] as number[]);
  const campaignTypeIds = filter.campaignTypeIds || ([] as number[]);
  const ioBudgetTypes = filter.ioBudgetTypes || ([] as number[]);

  return {
    creativeTypeIds,
    ...(status ? { status: [status] as CampaignStatusType[] } : {}),
    ...((auth.userData.isPlatformOwnerOrg || auth.userData.isWorkspaceOwnerOrg) &&
    filter.advertisersOwIds
      ? { owIds: filter.advertisersOwIds.split(',').map((owId) => +owId) }
      : {}),
    ioIds,
    ...(campaignTypeIds.length ? { campaignTypeIds } : {}),
    ...(ioBudgetTypes.length ? { ioBudgetTypeIds: ioBudgetTypes } : {}),
  } as IOCampaignsParams;
};

export const DEFAULT_IO_TABLE_SORTING = {
  field: 'ioId',
  direction: 'desc',
};

export enum IOBudgetActions {
  SET = 'set',
  ADD = 'add',
}

export const ioTotalBudgetOptions = [
  { title: '= Set', value: IOBudgetActions.SET },
  { title: '+ Add', value: IOBudgetActions.ADD },
];
