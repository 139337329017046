import React from 'react';
import { Tooltip, Icon } from 'factor';
import { Campaign, CampaignType } from '../../../../../../../models/Campaign';
import styles from './styles.module.scss';
import { AppState } from '../../../../../../../store';
import { connect } from 'react-redux';
import moment from 'moment';
import { INSIGHTS_URL } from '../../../../../../../constants/url';
import { getPathNumber } from 'iqm-framework';
import { User } from '../../../../../../../models/User';
import { showCampaignInsights } from '../../../../../../../utils/showCampaignInsights';

interface Props {
  selectedCampaigns: Campaign[];
  isAppAllowed: boolean;
  userData: User;
}

const BiddingInsightsItemComponent = (props: Props) => {
  const { selectedCampaigns, isAppAllowed, userData } = props;

  const selectedCampaign = selectedCampaigns[0];

  const isSingleSelect = selectedCampaigns.length === 1;

  const isViewInsightsDisabled =
    !['expired', 'paused', 'running'].includes(selectedCampaign.status) ||
    selectedCampaign.campaignTypeId === CampaignType.PG;

  const isCampaignTooOld =
    selectedCampaign.status === 'expired' &&
    Math.abs(
      moment
        .tz(moment().utc(), 'UTC')
        .tz(moment.tz.guess())
        .unix() - selectedCampaign.endTime,
    ) /
      (60 * 60 * 24) >
      14;

  const isPGCampaignSelected = selectedCampaigns[0].campaignTypeId === CampaignType.PG;

  const handleInsightClick = () => {
    const selectedCampaign = selectedCampaigns[0];
    const link = `${INSIGHTS_URL}/u/${getPathNumber()}/#/campaign-bidding-insights/${selectedCampaign.campaignId.toString()}`;
    window.open(link, '_blank');
  };

  const isUserAllowedForBiddingInsights = showCampaignInsights(userData?.email);

  if (!isAppAllowed || !isUserAllowedForBiddingInsights) {
    return null;
  }

  return (
    <Tooltip label={'View Bidding Insights'} position="top">
      <button
        className={`btn-select__btn ${styles.listBtn}`}
        onClick={handleInsightClick}
        disabled={
          !isSingleSelect || isViewInsightsDisabled || isCampaignTooOld || isPGCampaignSelected
        }
      >
        <Icon name="Insights" className={styles.icon} />
        View Bidding Insights
      </button>
    </Tooltip>
  );
};

const mapState = (state: AppState) => ({
  userData: state.auth.userData,
  selectedCampaigns: state.table.selectedTableCampaigns,
});

export const BiddingInsightsItem = connect<any, any, any, any>(
  mapState,
  null,
)(BiddingInsightsItemComponent);
