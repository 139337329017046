import isEmpty from 'lodash/isEmpty';
import { toDataDogCampaignAction, DataDogCampaignUpdateActionTypes } from './index';
import { DataDogLogger } from '../index';
import { Campaign, CampaignBudgetType } from '../../../models/Campaign';

type TFailureTransformationReturnType = Partial<Omit<Campaign, 'budgetDay' | 'budgetTotal'>> & {
  budgetTotal?: string | number;
  budgetDay?: string | number;
};

type TFailureTransformation = (item: Partial<Campaign>) => TFailureTransformationReturnType;

export const logUpdatedCampaigns = (
  updatedCampaignIds: number[],
  allCampaigns: Campaign[],
  actionType: DataDogCampaignUpdateActionTypes,
  latency: number | null,
  modifiedData?: Partial<Campaign>[],
  transformFailureItem?: TFailureTransformation,
  editType?: 'inline' | 'bulk',
) => {
  const successAction = `${actionType}Succeeded`;
  const failureAction = `${actionType}Failed`;

  allCampaigns.forEach((campaign) => {
    const modifiedItem =
      modifiedData && Array.isArray(modifiedData)
        ? modifiedData.find((data) => data.campaignId === campaign.campaignId) || {}
        : {};
    if (
      (modifiedData && !isEmpty(modifiedItem)) ||
      updatedCampaignIds.find((updatedId) => updatedId === +campaign.campaignId)
    ) {
      if (DataDogLogger.Campaigns[successAction]) {
        DataDogLogger.Campaigns[successAction](
          toDataDogCampaignAction({
            ...campaign,
            ...modifiedItem,
            latency,
            editType,
            success: true,
          }),
        );
      }
    } else if (DataDogLogger.Campaigns[failureAction]) {
      if (transformFailureItem) {
        DataDogLogger.Campaigns[failureAction](
          toDataDogCampaignAction({
            ...transformFailureItem(campaign),
            latency,
            editType,
            success: false,
          }),
        );
      } else {
        DataDogLogger.Campaigns[failureAction](
          toDataDogCampaignAction({
            ...campaign,
            latency,
            editType,
            success: false,
          }),
        );
      }
    }
  });
};

export const getBudgetFailureTransformation = (
  budgetType: CampaignBudgetType,
  value: number,
  selectedTableCampaigns: Campaign[],
  distributionMethod?: 'change' | 'addition' | 'distribution',
  id?: number,
): TFailureTransformation => {
  let transformFailureItems: TFailureTransformation = (item: Partial<Campaign>) => item;
  if (budgetType === 'totalBudget') {
    switch (distributionMethod) {
      case 'addition':
        transformFailureItems = (item: Partial<Campaign>) => ({
          ...item,
          budgetDay: 'unknown',
          budgetTotal: (item.budgetTotal || 0) + value,
        });
        break;
      case 'distribution':
        transformFailureItems = (item: Partial<Campaign>) => ({
          ...item,
          budgetDay: 'unknown',
          budgetTotal: value / (id ? 1 : selectedTableCampaigns.length),
        });
        break;
      case 'change':
      default:
        transformFailureItems = (item: Partial<Campaign>) => ({
          ...item,
          budgetDay: 'unknown',
          budgetTotal: value,
        });
        break;
    }
  } else if (budgetType === 'dailyBudget') {
    switch (distributionMethod) {
      case 'addition':
        transformFailureItems = (item: Partial<Campaign>) => ({
          ...item,
          budgetTotal: item.budgetTotal || 'unknown',
          budgetDay: (item.budgetDay || 0) + value,
        });
        break;
      case 'distribution':
        transformFailureItems = (item: Partial<Campaign>) => ({
          ...item,
          budgetTotal: item.budgetTotal || 'unknown',
          budgetDay: value / (id ? 1 : selectedTableCampaigns.length),
        });
        break;
      case 'change':
      default:
        transformFailureItems = (item: Partial<Campaign>) => ({
          ...item,
          budgetTotal: item.budgetTotal || 'unknown',
          budgetDay: value,
        });
        break;
    }
  } else if (budgetType === 'maxBid') {
    transformFailureItems = (item: Partial<Campaign>) => ({
      ...item,
      maxBid: value,
    });
  }
  return transformFailureItems;
};

export const getEndDateFailureTransformation = (endTime: number): TFailureTransformation => {
  return (item: Partial<Campaign>) => ({
    ...item,
    budgetDay: item.budgetPacing ? 'unknown' : item.budgetDay,
    endTime,
  });
};
