import React from 'react';
import { IO_BUDGET_TYPE_ID } from 'iqm-framework';
import { PacingProgressBar } from '../PacingProgressBar';
import { getPacingClassName, PercentFormat } from '../utils';
import { PercentFormat as PercentFormatRounded } from '../../../../../../../utils/format';
import styles from './styles.module.scss';

interface Props {
  actualSpent: number;
  expectedSpent: number;
  totalDuration?: number;
  remainingDuration?: number;
  endTime?: number | null;
  pacingPercentage: number;
  budgetTypeId: number;
  isStatusExpired: boolean;
  isStatusDeleted: boolean;
}

export const TotalPacingCell = (props: Props) => {
  const {
    pacingPercentage,
    endTime,
    actualSpent,
    expectedSpent,
    totalDuration = 0,
    remainingDuration = 0,
    budgetTypeId,
    isStatusExpired,
    isStatusDeleted,
  } = props;

  const pacingClasses = getPacingClassName(
    endTime ? (actualSpent / (expectedSpent || 1)) * 100 : null,
  );
  if (!endTime) {
    pacingClasses.text = styles.darkText;
  }
  const { text: pacingTextClassName, background: pacingBackgroundClassName } = pacingClasses;

  let daysLeftText = <span className={styles.darkText}>{remainingDuration} days left</span>;
  if (!endTime) {
    daysLeftText = <span className={getPacingClassName(null).text}>No end date</span>;
  }
  if (isStatusDeleted || isStatusExpired) {
    daysLeftText = (
      <span className={getPacingClassName(null).text}>
        {isStatusExpired ? 'Expired' : 'Deleted'}
      </span>
    );
  }

  let progressPercent =
    typeof totalDuration === 'number' && typeof remainingDuration === 'number'
      ? ((totalDuration - remainingDuration) / totalDuration) * 100
      : 0;
  if (isStatusExpired) {
    progressPercent = 100;
  }
  if (!endTime) {
    progressPercent = 0;
  }

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.progressBarCol}>
          <PacingProgressBar
            progressPercent={pacingPercentage}
            fillClassName={pacingBackgroundClassName}
          />
        </div>
        <div className={styles.textCol}>
          <span className={pacingTextClassName}>
            <span className={styles.demi}>
              {pacingPercentage > 999
                ? PercentFormatRounded.format(pacingPercentage / 100)
                : PercentFormat.format(pacingPercentage / 100)}
            </span>{' '}
            {budgetTypeId === IO_BUDGET_TYPE_ID.IMPRESSIONS_BASED ? 'served' : 'spent'}
          </span>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.progressBarCol}>
          <PacingProgressBar
            fillClassName={getPacingClassName(null).background}
            progressPercent={progressPercent}
          />
        </div>
        <div className={styles.textCol}>{daysLeftText}</div>
      </div>
    </div>
  );
};
