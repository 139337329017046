import axios, { Canceler } from 'axios';
import { getLambdaInstance } from './index';
import { Response, LambdaResponse } from '../../models/Response';

let cancel: Canceler;

export function cancelGetData() {
  if (cancel) {
    cancel('Canceled by the user');
  }
}

export function getData(query): Promise<Response<LambdaResponse>> {
  if (cancel) {
    cancel('Canceled by the user');
  }
  return getLambdaInstance()
    .post('/resultDashboard', query, {
      cancelToken: new axios.CancelToken((c) => {
        cancel = c;
      }),
    })
    .then((response) => response.data)
    .catch((err) => Promise.reject(err));
}
