import React from 'react';
import { connect } from 'react-redux';
import { DialogContent, Button } from 'factor';
import { getPathNumber } from 'iqm-framework';

import { AppState } from '../../../../../../../store';
import { Campaign, CampaignType } from '../../../../../../../models/Campaign';
import { DataDogLogger } from '../../../../../../../services/DataDog';

interface Props {
  selectedCampaigns: Campaign[];
  onClose: () => void;
}

const CAMPAIGN_BUILDER_URL = '/campaigns';

class EditDialogContentComponent extends React.Component<Props> {
  editHandler = (campaign: Campaign) => {
    const { campaignId: id, campaignTypeId } = campaign;
    DataDogLogger.Campaigns.clickOnEditCampaign();
    window.open(
      encodeURI(
        `${CAMPAIGN_BUILDER_URL}/u/${getPathNumber()}/#/${
          campaignTypeId === CampaignType.ADVANCED ? 'campaign-edit' : 'campaign-edit-pg'
        }/${id}`,
      ),
      '_blank',
    );
  };

  componentDidMount() {
    if (this.props.selectedCampaigns.length === 1) {
      this.editHandler(this.props.selectedCampaigns[0]);
      this.props.onClose();
    }
  }

  render() {
    const { onClose, selectedCampaigns } = this.props;

    const dialogContent =
      selectedCampaigns.length > 1 ? (
        <DialogContent>
          <h3 className="title-card mb-4">Edit Campaign</h3>
          <h5 className="title-card-subtitle mb-4">Please select only one Campaign</h5>
          {selectedCampaigns.length > 1 && (
            <div className="d-flex justify-content-end mt-2">
              <Button className="btn-square _conflower-blue _filled" onClick={onClose}>
                Ok
              </Button>
            </div>
          )}
        </DialogContent>
      ) : null;

    return dialogContent;
  }
}

const mapState = (state: AppState) => ({
  selectedCampaigns: state.table.selectedTableCampaigns,
});

export const EditDialogContent = connect(mapState)(EditDialogContentComponent);
