import { ColumnOptions } from 'iqm-framework';
import { cloneDeep } from 'lodash';

import { showVldInsightsCost } from '../utils/showColumns';

export type Option<T = any> = {
  label: string;
  value: T;
};

export interface OptionID extends Option {
  id: number;
  options?: Option[];
}

export interface OptionIDWithPayload<T> extends OptionID {
  payload?: T;
  reactLabel?: any;
}

export interface StickedOption<T = any> extends Option<T> {
  alwaysEnabled?: boolean;
  isGroupTitle?: boolean;
  active?: boolean;
  order?: number;
}

export interface OrderedOption {
  id: number;
  name: string;
  label: string;
  order: number;
  count?: number;
}

export type Select = Array<Option> | null;

export const toGroupMapper = (cols: (StickedOption & { options?: StickedOption[] })[]) => {
  return cols.reduce(
    (acc, curr) => ({
      ...acc,
      ...(curr.value && curr.options ? { [curr.value]: curr.options.map((opt) => opt.value) } : {}),
    }),
    {},
  );
};

export const flattenOptions = (options: StickedOption[]) => {
  return cloneDeep(options).reduce((acc, curr) => {
    if (Array.isArray(curr.options)) {
      return acc.concat(curr.options);
    } else {
      return [...acc, curr];
    }
  }, [] as any[]);
};

//  Extract the 'value' field from saved Option objects
export const toValues = (obj: { [key: string]: any }) => {
  const res: any = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] && obj[key].value && obj[key].label) {
      res[key] = obj[key].value;
    } else if (obj[key] && Array.isArray(obj[key])) {
      if (obj[key].length && obj[key].every((item) => item && item.value && item.label)) {
        res[key] = obj[key].map((item) => item.value);
      } else if (obj[key].every((item) => typeof item === 'number' || typeof item === 'string')) {
        res[key] = obj[key].slice().sort();
      } else {
        res[key] = obj[key];
      }
    } else {
      res[key] = obj[key];
    }
  });
  return cloneDeep(res);
};

const orgNameObj = {
  value: 'organizationName',
  label: 'Organization',
  draggableGroup: 1,
};

const IONameCol = {
  label: 'Insertion Order Name',
  value: 'ioName',
  draggableGroup: 1,
};

const IOEndDateCol = {
  label: 'IO End Date',
  value: 'ioEndTime',
  draggableGroup: 1,
};

export const IOPacingCol = {
  value: 'ioPacingPercentage',
  label: 'IO Pacing',
};

const campaignDailyBudgetCol = { ...ColumnOptions.ColumnDailyBudget, label: 'Daily Budget' };

const campaignTotalBudgetCol = { ...ColumnOptions.ColumnTotalBudget, label: 'Total Budget' };

const campaignTypeCol = { value: 'campaignTypeId', label: 'Campaign Type' };

const creativesCountCol = {
  value: 'creativesCount',
  label: 'Creatives',
  draggableGroup: 1,
};

const campaignStatusCol = {
  value: 'status',
  label: 'Status',
  draggableGroup: 1,
};

export const campaignDailyPacingCol = {
  value: 'dailyPacingPercentage',
  label: 'Daily Pacing',
};

export const campaignPacingCol = {
  value: 'pacingPercentage',
  label: 'Campaign Pacing',
};

export const ivtImpressionsCol = {
  value: 'ivtImpressions',
  label: 'IVT Impressions',
};

export const ivtClicksCol = {
  value: 'ivtClicks',
  label: 'IVT Clicks',
};

export const vldInsightsCostCol = {
  label: 'VLD Insights Cost ($)',
  value: 'vldInsightsCost',
};

export const platformVldInsightsEarningsCol = {
  label: 'Platform VLD Insights Earnings ($)',
  value: 'platformVLDInsightsEarnings',
};

export const workspaceVldInsightsEarningsCol = {
  label: 'Workspace VLD Insights Earnings ($)',
  value: 'workspaceVLDInsightsEarnings',
};

export const sortingColumnOptions: StickedOption[] = [
  {
    label: 'ID',
    value: 'campaignId',
    alwaysEnabled: true,
    draggableGroup: 1,
  },
  ColumnOptions.ColumnCampaignName,
  IONameCol,
  IOEndDateCol,
  orgNameObj,
  campaignStatusCol,
  creativesCountCol,
  campaignDailyBudgetCol,
  campaignTotalBudgetCol,
  ColumnOptions.ColumnMaxBid,
  ColumnOptions.ColumnBidShadingSavings,
  campaignPacingCol,
  campaignDailyPacingCol,
  { value: 'percentageOfTotalSpent', label: 'Budget Completion(%)' },
  ColumnOptions.ColumnImpressions,
  ColumnOptions.ColumnClicks,
  ColumnOptions.ColumnCtr,
  ColumnOptions.ColumnTotalSpent,
  // ColumnOptions.ColumnGoal, // temporarily hidden
  ColumnOptions.ColumnWinrate,
  { value: 'timezone', label: 'Time Zone', draggableGroup: 1 },
  ColumnOptions.ColumnStartDate,
  ColumnOptions.ColumnEndDate,
  ColumnOptions.ColumnEcpm,
  ColumnOptions.ColumnEcpc,
  ColumnOptions.ColumnDataCost,
  ColumnOptions.ColumnPreBidCost,
  ColumnOptions.ColumnMediaSpent,
  ColumnOptions.ColumnVcr,
  ColumnOptions.ColumnStartCount,
  ColumnOptions.ColumnFirstCount,
  ColumnOptions.ColumnMidCount,
  ColumnOptions.ColumnThirdCount,
  ColumnOptions.ColumnCompleteCount,
  ColumnOptions.ColumnCpcv,
  ColumnOptions.ColumnHoursViewed,
  campaignTypeCol,
  vldInsightsCostCol,
  platformVldInsightsEarningsCol,
  workspaceVldInsightsEarningsCol,
];

const WorkspaceSpentColumn: StickedOption = {
  label: 'Workspace Spent($)',
  value: 'workspaceSpent',
};

const PlatformSpentColumn: StickedOption = {
  label: 'Platform Spent($)',
  value: 'platformSpent',
};

const WorkspaceEarningColumn: StickedOption = {
  label: 'Workspace Media Earnings ($)',
  value: 'workspaceMediaEarning',
};

const PlatformEarningColumn: StickedOption = {
  label: 'Platform Media Earnings ($)',
  value: 'platformMediaEarning',
};

const WorkspaceAdvertiserCommonColumns = [
  {
    value: 'Budget',
    label: 'Budget',
    isGroupTitle: true,
    options: [
      ColumnOptions.ColumnTotalBudget,
      ColumnOptions.ColumnDailyBudget,
      ColumnOptions.ColumnBidShadingSavings,
    ],
  },
  {
    value: 'Performance',
    label: 'Performance',
    isGroupTitle: true,
    options: [
      ColumnOptions.ColumnImpressions,
      ColumnOptions.ColumnClicks,
      ColumnOptions.ColumnCtr,
      ColumnOptions.ColumnWinrate,
      ColumnOptions.ColumnVcr,
    ],
  },
  {
    value: 'Conversions',
    label: 'Conversions',
    isGroupTitle: true,
    options: [
      ColumnOptions.ColumnTotalAttributedConversion,
      ColumnOptions.ColumnTotalAttributedViewThroughConversion,
      ColumnOptions.ColumnTotalAttributedClickThroughConversion,
      ColumnOptions.ColumnCostPerAttributedConversion,
      ColumnOptions.ColumnTotalAttributedConversionRate,
    ].filter((c) => !!c),
  },
  {
    value: 'Spending',
    label: 'Spending',
    isGroupTitle: true,
    options: [
      ColumnOptions.ColumnDataCost,
      ColumnOptions.ColumnPreBidCost,
      ColumnOptions.ColumnMediaSpent,
      vldInsightsCostCol,
      ColumnOptions.ColumnTotalSpent,
    ],
  },
];

const workspaceUniqueColumns = [
  {
    label: 'ID',
    value: 'workspaceId',
    alwaysEnabled: true,
    draggableGroup: 1,
  },
  {
    label: 'Workspace Name',
    value: 'workspaceName',
    alwaysEnabled: true,
    draggableGroup: 1,
  },
];

const advertiserUniqueColumns = [
  {
    label: 'ID',
    value: 'owId',
    alwaysEnabled: true,
    draggableGroup: 1,
  },
  {
    label: 'Advertiser Name',
    value: 'organizationName',
    alwaysEnabled: true,
    draggableGroup: 1,
  },
];

interface GetWorkspaceColumnOptionsParams {
  withGroups?: boolean;
}

export const getWorkspaceColumnOptions = (
  params: GetWorkspaceColumnOptionsParams,
): StickedOption[] => {
  const { withGroups } = params;

  let cols = cloneDeep(WorkspaceAdvertiserCommonColumns);

  let res: StickedOption[] = [];
  const earningsOptions = {
    label: 'Earnings',
    value: 'Earnings',
    isGroupTitle: true,
    options: [
      WorkspaceEarningColumn,
      PlatformEarningColumn,
      ColumnOptions.ColumnTotalPlatformEarning,
      platformVldInsightsEarningsCol,
      ColumnOptions.ColumnPlatformBidShadingEarning,
      ColumnOptions.ColumnTotalWorkspaceEarning,
      workspaceVldInsightsEarningsCol,
      ColumnOptions.ColumnWorkspaceBidShadingEarning,
    ],
  };

  cols[cols.length - 1].options.push(WorkspaceSpentColumn);
  cols[cols.length - 1].options.push(PlatformSpentColumn);
  cols.push(earningsOptions);

  if (withGroups) {
    res = [...workspaceUniqueColumns, ...cols];
  } else {
    const options = cols.reduce(
      (currentCols, group) => currentCols.concat(group.options),
      [] as StickedOption[],
    );
    res = [...workspaceUniqueColumns, ...options];
  }
  return res.filter((i) => !!i);
};

interface GetAdvertiserColumnOptionsParams {
  withGroups: boolean;
  isIqmSuper: boolean;
}

export const getAdvertiserColumnOptions = (
  params: GetAdvertiserColumnOptionsParams,
): StickedOption[] => {
  const { withGroups, isIqmSuper } = params;

  let columns = cloneDeep(WorkspaceAdvertiserCommonColumns);

  const earningsOptions = {
    label: 'Earnings',
    value: 'Earnings',
    isGroupTitle: true,
    options: [
      ...(isIqmSuper
        ? [
            PlatformEarningColumn,
            ColumnOptions.ColumnTotalPlatformEarning,
            platformVldInsightsEarningsCol,
            ColumnOptions.ColumnPlatformBidShadingEarning,
            ColumnOptions.ColumnTotalWorkspaceEarning,
            workspaceVldInsightsEarningsCol,
            ColumnOptions.ColumnWorkspaceBidShadingEarning,
            WorkspaceEarningColumn,
          ]
        : [
            WorkspaceEarningColumn,
            ColumnOptions.ColumnTotalWorkspaceEarning,
            workspaceVldInsightsEarningsCol,
            ColumnOptions.ColumnWorkspaceBidShadingEarning,
          ]),
    ],
  };

  columns[columns.length - 1].options.push(WorkspaceSpentColumn);
  if (isIqmSuper) {
    columns[columns.length - 1].options.push(PlatformSpentColumn);
  }
  columns.push(earningsOptions);

  if (withGroups) {
    return [...advertiserUniqueColumns, ...columns];
  }

  const options = columns.reduce(
    (currentCols, group) => currentCols.concat(group.options),
    [] as StickedOption[],
  );
  return [...advertiserUniqueColumns, ...options];
};

export const getExchangesColumnOptions = (withGroups: boolean): StickedOption[] => {
  const alwaysEnabledCols = [
    {
      label: 'ID',
      value: 'exchangeId',
      alwaysEnabled: true,
      draggableGroup: 1,
    },
    {
      label: 'Exchange Name',
      value: 'exchangeName',
      alwaysEnabled: true,
      draggableGroup: 1,
    },
  ];

  const cols = [
    {
      value: 'Performance',
      label: 'Performance',
      isGroupTitle: true,
      options: [
        ColumnOptions.ColumnImpressions,
        ivtImpressionsCol,
        ColumnOptions.ColumnClicks,
        ivtClicksCol,
        ColumnOptions.ColumnCtr,
        ColumnOptions.ColumnWinrate,
        ColumnOptions.ColumnVcr,
      ],
    },
    {
      value: 'Spending',
      label: 'Spending',
      isGroupTitle: true,
      options: [
        ColumnOptions.ColumnTotalSpent,
        WorkspaceSpentColumn,
        PlatformSpentColumn,
        ColumnOptions.ColumnPreBidCost,
      ],
    },
  ];

  if (withGroups) {
    return [...alwaysEnabledCols, ...cols];
  }

  const options = cols.reduce(
    (currentCols, group) => currentCols.concat(group.options),
    [] as StickedOption[],
  );
  return [...alwaysEnabledCols, ...options];
};

export const getInsertionOrderColumnOptions = ({
  isWorkspaceOwnerOrg,
  isPlatformOwnerOrg,
  isVldEnabled,
  hadVldGenerated,
}: {
  isWorkspaceOwnerOrg: boolean;
  isPlatformOwnerOrg: boolean;
  isVldEnabled: boolean;
  hadVldGenerated: boolean;
}): StickedOption[] =>
  [
    {
      label: 'ID',
      value: 'ioId',
      alwaysEnabled: true,
      draggableGroup: 1,
      order: 1,
    },
    {
      label: 'Insertion Order Name',
      value: 'ioName',
      alwaysEnabled: true,
      draggableGroup: 1,
      order: 2,
    },
    {
      label: 'Insertion Order Specifics',
      value: 'Insertion Order Specifics',
      isGroupTitle: true,
      options: [
        ...(isWorkspaceOwnerOrg || isPlatformOwnerOrg
          ? [
              {
                label: 'Organization Name',
                value: 'organizationName',
                draggableGroup: 1,
                order: 3,
              },
            ]
          : []),
        {
          label: 'Status',
          value: 'ioStatusId',
          draggableGroup: 1,
          order: 4,
        },
        {
          label: 'Campaigns',
          value: 'campaignsCount',
          draggableGroup: 1,
          order: 5,
        },
        {
          label: 'Start Date',
          value: 'ioStartTime',
          order: 6,
        },
        {
          label: 'End Date',
          value: 'ioEndTime',
          order: 7,
        },
      ],
    },
    {
      value: 'Budget',
      label: 'Budget',
      isGroupTitle: true,
      options: [
        { label: 'Total Budget', value: 'ioTotalBudget', order: 8 },
        { ...IOPacingCol, order: 9 },
      ],
    },
    {
      label: 'Spending',
      value: 'Spending',
      isGroupTitle: true,
      options: [
        { ...ColumnOptions.ColumnTotalSpent, order: 10 },
        { ...ColumnOptions.ColumnMediaSpent, order: 11 },
        ...(showVldInsightsCost({
          isWorkspaceOwnerOrg,
          isPlatformOwnerOrg,
          isVldEnabled,
          hadVldGenerated,
        })
          ? [{ ...vldInsightsCostCol, order: 12 }]
          : []),
      ],
    },
    {
      value: 'Performance',
      label: 'Performance',
      isGroupTitle: true,
      options: [
        { ...ColumnOptions.ColumnImpressions, order: 13 },
        ...(isPlatformOwnerOrg ? [{ ...ivtImpressionsCol, order: 14 }] : []),
        { ...ColumnOptions.ColumnClicks, order: 15 },
        ...(isPlatformOwnerOrg ? [{ ...ivtClicksCol, order: 16 }] : []),
        { ...ColumnOptions.ColumnReach, order: 17 },
        { ...ColumnOptions.ColumnFrequency, order: 18 },
      ],
    },
    {
      value: 'Conversions',
      label: 'Conversions',
      isGroupTitle: true,
      options: [{ ...ColumnOptions.ColumnTotalAttributedConversion, order: 16 }],
    },
  ].filter((opt) => !!opt.value) as StickedOption[];

export const getColumns = ({
  isBetaUser,
  isPlatformOwnerOrg,
  isWorkspaceOwnerOrg,
  isBidShadingEnabled,
  isVldEnabled,
  hadVldGenerated,
}: {
  isBetaUser?: boolean;
  isPlatformOwnerOrg?: boolean;
  isWorkspaceOwnerOrg?: boolean;
  isBidShadingEnabled?: boolean;
  isVldEnabled?: boolean;
  hadVldGenerated?: boolean;
}) => [
  {
    label: 'ID',
    value: 'campaignId',
    alwaysEnabled: true,
    draggableGroup: 1,
  },
  ColumnOptions.ColumnCampaignName,
  {
    value: 'Insertion Order Specifics',
    label: 'Insertion Order Specifics',
    isGroupTitle: true,
    options:
      isWorkspaceOwnerOrg || isPlatformOwnerOrg
        ? [IONameCol, IOEndDateCol, orgNameObj]
        : [IONameCol, IOEndDateCol],
  },
  {
    value: 'Campaign Specifics',
    label: 'Campaign Specifics',
    isGroupTitle: true,
    options: [
      campaignStatusCol,
      creativesCountCol,
      { value: 'timezone', label: 'Time Zone' },
      ColumnOptions.ColumnStartDate,
      ColumnOptions.ColumnEndDate,
      campaignTypeCol,
    ],
  },
  {
    value: 'Budget',
    label: 'Budget',
    isGroupTitle: true,
    options: [
      campaignDailyBudgetCol,
      campaignTotalBudgetCol,
      ColumnOptions.ColumnMaxBid,
      ...(isBidShadingEnabled ? [ColumnOptions.ColumnBidShadingSavings] : []),
      { value: 'percentageOfTotalSpent', label: 'Budget Completion(%)' },
      campaignPacingCol,
      campaignDailyPacingCol,
    ],
  },
  ...(isWorkspaceOwnerOrg || isPlatformOwnerOrg
    ? [
        {
          value: 'Earnings',
          label: 'Earnings',
          isGroupTitle: true,
          options: [
            ...(isWorkspaceOwnerOrg || isPlatformOwnerOrg
              ? [
                  WorkspaceEarningColumn,
                  ColumnOptions.ColumnTotalWorkspaceEarning,
                  workspaceVldInsightsEarningsCol,
                  ColumnOptions.ColumnWorkspaceBidShadingEarning,
                ]
              : []),
            ...(isPlatformOwnerOrg
              ? [
                  PlatformEarningColumn,
                  ColumnOptions.ColumnTotalPlatformEarning,
                  platformVldInsightsEarningsCol,
                  ColumnOptions.ColumnPlatformBidShadingEarning,
                ]
              : []),
          ],
        },
      ]
    : []),
  {
    value: 'Performance',
    label: 'Performance',
    isGroupTitle: true,
    options: [
      ColumnOptions.ColumnImpressions,
      ...(isPlatformOwnerOrg ? [ivtImpressionsCol] : []),
      ColumnOptions.ColumnClicks,
      ...(isPlatformOwnerOrg ? [ivtClicksCol] : []),
      ColumnOptions.ColumnReach,
      ...(isBetaUser
        ? [
            ColumnOptions.ColumnCtr,
            ColumnOptions.ColumnWinrate,
            ColumnOptions.ColumnVcr,
            ColumnOptions.ColumnViewability,
            ColumnOptions.ColumnFrequency,
          ]
        : [
            ColumnOptions.ColumnCtr,
            ColumnOptions.ColumnWinrate,
            ColumnOptions.ColumnVcr,
            ColumnOptions.ColumnFrequency,
          ]),
    ],
  },
  {
    value: 'Spending',
    label: 'Spending',
    isGroupTitle: true,
    options: [
      ColumnOptions.ColumnTotalSpent,
      ColumnOptions.ColumnDataCost,
      ColumnOptions.ColumnPreBidCost,
      ColumnOptions.ColumnMediaSpent,
      ...(showVldInsightsCost({
        isPlatformOwnerOrg,
        isWorkspaceOwnerOrg,
        isVldEnabled,
        hadVldGenerated,
      })
        ? [vldInsightsCostCol]
        : []),
      ...(isWorkspaceOwnerOrg || isPlatformOwnerOrg ? [WorkspaceSpentColumn] : []),
      ...(isPlatformOwnerOrg ? [PlatformSpentColumn] : []),
    ],
  },
  {
    value: 'Cost',
    label: 'Cost',
    isGroupTitle: true,
    options: [ColumnOptions.ColumnEcpm, ColumnOptions.ColumnEcpc, ColumnOptions.ColumnCpcv],
  },
  {
    value: 'Video',
    label: 'Video',
    isGroupTitle: true,
    options: [
      ColumnOptions.ColumnStartCount,
      ColumnOptions.ColumnFirstCount,
      ColumnOptions.ColumnMidCount,
      ColumnOptions.ColumnThirdCount,
      ColumnOptions.ColumnCompleteCount,
      ColumnOptions.ColumnHoursViewed,
    ],
  },
  {
    value: 'Conversions',
    label: 'Conversions',
    isGroupTitle: true,
    options: [
      ColumnOptions.ColumnTotalAttributedConversion,
      ColumnOptions.ColumnTotalAttributedViewThroughConversion,
      ColumnOptions.ColumnTotalAttributedClickThroughConversion,
      ColumnOptions.ColumnCostPerAttributedConversion,
      ColumnOptions.ColumnTotalAttributedConversionRate,
    ].filter((c) => !!c),
  },
];

const defaultMetrics: StickedOption[] = [
  ColumnOptions.ColumnImpressions,
  ColumnOptions.ColumnClicks,
  ColumnOptions.ColumnCtr,
  ColumnOptions.ColumnEcpm,
  ColumnOptions.ColumnVcr,
];

export const defaultsortingColumnOptions = [
  {
    label: 'ID',
    value: 'campaignId',
    alwaysEnabled: true,
    draggableGroup: 1,
  },
  ColumnOptions.ColumnCampaignName,
  IONameCol,
  orgNameObj,
  campaignStatusCol,
  creativesCountCol,
  ColumnOptions.ColumnStartDate,
  ColumnOptions.ColumnEndDate,
  campaignDailyBudgetCol,
  campaignTotalBudgetCol,
  ColumnOptions.ColumnMaxBid,
  campaignPacingCol,
  campaignDailyPacingCol,
  ColumnOptions.ColumnTotalSpent,
  { value: 'percentageOfTotalSpent', label: 'Budget Completion(%)' },
  ...defaultMetrics,
  ColumnOptions.ColumnWinrate,
  vldInsightsCostCol,
];

const nonDefaultInsertionOrderColumns = [
  ColumnOptions.ColumnFrequency.value,
  ColumnOptions.ColumnReach.value,
  ivtImpressionsCol.value,
  ivtClicksCol.value,
];

export const getDefaultSortingInsertionOrdersColumns = ({
  isPlatformOwnerOrg,
  isWorkspaceOwnerOrg,
  isVldEnabled = false,
  hadVldGenerated = false,
}: {
  isWorkspaceOwnerOrg: boolean;
  isPlatformOwnerOrg: boolean;
  isVldEnabled?: boolean;
  hadVldGenerated?: boolean;
}) =>
  flattenOptions(
    getInsertionOrderColumnOptions({
      isWorkspaceOwnerOrg,
      isPlatformOwnerOrg,
      isVldEnabled,
      hadVldGenerated,
    }) as StickedOption[],
  )
    .filter((col) => !nonDefaultInsertionOrderColumns.includes(col.value))
    .sort((a, b) => (a.order || 0) - (b.order || 0));

export const defaultSortingWorkspacesColumns = getWorkspaceColumnOptions({
  withGroups: false,
}).filter(
  (i) =>
    !!i &&
    i.value !== ColumnOptions.ColumnDataCost.value &&
    i.value !== PlatformEarningColumn.value &&
    i.value !== ColumnOptions.ColumnBidShadingSavings.value &&
    i.value !== ColumnOptions.ColumnTotalPlatformEarning.value &&
    i.value !== ColumnOptions.ColumnPlatformBidShadingEarning.value &&
    i.value !== ColumnOptions.ColumnTotalWorkspaceEarning.value &&
    i.value !== ColumnOptions.ColumnWorkspaceBidShadingEarning.value &&
    i.value !== WorkspaceEarningColumn.value &&
    i.value !== ColumnOptions.ColumnMediaSpent.value &&
    i.value !== platformVldInsightsEarningsCol.value &&
    i.value !== workspaceVldInsightsEarningsCol.value,
);

export const defaultSortingAdvertisersColumns = getAdvertiserColumnOptions({
  withGroups: false,
  isIqmSuper: true,
}).filter(
  (i) =>
    !!i &&
    i.value !== ColumnOptions.ColumnDataCost.value &&
    i.value !== PlatformEarningColumn.value &&
    i.value !== ColumnOptions.ColumnBidShadingSavings.value &&
    i.value !== ColumnOptions.ColumnTotalPlatformEarning.value &&
    i.value !== ColumnOptions.ColumnPlatformBidShadingEarning.value &&
    i.value !== ColumnOptions.ColumnTotalWorkspaceEarning.value &&
    i.value !== ColumnOptions.ColumnWorkspaceBidShadingEarning.value &&
    i.value !== WorkspaceEarningColumn.value &&
    i.value !== ColumnOptions.ColumnMediaSpent.value &&
    i.value !== platformVldInsightsEarningsCol.value &&
    i.value !== workspaceVldInsightsEarningsCol.value,
);

const nonDefaultExchangesColumns = [
  ColumnOptions.ColumnCtr.value,
  ColumnOptions.ColumnVcr.value,
  ivtImpressionsCol.value,
  ivtClicksCol.value,
];

export const defaultSortingExchangesColumns = getExchangesColumnOptions(false).filter(
  (i) => !!i && !nonDefaultExchangesColumns.includes(i.value),
);
