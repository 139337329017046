import React from 'react';
import { LambdaResponse } from '../../../../../../models/Response';
import { ClickableCountCell } from '../ClickableCountCell';

interface Props {
  campaign: LambdaResponse & { rowClassName?: string };
  onClick: () => void;
  rumLogActionName?: string;
}

export const CreativesCountCell = (props: Props) => {
  const { campaign, onClick, rumLogActionName } = props;
  const { creativesCount, rowClassName } = campaign;

  return (
    <ClickableCountCell
      onClick={onClick}
      rowClassName={rowClassName}
      tooltip="Click to view creatives"
      value={creativesCount}
      rumLogActionName={rumLogActionName}
    />
  );
};
