import { Response } from './Response';
import { Campaign } from './Campaign';

export type TableResponse = Response<Campaign>;

export type TableNameUpdateParams = {
  id: string | number;
  name: string;
};

export type TableSortingParams = {
  field: string;
  direction: 'asc' | 'desc';
};

export enum TableLevel {
  Campaigns = 'campaigns',
  Advertisers = 'advertisers',
  Workspaces = 'workspaces',
  Exchanges = 'exchanges',
  InsertionOrders = 'insertionOrders',
}
