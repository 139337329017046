import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import get from 'lodash/get';
import { DialogContent, Button } from 'factor';
import { TimezoneProvider, TimezonePicker, EpochDatePicker } from 'iqm-framework';
import { AppState } from '../../../../../../../store';
import { Campaign } from '../../../../../../../models/Campaign';
import { TableComponentInstance } from '../../../Table';
import { toastActions } from '../../../../../../../store/toast/actions';
import { ToastContent } from '../../../../../../../components/toastContent/ToastContent';
import { OptionID } from '../../../../../../../models/Option';
import { filterActions } from '../../../../../../../store/filter/actions';
import { tableActions, RestoreOrRunCampaigns } from '../../../../../../../store/table/actions';
import {
  statisticsActions,
  GetStatisticsTotalStart,
} from '../../../../../../../store/statistics/actions';
import { Spinner } from '../../../../../../../components/Spinner';

interface Props extends RestoreOrRunCampaigns, GetStatisticsTotalStart {
  selectedCampaigns: Campaign[];
  action: 'run' | 'restore';
  label: string;
  onClose: () => void;
  openToast: (message: string | JSX.Element) => void;
  timezone: OptionID;
  timezones: OptionID[];
  buttonLabel?: string;
  getStatuses: () => void;
}

interface State {
  value: number | null;
  changingStatus: 'loading' | 'success' | 'fail' | null;
}

const dataDogLabelMap = {
  run: 'Run Campaign',
  restore: 'Restore Campaign',
};

class RunningDialogContentComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      changingStatus: null,
    };
  }

  get timezone() {
    const { timezone, timezones } = this.props;
    let campaignTz = timezone;
    const selectedCampaignsTimezones = this.selectedCampaignsTimezones();

    if (selectedCampaignsTimezones.length === 1) {
      campaignTz = timezones.find((tz) => tz.value === selectedCampaignsTimezones[0]) || timezone;
    }

    return campaignTz;
  }

  selectedCampaignsTimezones = () => {
    const { selectedCampaigns } = this.props;
    return selectedCampaigns.reduce((acc: string[], campaign) => {
      if (!acc.includes(campaign.campaignTimezone)) {
        acc.push(campaign.campaignTimezone);
      }
      return acc;
    }, []);
  };

  editHandler = async () => {
    const {
      selectedCampaigns,
      openToast,
      label,
      getStatuses,
      action,
      restoreOrRunCampaigns,
      getStatisticsTotalStart,
    } = this.props;
    const { value } = this.state;

    this.setState({ changingStatus: 'loading' });

    let endDateTime;

    if (TableComponentInstance) {
      if (value) {
        const momentValue = moment(value);
        endDateTime =
          momentValue.diff(moment(), 'm') < 15
            ? moment()
                .add(15, 'm')
                .unix()
            : momentValue.unix();
      }

      try {
        const res = await restoreOrRunCampaigns(action, endDateTime);
        const response = get(res, 'data.responseObject', {});

        if (response.status) {
          const toastMessageCampaign =
            selectedCampaigns.length > 1
              ? 'Campaigns'
              : `Campaign ${selectedCampaigns[0].campaignName}`;
          let toastMessage = `${toastMessageCampaign} status changed`;
          getStatisticsTotalStart();
          TableComponentInstance.getNewData();
          getStatuses();
          openToast(toastMessage);
          this.setState(
            { changingStatus: 'success' },
            () => {
              setTimeout(() => TableComponentInstance.clearSelected(), 1100);
            }, // animation time + .1s
          );
        } else {
          const errors = get(response, 'failedData', []);
          if (errors.length) {
            const errorMessage = errors.map((e) => e.message);
            throw errorMessage;
          }
        }
      } catch (e) {
        let toastMessage = `Error while update ${label}`;
        getStatisticsTotalStart();
        TableComponentInstance.getNewData();
        openToast((e as any).length ? <ToastContent messages={e as string[]} /> : toastMessage);
        this.setState({ changingStatus: 'fail' });
      }
    }
  };

  isEndDateADayInFuture = (campaign: Campaign) => {
    return moment.tz(campaign.endTime * 1000, 'UTC').diff(moment.tz(moment(), 'UTC'), 'days') >= 1;
  };

  getDefaultDate = (tz) => {
    const { selectedCampaigns } = this.props;

    let defaultDate;

    if (selectedCampaigns.length === 1) {
      if (this.isEndDateADayInFuture(selectedCampaigns[0])) {
        defaultDate = moment(selectedCampaigns[0].endTime * 1000).tz(tz);
      } else {
        defaultDate = moment()
          .add(1, 'd')
          .tz(tz);
      }
    } else {
      defaultDate = moment()
        .add(1, 'd')
        .tz(tz);
    }

    return moment.tz(defaultDate.format('YYYY-MM-DD HH:mm:ss'), moment.tz.guess());
  };

  shouldPopulatedDefaultEndDate = () => {
    const { selectedCampaigns } = this.props;

    if (selectedCampaigns.length > 1) {
      return !(
        selectedCampaigns.every((c) => c.status === 'deleted') ||
        selectedCampaigns.every((c) => c.status === 'paused')
      );
    } else if (
      selectedCampaigns[0].status === 'deleted' &&
      selectedCampaigns[0].budgetPacing === true
    ) {
      return false;
    } else {
      return Boolean(selectedCampaigns[0].endTime);
    }
  };

  onDateChanged = (value) => {
    this.setState({
      value,
    });
  };

  datePickerErrorHandler = (message) => {
    this.props.openToast(message);
  };

  render() {
    const { onClose, selectedCampaigns, label, buttonLabel, timezone, action } = this.props;
    const { value, changingStatus } = this.state;

    const moreThenOneTimezoneSelected = this.selectedCampaignsTimezones().length > 1;
    const shouldPopulatedDefaultEndDate = this.shouldPopulatedDefaultEndDate();

    return (
      <DialogContent>
        <TimezoneProvider timezone={this.timezone}>
          <h3 className="title-card mb-3">{label}</h3>
          {selectedCampaigns.length === 1 ? (
            <h5 className={`title-card-subtitle ${moreThenOneTimezoneSelected ? 'mb-2' : 'mb-4'}`}>
              Enter the
              <span className="_black"> End Date </span>
              for the
              <span className="_black"> {selectedCampaigns[0].campaignName}</span> campaign.
            </h5>
          ) : (
            <h5 className={`title-card-subtitle ${moreThenOneTimezoneSelected ? 'mb-2' : 'mb-4'}`}>
              Enter the
              <span className="_black"> End Date </span>
              for the
              <span className="_black"> {selectedCampaigns.length}</span> campaigns.
            </h5>
          )}
          {moreThenOneTimezoneSelected && (
            <h5 className="title-card-subtitle mb-4">
              Selected campaigns have different timezones, the time displayed in
              <span className="_black"> {timezone && timezone.value}</span> timezone and would be
              converted to Campaign timezone when saved.
            </h5>
          )}
          <div className="mb-3 d-flex justify-content-center align-items-center mt-2">
            <EpochDatePicker
              dateFormat="MM/DD/YYYY hh:mm A"
              datePickerProps={{
                numberOfCalendars: 1,
              }}
              label="End Date and Time"
              withTimePicker={true}
              singleDateMode={true}
              singleDate={value}
              placeholder={!shouldPopulatedDefaultEndDate ? '(Optional)' : ''}
              onDateChanged={this.onDateChanged}
              defaultDate={shouldPopulatedDefaultEndDate ? this.getDefaultDate : null}
              calendarMinimumDate={moment()
                .add(15, 'm')
                .valueOf()}
              onError={this.datePickerErrorHandler}
              tooltipParams={{ label: 'Date and Time when the Campaign will end' }}
            />
            <div className="d-flex duplicate-dialog__timezone ml-3">
              <TimezonePicker onTimezoneChange={null} />
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center mt-2">
            <Button className="btn-square _conflower-blue mr-2 _md" onClick={onClose}>
              {' '}
              Cancel
            </Button>
            <Button
              className="btn-square _conflower-blue _filled _md"
              onClick={() => this.editHandler()}
              {...{ 'data-dd-action-name': dataDogLabelMap[action] || buttonLabel || 'Run' }}
            >
              {buttonLabel || 'Run'}
            </Button>
          </div>
        </TimezoneProvider>
        {changingStatus ? <Spinner status={changingStatus} onClose={onClose} /> : null}
      </DialogContent>
    );
  }
}

const mapState = (state: AppState) => ({
  selectedCampaigns: state.table.selectedTableCampaigns,
  timezone: state.filter.timezone,
  timezones: state.filter.timezones,
});

const mapActions = {
  openToast: toastActions.open,
  getStatuses: filterActions.getStatuses,
  restoreOrRunCampaigns: tableActions.restoreOrRunCampaigns,
  getStatisticsTotalStart: statisticsActions.getStatisticsTotalStart,
};

// @ts-ignore
export const RunningDialogContent = connect(mapState, mapActions)(RunningDialogContentComponent);
