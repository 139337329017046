import React from 'react';
import { IO_BUDGET_TYPE_ID } from 'iqm-framework';
import moment from 'moment';
import { Tooltip } from 'factor';

import { EM_DASH } from '../../../../../../../constants/text';
import {
  PercentFormat as PercentFormatRounded,
  formatNumberWithSuffix,
} from '../../../../../../../utils/format';
import { LambdaResponse } from '../../../../../../../models/Response';
import { CampaignType } from '../../../../../../../models/Campaign';
import { PacingProgressBar } from '../PacingProgressBar';
import { getPacingClassName, PercentFormat, compactCurrencyFormatter } from '../utils';
import { PacingInfoTooltip } from '../PacingInfoTooltip';
import styles from './styles.module.scss';

interface Props {
  campaign: LambdaResponse;
}

const CurrencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const MAX_UNABBREVIATED = 100000;
const SHOW_DASH_FOR_STATUSES = ['draft', 'pending', 'deleted', 'rejected'];

export const DailyPacingCell = (props: Props) => {
  const {
    dailyPacingPercentage = null,
    actualDailySpent = null,
    expectedDailySpent = null,
    budgetDay,
    dailyImpression,
    status,
    budgetTypeId,
    startTime,
    endTime,
    dailyCampaignDuration,
    dailyRemainingDuration,
    campaignTimezone,
    campaignTypeId,
  } = props.campaign || {};

  const statusStr = (status || '').toLowerCase();
  const emptyCell = <div className={styles.emptyCell}>{EM_DASH}</div>;

  if (campaignTypeId === CampaignType.PG) {
    return (
      <Tooltip
        auto
        portal
        className="w-100"
        label="Daily Pacing is not applicable for PG campaigns."
      >
        {emptyCell}
      </Tooltip>
    );
  }

  if (
    typeof actualDailySpent !== 'number' ||
    typeof expectedDailySpent !== 'number' ||
    typeof dailyPacingPercentage !== 'number' ||
    SHOW_DASH_FOR_STATUSES.find((s) => s === statusStr) ||
    (startTime && moment.unix(+startTime).isAfter(moment()))
  ) {
    return emptyCell;
  }

  const progressBarColor = getPacingClassName((actualDailySpent / expectedDailySpent) * 100);

  const { dailySpent, formattedBudgetDay } =
    budgetTypeId === IO_BUDGET_TYPE_ID.IMPRESSIONS_BASED
      ? {
          dailySpent:
            actualDailySpent < MAX_UNABBREVIATED
              ? `${actualDailySpent} Imp${actualDailySpent === 1 ? '' : 's'}`
              : `${formatNumberWithSuffix(actualDailySpent, false, 2)} Imp${
                  actualDailySpent === 1 ? '' : 's'
                }`,
          formattedBudgetDay:
            typeof dailyImpression === 'number'
              ? dailyImpression < MAX_UNABBREVIATED
                ? `${dailyImpression} Imp${dailyImpression === 1 ? '' : 's'}`
                : `${formatNumberWithSuffix(dailyImpression, false, 2)} Imp${
                    dailyImpression === 1 ? '' : 's'
                  }`
              : EM_DASH,
        }
      : {
          dailySpent:
            actualDailySpent < 1000
              ? CurrencyFormat.format(actualDailySpent)
              : compactCurrencyFormatter.format(actualDailySpent),
          formattedBudgetDay:
            typeof budgetDay === 'number'
              ? budgetDay < 1000
                ? CurrencyFormat.format(budgetDay)
                : compactCurrencyFormatter.format(budgetDay)
              : EM_DASH,
        };

  return (
    <div className={styles.wrapper}>
      <PacingInfoTooltip
        budgetCellType="daily"
        pacingData={{
          totalDuration: dailyCampaignDuration,
          remainingDuration: dailyRemainingDuration,
          timezone: campaignTimezone,
          startTime: startTime,
          endTime: endTime,
          pacingPercentage: dailyPacingPercentage,
          expectedSpent: expectedDailySpent,
          budgetTypeId: budgetTypeId,
          actualSpent: actualDailySpent,
          budgetTotal: budgetDay,
          targetImpression: dailyImpression,
        }}
      >
        <div className={`${styles.row} ${styles.textRow}`}>
          <div className="mr-3">
            <span className={styles.demi}>{dailySpent}</span> /{formattedBudgetDay}
          </div>
          <div className={progressBarColor.text}>
            {dailyPacingPercentage > 999
              ? PercentFormatRounded.format(dailyPacingPercentage / 100)
              : PercentFormat.format(dailyPacingPercentage / 100)}
          </div>
        </div>
        <div className={styles.row}>
          <PacingProgressBar
            fillClassName={progressBarColor.background}
            progressPercent={dailyPacingPercentage}
          />
        </div>
      </PacingInfoTooltip>
    </div>
  );
};
