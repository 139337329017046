import axios, { Canceler } from 'axios';
import { getInstance, getInstanceWithTimestamps } from './index';
import { TableResponse } from '../models/Table';
import { ParamsDTO } from '../models/Params';
import { CampaignDTO, ListCampaign, UpdateCampaignsEndDate } from '../models/Campaign';
import { WithResponse, WithResponseTimestamps, WithRecordCount } from '../models/Response';

let cancel: Canceler;

export function getTableData(params: ParamsDTO): Promise<TableResponse> {
  if (cancel) {
    cancel('Canceled by the user');
  }
  return getInstance()
    .post('/campaigns/list', params, {
      cancelToken: new axios.CancelToken((c) => {
        cancel = c;
      }),
    })
    .then((response) => response.data)
    .catch((err) => {
      console.log('err', err);
      return Promise.reject(err);
    });
}

export function getCampaignDetails(id: number) {
  return getInstance()
    .get(`/campaigns/${id}`)
    .then((response) => response)
    .catch((err) => {
      console.log('err', err);
      return Promise.reject(err);
    });
}

export function updateCampaign(
  id: string | number,
  data: any,
): Promise<WithResponseTimestamps<{ message: string }>> {
  return getInstanceWithTimestamps()
    .patch(`/v3/cmp/campaign/update-name/${id}`, data)
    .then(({ data }: { data: WithResponseTimestamps<{ message: string }> }) => data)
    .catch((err) => {
      console.log('err', err);
      return Promise.reject(err);
    });
}

export function updateCampaignCompletely(
  id: string | number,
  campaignData: CampaignDTO,
  data: any,
) {
  return getInstance()
    .put(`/campaigns/${id}`, {
      ...campaignData,
      ...data,
    })
    .then((response) => response.data)
    .catch((err) => {
      console.log('err', err);
      return Promise.reject(err);
    });
}

export function updateCampaignBudget(reqBody: any) {
  return getInstanceWithTimestamps()
    .put('/v2/cmp/campaigns/update-budget', reqBody)
    .then((response) => response.data)
    .catch((err) => {
      console.log('err', err);
      return Promise.reject(err);
    });
}

export type CampaignCountParams = {
  sortType: 'asc' | 'desc';
  owIds: string;
  ioIds?: string;
};

type CampaignCountResponse = {
  order: number;
  status_key: string;
  status_label: string;
  status_count: number;
};

export function campaignsCount(
  params: CampaignCountParams,
  setCancelToken?: (c: Canceler) => void,
): Promise<CampaignCountResponse[]> {
  return getInstance()
    .post('/v3/cmp/campaigns/count', params, {
      cancelToken: new axios.CancelToken((c) => {
        if (setCancelToken) {
          setCancelToken(c);
        }
      }),
    })
    .then((response) => response.data.data || [])
    .catch((err) => {
      console.log('err', err);
      return Promise.reject(err);
    });
}

export function duplicateCampaigns(params: any) {
  return getInstanceWithTimestamps()
    .post('/v2/cmp/campaigns/duplicate', params)
    .then((response) => response.data)
    .catch((err) => {
      console.log('err', err);
      return Promise.reject(err);
    });
}

export function pauseCampaign(params: any) {
  return getInstance()
    .put(`v2/cmp/campaigns/update-status`, {
      ...params,
      status: 'paused',
    })
    .then((response) => response)
    .catch((err) => {
      console.log('err', err);
      return Promise.reject(err);
    });
}

export function deleteCampaign(reqBody: any) {
  return getInstanceWithTimestamps()
    .put('/v2/cmp/campaigns/update-status', reqBody)
    .then((response) => response)
    .catch((err) => {
      console.log('err', err);
      return Promise.reject(err);
    });
}

export function runCampaign(params: any) {
  return getInstanceWithTimestamps()
    .put(`v2/cmp/campaigns/update-status`, {
      ...params,
      status: 'running',
    })
    .then((response) => response)
    .catch((err) => {
      console.log('err', err);
      return Promise.reject(err);
    });
}

export function restoreCampaign(params: any) {
  return getInstanceWithTimestamps()
    .put(`v2/cmp/campaigns/update-status`, {
      ...params,
      status: 'draft',
    })
    .then((response) => response)
    .catch((err) => {
      console.log('err', err);
      return Promise.reject(err);
    });
}

export function updateEndTime(params: UpdateCampaignsEndDate) {
  return getInstanceWithTimestamps()
    .put('/v2/cmp/campaigns/update-end-date', params)
    .then((response) => response.data)
    .catch((err) => {
      console.log('Error while update End date for campaigns', err);
      return Promise.reject(err);
    });
}

interface GetCampaignsDropdownListParams {
  creativeTypeIds?: number[];
  owIds?: number[];
  status?: string[];
  searchField?: string;
  ioIdsList?: number[];
  pageNo: number;
}

let getCampaignsDropdownListCancel: Canceler;
export function cancelGetCampaignsDropdownList() {
  if (getCampaignsDropdownListCancel) {
    getCampaignsDropdownListCancel('Canceled by the user');
  }
}

export function getCampaignsDropdownList(
  params: GetCampaignsDropdownListParams,
  setCancelToken?: (ct: Canceler) => void,
): Promise<WithResponse<WithRecordCount<ListCampaign[]>>> {
  cancelGetCampaignsDropdownList();
  return getInstance()
    .post('/v3/cmp/basic/list', params, {
      cancelToken: new axios.CancelToken((c) => {
        if (setCancelToken) {
          setCancelToken(c);
        } else {
          getCampaignsDropdownListCancel = c;
        }
      }),
    })
    .then((response) => response.data)
    .catch((err) => {
      console.log('Error while loading Campaigns dropdown list', err);
      return Promise.reject(err);
    });
}

let getCampaignsSearchDropdownListCancel: Canceler;
export function cancelGetCampaignsSearchDropdownList() {
  if (getCampaignsSearchDropdownListCancel) {
    getCampaignsSearchDropdownListCancel('Canceled by the user');
  }
}

export function getCampaignsSearchDropdownList(params: GetCampaignsDropdownListParams) {
  cancelGetCampaignsSearchDropdownList();
  return getInstance()
    .post('/v3/cmp/basic/list', params, {
      cancelToken: new axios.CancelToken((c) => {
        getCampaignsSearchDropdownListCancel = c;
      }),
    })
    .then((response) => response.data)
    .catch((err) => {
      console.log('Error while loading Campaigns dropdown list', err);
      return Promise.reject(err);
    });
}
