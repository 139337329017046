import React from 'react';
import { Icon } from 'factor';

import styles from './styles.module.scss';

interface Props {
  name?: string;
  checked: boolean;
  onChange: (isChecked: boolean) => void;
  title: React.ReactNode;
  disabled?: boolean;
  className?: string;
}

export const Radio = (props: Props) => {
  const { className, name, checked, onChange, title, disabled } = props;

  const handleClick = () => {
    onChange(!checked);
  };

  return (
    <div
      className={`radio ${styles.radio} ${className} ${checked ? styles.checked : ''} ${
        disabled ? styles.disabled : ''
      }`}
      onClick={handleClick}
    >
      <input
        type="radio"
        name={name}
        style={{ display: 'none' }}
        checked={checked}
        onChange={() => {}}
      />
      <Icon className={styles.radioIcon} name={checked ? 'RadioChecked' : 'RadioUnchecked'} />
      <label>{title}</label>
    </div>
  );
};
