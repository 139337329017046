import React, { useLayoutEffect, useState, useRef } from 'react';
import capitalize from 'lodash/capitalize';
import reverse from 'lodash/reverse';
import { Icon } from 'factor';

import { GroupedCampaign, statusIconMap, creativeIconMap } from '../../models/Campaign';

import styles from './styles.module.scss';

interface Props {
  campaign: Partial<GroupedCampaign>;
  showOrganization: boolean;
  className?: string;
  searchField?: string;
}

const getHighlights = (str: string, searchField: string) => {
  const escaped = searchField
    .replaceAll(/[-[\]{}()+?.,\\^$|#]/g, (str) => `\\${str}`)
    .replaceAll('*', (str) => '.*');
  let parts: any[] = str.split(new RegExp(escaped, 'ig'));
  const matches = [...str.matchAll(new RegExp(escaped, 'ig'))];

  parts.forEach((part, index) => {
    if (index !== parts.length - 1) {
      parts[index] = (
        <>
          <span className={styles.highlightedPart}>{part}</span>
          <span className={`${styles.highlight} ${styles.highlightedPart}`}>
            {matches[index][0]}
          </span>
        </>
      );
    }
  });

  return parts;
};

export const SelectCampaignsWrapperOption = (props: Props) => {
  const { searchField, showOrganization, className, campaign } = props;
  const { creativeTypeId, id, name, status = '', organizationName } = campaign;
  const [isCampaignNameOverflowing, setIsCampaignNameOverflowing] = useState(false);
  const _this = useRef<any>(null);

  const renderName = () => {
    if (!name) {
      return '';
    }

    const withHighlights = searchField ? getHighlights(name, searchField) : name;
    if (isCampaignNameOverflowing) {
      const reversed = reverse(name.split('')).join('');
      const withHighlightsReversed = searchField
        ? getHighlights(reversed, reverse(searchField.split('')).join(''))
        : reversed;
      return (
        <div className={styles.withMiddleEllipses}>
          <span className={styles.campaignName}>{withHighlights}</span>
          <span aria-hidden="true" className={styles.ellipses}>
            ...
          </span>
          <span aria-hidden="true" className={styles.campaignName}>
            {withHighlightsReversed}
          </span>
        </div>
      );
    } else {
      return withHighlights;
    }
  };

  useLayoutEffect(() => {
    if (_this.current) {
      const nameNode = _this.current.querySelector(`.${styles.nameRow}`);
      const isOverflowing = nameNode.offsetWidth < nameNode.scrollWidth;
      if (isOverflowing) {
        setIsCampaignNameOverflowing(true);
      }
    }
  }, []);

  return (
    <div ref={_this} className={`${styles.option} ${className || ''}`}>
      <div className={styles.creativeType}>
        {creativeIconMap[`${creativeTypeId}`] ? (
          <Icon name={creativeIconMap[`${creativeTypeId}`] || ''} />
        ) : null}
      </div>
      <div className={styles.rows}>
        <div className={styles.nameRow}>{renderName()}</div>
        <div className={styles.infoRow}>
          <div className={styles.id}>
            <span className={`${styles.idLabel} ${styles.labelText}`}>ID</span>
            <span className={styles.idValue}>
              {searchField ? getHighlights(`${id}`, searchField) : id}
            </span>
          </div>

          <div className={styles.separator} />

          <div className={styles.status}>
            <Icon
              name={statusIconMap[(status || '').toLowerCase()] || ''}
              className={styles.labelIcon}
            />
            <span className={styles.statusValue}>
              {(status || '').toLowerCase() === 'draft_deleted' ? 'Deleted' : capitalize(status)}
            </span>
          </div>

          {showOrganization ? (
            <>
              <div className={styles.separator} />
              <div className={styles.organization}>
                <span className={`${styles.organizationLabel} ${styles.labelText}`}>
                  Organization
                </span>
                <span className={styles.organizationValue}>
                  {searchField
                    ? getHighlights(organizationName || '', searchField)
                    : organizationName}
                </span>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
