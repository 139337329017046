import { AuthSuccessResponse } from '../models/Auth';
import axios, { AxiosResponse } from 'axios';
import { IQM_LOGIN_URL } from '../config';

const LoginBaseUrl: string = IQM_LOGIN_URL;

type LoginData = {
  email: string;
  password: string;
};

export function login(data: LoginData): Promise<AuthSuccessResponse> {
  const { protocol } = window.document.location;
  return axios
    .post(`${protocol}//${LoginBaseUrl}/login`, data)
    .then((response: AxiosResponse<AuthSuccessResponse>) => response.data)
    .catch((err) => Promise.reject(err.response.data));
}
