import React from 'react';
import {
  percentFormatMetrics,
  integerFormatMetrics,
  currencyFormatMetrics,
} from '../../../../../../models/Report';
import { CurrencyFormat, formatNumberWithSuffix } from '../../../../../../utils/format';

import './customBarChartBarItem/styles.scss';

export default function CustomBarChartBarItem(props) {
  const { data, y, height, color, theme } = props.barItem;

  let { width } = props.barItem;

  const { onMouseEnter, onMouseMove, onMouseLeave } = props;

  if (!theme || !data) {
    return null;
  }

  const customHeight = 18;
  const { selectedMetric } = theme;
  let value: string | number = data.value - 100;
  if (!value) {
    width = 1;
  }

  if (percentFormatMetrics.includes(selectedMetric.value)) {
    value = `${(+value).toFixed(2)}%`;
  } else if (integerFormatMetrics.includes(selectedMetric.value)) {
    value = `${formatNumberWithSuffix(value)}`;
  } else if (currencyFormatMetrics.includes(selectedMetric.value)) {
    value = `${CurrencyFormat.format(value)}`;
  }

  return (
    <g
      className="custom-chart-bar-item"
      key={value}
      transform={`translate(0, ${y + (height - customHeight) / 2})`}
    >
      <rect
        strokeWidth="0"
        height={customHeight}
        width={width}
        rx={3}
        ry={3}
        fill={color}
        onMouseEnter={onMouseEnter}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      />
      <text
        x={width + 35}
        y={customHeight / 2}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontFamily: 'Avenir Next Demi, sans-serif',
          fontSize: '14px',
          fill: '#000000',
        }}
      >
        {value}
      </text>
    </g>
  );
}
