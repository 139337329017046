import React from 'react';
import { connect } from 'react-redux';
import { TableSortedOptions, ColumnOptions } from 'iqm-framework';

import { AppState } from '../../../../../store';
import { TableLevel } from '../../../../../store/filter/reducers';
import {
  IOPacingCol,
  Option,
  getAdvertiserColumnOptions,
  getColumns,
  getExchangesColumnOptions,
  getInsertionOrderColumnOptions,
  getWorkspaceColumnOptions,
  ivtClicksCol,
  ivtImpressionsCol,
  toGroupMapper,
  vldInsightsCostCol,
} from '../../../../../models/Option';

interface StateProps {
  tableLevel: Option;
  sortingColumns: any;
  sortingWorkspacesColumns: any;
  sortingAdvertisersColumns: any;
  sortingExchangesColumns: any;
  sortingInsertionOrdersColumns: any;
  isPlatformOwnerOrg: boolean;
  isWorkspaceOwnerOrg: boolean;
  isBetaUser: boolean;
  isVldEnabled: boolean;
  hadVldGenerated: boolean;
  isBidShadingEnabled: boolean;
}

interface OwnProps {}

interface Props extends StateProps, OwnProps {}

const addcols = [
  'workspaceId',
  'workspaceName',
  'campaignId',
  'campaignName',
  'ioId',
  'ioName',
  'ioEndTime',
  'campaignTypeId',
  'organizationName',
  'status',
  'creativesCount',
  'ioTotalBudget',
  ColumnOptions.ColumnStartDate.value,
  ColumnOptions.ColumnEndDate.value,
  'ioStartTime',
  'ioEndTime',
  ColumnOptions.ColumnBidShadingSavings.value,
  ColumnOptions.ColumnDailyBudget.value,
  ColumnOptions.ColumnTotalBudget.value,
  ColumnOptions.ColumnMaxBid.value,
  'pacingPercentage',
  'dailyPacingPercentage',
  ColumnOptions.ColumnTotalSpent.value,
  'percentageOfTotalSpent',
  ColumnOptions.ColumnImpressions.value,
  ivtImpressionsCol.value,
  ColumnOptions.ColumnClicks.value,
  ivtClicksCol.value,
  ColumnOptions.ColumnCtr.value,
  ColumnOptions.ColumnEcpm.value,
  ColumnOptions.ColumnEcpc.value,
  ColumnOptions.ColumnVcr.value,
  ColumnOptions.ColumnCpcv.value,
  ColumnOptions.ColumnHoursViewed.value,
  ColumnOptions.ColumnWinrate.value,
  ColumnOptions.ColumnMediaSpent.value,
  vldInsightsCostCol.value,
  ColumnOptions.ColumnDataCost.value,
  ColumnOptions.ColumnPreBidCost.value,
  ColumnOptions.ColumnReach.value,
  ColumnOptions.ColumnViewability.value,
  ColumnOptions.ColumnFrequency.value,
  'campaignTimezone',
  ColumnOptions.ColumnStartCount.value,
  ColumnOptions.ColumnFirstCount.value,
  ColumnOptions.ColumnMidCount.value,
  ColumnOptions.ColumnThirdCount.value,
  ColumnOptions.ColumnCompleteCount.value,
];

const addColsIO = [
  'ioId',
  'ioName',
  'organizationName',
  'ioStatusId',
  'campaignsCount',
  'ioStartTime',
  'ioEndTime',
  'ioTotalBudget',
  ColumnOptions.ColumnTotalSpent.value,
  IOPacingCol.value,
  ColumnOptions.ColumnImpressions.value,
  ivtImpressionsCol.value,
  ColumnOptions.ColumnClicks.value,
  ivtClicksCol.value,
  ColumnOptions.ColumnReach.value,
  ColumnOptions.ColumnFrequency.value,
  ColumnOptions.ColumnMediaSpent.value,
  vldInsightsCostCol.value,
  ColumnOptions.ColumnTotalAttributedConversion.value,
];

const TableSortedOptionsWrapperComponent = (props: Props) => {
  const {
    tableLevel,
    sortingWorkspacesColumns,
    sortingAdvertisersColumns,
    sortingExchangesColumns,
    sortingColumns,
    sortingInsertionOrdersColumns,
    isPlatformOwnerOrg,
    isWorkspaceOwnerOrg,
    isBetaUser,
    isVldEnabled,
    hadVldGenerated,
    isBidShadingEnabled,
  } = props;

  let selectedColumns;
  let transformColumnsOrder: any = undefined;
  let groupsMapper: any = undefined;
  switch (tableLevel.value) {
    case TableLevel.Workspaces:
      selectedColumns = sortingWorkspacesColumns;
      transformColumnsOrder = () => addcols;
      groupsMapper = toGroupMapper(
        getWorkspaceColumnOptions({
          withGroups: true,
        }),
      );
      break;
    case TableLevel.Advertisers:
      selectedColumns = sortingAdvertisersColumns;
      transformColumnsOrder = () => addcols;
      groupsMapper = toGroupMapper(
        getAdvertiserColumnOptions({
          withGroups: true,
          isIqmSuper: isPlatformOwnerOrg,
        }),
      );
      break;
    case TableLevel.Exchanges:
      transformColumnsOrder = () => getExchangesColumnOptions(false).map((col) => col.value);
      selectedColumns = sortingExchangesColumns;
      groupsMapper = toGroupMapper(getExchangesColumnOptions(true));
      break;
    case TableLevel.InsertionOrders:
      selectedColumns = sortingInsertionOrdersColumns;
      groupsMapper = toGroupMapper(
        getInsertionOrderColumnOptions({
          isPlatformOwnerOrg,
          isWorkspaceOwnerOrg,
          isVldEnabled,
          hadVldGenerated,
        }),
      );
      transformColumnsOrder = () => addColsIO;
      break;
    default:
      selectedColumns = sortingColumns;
      transformColumnsOrder = () => addcols;
      groupsMapper = toGroupMapper(
        getColumns({
          isPlatformOwnerOrg,
          isWorkspaceOwnerOrg,
          isBetaUser,
          isVldEnabled,
          hadVldGenerated,
          isBidShadingEnabled:
            !isPlatformOwnerOrg && !isWorkspaceOwnerOrg ? isBidShadingEnabled : true,
        }),
      );
      break;
  }

  return (
    <TableSortedOptions
      selectedColumns={selectedColumns}
      addcols={[]}
      transformColumnsOrder={transformColumnsOrder}
      columnsGroupMapper={groupsMapper}
    />
  );
};

const mapState = (state: AppState): StateProps => ({
  tableLevel: state.filter.tableLevel,
  sortingColumns: state.filter.sortingColumns,
  sortingWorkspacesColumns: state.filter.sortingWorkspacesColumns,
  sortingAdvertisersColumns: state.filter.sortingAdvertisersColumns,
  sortingExchangesColumns: state.filter.sortingExchangesColumns,
  sortingInsertionOrdersColumns: state.filter.sortingInsertionOrdersColumns,
  isPlatformOwnerOrg: state.auth.userData.isPlatformOwnerOrg,
  isWorkspaceOwnerOrg: state.auth.userData.isWorkspaceOwnerOrg,
  isBetaUser: state.auth.userData.isBetaUser,
  isVldEnabled: Boolean(state.auth.userData.isVldEnabled),
  hadVldGenerated: Boolean(state.auth.userData.hadVldGenerated),
  isBidShadingEnabled: Boolean(state.auth.userData.isBidShadingEnabled),
});

export const TableSortedOptionsWrapper = connect<StateProps, null, any, any>(
  mapState,
  null,
)(TableSortedOptionsWrapperComponent);
