import React from 'react';
import { IOBudgetTypeMapper } from 'iqm-framework';
import { BudgetLabelIcon } from '../../../../../../../../components/BudgetLabelIcon';
import styles from './styles.module.scss';

interface Props {
  budgetTypeId: number;
  value?: number;
}

export const BudgetCell = ({ budgetTypeId, value }: Props) => {
  const formatFn = IOBudgetTypeMapper[budgetTypeId]
    ? IOBudgetTypeMapper[budgetTypeId].format
    : (_value: any) => '—';
  const dataToRender = formatFn(value);

  return (
    <div className={styles.wrapper}>
      <BudgetLabelIcon budgetTypeId={budgetTypeId} />
      <div className={styles.value}>{dataToRender}</div>
    </div>
  );
};
