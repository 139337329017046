export const statisticsConstants = {
  GET_TOTAL__START: 'GET_TOTAL__START',
  GET_TOTAL_PREV__START: 'GET_TOTAL_PREV__START',
  GET_TOTAL__SUCCESS: 'GET_TOTAL__SUCCESS',
  GET_TOTAL_PREV__SUCCESS: 'GET_TOTAL_PREV__SUCCESS',
  GET_TOTAL_PREV__ERROR: 'GET_TOTAL_PREV__ERROR',
  GET_TOTAL__ERROR: 'GET_TOTAL__ERROR',
  CLEAR_TOTAL: 'CLEAR_TOTAL',
  CLEAR_LOADING_STATE: 'CLEAR_LOADING_STATE',
};
