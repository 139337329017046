import React, { useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { Dialog, Icon, Tooltip } from 'factor';
import { ChangeEndDate } from '../../components/ChangeEndDate';
import { AppState } from '../../../../../../../../store';
import { Campaign } from '../../../../../../../../models/Campaign';
import styles from './styles.module.scss';

interface Props {
  selectedCampaigns: Campaign[];
  closeBtnMenu: () => void;
  tooltip?: string;
  disabled?: boolean;
}

enum Actions {
  changeEndDate = 'end_date',
}

const SetEndDateButtonComponent = (props: Props) => {
  const { selectedCampaigns, closeBtnMenu, tooltip, disabled } = props;

  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const shouldAllowEndDateChange = useMemo(
    () =>
      selectedCampaigns.length &&
      !selectedCampaigns.some((campaign) => ['deleted', 'expired'].includes(campaign.status)),
    [selectedCampaigns],
  );

  const closeDialog = () => {
    setDialogIsOpen(false);
  };

  const actionHandler = () => {
    closeBtnMenu();
    setDialogIsOpen(true);
  };

  const value = selectedCampaigns.length === 1 ? +selectedCampaigns[0].endTime * 1000 : '';

  const dialogContent = (
    <ChangeEndDate
      label="End Date"
      value={value}
      action={Actions.changeEndDate}
      onClose={closeDialog}
    />
  );

  const dialog = (
    <Dialog open={dialogIsOpen} onClickOutside={closeDialog}>
      {dialogContent}
    </Dialog>
  );

  return (
    <React.Fragment>
      <Tooltip label={tooltip || 'Change the End Date and Time for the selected Campaigns'}>
        <button
          className={`btn-select__btn ${styles.button}`}
          onClick={actionHandler}
          disabled={disabled || !shouldAllowEndDateChange}
        >
          <i className="btn-select__icon">
            <Icon name="Calendar" />
          </i>
          Set End Date
        </button>
      </Tooltip>
      {createPortal(dialog, document.body)}
    </React.Fragment>
  );
};

const mapState = (state: AppState) => ({
  selectedCampaigns: state.table.selectedTableCampaigns,
});

export const SetEndDateButton = connect(mapState)(SetEndDateButtonComponent);
