import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Dialog, DialogHeader, DialogFooter, DialogContent, Icon, Spinner, Button } from 'factor';
import { TimezonePicker, TimezoneProvider, EpochDatePicker } from 'iqm-framework';
import { maxBy, get } from 'lodash';
import { LambdaResponse } from '../../../../../../../models/Response';
import { connect } from 'react-redux';
import { AppState } from '../../../../../../../store';
import { OptionID } from '../../../../../../../models/Option';
import { ChangingStatus } from '../../../../../../../models/LoadingStatus';
import {
  UpdateInsertionOrdersEndTime,
  tableActions,
} from '../../../../../../../store/table/actions';
import { TableComponentInstance } from '../../../Table';
import { PARSE_TIME_FORMAT } from '../../../../../../../constants/time';
import styles from './styles.module.scss';
import sharedStyles from '../shared.module.scss';
import { Open, toastActions } from '../../../../../../../store/toast/actions';

interface Props extends UpdateInsertionOrdersEndTime {
  onClose: () => void;
  selectedInsertionOrders: LambdaResponse[];
  timezones: OptionID[];
  timezone: OptionID;
  openToast: Open['open'];
}

const endOfToday = (tz) =>
  moment(
    moment()
      .tz(tz)
      .endOf('day')
      .format(PARSE_TIME_FORMAT),
    PARSE_TIME_FORMAT,
  );

const END_DATE_CORRECTION =
  'The end date is automatically corrected to ensure it is 30 minutes after the start time';

const SetInsertionOrderEndDateDialogComponent = (props: Props) => {
  const {
    onClose,
    selectedInsertionOrders,
    timezones,
    timezone,
    updateInsertionOrdersEndTime,
    openToast,
  } = props;
  const [updating, setUpdating] = useState<ChangingStatus | null>(null);
  const [selectedTimezone, setSelectedTimezone] = useState<OptionID | null>(null);
  const [endTime, setEndTime] = useState<number | null>(null);
  const [isMounted, setIsMounted] = useState(false);
  const [nextEndTime, setNextEndTime] = useState<number | null>(null);

  useEffect(() => {
    let tz = timezone;
    if (
      selectedInsertionOrders.every((io) => io.ioTimezone === selectedInsertionOrders[0].ioTimezone)
    ) {
      tz = timezones.find((tz) => tz.id === selectedInsertionOrders[0].ioTimezone) || tz;
    }
    setSelectedTimezone(tz);

    if (tz && selectedInsertionOrders.length === 1 && selectedInsertionOrders[0].ioEndTime) {
      setEndTime(selectedInsertionOrders[0].ioEndTime);
    }

    setIsMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (nextEndTime !== endTime) {
      setEndTime(nextEndTime);
    }
  }, [nextEndTime, endTime]);

  const update = async () => {
    if (!endTime || !selectedTimezone) {
      return;
    }
    try {
      setUpdating('loading');
      await updateInsertionOrdersEndTime({
        ioEndTime: endTime,
        ioTimezoneId: selectedTimezone.id,
      });
      const ioIds = new Set(selectedInsertionOrders.map((io) => io.ioId));
      if (TableComponentInstance && TableComponentInstance.state.data) {
        TableComponentInstance.setState({
          data: TableComponentInstance.state.data.map((io) => {
            if (ioIds.has(io.ioId)) {
              return {
                ...io,
                ioEndTime: endTime,
              };
            }
            return io;
          }),
        });
      }
      setUpdating('success');
    } catch (err) {
      setUpdating('fail');
    }
  };

  const maxIoStartDate = useMemo(
    () =>
      get(
        maxBy(selectedInsertionOrders || [], (io) => io.ioStartTime || 0),
        'ioStartTime',
      ),
    [selectedInsertionOrders],
  );

  const handleSelectEndTime = (endTime: number | null) => {
    const minEndTime = moment(maxIoStartDate).add(30, 'minute');

    if (endTime && maxIoStartDate && moment(endTime).isBefore(minEndTime)) {
      openToast(END_DATE_CORRECTION);

      // Set endTime to null and reset on re-render
      // to fix bug where EpochDatePicker displays wrong selected date
      setNextEndTime(minEndTime.valueOf());
      setEndTime(null);
    } else {
      setNextEndTime(endTime);
      setEndTime(null);
    }
  };

  const preselected = [{ key: 'today', title: 'Today', date: (tz) => endOfToday(tz) }];

  return (
    <Dialog className={`${styles.dialog} ${sharedStyles.dialog}`} open onClickOutside={onClose}>
      <DialogHeader headerFooterBorders>
        <h3 className="title-card">Set End Date</h3>
      </DialogHeader>
      <DialogContent>
        <div className={styles.dialogContent}>
          {!updating && (
            <>
              <div className={`${sharedStyles.subtitle} title-card-subtitle`}>
                Enter the <span className={sharedStyles.bold}>End Date</span> for the{' '}
                <span className={styles.bold}>
                  {selectedInsertionOrders.length === 1
                    ? selectedInsertionOrders[0].ioName
                    : selectedInsertionOrders.length}
                </span>{' '}
                insertion order{selectedInsertionOrders.length === 1 ? '' : 's'}.
              </div>
              {isMounted && (
                <TimezoneProvider timezone={selectedTimezone || undefined} disableSelectOnLoad>
                  <div className={styles.inputs}>
                    <div className={styles.datePickerWrapper}>
                      <EpochDatePicker
                        label="End Date"
                        placeholder="Select End Date"
                        dateFormat="MM/DD/YYYY hh:mm A"
                        className={styles.datePicker}
                        withTimePicker
                        singleDateMode
                        datePickerProps={{
                          minimumDate: moment()
                            .add(15, 'm')
                            .valueOf(),
                        }}
                        singleDatePlaceholder="Select End Date"
                        removeSafariSelectionIssue
                        onDateChanged={handleSelectEndTime}
                        singleDate={endTime}
                        usePortal
                        insidePreselectors
                        preselected={preselected}
                      />
                    </div>
                    <TimezonePicker
                      selectedTimezone={selectedTimezone}
                      onTimezoneChange={null}
                      placeholder="Select Timezone"
                      label="Timezone"
                      clearSearchOnToggleOpen
                      className={`${styles.timezonePicker}  ${
                        selectedTimezone ? '' : styles.debugPlaceholder
                      }`}
                      disableSelectOnLoad
                      disabled
                    />
                  </div>
                </TimezoneProvider>
              )}
              <div className={sharedStyles.alert}>
                <Icon name="Info" className={sharedStyles.infoIcon} />
                <div className={styles.alertText}>
                  Please note that updating the end date may impact the campaigns below. When the IO
                  end date is reached, all campaigns will stop spending
                </div>
              </div>
            </>
          )}
        </div>
        {updating ? (
          <div className="ml-auto mr-auto">
            <Spinner status={updating} onClose={onClose} />
          </div>
        ) : null}
      </DialogContent>
      <DialogFooter headerFooterBorders>
        <div className="d-flex justify-content-end">
          <Button
            className="_md btn-square _conflower-blue mr-3"
            disabled={!!updating}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            className="_md btn-square _conflower-blue _filled"
            disabled={!endTime || !selectedTimezone || !!updating}
            onClick={update}
          >
            Set
          </Button>
        </div>
      </DialogFooter>
    </Dialog>
  );
};

const mapState = (state: AppState) => ({
  selectedInsertionOrders: state.table.selectedTableInsertionOrders,
  timezones: state.filter.timezones,
  timezone: state.filter.timezone,
});

const mapAction = {
  updateInsertionOrdersEndTime: tableActions.updateInsertionOrdersEndTime,
  openToast: toastActions.open,
};

export const SetInsertionOrderEndDateDialog = connect<any, any, any, any>(
  mapState,
  mapAction,
)(SetInsertionOrderEndDateDialogComponent);
