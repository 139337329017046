import { GetGraphDataParameters } from './lambdaV3API/GraphData';
import {
  GetCampaignReportDataParameters,
  GetReportDataParameters,
} from './lambdaV3API/ReportsGraphData';

export const getGraphDataKeys = {
  keys: (args: { scope: 'getGraphData'; tableLevel: string } & GetGraphDataParameters) => [args],
};

export const getReportDataKeys = {
  keys: (args: { scope: 'getReportData'; dimension: string } & GetReportDataParameters) => [args],
};

export const getCampaignReportDataKeys = {
  keys: (
    args: {
      scope: 'getCampaignReportData';
      dimension: string;
    } & GetCampaignReportDataParameters,
  ) => [args],
};
