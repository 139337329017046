import React from 'react';
import './chartTooltip/style.scss';

interface Props {
  label: string;
  value1?: string;
  value2?: string;
  coordX: number;
  coordY: number;
  withDots?: boolean;
}

export const ChartTooltip = (props: Props) => {
  const { label, value1, value2, coordX, coordY, withDots } = props;

  return (
    <div
      className="tooltip tooltip__label vert-chart-tooltip"
      style={{ transform: `translate(${coordX}px, ${coordY}px)` }}
    >
      <div className="tooltip__label-inner">
        <p className="vert-chart-tooltip__text">{label}</p>
        <p className={`vert-chart-tooltip__text ${withDots ? '_dot _green' : ''}`}>{value1}</p>
        <p className={`vert-chart-tooltip__text ${withDots ? '_dot _blue' : ''}`}>{value2}</p>
      </div>
    </div>
  );
};
