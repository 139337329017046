export function promisifiedDebounce<F extends Function>(
  callback: F,
  delay: number,
  cancellar?: () => void,
) {
  let timer;

  return async (...args) => {
    return new Promise((resolve, reject) => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      if (cancellar) {
        cancellar();
      }

      timer = setTimeout(async () => {
        try {
          resolve(await callback(...args));
        } catch (err) {
          reject(err);
        }
      }, delay);
    });
  };
}
